import { useState } from 'react';
import { NumberInput } from '../inputs';
import { StepWrapper } from '../wrappers/step-wrapper';

interface StepBorrowAmountProps {
  borrowAmountState: number;
  onBackButton: () => void;
  onNextButton: (newBorrowAmount: number) => void;
}

const StepBorrowAmount = (props: StepBorrowAmountProps) => {
  const { borrowAmountState, onBackButton, onNextButton } = props;

  const [borrowAmount, setBorrowAmount] = useState<number>(borrowAmountState);

  const handleOnInputChange = (newValue: number) => setBorrowAmount(newValue);

  return (
    <StepWrapper
      nextButtonDisabled={!borrowAmount}
      onBackButton={onBackButton}
      onNextButton={() => onNextButton(borrowAmount)}
      showNavigationStepButtons
      title="How much do you want to borrow?"
    >
      <NumberInput
        numberType="amount"
        onChange={handleOnInputChange}
        placeholder="100,000"
        value={borrowAmount}
        variant="large"
        autofocus
      />
    </StepWrapper>
  );
};

export { StepBorrowAmount };
