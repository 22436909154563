import { IconProps, IconSvg } from './icon-props';

export const DesignPatternMain = (props: IconProps) => (
  <IconSvg
    height="245.912px"
    viewBox="0 0 329.812 245.912"
    width="329.812px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-921.325 -32.171)">
      <g>
        <g>
          <path
            d="M1209.931,90.837c-.421,8.58.929,14.505,4.01,17.612,2,2.018,4.75,2.927,8.415,2.778a6.984,6.984,0,0,0,5.013-2.7c3.782-4.368,5.881-13.814,4.991-22.463-.6-5.8-2.582-10.069-5.59-12.027a8.067,8.067,0,0,0-7.114-.721.941.941,0,0,1-.137.032C1219.146,73.406,1210.713,74.883,1209.931,90.837Z"
            fill="#fff"
          />
          <path
            d="M1222.622,72.821a7.494,7.494,0,0,1,4.147,1.217c3.009,1.958,4.994,6.229,5.591,12.027.89,8.649-1.209,18.1-4.991,22.463a6.984,6.984,0,0,1-5.013,2.7q-.387.015-.76.015c-3.287,0-5.794-.917-7.655-2.793-3.081-3.107-4.43-9.032-4.01-17.612.782-15.954,9.215-17.431,9.588-17.488a.941.941,0,0,0,.137-.032,9.558,9.558,0,0,1,2.966-.5m0-1.624a11.2,11.2,0,0,0-3.413.557c-.979.18-10.081,2.3-10.9,19-.444,9.054,1.063,15.391,4.479,18.835,2.185,2.2,5.066,3.273,8.808,3.273.271,0,.545,0,.826-.017a8.529,8.529,0,0,0,6.174-3.258c4.114-4.75,6.326-14.493,5.379-23.692-.648-6.3-2.892-10.992-6.32-13.222a9.075,9.075,0,0,0-5.033-1.48Z"
            fill="#fff"
          />
        </g>
        <path
          d="M1153.909,61.844s-3.543-4.872-6.2-6.2-29.008-5.757-37.423-6.421a16.819,16.819,0,0,0-13.508,5.536L1095.007,83.1c-3.322,0-5.276,4.767-3.1,11.293,2.214,6.643,10.186,9.965,10.186,9.965l.885,35.208c2.215,3.765,11.072,16.608,27.237,16.387a18.788,18.788,0,0,0,11.065-3.383c1.056-8.937,1.728-16.226,1.615-19.619-.439-13.153-.224-32.177-.222-32.367a.829.829,0,0,1,.829-.82h.01a.83.83,0,0,1,.819.839c0,.19-.216,19.177.221,32.293.109,3.265-.472,9.917-1.414,18.122a20.033,20.033,0,0,0,5.457-10.344l-.443-14.837s5.093-5.093,6.2-25.244A317.853,317.853,0,0,0,1153.909,61.844Z"
          fill="#fff"
        />
        <g>
          <g>
            <g>
              <path
                d="M1179.71,98.888c.373-7.2,2.825-14.694,7.5-18.15L1149.36,77.35c-1.023.217-8.409,2.03-9.862,9.291-1.573,7.867,1.235,12.2,3.505,13.195,1.995.872,28.5,12.5,41.6,18.251C1179.033,111.441,1179.675,99.462,1179.71,98.888Z"
                fill="#48185c"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            d="M1193.9,80.382a15.212,15.212,0,0,0-1.805.111c-6.794.808-10.284,9.866-10.73,18.484v.008a39.807,39.807,0,0,0,1.064,10.76c1.777,6.945,5.4,10.629,10.78,10.949a7.778,7.778,0,0,0,6.9-3.246c5.912-7.429,5.445-24.889,4.214-29.075C1202.807,83.224,1199.057,80.382,1193.9,80.382Z"
            fill="#fff"
          />
          <path
            d="M1193.9,80.382c5.157,0,8.907,2.842,10.422,7.991,1.231,4.186,1.7,21.646-4.213,29.075a7.791,7.791,0,0,1-6.348,3.262c-.182,0-.366,0-.552-.016-5.376-.32-9-4-10.78-10.949a39.807,39.807,0,0,1-1.064-10.76v-.008c.446-8.618,3.936-17.676,10.73-18.484a15.212,15.212,0,0,1,1.805-.111m0-1.624a16.894,16.894,0,0,0-2,.123c-7.729.919-11.676,10.709-12.158,20.006a41.176,41.176,0,0,0,1.112,11.261c2.456,9.6,8.021,11.915,12.256,12.167.219.013.436.019.649.019a9.428,9.428,0,0,0,7.618-3.875c6.354-7.983,5.831-26.02,4.5-30.544-1.712-5.819-6.078-9.157-11.98-9.157Z"
            fill="#fff"
          />
        </g>
        <path
          d="M1136.416,37.042s-10.629,1.993-26.794.222-20.594,5.093-19.708,16.608,3.986,29.673,3.986,29.673,5.757-.443,8.414,3.764,5.979,10.629,7.972,10.408.443-23.03.221-30.337,1.108-12.18,6.422-11.072,11.515,8.414,23.694,8.636,24.58-.443,29.008-15.722c0,0-5.535,1.107-8.414-.443a9.884,9.884,0,0,0,2.214-10.851,14.278,14.278,0,0,1-8.414,2.657s3.321-4.428.442-8.414C1155.459,32.171,1150.588,35.935,1136.416,37.042Z"
          fill="#38e6bf"
        />
        <path
          d="M1130.88,73.026s-1.827-1.993-5.647-1-7.1,2.649-4.982,4.152c1.328.941,2.657-1.163,5.812-1.5S1130.88,74.272,1130.88,73.026Z"
          fill="#48185c"
        />
        <path
          d="M1142.173,73.192s4.816-1.162,7.64-.5,3.487-1.661.664-2.491S1138.685,70.535,1142.173,73.192Z"
          fill="#48185c"
        />
        <path
          d="M1132.677,80.234c-1.368,0-5.379.284-5.964,3.567-.679,3.82,1.978,5,4.322,6.034l.347.154c1.7.76,4.791,1.221,6.505,1.651-.009-2.112-1.032-5.159,2.365-9.95l-7.177-1.447C1133,80.239,1132.864,80.234,1132.677,80.234Z"
          fill="#48185c"
        />
        <g>
          <path d="M1082.163,178.1s9.522,16.165,20.151,27.68" fill="#38ddb6" />
          <path
            d="M1102.115,206.935a.831.831,0,0,1-.61-.266c-10.566-11.448-19.96-27.987-20.056-28.15a.829.829,0,0,1,1.429-.841c.095.161,9.4,16.551,19.846,27.866a.828.828,0,0,1-.609,1.391Z"
            fill="#fff"
          />
        </g>
        <g>
          <path d="M1170.517,193.378s8.193,14.836,13.065,21.258" fill="#38ddb6" />
          <path
            d="M1183.583,215.465a.83.83,0,0,1-.662-.328c-4.851-6.4-12.794-20.75-13.13-21.358a.829.829,0,1,1,1.452-.8c.081.148,8.22,14.858,13,21.158a.829.829,0,0,1-.66,1.33Z"
            fill="#fff"
          />
        </g>
        <g>
          <path d="M1055.757,216.667s10.854,12.432,17.327,17.234" fill="#38ddb6" />
          <path
            d="M1073.083,234.731a.822.822,0,0,1-.493-.164c-6.471-4.8-17.012-16.845-17.458-17.355a.829.829,0,0,1,1.249-1.091c.108.124,10.87,12.421,17.2,17.115a.829.829,0,0,1-.495,1.5Z"
            fill="#fff"
          />
        </g>
        <g>
          <g>
            <g>
              <ellipse
                cx="2.94"
                cy="4.872"
                fill="#48185c"
                rx="2.94"
                ry="4.872"
                transform="translate(1190.942 94.838)"
              />
            </g>
            <g>
              <path
                d="M1186.432,100.8a.83.83,0,0,1-.726-1.229c.107-.194,2.665-4.762,7.189-5.747,2.582-.562,5.239.157,7.9,2.136a.829.829,0,1,1-.989,1.33c-2.26-1.68-4.468-2.3-6.558-1.846-3.8.826-6.066,4.885-6.089,4.926A.828.828,0,0,1,1186.432,100.8Z"
                fill="#48185c"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                d="M1140.321,118.051a11.215,11.215,0,0,0,2.315-.234q0-.642-.007-1.274a10.228,10.228,0,0,1-2.654.26c-5.623-.152-8.983-3.987-10.4-6.073a39.594,39.594,0,0,0,13.044,1.009v-1.267a37.108,37.108,0,0,1-14.2-1.388.622.622,0,0,0-.759.855c.151.321,3.8,7.878,12.282,8.107C1140.069,118.05,1140.2,118.051,1140.321,118.051Z"
                fill="#48185c"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                d="M1140.254,128.449c.9-.107,1.728-.252,2.51-.421q-.014-.628-.024-1.265a25.821,25.821,0,0,1-2.634.452,25,25,0,0,1-18.116-5.164.622.622,0,0,0-.822.933,25.778,25.778,0,0,0,16.046,5.648A25.411,25.411,0,0,0,1140.254,128.449Z"
                fill="#48185c"
              />
            </g>
          </g>
        </g>
        <path
          d="M1199.676,159.217c2.569,4.933,5.787,11.986,8.482,18.091-.276-2.167-.515-4.083-.71-5.655-2.139-4.732-4.4-9.543-6.3-13.2-6.345-12.185-14.75-20.457-22.482-22.129a40.556,40.556,0,0,0-15.541-.24h-15.357v4.5a22.362,22.362,0,0,1-4.43,8.684q-.144,1.291-.3,2.639a24.217,24.217,0,0,0,6.37-11.052l.019-3.113h13.786l.176-.019a39.428,39.428,0,0,1,14.928.222C1185.461,139.488,1193.647,147.64,1199.676,159.217Z"
          fill="#fff"
        />
        <g>
          <path
            d="M1239.163,173.006c-3.087-11.771-13.4-50.442-16.006-60.193-.243.033-.486.06-.734.07q-.405.016-.8.017a11.963,11.963,0,0,1-8.859-3.283c-2.044-2.061-3.388-5.056-4.06-9.04l-2.112.656c-.445,6.263-1.89,13.106-5.186,17.248a12.037,12.037,0,0,1-1.16,1.25c.563,3.163,5.316,29.906,8.013,46.678a.817.817,0,0,1,.283.52c.011.091.286,2.39.738,6.022.287,1.914.511,3.518.653,4.705.33,2.745.577,4.808.764,6.36,1.554,11.827,3.667,26.866,5.363,35.1a.831.831,0,0,1-.645.98.879.879,0,0,1-.168.017.829.829,0,0,1-.811-.662c-1.454-7.06-3.21-19.072-4.662-29.808-4.2,2.635-16.207,10.455-21.1,16.8l-5.979,7.75s28.345,38.531,43.181,42.074,24.8-2.879,25.244-17.937S1242.706,186.513,1239.163,173.006Z"
            fill="#fff"
          />
        </g>
        <g>
          <g>
            <g>
              <ellipse
                cx="2.94"
                cy="4.872"
                fill="#48185c"
                rx="2.94"
                ry="4.872"
                transform="translate(1218.843 87.752)"
              />
            </g>
            <g>
              <path
                d="M1214.333,93.711a.817.817,0,0,1-.4-.1.829.829,0,0,1-.328-1.126c.107-.194,2.665-4.762,7.189-5.747,2.582-.563,5.239.156,7.9,2.136a.829.829,0,0,1-.99,1.33c-2.259-1.68-4.467-2.3-6.558-1.846-3.8.826-6.066,4.885-6.088,4.926A.831.831,0,0,1,1214.333,93.711Z"
                fill="#48185c"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              d="M1212.262,109.067c-3.108-3.58-4.415-9.579-3.987-18.311.824-16.8,9.91-18.859,10.93-19.036q.578-.185,1.148-.308l-56.424,1.282a14.883,14.883,0,0,0-7.307,1.993,3.771,3.771,0,0,0-1.362,1.526l34.622,3.1a9.59,9.59,0,0,1,2.017-.466c6.87-.815,12.1,2.57,14.014,9.059.848,2.887,1.365,11.27-.017,19.039Z"
              fill="#48185c"
            />
            <g>
              <path
                d="M1220.316,69.788l-11.648.265a10.155,10.155,0,0,1-2.993,1.692l14.678-.333q-.57.123-1.148.308c-1.02.177-10.106,2.235-10.931,19.036-.427,8.732.88,14.731,3.988,18.311l-6.367-2.123c1.383-7.769.866-16.152.017-19.039-1.721-5.851-6.146-9.179-12.036-9.179a16.805,16.805,0,0,0-1.977.12,9.59,9.59,0,0,0-2.017.466l-34.622-3.1a3.771,3.771,0,0,1,1.362-1.526,15.182,15.182,0,0,1,7.129-2c.117,0,.179,0,.179,0l17.842-.4a6.166,6.166,0,0,1,.642-1.639l-18.475.42c-.043,0-.106,0-.188,0a16.811,16.811,0,0,0-7.928,2.206,5.342,5.342,0,0,0-1.988,2.161l-1.189,2.176,2.469.221,34.623,3.1.348.031.33-.115a7.9,7.9,0,0,1,1.675-.387,15.181,15.181,0,0,1,1.785-.109c5.237,0,8.959,2.846,10.479,8.013.814,2.769,1.262,10.878-.058,18.3l-.246,1.381,1.331.444,6.367,2.122,5.631,1.877L1213.488,108c-2.823-3.252-4-8.867-3.592-17.166.762-15.535,8.687-17.36,9.588-17.517l.109-.019.106-.033c.333-.107.669-.2,1-.268Z"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <path
          d="M1209.544,68.045c-.08-.3-1.035-3.21-6.833-.621s-12.526,6.832-11.387,9.524,8.8-3.52,12.423-4.555S1209.989,69.705,1209.544,68.045Z"
          fill="#fff"
        />
        <path
          d="M1185.354,75.794s.221.775,2.435,0,7.086-5.892,12.013-8.082c4.983-2.215,7.806-2.658,6.81-4.208-1.761-2.739-6.422-.885-10.408,1.218S1182.364,73.8,1185.354,75.794Z"
          fill="#fff"
        />
        <path
          d="M1194.765,60.958c-.443.111-7.418,4.1-10.629,7.418s-2.658,5.979-1.772,5.757.665.333,3.765-2.989a40.7,40.7,0,0,1,11.57-7.916c2.546-1,3.155-2.6,2.27-3.377S1194.765,60.958,1194.765,60.958Z"
          fill="#fff"
        />
        <path
          d="M1183.195,63.283s-6.09,5.647-5.093,7.307,2.657-.885,4.982-2.768a96.238,96.238,0,0,1,8.082-5.978c1.108-.554.554-2.658-2.325-1.772A19.22,19.22,0,0,0,1183.195,63.283Z"
          fill="#fff"
        />
        <path
          d="M1171.9,80.915c-1.827-1-3.321.581-6.145,9.051a81.133,81.133,0,0,0-3.487,13.536s-1.412,1-.914-2.325,4.4-16.857,6.56-20.843c2.188-4.039-2.159-5.481-4.318-1.5a75.852,75.852,0,0,0-6.394,17.6c-1,5.315-1.079,5.564-1.827,5.066s1-8.138,2.741-13.619a62.849,62.849,0,0,1,4.816-10.214s.913-2.491-.581-3.072-4.9,3.82-6.228,6.975a137.72,137.72,0,0,0-4.9,15.529l-.5,2.657s-1.162-.249-.581-2.823,1.661-8.3,3.986-13.287a48.259,48.259,0,0,1,4.816-8.221s-.166-3.072-3.737-.083-5.812,7.474-8.054,12.705a45.5,45.5,0,0,0-3.02,12.023.817.817,0,0,1,.2.526c0,.19-.216,19.177.221,32.293.207,6.22-2.085,24.717-4.618,42.859-2.189,19.584-4.823,40.1-6.4,44.913-2.989,9.135-7.307,8.636-13.618,3.156s-17.383-18.49-17.383-18.49-9.19,2.38-19.487,13.009-11.736,15.113-11.736,15.113,13.618,12.18,22.587,20.151,38.641,25.521,51.595,21.7,24.247-7.639,23.583-35.208-2.159-79.884-2.657-90.347-1-12.29,0-16.442,2.989-8.47,1.827-15.445.918-24.135,2.159-28.4C1171.735,84.9,1173.728,81.911,1171.9,80.915Z"
          fill="#fff"
        />
        <rect
          fill="#fff"
          height="40.482"
          transform="matrix(0.096, -0.995, 0.995, 0.096, 1149.858, 77.346)"
          width="1.856"
        />
        <rect
          width="1.855"
          height="55.529"
          transform="matrix(0.387, -0.922, 0.922, 0.387, 1138.682, 100.073)"
          fill="#fff"
        />
        <path
          d="M1229.2,76.466a7.878,7.878,0,0,0-4.6-4.526c-2.246-.767-6.617-.505-6.661-.5l-.1-1.621c.192-.013,4.735-.286,7.288.586a9.527,9.527,0,0,1,5.584,5.454Z"
          fill="#fff"
        />
        <path
          d="M1185.8,278.083h1.661c-1.3-24.505-3.652-59.719-3.912-63.6,1.52-1.7,10.228-11.395,15.377-15.356a66.456,66.456,0,0,1,10.7-6.33l.542-.26q-.12-.865-.237-1.725l-1.01.483a66.9,66.9,0,0,0-11.009,6.518c-5.755,4.426-15.429,15.367-15.838,15.831l-.229.259.023.345C1181.9,214.68,1184.434,252.287,1185.8,278.083Z"
          fill="#fff"
        />
        <path
          d="M1060.976,223.216c.135,3.632,1.26,34.022,1.885,54.867h1.654c-.671-22-1.927-54.559-1.941-54.954l-.014-.36-.274-.236c-.126-.11-12.676-11.429-17.2-25.642-6.891-21.655-.35-42.934,9.662-50.231,11.863-8.645,42.632-7.967,46.831-7.83,4.718,8.848,15.465,17.8,28.716,17.8.649,0,1.306-.021,1.967-.066a16.951,16.951,0,0,0,8.915-3.134q.137-1.146.266-2.257a15.472,15.472,0,0,1-9.292,3.737c-13.618.9-24.747-8.313-29.322-17.265l-.22-.429-.482-.022c-1.43-.063-35.207-1.453-48.355,8.13-13.455,9.807-15.806,34.666-10.267,52.074C1047.854,211.055,1059.03,221.477,1060.976,223.216Z"
          fill="#fff"
        />
      </g>
      <g>
        <g>
          <g>
            <path
              d="M979.492,103.279a3.943,3.943,0,0,1-2.368,5.134l-.443.159a4,4,0,0,1-2.7-7.528l.443-.159A3.971,3.971,0,0,1,979.492,103.279Zm-.24-33.109c2.441,9.209-6.278,15.442-5.131,18.8.376,1.1.894,2.423.894,2.423a4,4,0,1,1-7.527,2.7,24.675,24.675,0,0,1-1.3-3.583c-1.738-5.669,6.209-11.231,5.315-16.758a6.628,6.628,0,0,0-8.454-4.98,6.914,6.914,0,0,0-4.159,4.133,3.967,3.967,0,1,1-7.5-2.593,14.785,14.785,0,0,1,8.955-9.067C968,58.5,977.152,62.246,979.252,70.17Z"
              fill="#48185c"
            />
            <path
              d="M975.321,109.687a4.885,4.885,0,0,1-1.636-9.467l.443-.158a4.874,4.874,0,0,1,3.292,9.174l-.444.16A4.9,4.9,0,0,1,975.321,109.687Zm.439-8.16a3.072,3.072,0,0,0-1.041.182l-.444.159a3.093,3.093,0,0,0-1.865,3.951,3.056,3.056,0,0,0,3.975,1.929l.443-.158a3.057,3.057,0,0,0,1.841-4.016h0a3.1,3.1,0,0,0-2.909-2.047Zm-4.492-3.874a4.91,4.91,0,0,1-4.585-3.215,27.219,27.219,0,0,1-1.334-3.67c-1.026-3.347.856-6.5,2.676-9.556,1.535-2.575,2.986-5.008,2.612-7.319a5.755,5.755,0,0,0-7.3-4.3,6.075,6.075,0,0,0-3.631,3.607,4.847,4.847,0,0,1-8.972.506,4.724,4.724,0,0,1-.168-3.7,15.576,15.576,0,0,1,9.479-9.587,16.487,16.487,0,0,1,13.022.892,13.689,13.689,0,0,1,7.026,8.632h0c1.592,6-1.263,10.79-3.347,14.283-1.135,1.9-2.114,3.543-1.8,4.458.37,1.083.879,2.382.881,2.387a4.861,4.861,0,0,1-2.952,6.3A4.772,4.772,0,0,1,971.268,97.653Zm-6.273-30.088a7.563,7.563,0,0,1,3.834,1.079,7.172,7.172,0,0,1,3.536,4.97c.475,2.939-1.209,5.764-2.837,8.5-1.7,2.849-3.3,5.541-2.505,8.146a24.728,24.728,0,0,0,1.244,3.439l.045.1a3.167,3.167,0,0,0,3.975,1.928,3.123,3.123,0,0,0,1.9-4.037s-.52-1.328-.9-2.436c-.574-1.682.542-3.552,1.955-5.92,2.032-3.407,4.561-7.646,3.159-12.939h0a11.963,11.963,0,0,0-6.149-7.531,14.706,14.706,0,0,0-11.617-.794,13.845,13.845,0,0,0-8.423,8.532,3,3,0,0,0,.1,2.357,3.145,3.145,0,0,0,1.764,1.6,3.1,3.1,0,0,0,3.978-1.941,7.746,7.746,0,0,1,4.69-4.67A6.663,6.663,0,0,1,964.995,67.565Z"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M1001.526,100.016l-.443.159a4.066,4.066,0,0,1-2.746-7.654l.443-.159a4.066,4.066,0,1,1,2.746,7.654ZM996.681,87.9a4.041,4.041,0,0,1-5.155-2.433L981.451,57.387a4.065,4.065,0,1,1,7.653-2.745l10.075,28.083A4.056,4.056,0,0,1,996.681,87.9Z"
              fill="#48185c"
            />
            <path
              d="M999.747,101.283a4.979,4.979,0,0,1-4.665-3.309,4.913,4.913,0,0,1,2.959-6.276l.444-.159a4.941,4.941,0,1,1,3.336,9.3l-.443.159A4.822,4.822,0,0,1,999.747,101.283Zm.433-8.29a3.274,3.274,0,0,0-1.1.193l-.443.159a3.191,3.191,0,0,0,2.156,6.006l.443-.158a3.2,3.2,0,0,0,1.947-4.123A3.17,3.17,0,0,0,1000.18,92.993Zm-4.855-3.977a4.915,4.915,0,0,1-4.623-3.25L980.628,57.682a4.94,4.94,0,1,1,9.3-3.336L1000,82.43a4.844,4.844,0,0,1-.2,3.756,4.974,4.974,0,0,1-2.83,2.542A4.9,4.9,0,0,1,995.325,89.016Zm-10.05-36.221a3.306,3.306,0,0,0-1.117.2,3.194,3.194,0,0,0-1.883,4.1l10.074,28.084a3.161,3.161,0,0,0,4.037,1.9h0a3.231,3.231,0,0,0,1.843-1.65,3.1,3.1,0,0,0,.126-2.409L988.281,54.937A3.185,3.185,0,0,0,985.275,52.795Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          d="M983.384,133.681a102.288,102.288,0,0,1-30.539-4.776C922.1,119.2,921.4,83.714,921.387,82.558c-1.135-18.183,13.508-40.166,30.141-45.244,17.254-5.266,49.512-5.879,67.212,9.246,8.537,7.295,14.114,18.982,15.7,32.909,1.438,12.6-.657,25.623-5.606,34.85C1022.2,126.7,1004.285,133.68,983.384,133.681ZM976.672,35.4A87.883,87.883,0,0,0,951.96,38.73c-16.064,4.9-30.2,26.168-29.1,43.755,0,.015,0,.031,0,.046,0,.354.37,35.48,30.423,44.962,15.014,4.737,31.047,5.927,45.147,3.349,14.2-2.6,24.533-8.712,29.1-17.223,4.812-8.971,6.845-21.674,5.439-33.982-1.548-13.554-6.945-24.9-15.2-31.952C1007.224,38.666,991.313,35.4,976.672,35.4Z"
          fill="#fff"
        />
      </g>
      <g>
        <path
          d="M1050.772,102.711a20.174,20.174,0,0,1-5.556-.8l-.058-.021a8.041,8.041,0,0,1-4.343-4.207,4.036,4.036,0,0,1,.172-3.328c1.418-2.736,3.389-4.717,8.4-5.493,3.5-.541,9.318,1.672,11.1,5.029a4.515,4.515,0,0,1-.947,5.451A11.616,11.616,0,0,1,1050.772,102.711Zm-5.083-2.2c.529.158,8.512,2.43,12.769-2.17,1.162-1.257,1.4-2.484.726-3.751-1.454-2.732-6.624-4.716-9.572-4.261-4.428.686-6.033,2.246-7.311,4.711a2.561,2.561,0,0,0-.1,2.137A6.521,6.521,0,0,0,1045.689,100.511Z"
          fill="#fff"
        />
      </g>
      <g>
        <path
          d="M1075.609,102.718a8.4,8.4,0,0,1-6.5-3.5.826.826,0,0,1-.084-.175,4.47,4.47,0,0,1,.257-4.034c1.376-2.039,4.832-2.431,7.963-2.638a5.07,5.07,0,0,1,5.364,3.779c.449,1.9-.274,4.432-3.646,5.85A8.609,8.609,0,0,1,1075.609,102.718Zm-5.211-4.24a6.469,6.469,0,0,0,7.991,2.155c2.14-.9,3.179-2.45,2.778-4.145a3.584,3.584,0,0,0-3.825-2.642c-2.986.2-5.88.575-6.834,1.989A2.993,2.993,0,0,0,1070.4,98.478Z"
          fill="#fff"
        />
      </g>
    </g>
  </IconSvg>
);
