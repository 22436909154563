import styled from 'styled-components';
import { LogoANZ, LogoING, LogoMacquarie, LogoNAB, LogoUBank, LogoWestpac } from '../assets/logos';
import { color } from '../constants/color';
import { deviceTypeBreakpoint } from '../constants/device-type-breakpoints';
import { fontFamily } from '../constants/font-family';
import { Box, Flex, Text } from './primitives';

const LendersBarContainer = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 80%;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    column-gap: 16px;
    justify-content: flex-start;
    row-gap: 16px;
  }
`;

const LendersBarOuterContainer = styled(Box)`
  background: ${color.greyLoop};
  padding: 12px 0;
`;

const LendersText = styled(Text)`
  font-family: ${fontFamily.latoBold};
  font-size: 17px;
`;

LendersText.defaultProps = {
  children: 'OVER 40 LENDERS'
};

const LendersBar = () => (
  <LendersBarOuterContainer>
    <LendersBarContainer>
      <LendersText />
      <LogoING />
      <LogoWestpac />
      <LogoANZ />
      <LogoMacquarie />
      <LogoUBank />
      <LogoNAB />
    </LendersBarContainer>
  </LendersBarOuterContainer>
);

export { LendersBar };
