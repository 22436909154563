import { IconProps, IconSvg } from '../icons/icon-props';

export const LogoANZ = (props: IconProps) => (
  <IconSvg
    height="23.681px"
    viewBox="0 0 74.992 23.681"
    width="74.992px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-780.505 -272.888)">
      <path
        d="M827.75,291.737h-5.725l2.349-3.093h-6.359l-2.267,2.978a5.25,5.25,0,0,0-.929,3.207v.469a.77.77,0,0,0,.834.836h12.1a.746.746,0,0,0,.817-.82v-2.755a.746.746,0,0,0-.817-.822m-32.587-10.928a.86.86,0,0,0-.9-.773h-1.618a4.7,4.7,0,0,0-4,2.241s-1.544,2.469-3.274,5.248h10.983Zm18.091.047a.746.746,0,0,0-.82-.82h-3.293a.746.746,0,0,0-.817.82V287.1l-3.121-5.611a3.206,3.206,0,0,0-2.9-1.45h-2.434a.745.745,0,0,0-.814.82v6.672h4.091l.63,1.116h-4.721v6.67a.749.749,0,0,0,.814.825h3.3a.746.746,0,0,0,.815-.825l0-6.28,3.151,5.657a3.21,3.21,0,0,0,2.889,1.448h2.411a.75.75,0,0,0,.82-.825v-6.67h-4.061l-.628-1.116h4.689v-6.672Zm-16.705,7.788h-5.424c.139.758,1.139,6.4,1.2,6.728a.835.835,0,0,0,.873.767h3.732a.767.767,0,0,0,.675-.3.786.786,0,0,0,.1-.644l-1.158-6.548Zm-15.925,6.485a.728.728,0,0,0-.057.691.646.646,0,0,0,.585.319h3.876a1.38,1.38,0,0,0,1.169-.658l4.277-6.837h-5.8c-1.991,3.192-4,6.411-4.048,6.485m35.137-10.7h5.454l-2.352,3.1h6.358l2.319-3.051a3.986,3.986,0,0,0,.882-2.534v-1.062a.769.769,0,0,0-.831-.842h-11.83a.743.743,0,0,0-.814.82v2.755a.742.742,0,0,0,.814.817"
        fill="#007dba"
      />
      <path
        d="M842.848,279.815a2.433,2.433,0,0,1,2.381,2.3,3.232,3.232,0,0,1-.593,1.864,3.534,3.534,0,0,0-.47,1.009c-.076.319-.244,1.089.7,1.1a5.1,5.1,0,0,0,3.339-1.987,6.853,6.853,0,1,0-10.718,0,5.106,5.106,0,0,0,3.34,1.987c.942-.013.771-.783.7-1.1a3.43,3.43,0,0,0-.47-1.009,3.24,3.24,0,0,1-.593-1.864,2.437,2.437,0,0,1,2.388-2.3m12.117,6.982a3.409,3.409,0,0,0-1.784-.728,6.725,6.725,0,0,0-3.41.419,7.47,7.47,0,0,0-3.056,2.445,6.272,6.272,0,0,0-.909,5.013,4.512,4.512,0,0,0,.461,1.231,2.884,2.884,0,0,0,1.567,1.371,1.108,1.108,0,0,0,1.041-.386c1.225-1.419,5.87-6.918,6.373-7.62a1.235,1.235,0,0,0-.283-1.745m-19.044-.309a6.718,6.718,0,0,0-3.408-.419,3.4,3.4,0,0,0-1.786.728,1.234,1.234,0,0,0-.283,1.745c.5.7,5.145,6.2,6.37,7.62a1.108,1.108,0,0,0,1.042.386,2.862,2.862,0,0,0,1.566-1.371,4.48,4.48,0,0,0,.465-1.231,6.3,6.3,0,0,0-.909-5.013,7.479,7.479,0,0,0-3.057-2.445"
        fill="#007dba"
      />
    </g>
  </IconSvg>
);
