import styled from 'styled-components';
import { flexbox, FlexboxProps } from 'styled-system';
import { Box } from './box';
import { columnGap, GapProps, rowGap } from './custom-css-props';

type CustomFlexProps = FlexboxProps & GapProps;

const Flex = styled(Box)<CustomFlexProps>`
  display: flex;

  ${columnGap}
  ${flexbox}
  ${rowGap}
`;

Flex.displayName = 'Flex';

export { Flex };

