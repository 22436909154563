import styled from 'styled-components';
import { color } from '../../constants/color';
import { fontSize } from '../../constants/font-size';
import { cursor, CursorProps } from './custom-css-props';

const Input = styled.input.attrs({
  type: 'text'
})<CursorProps>`
  background: ${color.whiteLoop};
  border: none;
  color: ${color.purpleLoop};
  font-size: ${fontSize.s};
  outline: none;
  width: 100%;

  ${cursor}
`;

Input.displayName = 'Input';

export { Input };
