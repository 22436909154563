import styled from 'styled-components';
import { color } from '../constants/color';
import { fontFamily } from '../constants/font-family';
import { fontSize } from '../constants/font-size';
import { IconWithLabelModel } from '../types/icon-with-label';
import { Text } from './primitives';

const IconWithLabelContainer = styled('button')`
  align-items: center;
  border: 2px solid ${color.purpleLoop};
  flex-direction: column;
  height: 272px;
  padding-bottom: 40px;
  padding-top: 44px;
  width: 270px;
  cursor: pointer;
  background: ${color.whiteLoop};
  :hover {
    box-shadow: 12px 12px 15px 0 rgb(10 31 68 / 28%);
  }
  :focus {
    outline: 1px solid;
  }
`;

interface LabelProps {
  marginTopVariant: 'large' | 'small';
}

const Label = styled(Text)<LabelProps>`
  font-family: ${fontFamily.desolator};
  font-size: ${fontSize.m};
  margin-top: ${({ marginTopVariant }) => (marginTopVariant === 'large' ? 42 : 24)}px;
`;

const IconWithLabel = (props: IconWithLabelModel) => {
  const { Icon, label, marginTopVariant, onClick } = props;

  return (
    <IconWithLabelContainer onClick={onClick}>
      <Icon />
      <Label marginTopVariant={marginTopVariant}>{label}</Label>
    </IconWithLabelContainer>
  );
};

export { IconWithLabel };
