import styled from 'styled-components';
import { grid, GridProps } from 'styled-system';
import { Box } from './box';
import { columnGap, GapProps, rowGap } from './custom-css-props';

type CustomGridProps = GapProps & GridProps;

const Grid = styled(Box)<CustomGridProps>`
  display: grid;

  ${columnGap}
  ${grid}
  ${rowGap}
`;

Grid.displayName = 'Grid';

export { Grid };
