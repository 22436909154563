import { IconProps, IconSvg } from '../icons/icon-props';

export const LogoListingLoop = (props: IconProps) => (
  <IconSvg
    height="40px"
    viewBox="0 0 235 40"
    width="232px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.54,24.13h2.97v-7.26h-2.97c-5.92,0-10.73-4.81-10.73-10.73V2.27h-7.26v3.87c0,9.92,8.07,17.98,17.98,17.98Z"
      fill="#fff"
    />
    <path
      d="M22.92,38.73v-3.87c0-9.92-8.07-17.98-17.98-17.98H1.97v7.26h2.97c5.92,0,10.73,4.81,10.73,10.73v3.87h7.26Z"
      fill="#fff"
    />
    <g>
      <rect fill="#fff" height="17.58" width="3.33" x="47.03" y="11.18" />
      <path
        d="M82.28,14.93c-1.54,0-3.98,.58-6.02,1.87v-.84h-3.33v12.81h3.33v-6.78c0-2.11,4.01-3.72,6.02-3.72,1.35,0,4.49,.22,4.49,2.3v8.19h3.33v-8.19c0-3.48-3-5.64-7.83-5.64Z"
        fill="#fff"
      />
      <path
        d="M106.23,16.62c-1.61-1.15-3.54-1.74-5.74-1.74-6.59,0-8.72,4.54-8.73,6.73-.03,2.43,.65,4.37,2.03,5.77,1.45,1.47,3.57,2.22,6.28,2.22,2.46,0,4.53-.49,6.15-1.45v.6h3.33V11.18h-3.33v5.44Zm-11.13,5.04v-.12s.36-3.32,5.39-3.32c4.05,0,5.41,2.71,5.74,3.58v1.6c-.18,.52-1.27,2.87-6.15,2.87-1.79,0-3.11-.41-3.91-1.23-1.1-1.11-1.07-2.82-1.07-3.38Z"
        fill="#fff"
      />
      <rect fill="#fff" height="2.66" width="3.33" x="112.55" y="11.18" />
      <rect fill="#fff" height="12.81" width="3.33" x="112.55" y="15.95" />
      <path
        d="M128.11,14.93c-1.54,0-3.98,.58-6.02,1.87v-.84h-3.33v12.81h3.33v-6.78c0-2.11,4.01-3.72,6.02-3.72,1.35,0,4.49,.22,4.49,2.3v8.19h3.33v-8.19c0-3.48-3-5.64-7.82-5.64Z"
        fill="#fff"
      />
      <path
        d="M151.68,16.7c-1.63-1.2-3.6-1.81-5.84-1.81-6.59,0-8.72,4.54-8.73,6.73-.03,2.43,.65,4.37,2.03,5.77,1.45,1.47,3.57,2.22,6.28,2.22,2.51,0,4.62-.51,6.26-1.51v.54c0,1.49-2.56,2.57-6.09,2.57-3.15,0-5.21-.59-5.26-1.5v-.12s-3.33,.15-3.33,.15v.12c.15,3.1,3.04,4.68,8.59,4.68s9.42-2.32,9.42-5.9V15.95h-3.33v.75Zm0,6.47h-.04s-.03,.11-.03,.11c-.03,.12-.76,2.99-6.19,2.99-1.79,0-3.11-.41-3.91-1.23-1.1-1.11-1.07-2.82-1.06-3.38v-.12s.36-3.32,5.39-3.32c4.6,0,5.76,3.61,5.8,3.76l.04,.11h0s0,1.08,0,1.08Z"
        fill="#fff"
      />
      <rect fill="#fff" height="17.58" width="3.33" x="169.71" y="11.18" />
      <path
        d="M184.09,14.97c-5.44,0-9.69,3.24-9.69,7.38s4.26,7.38,9.69,7.38,9.69-3.24,9.69-7.38-4.26-7.38-9.69-7.38Zm0,11.44c-3.45,0-6.36-1.86-6.36-4.05s2.91-4.05,6.36-4.05,6.36,1.86,6.36,4.05-2.91,4.05-6.36,4.05Z"
        fill="#fff"
      />
      <path
        d="M204.15,14.97c-5.44,0-9.69,3.24-9.69,7.38s4.26,7.38,9.69,7.38,9.69-3.24,9.69-7.38-4.26-7.38-9.69-7.38Zm0,11.44c-3.45,0-6.36-1.86-6.36-4.05s2.91-4.05,6.36-4.05,6.36,1.86,6.36,4.05-2.91,4.05-6.36,4.05Z"
        fill="#fff"
      />
      <path
        d="M230.98,17.21c-1.45-1.47-3.57-2.22-6.28-2.22-2.46,0-4.53,.49-6.15,1.45v-.5h-3.33v17.6h3.33v-5.57c1.61,1.15,3.54,1.74,5.74,1.74,6.59,0,8.72-4.53,8.73-6.73,.03-2.43-.65-4.37-2.03-5.76Zm-12.44,3.98c.18-.52,1.27-2.87,6.15-2.87,1.79,0,3.11,.41,3.91,1.23,1.1,1.11,1.07,2.82,1.07,3.38v.12s-.36,3.32-5.39,3.32c-4.04,0-5.41-2.71-5.74-3.58v-1.6Z"
        fill="#fff"
      />
      <path
        d="M67.46,24.56c-.81,.77-2.54,1.9-5.63,1.9s-5.39-1.08-6.07-2.9h13.79l1.61-1.67v-.05c-.08-2.4-2.04-6.95-8.86-6.95-9.08,0-10.06,5.32-10.06,7.61,0,3.51,3,7.3,9.59,7.3,4.73,0,7.3-2.09,8.42-3.35l-2.79-1.88Zm-5.16-6.34c2.88,0,4.25,1.05,4.91,2.01h-10.97c.97-1.33,3.01-2.01,6.06-2.01Z"
        fill="#fff"
      />
    </g>
  </IconSvg>
);
