import { DetailedHTMLProps, SVGProps } from 'react';
import styled from 'styled-components';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps
} from 'styled-system';
import {
  svgColor,
  SvgColorProps,
  transform,
  TransformProps,
  visibility,
  VisibilityProps
} from '../../components/primitives/custom-css-props';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

type ReactSvgProps = DetailedHTMLProps<SVGProps<SVGElement>, SVGElement>;

export type IconProps = Omit<ReactSvgProps, 'ref'> &
  ColorProps &
  LayoutProps &
  SpaceProps &
  SvgColorProps &
  TransformProps &
  TypographyProps &
  VisibilityProps;

export const IconSvg = styled.svg<IconProps>`
  ${color}
  ${layout}
  ${space}
  ${svgColor}
  ${transform}
  ${typography}
  ${visibility}
`;

IconSvg.displayName = 'IconSvg';

IconSvg.defaultProps = {
  height: '1em',
  width: '1em'
};
