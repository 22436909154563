import styled from 'styled-components';
import { color } from '../../constants/color';

const Link = styled.a`
  color: ${color.purpleLoop};
  text-decoration: underline;
`;

Link.displayName = 'Link';

export { Link };
