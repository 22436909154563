import { IconProps, IconSvg } from '../icons/icon-props';

export const LogoNAB = (props: IconProps) => (
  <IconSvg
    height="25.644"
    viewBox="0 0 66.271 25.644"
    width="66.271"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-1202.929 -275.868)">
      <path d="M1235.077,283.933c-.074-.412-.228-1.242-.228-1.242l-3.573.2v1.85l1.005.127v9.682h3.286V286.62c.972-1.031,1.57-1.469,2.617-1.469,1.144,0,1.652.789,1.652,2.565v6.833h3.275v-7.413c0-3.106-1.495-4.751-4.28-4.751a5,5,0,0,0-3.739,1.544" />
      <path d="M1263.821,285.181c1.319,0,1.985,1.122,1.985,3.365,0,1.637-.483,3.584-2.789,3.584a5.126,5.126,0,0,1-1.9-.373v-5.021a3.571,3.571,0,0,1,2.7-1.559m-7.069-6.889v1.869l1.2.153v13.738a17.377,17.377,0,0,0,4.86.767c4.063,0,6.392-2.4,6.392-6.591,0-3.555-1.839-5.772-4.792-5.772a4.889,4.889,0,0,0-3.3,1.23v-5.551Z" />
      <path d="M1245.761,283.129l-.179.067.591,2.5.231-.093a9.257,9.257,0,0,1,3.275-.651c1.82,0,2.164.666,2.164,1.9v.374h-1.162c-3.937,0-5.925,1.32-5.925,3.925,0,2.322,1.54,3.7,4.112,3.7a5.234,5.234,0,0,0,3.458-1.233c.067.321.187.919.187.919l3.652-.116v-1.869l-1.122-.1v-5.862c0-2.822-1.63-4.2-4.99-4.2a13.342,13.342,0,0,0-4.322.748m2.266,7.872c0-.643.269-1.495,2.617-1.495h1.207v1.839a3.365,3.365,0,0,1-2.3,1.024c-.98,0-1.522-.482-1.522-1.364" />
      <path
        d="M1218.689,276.082l-4.254,5.234-6.075-2.946a.325.325,0,0,0-.5.4l1.432,6.583-6.079,2.924c-.179.086-.28.2-.28.317s.1.236.276.322l6.03,2.99-1.495,6.587a.325.325,0,0,0,.5.4l6.075-2.875,4.228,5.286c.123.157.254.228.373.2s.206-.146.255-.34l1.529-6.553h6.777c.2,0,.34-.056.374-.165a.5.5,0,0,0-.109-.411l-4.175-5.293,4.239-5.29a.405.405,0,0,0,.116-.407.39.39,0,0,0-.374-.169h-1.716l-.318.139-6.355,6.758-.145.06.029-.146c.128-.16,3.642-4.429,5.413-6.575l.023-.153-.15-.094h-2.243a.7.7,0,0,0-.467.236c-1.032,1.1-6.168,6.564-6.276,6.687l-.146.06.03-.146,5.364-6.519c.127-.153.2-.258.176-.393l-1.454-6.575c-.045-.194-.131-.318-.247-.344s-.251.041-.374.2"
        fill="#df271c"
      />
      <g>
        <g>
          <g>
            <path
              d="M1214.663,291.073c-.684,0-.983-1.279-.781-1.522s5.828-6.063,6.138-6.5c.168-.235-.83-4.25-1.122-5.749a2.216,2.216,0,0,1,.247-1.428h0a2.212,2.212,0,0,0-.243,1.42c.307,1.5,1.305,5.518,1.122,5.753-.307.43-5.884,6.2-6.131,6.5-.2.243.1,1.5.774,1.5a.885.885,0,0,0,.576-.269c1.072-.965,5.607-6.5,6.052-7.129a2.34,2.34,0,0,0,.269-.539h0a2.32,2.32,0,0,1-.277.546c-.445.639-4.972,6.165-6.056,7.14a.892.892,0,0,1-.579.269"
              fill="#fff"
            />
            <path
              d="M1214.671,291.058c-.677,0-.972-1.264-.774-1.5.246-.3,5.824-6.067,6.13-6.5.169-.235-.829-4.254-1.121-5.753a2.212,2.212,0,0,1,.243-1.42h0a2.175,2.175,0,0,0-.239,1.413c.306,1.495,1.3,5.521,1.121,5.757-.3.426-5.88,6.2-6.123,6.5-.2.239.1,1.495.748,1.495a.885.885,0,0,0,.568-.269c1.065-.957,5.607-6.494,6.048-7.122a2.283,2.283,0,0,0,.266-.53h0a2.313,2.313,0,0,1-.27.538c-.441.632-4.979,6.164-6.052,7.129a.88.88,0,0,1-.575.269"
              fill="#fcfcfc"
            />
            <path
              d="M1214.678,291.035c-.669,0-.961-1.252-.748-1.495.247-.3,5.821-6.071,6.124-6.5.168-.236-.83-4.258-1.122-5.757a2.19,2.19,0,0,1,.239-1.413h0a2.193,2.193,0,0,0-.235,1.406c.306,1.495,1.3,5.528,1.121,5.76-.3.426-5.876,6.2-6.119,6.5-.195.239.093,1.495.748,1.495a.855.855,0,0,0,.56-.265c1.058-.95,5.608-6.49,6.045-7.1a2.3,2.3,0,0,0,.262-.519h0a2.246,2.246,0,0,1-.266.53c-.437.628-4.983,6.168-6.048,7.122a.885.885,0,0,1-.568.269"
              fill="#fafafb"
            />
            <path
              d="M1214.686,291.035c-.662,0-.954-1.241-.748-1.495.243-.3,5.817-6.075,6.119-6.5.165-.232-.83-4.261-1.121-5.76a2.174,2.174,0,0,1,.235-1.406h0a2.163,2.163,0,0,0-.235,1.394c.306,1.5,1.3,5.533,1.121,5.768-.3.423-5.869,6.206-6.112,6.5-.194.236.094,1.466.748,1.466a.864.864,0,0,0,.557-.266c1.047-.942,5.607-6.486,6.045-7.1a2.179,2.179,0,0,0,.258-.509h0a2.283,2.283,0,0,1-.262.52c-.434.624-4.991,6.164-6.049,7.1a.856.856,0,0,1-.56.266"
              fill="#f8f8f9"
            />
            <path
              d="M1214.693,291.017c-.654,0-.942-1.227-.748-1.466.243-.295,5.813-6.078,6.112-6.5.169-.235-.83-4.265-1.121-5.768a2.163,2.163,0,0,1,.235-1.394h0a2.163,2.163,0,0,0-.231,1.387c.306,1.495,1.3,5.54,1.121,5.772-.3.418-5.865,6.205-6.1,6.5-.191.236.093,1.451.747,1.451a.849.849,0,0,0,.553-.258c1.043-.935,5.608-6.486,6.041-7.1a2.115,2.115,0,0,0,.251-.5h0a2.174,2.174,0,0,1-.258.509c-.43.62-4.994,6.164-6.045,7.1a.857.857,0,0,1-.557.262"
              fill="#f7f7f8"
            />
            <path
              d="M1214.7,291c-.65,0-.934-1.215-.747-1.451.239-.291,5.809-6.082,6.1-6.5.169-.235-.83-4.269-1.121-5.775a2.157,2.157,0,0,1,.232-1.383h0a2.133,2.133,0,0,0-.228,1.375c.306,1.5,1.3,5.54,1.121,5.78-.3.415-5.861,6.209-6.1,6.5-.191.232.093,1.436.748,1.436a.844.844,0,0,0,.545-.258c1.032-.927,5.608-6.482,6.038-7.1a2.13,2.13,0,0,0,.246-.493h0a2.155,2.155,0,0,1-.25.5c-.426.613-5,6.164-6.041,7.1a.844.844,0,0,1-.553.258"
              fill="#f5f6f6"
            />
            <path
              d="M1214.708,290.987c-.643,0-.927-1.2-.748-1.436.24-.291,5.806-6.086,6.1-6.5.169-.235-.83-4.272-1.121-5.779a2.15,2.15,0,0,1,.228-1.376h0a2.134,2.134,0,0,0-.224,1.369c.31,1.495,1.3,5.547,1.121,5.783-.3.411-5.858,6.213-6.093,6.5-.187.232.089,1.421.725,1.421a.839.839,0,0,0,.542-.254c1.021-.92,5.607-6.482,6.034-7.1a2.054,2.054,0,0,0,.242-.482h0a2.12,2.12,0,0,1-.246.493c-.423.61-5.009,6.165-6.037,7.1a.849.849,0,0,1-.546.258"
              fill="#f4f5f5"
            />
            <path
              d="M1214.715,290.972c-.639,0-.915-1.189-.729-1.421.236-.288,5.8-6.089,6.094-6.5.168-.235-.83-4.276-1.122-5.783a2.151,2.151,0,0,1,.225-1.368h0a2.124,2.124,0,0,0-.221,1.357c.307,1.495,1.3,5.551,1.121,5.791-.291.407-5.85,6.213-6.085,6.5-.187.228.089,1.406.721,1.406a.822.822,0,0,0,.538-.254c1.013-.912,5.608-6.479,6.034-7.077a2.032,2.032,0,0,0,.239-.471h0a2.047,2.047,0,0,1-.243.483c-.422.6-5.013,6.164-6.037,7.1a.834.834,0,0,1-.542.254"
              fill="#f3f3f4"
            />
            <path
              d="M1214.723,290.957c-.632,0-.909-1.178-.722-1.406.236-.288,5.8-6.089,6.086-6.5.168-.235-.83-4.28-1.121-5.79a2.123,2.123,0,0,1,.217-1.357h0a2.115,2.115,0,0,0-.217,1.346c.306,1.495,1.3,5.558,1.121,5.794-.288.4-5.85,6.217-6.082,6.5-.183.228.09,1.39.714,1.39a.808.808,0,0,0,.531-.25c1.006-.9,5.607-6.475,6.033-7.069a2.044,2.044,0,0,0,.236-.46h0a2.07,2.07,0,0,1-.239.471c-.415.6-5.021,6.161-6.034,7.076a.821.821,0,0,1-.534.255"
              fill="#f2f2f3"
            />
            <path
              d="M1214.73,290.942c-.624,0-.9-1.167-.714-1.391.232-.284,5.791-6.1,6.082-6.5.169-.235-.829-4.287-1.121-5.794a2.117,2.117,0,0,1,.217-1.346h0a2.1,2.1,0,0,0-.213,1.335c.31,1.495,1.3,5.562,1.121,5.8-.284.4-5.843,6.22-6.074,6.5-.184.224.089,1.375.7,1.375a.807.807,0,0,0,.527-.246c1-.9,5.608-6.475,6.03-7.062a1.959,1.959,0,0,0,.228-.452h0a2,2,0,0,1-.235.46c-.415.594-5.024,6.164-6.034,7.069a.812.812,0,0,1-.531.25"
              fill="#f0f0f1"
            />
            <path
              d="M1214.738,290.931c-.617,0-.886-1.156-.7-1.376.232-.281,5.787-6.1,6.075-6.5.168-.235-.826-4.288-1.122-5.8a2.1,2.1,0,0,1,.213-1.334h0a2.1,2.1,0,0,0-.205,1.327c.31,1.495,1.3,5.57,1.121,5.8-.28.4-5.839,6.224-6.067,6.5-.183.22.086,1.361.695,1.361a.8.8,0,0,0,.52-.247c.991-.89,5.607-6.471,6.026-7.054a1.969,1.969,0,0,0,.224-.441h0a1.917,1.917,0,0,1-.228.452c-.411.591-5.031,6.164-6.03,7.062a.807.807,0,0,1-.527.246"
              fill="#eff0f0"
            />
            <path
              d="M1214.745,290.912c-.609,0-.878-1.122-.695-1.361s5.783-6.1,6.067-6.5c.168-.235-.826-4.295-1.121-5.8a2.1,2.1,0,0,1,.205-1.327h0a2.092,2.092,0,0,0-.205,1.319c.31,1.5,1.3,5.574,1.121,5.81-.28.373-5.831,6.224-6.063,6.5-.18.221.086,1.346.691,1.346a.793.793,0,0,0,.516-.243c.98-.882,5.608-6.467,6.022-7.046a2.009,2.009,0,0,0,.221-.43h0a1.969,1.969,0,0,1-.224.441c-.408.587-5.036,6.164-6.026,7.054a.8.8,0,0,1-.52.247"
              fill="#edeeef"
            />
            <path
              d="M1214.753,290.9c-.606,0-.871-1.122-.692-1.346.228-.277,5.78-6.108,6.06-6.5.168-.235-.826-4.3-1.122-5.809a2.109,2.109,0,0,1,.206-1.32h0a2.082,2.082,0,0,0-.2,1.309c.31,1.495,1.305,5.577,1.121,5.813-.28.373-5.828,6.227-6.056,6.5s.086,1.335.685,1.335a.781.781,0,0,0,.508-.239c.972-.875,5.607-6.467,6.018-7.039a1.779,1.779,0,0,0,.217-.423h0a1.839,1.839,0,0,1-.22.43c-.4.58-5.043,6.164-6.023,7.047a.789.789,0,0,1-.515.243"
              fill="#ededee"
            />
            <path
              d="M1214.76,290.886c-.6,0-.86-1.122-.684-1.335s5.779-6.108,6.056-6.5c.168-.235-.826-4.3-1.121-5.813a2.078,2.078,0,0,1,.2-1.308h0a2.085,2.085,0,0,0-.2,1.3c.31,1.495,1.3,5.581,1.121,5.817-.277.373-5.824,6.231-6.048,6.5s.082,1.32.673,1.32a.749.749,0,0,0,.5-.239c.965-.868,5.608-6.46,6.019-7.032a1.84,1.84,0,0,0,.209-.415h0a1.854,1.854,0,0,1-.217.423c-.4.572-5.05,6.16-6.018,7.039a.782.782,0,0,1-.509.239"
              fill="#ebecec"
            />
            <path
              d="M1214.768,290.871c-.591,0-.849-1.122-.677-1.32s5.772-6.116,6.049-6.5c.168-.235-.827-4.306-1.122-5.816a2.082,2.082,0,0,1,.194-1.3h0a2.061,2.061,0,0,0-.194,1.289c.31,1.5,1.305,5.585,1.122,5.821-.277.374-5.821,6.235-6.041,6.5s.082,1.3.665,1.3a.766.766,0,0,0,.5-.235c.953-.864,5.608-6.464,6.015-7.024a1.721,1.721,0,0,0,.209-.4h0a1.771,1.771,0,0,1-.213.415c-.4.568-5.054,6.16-6.018,7.031a.75.75,0,0,1-.505.24"
              fill="#eaebec"
            />
            <path
              d="M1214.775,290.856c-.587,0-.841-1.092-.665-1.3s5.768-6.119,6.041-6.5c.168-.236-.826-4.311-1.122-5.821a2.06,2.06,0,0,1,.195-1.289h0a2.045,2.045,0,0,0-.187,1.278c.306,1.514,1.3,5.607,1.121,5.828-.273.374-5.816,6.239-6.033,6.5-.172.21.082,1.29.658,1.29a.745.745,0,0,0,.493-.235c.95-.856,5.607-6.46,6.015-7.017a1.751,1.751,0,0,0,.206-.4h0a1.735,1.735,0,0,1-.21.4c-.374.561-5.061,6.161-6.015,7.025a.771.771,0,0,1-.5.235"
              fill="#e9e9ea"
            />
            <path
              d="M1214.783,290.845c-.58,0-.83-1.081-.658-1.29.22-.269,5.764-6.123,6.033-6.505.168-.239-.826-4.314-1.121-5.828a2.033,2.033,0,0,1,.187-1.278h0a2.037,2.037,0,0,0-.183,1.264c.31,1.517,1.3,5.607,1.121,5.835-.269.374-5.809,6.239-6.03,6.5-.172.206.079,1.275.651,1.275a.748.748,0,0,0,.486-.232c.938-.848,5.607-6.452,6.007-7.009a1.761,1.761,0,0,0,.2-.374h0a1.75,1.75,0,0,1-.2.4c-.374.557-5.065,6.16-6.015,7.016a.748.748,0,0,1-.493.236"
              fill="#e8e8e9"
            />
            <path
              d="M1214.79,290.826c-.572,0-.822-1.066-.65-1.275.217-.265,5.76-6.127,6.029-6.5.169-.24-.826-4.318-1.121-5.836a2.049,2.049,0,0,1,.183-1.263h0a2.039,2.039,0,0,0-.176,1.256c.311,1.517,1.3,5.607,1.122,5.839-.265.374-5.806,6.243-6.022,6.5-.169.206.078,1.26.643,1.26a.746.746,0,0,0,.482-.228c.927-.841,5.607-6.452,6.007-7a1.748,1.748,0,0,0,.2-.373h0a1.758,1.758,0,0,1-.2.373c-.373.557-5.072,6.161-6.007,7.01a.745.745,0,0,1-.486.231"
              fill="#e6e7e8"
            />
            <path
              d="M1214.8,290.811c-.564,0-.815-1.051-.643-1.26s5.757-6.131,6.023-6.5c.172-.24-.823-4.322-1.122-5.839a2.043,2.043,0,0,1,.18-1.257h0a2.016,2.016,0,0,0-.172,1.242c.31,1.517,1.3,5.607,1.121,5.846-.265.374-5.8,6.243-6.015,6.505s.079,1.244.636,1.244a.717.717,0,0,0,.475-.228c.919-.829,5.607-6.448,6-6.994a1.691,1.691,0,0,0,.191-.374h0a1.749,1.749,0,0,1-.2.374c-.373.55-5.076,6.161-6.007,7a.745.745,0,0,1-.482.228"
              fill="#e5e6e7"
            />
            <path
              d="M1214.805,290.8c-.557,0-.8-1.043-.635-1.245s5.753-6.135,6.014-6.505c.172-.239-.822-4.328-1.121-5.846a2.013,2.013,0,0,1,.172-1.241h0a2.01,2.01,0,0,0-.168,1.233c.31,1.518,1.3,5.608,1.121,5.851-.262.373-5.8,6.246-6.011,6.5s.079,1.23.628,1.23a.728.728,0,0,0,.471-.224c.912-.826,5.626-6.449,6-6.987a1.63,1.63,0,0,0,.187-.355h0a1.719,1.719,0,0,1-.191.374c-.374.545-5.084,6.164-6,6.994a.721.721,0,0,1-.474.228"
              fill="#e3e4e5"
            />
            <path
              d="M1214.813,290.785c-.55,0-.793-1.028-.628-1.23s5.745-6.138,6.007-6.505c.172-.239-.823-4.332-1.122-5.846a2.026,2.026,0,0,1,.169-1.238h0a2.006,2.006,0,0,0-.165,1.223c.31,1.518,1.305,5.607,1.122,5.854-.258.374-5.791,6.25-6,6.508-.165.2.075,1.215.62,1.215a.711.711,0,0,0,.468-.221c.9-.818,5.629-6.44,6-6.983l.184-.343h0a1.653,1.653,0,0,1-.187.355c-.374.542-5.088,6.16-6,6.986a.725.725,0,0,1-.471.225"
              fill="#e2e3e4"
            />
            <path
              d="M1214.82,290.77c-.546,0-.785-1.017-.62-1.215.209-.254,5.745-6.142,6-6.508.172-.236-.822-4.333-1.121-5.851a2.018,2.018,0,0,1,.164-1.222h0a2,2,0,0,0-.161,1.211c.311,1.518,1.305,5.607,1.122,5.858-.258.374-5.787,6.254-5.981,6.508-.165.2.074,1.2.613,1.2a.709.709,0,0,0,.46-.217c.9-.807,5.629-6.441,5.981-6.971l.175-.337h0l-.183.344c-.374.538-5.095,6.164-6,6.983a.71.71,0,0,1-.467.221"
              fill="#e1e2e3"
            />
            <path
              d="M1214.828,290.755c-.539,0-.774-1.006-.614-1.2.21-.251,5.739-6.146,5.982-6.505.172-.239-.823-4.34-1.122-5.857a1.971,1.971,0,0,1,.161-1.212h0a1.992,1.992,0,0,0-.157,1.2c.31,1.522,1.305,5.608,1.121,5.866-.254.373-5.783,6.254-5.981,6.5s.075,1.189.61,1.189a.7.7,0,0,0,.456-.217c.886-.8,5.629-6.437,5.981-6.964l.172-.325h0l-.176.336c-.374.527-5.1,6.161-5.981,6.972a.719.719,0,0,1-.46.217"
              fill="#e0e1e2"
            />
            <path
              d="M1214.839,290.74c-.535,0-.767-.994-.61-1.189s5.739-6.149,5.982-6.5c.172-.24-.819-4.344-1.122-5.866a2,2,0,0,1,.157-1.2h0a1.985,1.985,0,0,0-.149,1.189c.31,1.522,1.3,5.63,1.121,5.869-.25.352-5.779,6.258-5.981,6.505s.075,1.173.6,1.173a.69.69,0,0,0,.452-.213c.879-.792,5.63-6.433,5.982-6.956l.168-.318h0l-.172.325c-.374.527-5.107,6.164-5.981,6.964a.7.7,0,0,1-.456.217"
              fill="#dedfe1"
            />
            <path
              d="M1214.846,290.725c-.523,0-.747-.979-.6-1.174s5.731-6.153,5.981-6.5c.172-.24-.822-4.348-1.121-5.869a1.984,1.984,0,0,1,.149-1.189h0a1.953,1.953,0,0,0-.145,1.177c.31,1.522,1.3,5.634,1.121,5.873-.25.352-5.776,6.262-5.981,6.508s.075,1.159.59,1.159a.678.678,0,0,0,.445-.209c.871-.789,5.63-6.437,5.981-6.953l.165-.3h0l-.168.318c-.374.519-5.114,6.16-5.981,6.957a.693.693,0,0,1-.453.213"
              fill="#dedfe0"
            />
            <path
              d="M1214.854,290.71c-.52,0-.748-.964-.591-1.159s5.727-6.157,5.981-6.508c.172-.239-.822-4.351-1.121-5.873a1.945,1.945,0,0,1,.146-1.177h0a1.955,1.955,0,0,0-.142,1.166c.314,1.521,1.3,5.637,1.121,5.876-.25.348-5.772,6.266-5.981,6.509s.071,1.144.583,1.144a.665.665,0,0,0,.437-.21c.864-.777,5.634-6.43,5.982-6.942l.157-.3h0l-.161.3c-.374.516-5.118,6.164-5.981,6.953a.686.686,0,0,1-.445.206"
              fill="#dcddde"
            />
            <path
              d="M1214.861,290.7c-.512,0-.747-.953-.583-1.144s5.723-6.16,5.981-6.508c.172-.239-.818-4.355-1.121-5.877a1.95,1.95,0,0,1,.142-1.162h0a1.953,1.953,0,0,0-.139,1.151c.311,1.522,1.305,5.641,1.122,5.88-.243.344-5.764,6.266-5.981,6.509-.153.187.071,1.121.575,1.121a.657.657,0,0,0,.434-.205c.856-.774,5.634-6.43,5.981-6.935l.154-.288h0l-.161.3c-.355.512-5.125,6.164-5.981,6.941a.672.672,0,0,1-.441.206"
              fill="#dbdcde"
            />
            <path
              d="M1214.869,290.684c-.505,0-.729-.942-.576-1.122.2-.243,5.719-6.164,5.981-6.508.176-.239-.819-4.359-1.121-5.88a1.936,1.936,0,0,1,.134-1.151h0a1.957,1.957,0,0,0-.131,1.121c.311,1.525,1.305,5.648,1.122,5.888-.243.34-5.764,6.269-5.959,6.508-.149.183.071,1.121.568,1.121a.654.654,0,0,0,.43-.205c.845-.748,5.634-6.426,5.981-6.923l.15-.277h0l-.153.288c-.352.5-5.129,6.16-5.981,6.934a.666.666,0,0,1-.434.206"
              fill="#dadbdc"
            />
            <path
              d="M1214.876,290.661c-.5,0-.718-.93-.568-1.121.2-.239,5.716-6.172,5.959-6.508.172-.24-.819-4.363-1.122-5.888a1.932,1.932,0,0,1,.131-1.121h0a1.949,1.949,0,0,0-.127,1.121c.31,1.529,1.3,5.652,1.121,5.891-.239.337-5.756,6.273-5.951,6.509-.149.179.067,1.1.561,1.1a.642.642,0,0,0,.422-.2c.838-.748,5.634-6.426,5.981-6.92l.146-.265h0l-.149.277c-.348.5-5.137,6.16-5.981,6.927a.657.657,0,0,1-.427.205"
              fill="#d9dadb"
            />
            <path
              d="M1214.884,290.661c-.494,0-.711-.919-.561-1.1.194-.239,5.712-6.171,5.951-6.508.172-.239-.819-4.366-1.121-5.895a1.936,1.936,0,0,1,.127-1.122h0a1.919,1.919,0,0,0-.12,1.122c.31,1.529,1.3,5.656,1.122,5.9-.24.333-5.754,6.273-5.948,6.508-.146.176.067,1.084.553,1.084a.642.642,0,0,0,.419-.2c.826-.748,5.634-6.418,5.981-6.912l.139-.258h0l-.143.266c-.343.493-5.143,6.164-5.981,6.919a.647.647,0,0,1-.422.2"
              fill="#d7d8da"
            />
            <path
              d="M1214.891,290.639c-.486,0-.7-.908-.553-1.084.194-.236,5.708-6.176,5.947-6.508.169-.243-.818-4.37-1.121-5.9a1.926,1.926,0,0,1,.12-1.122h0a1.911,1.911,0,0,0-.116,1.1c.314,1.529,1.3,5.66,1.121,5.9-.235.329-5.749,6.276-5.94,6.508s.067,1.073.546,1.073a.628.628,0,0,0,.411-.2c.819-.748,5.637-6.419,5.981-6.9l.135-.247h0l-.142.258c-.34.49-5.148,6.161-5.981,6.912a.642.642,0,0,1-.415.2"
              fill="#d6d7d9"
            />
            <path
              d="M1214.9,290.624c-.479,0-.692-.893-.546-1.073s5.7-6.179,5.94-6.508c.172-.243-.819-4.374-1.122-5.9a1.929,1.929,0,0,1,.116-1.1h0a1.919,1.919,0,0,0-.112,1.088c.31,1.529,1.3,5.664,1.122,5.907-.236.325-5.742,6.28-5.933,6.508s.064,1.054.538,1.054a.618.618,0,0,0,.408-.194c.811-.748,5.637-6.419,5.981-6.9l.127-.24h0l-.135.247c-.34.486-5.155,6.161-5.981,6.9a.628.628,0,0,1-.411.2"
              fill="#d4d6d7"
            />
            <path
              d="M1214.906,290.609c-.475,0-.68-.878-.538-1.054s5.7-6.183,5.932-6.512c.172-.243-.818-4.378-1.121-5.907a1.9,1.9,0,0,1,.112-1.087h0a1.911,1.911,0,0,0-.1,1.076c.311,1.529,1.3,5.671,1.122,5.914-.232.322-5.738,6.284-5.929,6.512s.064,1.039.531,1.039a.61.61,0,0,0,.4-.19c.8-.726,5.637-6.415,5.981-6.89l.127-.228h0l-.131.239c-.333.479-5.159,6.161-5.981,6.9a.624.624,0,0,1-.408.2"
              fill="#d4d5d7"
            />
            <path
              d="M1214.914,290.594c-.468,0-.673-.867-.531-1.039s5.7-6.191,5.925-6.512c.175-.239-.819-4.381-1.122-5.914a1.915,1.915,0,0,1,.1-1.077h0a1.873,1.873,0,0,0-.1,1.062c.31,1.533,1.305,5.675,1.122,5.918-.229.317-5.735,6.284-5.922,6.512s.064,1.024.52,1.024a.611.611,0,0,0,.4-.187c.793-.721,5.637-6.411,5.981-6.882l.12-.217h0l-.124.228c-.332.475-5.166,6.161-5.981,6.89a.608.608,0,0,1-.4.19"
              fill="#d2d3d5"
            />
            <path
              d="M1214.921,290.579c-.46,0-.662-.856-.52-1.024s5.69-6.191,5.918-6.508c.176-.243-.815-4.385-1.121-5.918a1.9,1.9,0,0,1,.1-1.062h0a1.918,1.918,0,0,0-.1,1.047c.314,1.533,1.3,5.682,1.122,5.925-.225.314-5.731,6.288-5.914,6.508s.06,1.013.516,1.013a.609.609,0,0,0,.374-.186c.785-.714,5.637-6.412,5.981-6.875l.116-.206h0l-.12.217c-.329.471-5.17,6.161-5.981,6.882a.606.606,0,0,1-.4.187"
              fill="#d0d2d3"
            />
            <path
              d="M1214.928,290.568c-.452,0-.654-.845-.515-1.013s5.685-6.194,5.913-6.508c.176-.243-.814-4.393-1.121-5.925a1.909,1.909,0,0,1,.1-1.047h0a1.9,1.9,0,0,0-.093,1.032c.314,1.532,1.3,5.685,1.121,5.928-.224.314-5.727,6.292-5.906,6.512s.06,1,.508,1a.6.6,0,0,0,.374-.184c.778-.706,5.641-6.4,5.963-6.867l.112-.194h0l-.116.205c-.325.468-5.178,6.161-5.981,6.875a.606.606,0,0,1-.374.187"
              fill="#d0d1d3"
            />
            <path
              d="M1214.936,290.553c-.445,0-.643-.83-.508-1s5.682-6.2,5.906-6.512c.176-.243-.815-4.4-1.122-5.929a1.9,1.9,0,0,1,.09-1.032h0a1.872,1.872,0,0,0-.086,1.021c.314,1.533,1.305,5.689,1.122,5.932-.221.307-5.72,6.3-5.9,6.512s.06.984.5.984a.585.585,0,0,0,.374-.18c.766-.7,5.641-6.4,5.958-6.86l.105-.186h0l-.112.194c-.321.463-5.185,6.161-5.962,6.867a.6.6,0,0,1-.374.183"
              fill="#cecfd1"
            />
            <path
              d="M1214.943,290.538c-.441,0-.635-.819-.5-.983s5.682-6.2,5.9-6.512c.176-.243-.815-4.4-1.121-5.933a1.867,1.867,0,0,1,.086-1.02h0a1.868,1.868,0,0,0-.083,1c.314,1.532,1.3,5.693,1.122,5.936-.221.306-5.716,6.295-5.892,6.512s.06.968.49.968a.574.574,0,0,0,.374-.179c.748-.692,5.641-6.4,5.955-6.852l.1-.176h0l-.1.187c-.318.456-5.188,6.164-5.958,6.859a.584.584,0,0,1-.374.18"
              fill="#cdcfd0"
            />
            <path
              d="M1214.951,290.523c-.434,0-.624-.807-.49-.968s5.675-6.206,5.892-6.512c.175-.243-.815-4.4-1.122-5.936a1.878,1.878,0,0,1,.082-1h0a1.861,1.861,0,0,0-.074.987c.314,1.532,1.3,5.7,1.121,5.94-.217.3-5.712,6.3-5.888,6.512s.06.953.486.953a.571.571,0,0,0,.374-.176c.748-.68,5.641-6.4,5.951-6.844l.094-.165h0l-.1.176c-.314.452-5.2,6.16-5.955,6.852a.574.574,0,0,1-.374.179"
              fill="#cbcdcf"
            />
            <path
              d="M1214.958,290.508c-.426,0-.616-.792-.486-.953s5.671-6.209,5.888-6.512c.176-.239-.815-4.4-1.121-5.94a1.877,1.877,0,0,1,.074-.991h0a1.88,1.88,0,0,0-.071.972c.314,1.537,1.305,5.7,1.122,5.944-.217.3-5.708,6.3-5.88,6.512s.056.938.474.938a.559.559,0,0,0,.374-.172c.748-.676,5.641-6.4,5.951-6.837l.09-.157h0l-.093.165c-.311.444-5.2,6.16-5.952,6.844a.57.57,0,0,1-.373.176"
              fill="#caccce"
            />
            <path
              d="M1214.966,290.493c-.423,0-.606-.781-.475-.938s5.667-6.213,5.88-6.512c.176-.243-.815-4.411-1.121-5.948a1.869,1.869,0,0,1,.071-.972h0a1.874,1.874,0,0,0-.064.957c.314,1.537,1.305,5.709,1.122,5.952s-5.7,6.317-5.873,6.508.056.923.467.927a.547.547,0,0,0,.374-.172c.748-.669,5.645-6.392,5.951-6.83l.083-.145h0l-.09.157c-.307.441-5.207,6.16-5.951,6.837a.566.566,0,0,1-.374.172"
              fill="#c9cacd"
            />
            <path
              d="M1214.973,290.478c-.415,0-.6-.77-.467-.927s5.663-6.217,5.877-6.512-.815-4.415-1.122-5.951a1.874,1.874,0,0,1,.064-.957h0a1.855,1.855,0,0,0-.06.938c.314,1.537,1.3,5.712,1.121,5.955s-5.7,6.325-5.865,6.516.052.908.46.908a.545.545,0,0,0,.351-.168c.725-.662,5.645-6.389,5.948-6.822l.078-.135h0l-.082.146c-.307.434-5.211,6.161-5.948,6.83a.557.557,0,0,1-.373.172"
              fill="#c8cacc"
            />
            <path
              d="M1214.981,290.463c-.408,0-.587-.747-.464-.908s5.66-6.221,5.869-6.512-.815-4.419-1.121-5.955a1.855,1.855,0,0,1,.06-.938h0a1.865,1.865,0,0,0-.053.923c.314,1.54,1.3,5.716,1.122,5.962s-5.69,6.318-5.858,6.505.052.9.452.9l.348-.168c.718-.654,5.645-6.385,5.944-6.815l.071-.123h0l-.079.134c-.3.43-5.233,6.161-5.944,6.823a.55.55,0,0,1-.351.168"
              fill="#c6c8ca"
            />
            <path
              d="M1215.007,290.452c-.4,0-.579-.748-.452-.9s5.656-6.224,5.861-6.512-.815-4.422-1.121-5.963a1.876,1.876,0,0,1,.052-.923h0a1.851,1.851,0,0,0-.049.908c.314,1.541,1.3,5.724,1.122,5.982s-5.705,6.306-5.869,6.493.052.882.445.882l.344-.164c.71-.647,5.644-6.385,5.94-6.808l.063-.108h0l-.071.12c-.3.426-5.233,6.16-5.944,6.814l-.347.169"
              fill="#c6c7c9"
            />
            <path
              d="M1215.007,290.437c-.393,0-.568-.748-.445-.882s5.652-6.228,5.854-6.512-.811-4.426-1.121-5.981a1.862,1.862,0,0,1,.048-.9h0a1.885,1.885,0,0,0-.041.886c.314,1.544,1.3,5.727,1.122,5.981s-5.686,6.318-5.851,6.512.049.867.438.867l.336-.16c.7-.64,5.649-6.385,5.94-6.8l.06-.1h0l-.064.108c-.291.423-5.233,6.161-5.94,6.807l-.34.165"
              fill="#c4c6c8"
            />
            <path
              d="M1215.007,290.422c-.374,0-.561-.718-.437-.867s5.644-6.232,5.85-6.512-.815-4.43-1.122-5.981a1.872,1.872,0,0,1,.042-.886h0a1.857,1.857,0,0,0-.038.867c.314,1.544,1.305,5.734,1.122,5.981s-5.682,6.322-5.843,6.516.048.852.43.852l.329-.157c.691-.632,5.648-6.381,5.936-6.792l.052-.086h0l-.06.1c-.291.415-5.233,6.161-5.936,6.8l-.336.161"
              fill="#c3c5c7"
            />
            <path
              d="M1215.007,290.407c-.374,0-.55-.706-.43-.852s5.641-6.235,5.843-6.512-.811-4.434-1.122-5.981a1.888,1.888,0,0,1,.038-.868h0a1.836,1.836,0,0,0-.03.853c.314,1.54,1.3,5.738,1.121,5.981s-5.67,6.336-5.831,6.512.048.837.422.837l.325-.153c.685-.624,5.649-6.377,5.933-6.785l.045-.075h0l-.053.086c-.287.415-5.233,6.161-5.936,6.793l-.329.157"
              fill="#c1c3c5"
            />
            <path
              d="M1215.007,290.388c-.374,0-.538-.691-.422-.837s5.637-6.239,5.835-6.512-.811-4.437-1.122-5.981a1.858,1.858,0,0,1,.03-.852h0a1.861,1.861,0,0,0-.026.83c.314,1.543,1.305,5.745,1.122,5.981s-5.653,6.347-5.813,6.523.045.822.415.822l.321-.153c.677-.613,5.649-6.374,5.933-6.777l.037-.06h0l-.045.075c-.284.407-5.233,6.16-5.932,6.784l-.326.154"
              fill="#c1c2c4"
            />
            <path
              d="M1215.026,290.377c-.374,0-.531-.68-.415-.822s5.633-6.243,5.828-6.512-.812-4.445-1.122-5.981a1.876,1.876,0,0,1,.026-.83h0a1.821,1.821,0,0,0-.018.811c.314,1.544,1.3,5.746,1.121,5.981s-5.663,6.344-5.813,6.516.045.807.408.807l.314-.153c.669-.609,5.652-6.355,5.928-6.77l.03-.048h0l-.037.059c-.28.4-5.256,6.161-5.933,6.778l-.321.153"
              fill="#bfc1c3"
            />
            <path
              d="M1215.033,290.362c-.374,0-.519-.669-.407-.807s5.629-6.25,5.82-6.516-.811-4.445-1.121-5.981a1.821,1.821,0,0,1,.018-.811h0a1.873,1.873,0,0,0,0,.789c.318,1.547,1.3,5.753,1.122,5.981s-5.667,6.351-5.832,6.523.045.792.4.792l.31-.149c.658-.6,5.653-6.355,5.926-6.763l.022-.033h0l-.03.048c-.28.4-5.263,6.161-5.929,6.77l-.314.154"
              fill="#bec0c2"
            />
            <path
              d="M1215.041,290.347c-.374,0-.513-.658-.4-.792s5.629-6.25,5.816-6.516-.807-4.448-1.121-5.981a1.873,1.873,0,0,1,0-.789h0a1.866,1.866,0,0,0,0,.767c.318,1.547,1.3,5.753,1.121,5.981s-5.648,6.355-5.8,6.523.044.777.373.777l.3-.145c.651-.6,5.652-6.355,5.922-6.755h0l-.023.033c-.273.374-5.267,6.161-5.925,6.763l-.31.149"
              fill="#bcbec1"
            />
            <path
              d="M1215.048,290.332c-.348,0-.505-.643-.374-.777s5.608-6.254,5.809-6.516-.807-4.452-1.121-5.981a1.862,1.862,0,0,1,0-.766h0a1.842,1.842,0,0,0,0,.747c.318,1.548,1.3,5.761,1.121,6.008s-5.655,6.355-5.805,6.515.041.748.374.748l.3-.146c.639-.587,5.63-6.355,5.917-6.729h0c-.269.374-5.27,6.161-5.921,6.755l-.3.146"
              fill="#bbbdc0"
            />
            <path
              d="M1215.056,290.321c-.341,0-.494-.632-.374-.747s5.607-6.258,5.805-6.516-.807-4.46-1.121-6.007a1.847,1.847,0,0,1,0-.748v.019a1.8,1.8,0,0,0,0,.717c.318,1.548,1.3,5.765,1.121,6.011s-5.648,6.355-5.8,6.516.041.748.374.748l.292-.142c.62-.572,5.5-6.187,5.9-6.729h0c-.288.407-5.278,6.16-5.917,6.729l-.3.145"
              fill="#babcbe"
            />
            <path
              d="M1215.063,290.288c-.333,0-.486-.621-.374-.748s5.608-6.262,5.8-6.516-.807-4.463-1.121-6.011a1.809,1.809,0,0,1,0-.718v.019a1.848,1.848,0,0,0,0,.7c.314,1.548,1.3,5.768,1.121,6.015s-5.644,6.355-5.79,6.52.041.747.374.747l.287-.138c.606-.55,5.324-5.981,5.873-6.676l.026-.03c-.4.534-5.278,6.149-5.9,6.728l-.292.142"
              fill="#b9bbbd"
            />
            <path
              d="M1215.071,290.288c-.329,0-.475-.606-.374-.748s5.607-6.265,5.794-6.52-.811-4.467-1.122-6.014a1.9,1.9,0,0,1,0-.7h0a1.878,1.878,0,0,0,0,.669c.318,1.552,1.3,5.776,1.122,6.023s-5.641,6.355-5.787,6.519.041.722.374.722l.284-.135c.587-.538,5.211-5.846,5.85-6.643l.023-.022c-.55.7-5.271,6.123-5.873,6.676l-.288.139"
              fill="#b7b9bc"
            />
            <path
              d="M1215.078,290.288c-.321,0-.467-.6-.374-.718s5.608-6.273,5.787-6.52-.807-4.486-1.122-6.022a1.834,1.834,0,0,1,0-.669v.019a1.877,1.877,0,0,0,0,.643c.318,1.551,1.3,5.779,1.122,6.026s-5.634,6.355-5.779,6.519.041.707.355.707l.276-.135c.572-.523,5.077-5.686,5.817-6.6l.03-.034c-.639.8-5.26,6.1-5.85,6.643l-.281.135"
              fill="#b7b9bb"
            />
            <path
              d="M1215.089,290.261c-.318,0-.456-.583-.355-.706s5.607-6.273,5.779-6.52-.807-4.485-1.121-6.026a1.877,1.877,0,0,1,0-.643v.023a1.83,1.83,0,0,0,.019.613c.317,1.555,1.3,5.787,1.121,6.03s-5.633,6.355-5.772,6.519.038.692.344.692l.273-.131c.557-.509,4.949-5.54,5.779-6.553l.034-.034c-.748.908-5.234,6.075-5.817,6.6l-.276.135"
              fill="#b5b7b9"
            />
            <path
              d="M1215.1,290.246c-.311,0-.449-.568-.344-.691s5.607-6.277,5.772-6.52-.808-4.485-1.122-6.029a1.854,1.854,0,0,1-.019-.617v.022a1.894,1.894,0,0,0,.023.587c.318,1.552,1.3,5.787,1.121,6.034s-5.629,6.355-5.768,6.519.034.677.34.677l.266-.127c.538-.494,4.822-5.4,5.742-6.5l.037-.041c-.83,1.009-5.233,6.04-5.779,6.553l-.269.127"
              fill="#b4b6b8"
            />
            <path
              d="M1215.1,290.231c-.3,0-.437-.557-.34-.676s5.608-6.28,5.768-6.52-.807-4.485-1.121-6.033a1.894,1.894,0,0,1-.023-.587v.022a1.883,1.883,0,0,0,.027.561c.317,1.552,1.3,5.794,1.121,6.037s-5.607,6.355-5.761,6.52a.472.472,0,0,0,.329.662l.262-.128c.527-.482,4.721-5.278,5.708-6.448l.034-.034c-.92,1.1-5.2,6-5.742,6.5l-.265.127"
              fill="#b2b5b7"
            />
            <path
              d="M1215.112,290.217c-.3,0-.427-.546-.329-.662s5.607-6.284,5.76-6.52a55.615,55.615,0,0,0-1.121-6.037,1.851,1.851,0,0,1-.026-.561v.019a1.862,1.862,0,0,0,.03.527c.317,1.555,1.3,5.8,1.121,6.045s-5.607,6.355-5.753,6.519.034.647.321.647l.255-.123c.508-.471,4.586-5.118,5.652-6.385l.052-.052c-.983,1.173-5.181,5.981-5.7,6.448l-.262.127"
              fill="#b1b4b6"
            />
            <path
              d="M1215.115,290.2c-.287,0-.418-.535-.321-.647s5.589-6.288,5.753-6.52-.807-4.485-1.121-6.044a1.864,1.864,0,0,1-.03-.527v.029a1.814,1.814,0,0,0,.033.5c.318,1.555,1.3,5.8,1.122,6.048s-5.608,6.355-5.75,6.52a.451.451,0,0,0,.318.631l.251-.123c.493-.456,4.485-4.994,5.607-6.329l.041-.045c-1.065,1.264-5.144,5.914-5.652,6.385l-.254.124"
              fill="#b0b2b5"
            />
            <path
              d="M1215.127,290.187c-.284,0-.408-.52-.318-.632s5.581-6.292,5.749-6.52-.807-4.485-1.121-6.048a1.844,1.844,0,0,1-.034-.5v.03a1.885,1.885,0,0,0,.034.456c.318,1.559,1.3,5.809,1.121,6.056s-5.607,6.355-5.742,6.519.03.617.311.617l.243-.12c.478-.445,4.358-4.859,5.551-6.261l.052-.056c-1.121,1.334-5.114,5.869-5.607,6.329l-.251.119"
              fill="#afb1b4"
            />
            <path
              d="M1215.134,290.175c-.277,0-.4-.508-.31-.616s5.577-6.3,5.742-6.52-.808-4.486-1.122-6.056a1.831,1.831,0,0,1-.033-.456v.034a1.951,1.951,0,0,0,.037.418c.318,1.559,1.3,5.81,1.121,6.06s-5.607,6.355-5.734,6.52.03.6.3.6l.239-.115c.468-.43,4.251-4.729,5.5-6.2l.049-.052c-1.2,1.406-5.073,5.817-5.552,6.262l-.246.115"
              fill="#adb0b2"
            />
            <path
              d="M1215.142,290.157c-.27,0-.374-.494-.3-.6s5.573-6.3,5.734-6.52-.8-4.485-1.122-6.059a1.907,1.907,0,0,1-.037-.419v.037l.037.374c.318,1.559,1.3,5.817,1.122,6.064s-5.607,6.355-5.727,6.523.03.591.292.591l.235-.116c.452-.419,4.142-4.606,5.447-6.124l.052-.059c-1.252,1.465-5.032,5.764-5.5,6.194l-.239.116"
              fill="#adafb1"
            />
            <path
              d="M1215.149,290.145c-.265,0-.374-.482-.292-.59s5.57-6.3,5.727-6.52a55.435,55.435,0,0,0-1.121-6.067l-.037-.374v.042l.037.329c.318,1.558,1.3,5.82,1.121,6.07s-5.607,6.355-5.723,6.524.03.572.284.572l.228-.109c.441-.407,4.041-4.486,5.387-6.056l.056-.06c-1.3,1.518-4.99,5.7-5.446,6.124l-.236.115"
              fill="#abadb0"
            />
            <path
              d="M1215.157,290.131c-.258,0-.374-.468-.288-.572s5.566-6.307,5.723-6.524a55.31,55.31,0,0,0-1.122-6.07l-.037-.329v.048l.034.273c.317,1.563,1.3,5.828,1.121,6.075s-5.581,6.377-5.7,6.508.027.557.277.557l.224-.109c.426-.4,3.937-4.369,5.327-5.981l.056-.059c-1.345,1.566-4.945,5.644-5.386,6.052l-.228.108"
              fill="#aaacaf"
            />
            <path
              d="M1215.164,290.116c-.25,0-.374-.456-.277-.557s5.559-6.314,5.727-6.524-.8-4.515-1.121-6.074l-.034-.273v.063l.03.206c.322,1.563,1.3,5.832,1.122,6.082s-5.6,6.374-5.709,6.5.026.546.269.546l.217-.105c.411-.374,3.824-4.239,5.256-5.9l.071-.071c-1.39,1.611-4.9,5.585-5.33,5.981l-.225.109"
              fill="#a9abad"
            />
            <path
              d="M1215.171,290.1c-.243,0-.355-.445-.269-.542s5.555-6.318,5.712-6.524a55.821,55.821,0,0,0-1.121-6.078l-.03-.206.022.1v.094c.318,1.562,1.3,5.835,1.122,6.085s-5.57,6.378-5.69,6.505.026.531.262.531l.213-.105c.4-.374,3.738-4.112,5.2-5.82l.06-.064c-1.432,1.656-4.86,5.51-5.256,5.895l-.217.105"
              fill="#a8aaad"
            />
            <path
              d="M1215.179,290.089c-.236,0-.344-.433-.262-.53s5.555-6.318,5.7-6.524a59.16,59.16,0,0,0-1.121-6.085v-.094l.123.55c.374,1.809,1.189,5.4,1.024,5.629s-5.6,6.382-5.716,6.505.027.516.255.516l.2-.1c.374-.374,3.611-3.993,5.114-5.723l.079-.086c-1.466,1.693-4.8,5.446-5.2,5.82l-.214.105"
              fill="#a6a8ab"
            />
            <path
              d="M1215.186,290.074c-.228,0-.332-.422-.254-.515s5.551-6.322,5.7-6.524a45.2,45.2,0,0,0-1.024-5.629c.09.4.194.871.3,1.368a29.465,29.465,0,0,1,.747,4.258c-.112.175-5.592,6.388-5.708,6.508s.023.5.247.5l.2-.1c.374-.348,3.514-3.884,5.046-5.641l.068-.071c-1.5,1.73-4.725,5.364-5.114,5.723l-.206.1"
              fill="#a5a7a9"
            />
            <path
              d="M1215.194,290.056c-.224,0-.325-.408-.247-.5s5.548-6.325,5.69-6.523a22.073,22.073,0,0,0-.748-4.262l.236,1.051a15.076,15.076,0,0,1,.5,3.211c-.108.149-5.57,6.388-5.682,6.523a.343.343,0,0,0,.239.486l.2-.094c.373-.34,3.4-3.76,4.96-5.543l.082-.086c-1.532,1.757-4.672,5.293-5.042,5.641l-.2.1"
              fill="#a4a6a9"
            />
            <path
              d="M1215.2,290.045c-.216,0-.314-.4-.239-.486s5.54-6.329,5.682-6.524a12.7,12.7,0,0,0-.508-3.211c.048.21.093.419.138.621a10.58,10.58,0,0,1,.374,2.587c-.138.19-5.563,6.388-5.675,6.523a.33.33,0,0,0,.232.471l.191-.094c.351-.325,3.3-3.641,4.882-5.446l.078-.086c-1.555,1.776-4.6,5.211-4.975,5.547l-.195.094"
              fill="#a2a4a7"
            />
            <path
              d="M1215.209,290.03c-.209,0-.307-.374-.232-.471s5.536-6.333,5.678-6.524a11.215,11.215,0,0,0-.373-2.586l.1.459a7.986,7.986,0,0,1,.287,2.131c-.138.187-5.558,6.4-5.67,6.523a.315.315,0,0,0,.224.456l.187-.089c.336-.314,3.188-3.514,4.788-5.342l.09-.094c-1.581,1.806-4.531,5.122-4.882,5.447l-.191.093"
              fill="#a2a4a6"
            />
            <path
              d="M1215.216,290.015c-.2,0-.295-.374-.224-.456s5.533-6.355,5.671-6.524a8.11,8.11,0,0,0-.288-2.13l.075.336a6.483,6.483,0,0,1,.224,1.794c-.134.184-5.559,6.4-5.663,6.524a.3.3,0,0,0,.217.441l.179-.09c.325-.3,3.084-3.394,4.7-5.234l.09-.093c-1.6,1.828-4.453,5.028-4.789,5.342l-.187.09"
              fill="#a0a2a5"
            />
            <path
              d="M1215.224,290c-.2,0-.288-.374-.217-.441s5.529-6.355,5.663-6.524a6.58,6.58,0,0,0-.22-1.794l.063.28a5.086,5.086,0,0,1,.169,1.5c-.131.183-5.552,6.4-5.656,6.527a.285.285,0,0,0,.2.426l.176-.086c.314-.292,2.991-3.279,4.6-5.125l.09-.1c-1.615,1.839-4.374,4.93-4.7,5.233l-.179.09"
              fill="#9fa1a4"
            />
            <path
              d="M1215.231,289.985c-.19,0-.276-.344-.209-.426s5.525-6.355,5.656-6.527a4.4,4.4,0,0,0-.168-1.5l.048.225a4,4,0,0,1,.127,1.289c-.13.176-5.547,6.4-5.652,6.527a.273.273,0,0,0,.2.411l.168-.082c.3-.28,2.871-3.155,4.505-5.013l.1-.1c-1.634,1.828-4.288,4.815-4.6,5.122l-.176.086"
              fill="#9ea0a2"
            />
            <path
              d="M1215.239,289.97c-.183,0-.269-.333-.2-.411s5.521-6.355,5.652-6.524a4.059,4.059,0,0,0-.127-1.289l.041.183a3.308,3.308,0,0,1,.1,1.121c-.127.176-5.543,6.4-5.644,6.524a.267.267,0,0,0,.194.4l.164-.083c.288-.269,2.759-3.031,4.4-4.886l.1-.112c-1.637,1.869-4.206,4.733-4.508,5.013l-.169.082"
              fill="#9d9fa2"
            />
            <path
              d="M1215.246,289.955c-.179,0-.258-.322-.194-.4s5.517-6.355,5.645-6.523a3.342,3.342,0,0,0-.1-1.122l.034.165a2.644,2.644,0,0,1,.071.942c-.123.172-5.536,6.407-5.641,6.527a.243.243,0,0,0,.187.373l.157-.078c.277-.258,2.65-2.908,4.288-4.759l.1-.116c-1.641,1.869-4.112,4.617-4.4,4.886l-.165.082"
              fill="#9b9da0"
            />
            <path
              d="M1215.254,289.944c-.172,0-.251-.311-.187-.374s5.514-6.355,5.641-6.527a2.646,2.646,0,0,0-.068-.942l.03.138a2.1,2.1,0,0,1,.045.8c-.123.168-5.532,6.411-5.633,6.527a.246.246,0,0,0,.179.374l.153-.075c.266-.251,2.535-2.778,4.169-4.621l.119-.127c-1.641,1.869-4.011,4.486-4.288,4.759l-.157.079"
              fill="#9b9d9f"
            />
            <path
              d="M1215.261,289.914c-.164,0-.239-.3-.179-.374s5.51-6.355,5.633-6.527a2.074,2.074,0,0,0-.045-.8l.027.123a1.718,1.718,0,0,1,.029.681c-.119.164-5.528,6.414-5.626,6.527a.233.233,0,0,0,.169.355l.149-.071c.251-.24,2.426-2.658,4.049-4.486l.116-.123c-1.634,1.842-3.9,4.37-4.169,4.62l-.153.075"
              fill="#999b9e"
            />
            <path
              d="M1215.269,289.914c-.157,0-.228-.284-.169-.355s5.507-6.355,5.608-6.527a1.734,1.734,0,0,0-.03-.681l.022.1a1.377,1.377,0,0,1,0,.572c-.116.161-5.521,6.418-5.607,6.527a.226.226,0,0,0,.164.34l.143-.071c.239-.228,2.31-2.531,3.917-4.34l.127-.135c-1.626,1.828-3.8,4.247-4.048,4.486l-.146.071"
              fill="#989a9d"
            />
            <path
              d="M1215.276,289.914c-.153,0-.22-.273-.164-.34s5.5-6.355,5.607-6.527a1.377,1.377,0,0,0,0-.572l.019.093a1.14,1.14,0,0,1,0,.479c-.116.157-5.522,6.418-5.608,6.527a.213.213,0,0,0,.154.325l.138-.068c.228-.216,2.191-2.4,3.783-4.179l.135-.146c-1.612,1.81-3.679,4.112-3.918,4.34l-.142.071"
              fill="#97999b"
            />
            <path
              d="M1215.284,289.884c-.146,0-.21-.258-.154-.325s5.5-6.355,5.608-6.527a1.108,1.108,0,0,0,0-.479v.079a1.239,1.239,0,0,1,0,.374v.029c-.228.285-5.51,6.415-5.608,6.52a.2.2,0,0,0,.15.31l.131-.063c.22-.206,2.086-2.277,3.648-4.03l.131-.139c-1.589,1.78-3.551,3.963-3.783,4.18l-.138.067"
              fill="#96989b"
            />
            <path
              d="M1215.291,289.869c-.138,0-.2-.247-.149-.31s5.375-6.236,5.607-6.52l-.034.052c-.549.662-5.465,6.355-5.551,6.468a.188.188,0,0,0,.142.3l.127-.064c.206-.194,1.963-2.142,3.495-3.854l.154-.164c-1.563,1.753-3.428,3.824-3.649,4.03l-.131.063"
              fill="#949699"
            />
            <path d="M1220.76,283.009a1.172,1.172,0,0,0,0-.374v.378" fill="#949699" />
            <path
              d="M1215.3,289.854c-.135,0-.195-.236-.142-.3s5-5.81,5.551-6.468l-.1.128-.03.033c-.968,1.144-5.327,6.213-5.406,6.307l.131.284.124-.064c.194-.187,1.843-2.007,3.338-3.678l.153-.165c-1.532,1.712-3.289,3.66-3.495,3.854l-.127.064"
              fill="#949698"
            />
            <path d="M1220.775,282.972l0-.269.015.06v.172Z" fill="#949698" />
            <path
              d="M1215.306,289.843l-.131-.284c.079-.094,4.441-5.167,5.406-6.307l-.273.333c-1.335,1.562-5.051,5.891-5.118,5.981l.124.269.115-.06c.184-.175,1.724-1.869,3.178-3.5l.157-.168c-1.495,1.671-3.144,3.492-3.338,3.679l-.124.063"
              fill="#929497"
            />
            <path d="M1220.786,282.934v-.172l.007.037,0,.052v0Z" fill="#929497" />
            <path
              d="M1215.314,289.824l-.124-.269c.071-.082,3.787-4.411,5.118-5.981l-.277.332c-1.495,1.769-4.762,5.567-4.83,5.641l.034.213h0l.022.023h.045l.112-.056c.172-.165,1.6-1.738,2.991-3.3l.176-.187c-1.454,1.618-2.991,3.323-3.178,3.5l-.116.06"
              fill="#919396"
            />
            <path d="M1220.8,282.856" fill="#919396" />
            <path
              d="M1215.321,289.809h.007l.1-.052c.161-.154,1.477-1.6,2.819-3.092l.179-.194c-1.4,1.559-2.826,3.132-2.99,3.3l-.109.056"
              fill="#909295"
            />
            <path
              d="M1215.239,289.772l-.034-.213c.064-.075,3.32-3.873,4.83-5.641l-.262.321c-1.592,1.869-4.486,5.256-4.549,5.323l.022.191v.022"
              fill="#909295"
            />
            <path
              d="M1215.328,289.794h-.011l.1-.052c.15-.142,1.346-1.462,2.617-2.871l.2-.21c-1.342,1.5-2.658,2.939-2.819,3.092l-.1.052"
              fill="#8f9194"
            />
            <path
              d="M1215.246,289.749l-.026-.19c.06-.068,2.957-3.455,4.553-5.324l-.28.341c-1.63,1.914-4.2,4.919-4.258,4.983v.172h0"
              fill="#8f9194"
            />
            <path
              d="M1215.336,289.783h-.022l.1-.049c.134-.134,1.218-1.323,2.411-2.646l.2-.21c-1.271,1.41-2.467,2.729-2.617,2.871l-.1.053"
              fill="#8e9092"
            />
            <path
              d="M1215.254,289.731v-.172c.056-.064,2.616-3.069,4.258-4.983l-.3.373-3.962,4.591v.157h0"
              fill="#8e9092"
            />
            <path
              d="M1215.343,289.768h0l.09-.049c.123-.119,1.084-1.177,2.191-2.4l.224-.239c-1.2,1.323-2.277,2.516-2.411,2.647l-.094.048"
              fill="#8d8f92"
            />
            <path
              d="M1215.261,289.716v-.157c.049-.06,2.318-2.714,3.944-4.621l-.318.374-3.615,4.239v.142h0"
              fill="#8d8f92"
            />
            <path
              d="M1215.351,289.753h0l.082-.045c.112-.112.95-1.032,1.959-2.142l.232-.269c-1.122,1.222-2.064,2.277-2.191,2.4l-.086.049"
              fill="#8b8d90"
            />
            <path
              d="M1215.272,289.7v-.142l3.615-4.239-.374.437-3.233,3.787v.131h0"
              fill="#8b8d90"
            />
            <path
              d="M1215.358,289.738h0l.079-.041c.1-.1.807-.871,1.693-1.851l.262-.28c-1.006,1.122-1.847,2.03-1.959,2.142l-.082.045"
              fill="#8b8d8f"
            />
            <path
              d="M1215.284,289.686v-.124l3.237-3.8-.344.418-2.878,3.387v.112h0"
              fill="#8b8d8f"
            />
            <path
              d="M1215.381,289.723h0l.063-.041c.09-.086.67-.722,1.432-1.559l.262-.28c-.89.979-1.593,1.749-1.694,1.869l-.078.041"
              fill="#898b8e"
            />
            <path d="M1215.3,289.671v-.112l2.878-3.387-.4.486-2.463,2.9v.1h0" fill="#898b8e" />
            <path
              d="M1215.381,289.708h0l.056-.034c.071-.074.519-.56,1.121-1.241l.288-.306c-.747.837-1.342,1.473-1.432,1.559l-.063.037"
              fill="#888a8d"
            />
            <path d="M1215.306,289.656v-.1l2.464-2.9-.415.5-2.034,2.4v.082h0" fill="#888a8d" />
            <path
              d="M1215.381,289.693h0l.041-.026c.06-.06.374-.374.8-.878l.332-.356c-.616.681-1.065,1.17-1.121,1.245l-.056.034"
              fill="#87898b"
            />
            <path
              d="M1215.321,289.641v-.082c.03-.03.953-1.122,2.034-2.4l-.456.557-1.563,1.84v.067h0"
              fill="#87898b"
            />
            <path
              d="M1215.411,289.674l.018-.018.023-.019.437-.471.374-.374c-.441.486-.748.819-.8.879l-.041.026"
              fill="#86888b"
            />
            <path d="M1215.332,289.626v-.067l1.563-1.84-.494.6-1.054,1.245v.052h0" fill="#86888b" />
            <path d="M1215.433,289.652l.456-.493-.437.471-.023.018" fill="#858789" />
            <path d="M1215.343,289.611v-.052l1.055-1.245-.509.62-.508.606v.037h0" fill="#858789" />
            <path d="M1215.358,289.6v-.034l.531-.628-.508.625h0l-.026.033" fill="#848688" />
            <path d="M1215.381,289.562l0,0v0" fill="#838487" />
            <path
              d="M1218.409,290.744c-.438,0-.434-.748-.251-.969.251-.306,5.552-6.317,5.858-6.751l.161-.2h0l-.168.21c-.307.433-5.608,6.437-5.851,6.728-.183.221-.187.961.247.961a1.126,1.126,0,0,0,.662-.374c1.076-.968,5.607-6.127,6.074-6.766a4.2,4.2,0,0,0,.419-.7h0a4.556,4.556,0,0,1-.422.7c-.46.643-5,5.805-6.083,6.777a1.117,1.117,0,0,1-.669.374"
              fill="#fff"
            />
            <path
              d="M1218.416,290.732c-.433,0-.426-.747-.246-.96.246-.3,5.54-6.307,5.85-6.729l.168-.209h0l-.172.22c-.306.426-5.607,6.426-5.839,6.729-.179.217-.183.949.247.949a1.093,1.093,0,0,0,.654-.351c1.065-.961,5.607-6.119,6.063-6.755a4.289,4.289,0,0,0,.415-.7h0a4.305,4.305,0,0,1-.418.7c-.456.639-5,5.8-6.075,6.766a1.12,1.12,0,0,1-.662.374"
              fill="#fcfcfc"
            />
            <path
              d="M1218.424,290.721c-.43,0-.426-.747-.247-.949.247-.3,5.536-6.3,5.839-6.729l.172-.221h0l-.179.228c-.3.427-5.589,6.419-5.832,6.729-.176.217-.183.942.243.942a1.086,1.086,0,0,0,.647-.347c1.058-.95,5.607-6.116,6.056-6.729a4.4,4.4,0,0,0,.415-.692h0a4.245,4.245,0,0,1-.415.7c-.453.632-5,5.795-6.064,6.752a1.1,1.1,0,0,1-.654.351"
              fill="#fafafb"
            />
            <path
              d="M1218.431,290.706c-.426,0-.422-.725-.243-.942.247-.3,5.529-6.291,5.832-6.729l.179-.228h0l-.187.236c-.3.422-5.577,6.411-5.82,6.706-.176.213-.179.931.239.931a1.061,1.061,0,0,0,.643-.344c1.047-.942,5.608-6.108,6.045-6.729a4.193,4.193,0,0,0,.411-.684h0a4.26,4.26,0,0,1-.415.692c-.448.628-5,5.79-6.056,6.729a1.083,1.083,0,0,1-.646.347"
              fill="#f8f8f9"
            />
            <path
              d="M1218.439,290.7c-.419,0-.415-.718-.24-.931.243-.3,5.522-6.287,5.821-6.706l.187-.232h0l-.2.239c-.3.419-5.57,6.4-5.813,6.7-.175.217-.179.928.24.928a1.065,1.065,0,0,0,.635-.344c1.043-.935,5.608-6.1,6.037-6.729a4.184,4.184,0,0,0,.408-.684h0a4.28,4.28,0,0,1-.411.684c-.445.62-4.995,5.787-6.045,6.729a1.072,1.072,0,0,1-.643.344"
              fill="#f7f7f8"
            />
            <path
              d="M1218.45,290.684c-.415,0-.411-.71-.239-.927.243-.292,5.514-6.28,5.813-6.695l.194-.243h0l-.2.246c-.3.415-5.562,6.4-5.8,6.688-.175.213-.175.916.236.916a1.064,1.064,0,0,0,.628-.34c1.032-.927,5.607-6.09,6.026-6.707a3.955,3.955,0,0,0,.4-.676h0a4.263,4.263,0,0,1-.408.684c-.441.617-4.994,5.779-6.037,6.729a1.058,1.058,0,0,1-.636.343"
              fill="#f5f6f6"
            />
            <path
              d="M1218.457,290.661c-.411,0-.411-.7-.235-.915.239-.288,5.506-6.273,5.8-6.688l.2-.247h0l-.209.258c-.291.411-5.555,6.389-5.79,6.677-.172.213-.176.908.231.908a1.036,1.036,0,0,0,.625-.333c1.024-.919,5.585-6.086,6.018-6.7a3.93,3.93,0,0,0,.4-.669h0a3.923,3.923,0,0,1-.4.677c-.437.616-4.994,5.779-6.026,6.706a1.052,1.052,0,0,1-.628.34"
              fill="#f4f5f5"
            />
            <path
              d="M1218.465,290.661c-.408,0-.4-.7-.232-.908.236-.288,5.5-6.265,5.791-6.676l.209-.258h0l-.217.265c-.291.407-5.547,6.381-5.779,6.665-.168.21-.176.9.232.9a1.027,1.027,0,0,0,.616-.332c1.013-.912,5.578-6.079,6.008-6.677a3.969,3.969,0,0,0,.392-.661h0a4.049,4.049,0,0,1-.385.658c-.437.609-4.994,5.771-6.018,6.7a1.04,1.04,0,0,1-.625.332"
              fill="#f3f3f4"
            />
            <path
              d="M1218.472,290.661c-.4,0-.4-.687-.228-.9.236-.288,5.488-6.261,5.78-6.669l.216-.265h0l-.224.273c-.288.4-5.536,6.355-5.768,6.657-.168.206-.172.886.228.886a1.013,1.013,0,0,0,.609-.329c1.006-.9,5.57-6.067,5.982-6.665a4.011,4.011,0,0,0,.373-.65h0a4.05,4.05,0,0,1-.4.661c-.43.6-4.994,5.765-6.007,6.677a1.034,1.034,0,0,1-.617.333"
              fill="#f2f2f3"
            />
            <path
              d="M1218.48,290.639c-.4,0-.4-.68-.228-.886.232-.284,5.48-6.254,5.768-6.658l.224-.273h0l-.232.281c-.287.4-5.528,6.355-5.76,6.646-.165.206-.168.879.224.879a1.014,1.014,0,0,0,.606-.325c1-.9,5.566-6.064,5.981-6.655a3.883,3.883,0,0,0,.374-.642h0a4.069,4.069,0,0,1-.374.65c-.426.6-4.994,5.764-5.981,6.665a1.017,1.017,0,0,1-.61.329"
              fill="#f0f0f1"
            />
            <path
              d="M1218.487,290.628c-.4,0-.374-.677-.224-.879.232-.28,5.476-6.246,5.761-6.646l.228-.281h0l-.24.288c-.284.4-5.521,6.355-5.753,6.636-.164.2-.168.871.225.871a1,1,0,0,0,.6-.322c.99-.89,5.558-6.056,5.981-6.643a4.049,4.049,0,0,0,.374-.632h0a4,4,0,0,1-.374.64c-.426.59-4.994,5.757-5.981,6.654a1.02,1.02,0,0,1-.606.325"
              fill="#eff0f0"
            />
            <path
              d="M1218.5,290.617c-.373,0-.373-.67-.224-.871.228-.277,5.465-6.24,5.75-6.636l.239-.288h0l-.247.3c-.28.392-5.514,6.355-5.738,6.624-.165.2-.165.86.22.86a.988.988,0,0,0,.591-.322c.98-.882,5.555-6.048,5.981-6.632a3.753,3.753,0,0,0,.374-.62h0a3.792,3.792,0,0,1-.374.632c-.422.587-4.99,5.753-5.981,6.643a1,1,0,0,1-.6.321"
              fill="#edeeef"
            />
            <path
              d="M1218.506,290.605c-.374,0-.374-.661-.221-.859.228-.277,5.458-6.232,5.739-6.625l.246-.3h0l-.254.3c-.28.374-5.506,6.355-5.731,6.617-.164.2-.164.848.217.848a.962.962,0,0,0,.587-.318c.972-.874,5.548-6.041,5.963-6.616a3.728,3.728,0,0,0,.373-.613h0a3.747,3.747,0,0,1-.373.62c-.419.58-4.991,5.746-5.982,6.632a.992.992,0,0,1-.59.321"
              fill="#ededee"
            />
            <path
              d="M1218.513,290.59c-.373,0-.373-.654-.216-.848.224-.273,5.454-6.224,5.73-6.617l.255-.3h0l-.262.311c-.277.373-5.5,6.332-5.723,6.605-.161.194-.165.841.216.841a.963.963,0,0,0,.606-.291c.964-.871,5.544-6.038,5.955-6.606a4.028,4.028,0,0,0,.374-.6h0a3.758,3.758,0,0,1-.374.613c-.415.576-4.991,5.738-5.962,6.617a.97.97,0,0,1-.587.317"
              fill="#ebecec"
            />
            <path
              d="M1218.521,290.579c-.374,0-.374-.647-.217-.841.224-.273,5.447-6.217,5.723-6.605l.262-.311h0l-.273.318c-.273.374-5.488,6.325-5.712,6.594-.161.195-.161.834.213.834a.958.958,0,0,0,.572-.31c.953-.86,5.536-6.026,5.944-6.6a3.688,3.688,0,0,0,.374-.59h0a4.006,4.006,0,0,1-.374.6c-.411.568-4.991,5.738-5.955,6.605a.97.97,0,0,1-.58.311"
              fill="#eaebec"
            />
            <path
              d="M1218.528,290.568c-.373,0-.373-.639-.213-.834.221-.269,5.439-6.209,5.712-6.594l.269-.318h0l-.276.326c-.273.373-5.48,6.317-5.7,6.583-.157.19-.161.826.209.826a.955.955,0,0,0,.569-.307c.945-.856,5.532-6.022,5.936-6.583a3.6,3.6,0,0,0,.351-.583h0a3.762,3.762,0,0,1-.374.591c-.407.568-4.99,5.73-5.943,6.594a.966.966,0,0,1-.576.31"
              fill="#e9e9ea"
            />
            <path
              d="M1218.54,290.557c-.374,0-.374-.632-.21-.826.217-.266,5.428-6.2,5.7-6.583l.277-.326h0l-.288.333c-.269.374-5.473,6.31-5.693,6.576-.157.19-.157.815.209.815a.946.946,0,0,0,.561-.3c.938-.849,5.525-6.015,5.925-6.572a3.634,3.634,0,0,0,.344-.572h0a3.792,3.792,0,0,1-.352.583c-.4.561-4.99,5.727-5.936,6.583a.96.96,0,0,1-.568.307"
              fill="#e8e8e9"
            />
            <path
              d="M1218.547,290.545c-.374,0-.374-.624-.209-.814.217-.266,5.42-6.2,5.689-6.576l.288-.333h0l-.295.341c-.269.373-5.465,6.3-5.682,6.564-.154.187-.154.807.209.807a.917.917,0,0,0,.553-.3c.931-.837,5.522-6,5.918-6.557a3.521,3.521,0,0,0,.34-.557h0a3.607,3.607,0,0,1-.344.572c-.4.557-4.987,5.724-5.925,6.572a.943.943,0,0,1-.561.3"
              fill="#e6e7e8"
            />
            <path
              d="M1218.555,290.534c-.374,0-.374-.617-.206-.8.217-.266,5.413-6.191,5.682-6.565l.3-.34h0a2.426,2.426,0,0,0-.306.351c-.266.374-5.458,6.292-5.671,6.554-.153.186-.153.8.206.8a.906.906,0,0,0,.549-.3c.92-.829,5.514-5.981,5.906-6.545a3.505,3.505,0,0,0,.333-.55h0a3.6,3.6,0,0,1-.34.561c-.4.55-4.987,5.72-5.918,6.557a.922.922,0,0,1-.553.3"
              fill="#e5e6e7"
            />
            <path
              d="M1218.562,290.519c-.374,0-.374-.609-.206-.8.214-.258,5.406-6.183,5.671-6.553a2.319,2.319,0,0,1,.326-.359h0a2.123,2.123,0,0,0-.314.374c-.262.374-5.451,6.284-5.66,6.542-.153.183-.153.785.2.785a.9.9,0,0,0,.542-.292c.912-.826,5.51-5.981,5.9-6.534a3.429,3.429,0,0,0,.329-.542h0a3.515,3.515,0,0,1-.333.549c-.393.546-4.987,5.716-5.91,6.546a.907.907,0,0,1-.55.295"
              fill="#e3e4e5"
            />
            <path
              d="M1218.573,290.508c-.355,0-.351-.6-.2-.785.21-.258,5.4-6.179,5.66-6.542a2.154,2.154,0,0,1,.314-.374h0a2.269,2.269,0,0,0-.325.374c-.258.374-5.439,6.28-5.652,6.535-.15.183-.154.777.2.777a.9.9,0,0,0,.534-.288c.905-.818,5.507-5.981,5.892-6.523a3.4,3.4,0,0,0,.321-.531h0a3.255,3.255,0,0,1-.329.542c-.374.542-4.986,5.709-5.9,6.535a.9.9,0,0,1-.542.291"
              fill="#e2e3e4"
            />
            <path
              d="M1218.581,290.5c-.352,0-.348-.6-.2-.778.209-.254,5.39-6.171,5.652-6.534a2.269,2.269,0,0,1,.325-.374h0a2.156,2.156,0,0,0-.333.374c-.258.374-5.435,6.273-5.641,6.523-.149.18-.149.77.2.77a.879.879,0,0,0,.531-.288c.9-.811,5.5-5.981,5.88-6.508a3.535,3.535,0,0,0,.318-.519h0a3.556,3.556,0,0,1-.326.53c-.374.535-4.983,5.705-5.887,6.524a.907.907,0,0,1-.535.287"
              fill="#e1e2e3"
            />
            <path
              d="M1218.588,290.486c-.344,0-.344-.591-.2-.77.209-.251,5.387-6.161,5.641-6.524a2.229,2.229,0,0,1,.333-.373h0a2.231,2.231,0,0,0-.34.373c-.255.374-5.425,6.266-5.634,6.512-.146.18-.146.748.194.748a.883.883,0,0,0,.524-.28c.886-.8,5.491-5.981,5.873-6.5a3.66,3.66,0,0,0,.31-.508h0a3.544,3.544,0,0,1-.318.519c-.374.531-4.983,5.7-5.88,6.512a.887.887,0,0,1-.531.284"
              fill="#e0e1e2"
            />
            <path
              d="M1218.6,290.474c-.34,0-.34-.579-.195-.747.206-.251,5.38-6.157,5.634-6.512a2.238,2.238,0,0,1,.34-.374h0a2.206,2.206,0,0,0-.348.374c-.25.351-5.416,6.254-5.607,6.5-.146.175-.146.747.194.747a.862.862,0,0,0,.516-.276c.879-.8,5.484-5.982,5.862-6.486a3.657,3.657,0,0,0,.306-.5h0a3.559,3.559,0,0,1-.31.508c-.374.527-4.983,5.694-5.873,6.5a.872.872,0,0,1-.523.28"
              fill="#dedfe1"
            />
            <path
              d="M1218.6,290.463c-.34,0-.336-.575-.194-.747.2-.247,5.372-6.15,5.607-6.5a2.2,2.2,0,0,1,.348-.374h0a2.2,2.2,0,0,0-.374.374c-.251.351-5.413,6.246-5.607,6.493-.142.172-.142.748.19.748a.848.848,0,0,0,.512-.277c.868-.789,5.481-5.959,5.854-6.471a3.362,3.362,0,0,0,.3-.493h0a3.668,3.668,0,0,1-.306.5c-.374.519-4.983,5.689-5.862,6.486a.862.862,0,0,1-.515.276"
              fill="#dedfe0"
            />
            <path
              d="M1218.611,290.452c-.333,0-.333-.568-.191-.748.2-.246,5.364-6.142,5.607-6.493a2.17,2.17,0,0,1,.374-.374h0a2.081,2.081,0,0,0-.374.393c-.246.347-5.4,6.239-5.607,6.482-.142.172-.142.748.187.748a.844.844,0,0,0,.505-.273c.863-.782,5.476-5.952,5.846-6.46a3.322,3.322,0,0,0,.3-.482h0a3.362,3.362,0,0,1-.3.493c-.373.516-4.983,5.686-5.854,6.471a.846.846,0,0,1-.508.277"
              fill="#dcddde"
            />
            <path
              d="M1218.622,290.441c-.329,0-.329-.561-.187-.748.2-.239,5.353-6.134,5.607-6.482a2.139,2.139,0,0,1,.374-.392h0a2.121,2.121,0,0,0-.374.4c-.246.344-5.394,6.231-5.607,6.47-.142.169-.142.726.183.726a.823.823,0,0,0,.5-.269c.856-.771,5.469-5.944,5.836-6.449a3.339,3.339,0,0,0,.287-.471h0a3.171,3.171,0,0,1-.3.482c-.374.509-4.983,5.679-5.843,6.46a.841.841,0,0,1-.5.273"
              fill="#dbdcde"
            />
            <path
              d="M1218.629,290.43c-.325,0-.325-.554-.187-.726.2-.239,5.346-6.127,5.608-6.47a2.085,2.085,0,0,1,.374-.4h0a2.041,2.041,0,0,0-.374.4c-.243.34-5.387,6.224-5.581,6.46-.139.168-.139.714.183.714a.82.82,0,0,0,.493-.266c.845-.747,5.465-5.932,5.824-6.437a3.141,3.141,0,0,0,.284-.463h0a3.44,3.44,0,0,1-.287.467c-.374.505-4.98,5.678-5.836,6.448a.828.828,0,0,1-.5.27"
              fill="#dadbdc"
            />
            <path
              d="M1218.637,290.415c-.322,0-.322-.546-.183-.714.2-.24,5.338-6.12,5.581-6.46a2.1,2.1,0,0,1,.374-.4h0a1.931,1.931,0,0,0-.374.408c-.239.336-5.376,6.216-5.57,6.452-.135.164-.135.706.183.706a.817.817,0,0,0,.486-.261c.837-.748,5.458-5.929,5.817-6.426a3.341,3.341,0,0,0,.28-.453h0a3.514,3.514,0,0,1-.288.464c-.374.5-4.979,5.671-5.824,6.437a.82.82,0,0,1-.493.266"
              fill="#d9dadb"
            />
            <path
              d="M1218.644,290.4c-.317,0-.317-.538-.179-.706.194-.236,5.331-6.116,5.57-6.452a1.907,1.907,0,0,1,.374-.408h0a1.939,1.939,0,0,0-.393.411c-.239.333-5.372,6.21-5.562,6.441-.135.165-.135.7.179.7a.8.8,0,0,0,.479-.258c.829-.748,5.454-5.922,5.8-6.411a3.21,3.21,0,0,0,.273-.445h0a3.27,3.27,0,0,1-.28.452c-.374.5-4.98,5.667-5.817,6.426a.819.819,0,0,1-.486.262"
              fill="#d7d8da"
            />
            <path
              d="M1218.655,290.392c-.314,0-.314-.534-.179-.7.194-.232,5.327-6.108,5.563-6.441a1.981,1.981,0,0,1,.4-.411h0a1.9,1.9,0,0,0-.4.415c-.236.332-5.361,6.2-5.551,6.429-.135.165-.135.688.175.688a.783.783,0,0,0,.475-.258c.819-.747,5.447-5.914,5.8-6.4a3.227,3.227,0,0,0,.269-.433h0a3.176,3.176,0,0,1-.273.445c-.355.486-4.979,5.659-5.8,6.411a.8.8,0,0,1-.479.258"
              fill="#d6d7d9"
            />
            <path
              d="M1218.663,290.381c-.31,0-.31-.527-.176-.688.191-.232,5.316-6.1,5.552-6.43a1.923,1.923,0,0,1,.4-.415h0a1.931,1.931,0,0,0-.408.419c-.232.325-5.353,6.191-5.544,6.419-.131.16-.131.68.176.68a.775.775,0,0,0,.467-.254c.815-.748,5.439-5.907,5.791-6.389a2.987,2.987,0,0,0,.261-.422h0a3.217,3.217,0,0,1-.269.433c-.351.486-4.979,5.656-5.8,6.4a.788.788,0,0,1-.474.258"
              fill="#d4d6d7"
            />
            <path
              d="M1218.67,290.37c-.3,0-.306-.52-.175-.681.19-.228,5.312-6.093,5.544-6.418a1.9,1.9,0,0,1,.407-.419h0a1.871,1.871,0,0,0-.415.423c-.232.321-5.346,6.183-5.533,6.407-.13.157-.13.669.172.669a.771.771,0,0,0,.46-.25c.8-.729,5.436-5.9,5.78-6.378a2.959,2.959,0,0,0,.257-.415h0a2.911,2.911,0,0,1-.261.423c-.348.482-4.976,5.652-5.791,6.388a.782.782,0,0,1-.467.255"
              fill="#d4d5d7"
            />
            <path
              d="M1218.678,290.359c-.3,0-.3-.513-.172-.67.187-.224,5.3-6.085,5.533-6.407a1.885,1.885,0,0,1,.414-.422h0a1.8,1.8,0,0,0-.422.426c-.228.321-5.338,6.179-5.521,6.4-.127.157-.127.658.168.658a.749.749,0,0,0,.452-.247c.8-.718,5.432-5.892,5.772-6.355a3.058,3.058,0,0,0,.251-.4h0a3.059,3.059,0,0,1-.258.415c-.344.479-4.98,5.652-5.78,6.378a.771.771,0,0,1-.46.25"
              fill="#d2d3d5"
            />
            <path
              d="M1218.685,290.347c-.3,0-.3-.5-.168-.658.187-.224,5.293-6.078,5.522-6.4a1.8,1.8,0,0,1,.422-.427h0a1.816,1.816,0,0,0-.426.427c-.225.317-5.331,6.168-5.51,6.388-.127.157-.127.651.164.651a.749.749,0,0,0,.449-.243c.785-.711,5.424-5.888,5.76-6.355a2.907,2.907,0,0,0,.247-.374h0a2.952,2.952,0,0,1-.25.4c-.341.471-4.976,5.644-5.772,6.355a.748.748,0,0,1-.453.246"
              fill="#d0d2d3"
            />
            <path
              d="M1218.693,290.332c-.292,0-.292-.5-.165-.65.184-.221,5.286-6.071,5.511-6.389a1.874,1.874,0,0,1,.426-.426h0a1.774,1.774,0,0,0-.434.43c-.224.31-5.319,6.161-5.5,6.377-.127.154-.123.643.165.643a.745.745,0,0,0,.441-.239c.778-.706,5.42-5.88,5.753-6.355a2.852,2.852,0,0,0,.239-.374h0a2.912,2.912,0,0,1-.246.374c-.337.468-4.976,5.641-5.761,6.355a.747.747,0,0,1-.449.243"
              fill="#d0d1d3"
            />
            <path
              d="M1218.7,290.325c-.288,0-.288-.49-.164-.643.183-.221,5.278-6.067,5.5-6.377a1.756,1.756,0,0,1,.434-.434h0a1.7,1.7,0,0,0-.437.43c-.221.31-5.312,6.153-5.492,6.355-.123.153-.123.635.161.635a.744.744,0,0,0,.433-.235c.771-.7,5.413-5.873,5.746-6.329a2.941,2.941,0,0,0,.232-.374h0a2.864,2.864,0,0,1-.239.374c-.333.464-4.976,5.633-5.754,6.355a.743.743,0,0,1-.441.239"
              fill="#cecfd1"
            />
            <path
              d="M1218.712,290.31c-.285,0-.288-.482-.161-.632.179-.217,5.271-6.059,5.491-6.355a1.756,1.756,0,0,1,.438-.43h0a1.733,1.733,0,0,0-.445.434c-.221.3-5.305,6.142-5.484,6.355-.124.149-.12.624.161.624a.714.714,0,0,0,.426-.232c.747-.691,5.409-5.865,5.734-6.313a3.1,3.1,0,0,0,.228-.374h0a2.954,2.954,0,0,1-.232.374c-.329.456-4.975,5.629-5.745,6.328a.746.746,0,0,1-.434.236"
              fill="#cdcfd0"
            />
            <path
              d="M1218.719,290.288c-.28,0-.28-.475-.161-.625.18-.213,5.264-6.052,5.484-6.355a1.726,1.726,0,0,1,.445-.433h0a1.71,1.71,0,0,0-.448.437c-.217.3-5.3,6.134-5.473,6.355-.124.146-.12.613.157.613a.7.7,0,0,0,.422-.228c.748-.684,5.4-5.858,5.727-6.3a2.841,2.841,0,0,0,.221-.351h0a3.041,3.041,0,0,1-.228.374c-.329.452-4.972,5.607-5.735,6.314a.7.7,0,0,1-.426.231"
              fill="#cbcdcf"
            />
            <path
              d="M1218.745,290.288c-.276,0-.276-.468-.157-.614.176-.213,5.256-6.044,5.473-6.354a1.7,1.7,0,0,1,.449-.438h0a1.67,1.67,0,0,0-.453.438c-.213.3-5.289,6.13-5.461,6.354-.12.143-.116.606.153.606a.694.694,0,0,0,.415-.224c.748-.673,5.4-5.847,5.716-6.292l.216-.34h0a3.034,3.034,0,0,1-.22.352c-.322.448-4.972,5.607-5.727,6.3a.709.709,0,0,1-.4.21"
              fill="#caccce"
            />
            <path
              d="M1218.745,290.288c-.273,0-.276-.46-.153-.606.176-.209,5.233-6.037,5.461-6.355a1.7,1.7,0,0,1,.453-.437h0a1.622,1.622,0,0,0-.46.441c-.213.3-5.282,6.119-5.454,6.329-.116.142-.116.6.153.6a.675.675,0,0,0,.411-.225c.748-.669,5.391-5.842,5.709-6.28l.209-.329h0l-.217.34c-.321.441-4.972,5.608-5.716,6.292a.684.684,0,0,1-.414.224"
              fill="#c9cacd"
            />
            <path
              d="M1218.745,290.265c-.269,0-.269-.456-.153-.6.172-.206,5.233-6.033,5.454-6.329a1.6,1.6,0,0,1,.46-.437h0a1.6,1.6,0,0,0-.464.437c-.209.292-5.274,6.116-5.443,6.318-.115.142-.112.587.15.587a.686.686,0,0,0,.4-.221c.725-.658,5.383-5.831,5.7-6.265l.205-.322h0l-.209.329c-.318.438-4.972,5.608-5.708,6.281a.681.681,0,0,1-.411.224"
              fill="#c8cacc"
            />
            <path
              d="M1218.745,290.254c-.265,0-.265-.449-.149-.587.168-.206,5.233-6.026,5.443-6.318a1.6,1.6,0,0,1,.463-.437h0a1.559,1.559,0,0,0-.467.441c-.206.288-5.264,6.105-5.432,6.307-.116.138-.112.579.146.579a.669.669,0,0,0,.4-.217c.722-.654,5.38-5.828,5.69-6.254l.2-.31h0l-.206.321c-.31.434-4.972,5.608-5.7,6.266a.674.674,0,0,1-.4.22"
              fill="#c6c8ca"
            />
            <path
              d="M1218.745,290.243c-.258,0-.261-.441-.146-.58.169-.2,5.234-6.015,5.436-6.306a1.547,1.547,0,0,1,.467-.441h0a1.538,1.538,0,0,0-.471.437c-.206.288-5.256,6.1-5.424,6.3-.112.135-.109.568.146.568a.645.645,0,0,0,.374-.213c.71-.643,5.371-5.82,5.678-6.243l.191-.3h0l-.2.31c-.31.426-4.971,5.608-5.689,6.254a.668.668,0,0,1-.4.217"
              fill="#c6c7c9"
            />
            <path
              d="M1218.768,290.228c-.255,0-.258-.434-.146-.568.164-.2,5.233-6.012,5.424-6.3a1.529,1.529,0,0,1,.471-.438h0a1.506,1.506,0,0,0-.475.434c-.2.284-5.233,6.089-5.413,6.288-.112.134-.108.56.142.56a.645.645,0,0,0,.374-.209c.7-.639,5.368-5.813,5.671-6.232l.183-.287h0l-.19.3c-.3.422-4.968,5.607-5.679,6.243a.65.65,0,0,1-.374.213"
              fill="#c4c6c8"
            />
            <path
              d="M1218.775,290.217c-.25,0-.254-.427-.142-.561.165-.2,5.211-6,5.413-6.288a1.506,1.506,0,0,1,.475-.434h0a1.486,1.486,0,0,0-.479.434c-.2.28-5.233,6.082-5.4,6.277-.113.134-.109.553.142.553a.625.625,0,0,0,.373-.21c.692-.628,5.361-5.8,5.66-6.216l.18-.281h0l-.184.288c-.3.415-4.968,5.608-5.671,6.232a.646.646,0,0,1-.373.209"
              fill="#c3c5c7"
            />
            <path
              d="M1218.786,290.209c-.246,0-.25-.419-.142-.549.161-.2,5.2-5.982,5.4-6.281a1.5,1.5,0,0,1,.478-.433h0a1.5,1.5,0,0,0-.478.433c-.2.277-5.234,6.075-5.394,6.266-.109.13-.1.542.138.542a.619.619,0,0,0,.374-.2c.684-.625,5.357-5.8,5.652-6.206l.172-.273h0l-.179.281c-.3.411-4.969,5.588-5.66,6.216a.63.63,0,0,1-.374.206"
              fill="#c1c3c5"
            />
            <path
              d="M1218.794,290.194c-.243,0-.247-.411-.139-.542.161-.194,5.2-5.981,5.4-6.265a1.493,1.493,0,0,1,.478-.434h0a1.43,1.43,0,0,0-.478.434c-.2.273-5.234,6.067-5.383,6.258-.109.127-.1.534.134.534a.612.612,0,0,0,.374-.2c.677-.617,5.349-5.794,5.641-6.194l.168-.258h0l-.172.269c-.3.4-4.968,5.581-5.652,6.2a.615.615,0,0,1-.374.2"
              fill="#c1c2c4"
            />
            <path
              d="M1218.8,290.183c-.239,0-.243-.4-.134-.535.157-.19,5.188-5.981,5.383-6.257a1.423,1.423,0,0,1,.482-.434h0a1.38,1.38,0,0,0-.482.426c-.2.269-5.234,6.06-5.372,6.247-.1.127-.1.523.134.523a.612.612,0,0,0,.374-.194c.669-.61,5.346-5.787,5.634-6.183l.161-.247h0l-.169.258c-.291.4-4.968,5.577-5.644,6.194a.62.62,0,0,1-.374.2"
              fill="#bfc1c3"
            />
            <path
              d="M1218.809,290.172c-.236,0-.239-.4-.135-.524.157-.187,5.181-5.981,5.372-6.246a1.374,1.374,0,0,1,.482-.43h0a1.344,1.344,0,0,0-.482.426c-.191.265-5.207,6.048-5.364,6.235-.1.124-.1.516.131.516a.588.588,0,0,0,.355-.19c.661-.6,5.342-5.78,5.607-6.172l.153-.236h0l-.157.247c-.288.4-4.968,5.577-5.633,6.183a.609.609,0,0,1-.374.194"
              fill="#bec0c2"
            />
            <path
              d="M1218.816,290.157c-.228,0-.235-.374-.131-.516.157-.187,5.178-5.981,5.365-6.236a1.352,1.352,0,0,1,.482-.426h0a1.318,1.318,0,0,0-.482.423c-.187.261-5.2,6.041-5.353,6.224-.1.123-.1.505.127.505a.59.59,0,0,0,.347-.187c.651-.6,5.335-5.772,5.608-6.161l.145-.224h0l-.153.235c-.288.374-4.968,5.57-5.607,6.172a.591.591,0,0,1-.355.191"
              fill="#bcbec1"
            />
            <path
              d="M1218.824,290.149c-.228,0-.228-.374-.127-.5.153-.184,5.166-5.982,5.353-6.225a1.315,1.315,0,0,1,.482-.422h0a1.29,1.29,0,0,0-.482.419c-.187.258-5.193,6.033-5.346,6.213-.1.119-.093.5.127.5a.572.572,0,0,0,.34-.187c.643-.587,5.327-5.768,5.608-6.149l.138-.214h0l-.146.225c-.28.374-4.964,5.566-5.607,6.16a.584.584,0,0,1-.348.187"
              fill="#bbbdc0"
            />
            <path
              d="M1218.835,290.138c-.224,0-.228-.374-.127-.5.149-.183,5.159-5.955,5.342-6.213a1.289,1.289,0,0,1,.482-.419h0a1.321,1.321,0,0,0-.479.415c-.183.258-5.184,6.026-5.334,6.206-.1.116-.093.486.123.486a.56.56,0,0,0,.337-.184c.632-.579,5.323-5.76,5.607-6.134l.131-.2h0l-.142.213c-.277.374-4.964,5.559-5.607,6.146a.577.577,0,0,1-.341.187"
              fill="#babcbe"
            />
            <path
              d="M1218.842,290.123c-.216,0-.224-.374-.123-.486.15-.179,5.151-5.951,5.334-6.2a1.282,1.282,0,0,1,.483-.415h0a1.236,1.236,0,0,0-.479.411c-.183.25-5.177,6.018-5.323,6.194-.1.116-.093.479.123.479a.543.543,0,0,0,.329-.18c.628-.568,5.316-5.749,5.589-6.119l.127-.191h0l-.135.2c-.272.374-4.96,5.555-5.607,6.134a.561.561,0,0,1-.336.184"
              fill="#b9bbbd"
            />
            <path
              d="M1218.85,290.112c-.213,0-.217-.374-.123-.479.145-.175,5.143-5.94,5.326-6.194a1.225,1.225,0,0,1,.475-.411h0a1.191,1.191,0,0,0-.475.4c-.179.25-5.169,6.011-5.315,6.183-.1.116-.094.471.116.471a.534.534,0,0,0,.321-.176c.617-.564,5.312-5.746,5.578-6.108l.119-.18h0l-.127.191c-.269.374-4.961,5.551-5.589,6.119a.541.541,0,0,1-.329.18"
              fill="#b7b9bc"
            />
            <path
              d="M1218.857,290.1c-.209,0-.213-.355-.119-.471.146-.172,5.136-5.933,5.315-6.183a1.2,1.2,0,0,1,.475-.4h0a1.188,1.188,0,0,0-.467.4c-.176.247-5.163,6-5.305,6.175-.093.113-.089.46.116.46a.525.525,0,0,0,.318-.172c.609-.557,5.305-5.738,5.57-6.1l.112-.168h0l-.119.179c-.27.374-4.961,5.548-5.582,6.109a.522.522,0,0,1-.321.175"
              fill="#b7b9bb"
            />
            <path
              d="M1218.865,290.089c-.206,0-.21-.347-.116-.459.142-.172,5.129-5.929,5.3-6.176a1.2,1.2,0,0,1,.468-.4h0a1.16,1.16,0,0,0-.464.374c-.175.243-5.155,5.981-5.3,6.164-.09.109-.086.453.116.453a.515.515,0,0,0,.31-.169c.6-.549,5.3-5.73,5.563-6.085l.1-.157h0l-.112.168c-.265.374-4.96,5.544-5.57,6.1a.523.523,0,0,1-.317.172"
              fill="#b5b7b9"
            />
            <path
              d="M1218.872,290.078c-.2,0-.2-.34-.116-.452.142-.168,5.122-5.921,5.3-6.164a1.171,1.171,0,0,1,.46-.374h0a1.147,1.147,0,0,0-.452.374c-.172.239-5.144,5.981-5.286,6.156-.09.109-.086.442.112.442a.5.5,0,0,0,.3-.165c.594-.542,5.293-5.723,5.551-6.075l.1-.145h0l-.1.157c-.262.373-4.961,5.536-5.563,6.085a.515.515,0,0,1-.31.169"
              fill="#b4b6b8"
            />
            <path
              d="M1218.884,290.067c-.2,0-.2-.333-.113-.441.139-.165,5.114-5.914,5.286-6.153a1.143,1.143,0,0,1,.453-.374h0a1.122,1.122,0,0,0-.442.374c-.168.235-5.136,5.981-5.274,6.142s-.086.433.108.433l.3-.164c.583-.535,5.29-5.716,5.544-6.06l.09-.134h0l-.1.145c-.258.352-4.957,5.533-5.552,6.075a.5.5,0,0,1-.3.164"
              fill="#b2b5b7"
            />
            <path
              d="M1218.891,290.056c-.194,0-.2-.325-.108-.434s5.106-5.906,5.274-6.142a1.157,1.157,0,0,1,.441-.374h0a1.1,1.1,0,0,0-.426.374c-.168.232-5.129,5.981-5.267,6.131s-.082.422.108.422l.292-.157c.576-.527,5.282-5.708,5.533-6.048l.082-.116h0l-.09.135c-.254.344-4.961,5.528-5.54,6.059l-.3.165"
              fill="#b1b4b6"
            />
            <path
              d="M1218.9,290.041c-.19,0-.194-.318-.108-.423s5.1-5.9,5.267-6.13a1.1,1.1,0,0,1,.426-.374h0a1.1,1.1,0,0,0-.411.374c-.164.231-5.121,5.981-5.256,6.123s-.082.415.1.415l.288-.153c.564-.52,5.278-5.7,5.521-6.038l.075-.108h0l-.082.116c-.251.34-4.961,5.521-5.533,6.048l-.292.157"
              fill="#b0b2b5"
            />
            <path
              d="M1218.906,290.03c-.187,0-.191-.311-.1-.415s5.088-5.892,5.252-6.124a1.1,1.1,0,0,1,.412-.373v.018a1.072,1.072,0,0,0-.4.344c-.16.228-5.113,5.955-5.233,6.112s-.079.408.1.408l.276-.154c.557-.512,5.271-5.693,5.518-6.022l.064-.093h0l-.075.108c-.247.337-4.957,5.518-5.521,6.037l-.288.154"
              fill="#afb1b4"
            />
            <path
              d="M1218.913,290.018c-.179,0-.186-.306-.1-.407s5.083-5.888,5.233-6.112a1.059,1.059,0,0,1,.4-.344v.019a1.047,1.047,0,0,0-.374.332c-.16.221-5.106,5.948-5.233,6.1-.082.1-.075.4.1.4l.273-.149c.549-.505,5.263-5.686,5.506-6.011l.056-.082h0l-.063.093c-.243.329-4.957,5.51-5.514,6.022l-.281.154"
              fill="#adb0b2"
            />
            <path
              d="M1218.925,290.007c-.176,0-.184-.3-.1-.4s5.076-5.88,5.233-6.1a1.077,1.077,0,0,1,.374-.333h0a1.037,1.037,0,0,0-.374.322c-.157.217-5.095,5.94-5.233,6.093s-.075.374.1.374l.269-.146c.538-.5,5.26-5.682,5.5-6l.049-.067h0l-.056.082c-.239.326-4.957,5.507-5.507,6.011l-.273.15"
              fill="#adafb1"
            />
            <path
              d="M1218.932,290c-.172,0-.179-.288-.1-.374s5.069-5.876,5.233-6.093a1.028,1.028,0,0,1,.374-.322h0a1.035,1.035,0,0,0-.347.314c-.157.213-5.088,5.933-5.234,6.082-.078.094-.075.374.094.374l.261-.142c.531-.489,5.252-5.671,5.488-5.981l.037-.052h0l-.048.067c-.236.322-4.953,5.5-5.495,6l-.266.146"
              fill="#abadb0"
            />
            <path
              d="M1218.94,289.981c-.169,0-.176-.28-.1-.374.127-.153,5.062-5.869,5.234-6.086a1.028,1.028,0,0,1,.348-.314v.023a.967.967,0,0,0-.333.3c-.153.213-5.08,5.925-5.207,6.074-.079.09-.072.374.093.374l.254-.138c.524-.482,5.234-5.667,5.477-5.981l.026-.034.019-.022-.038.052c-.235.318-4.953,5.5-5.488,5.981l-.258.142"
              fill="#aaacaf"
            />
            <path
              d="M1218.947,289.97c-.164,0-.168-.277-.093-.374.123-.15,5.054-5.862,5.207-6.075a1,1,0,0,1,.329-.3h0a1.018,1.018,0,0,0-.314.288c-.15.209-5.073,5.918-5.2,6.064-.075.089-.071.373.09.373l.25-.134c.516-.475,5.233-5.66,5.469-5.981h0l-.022.033c-.228.311-4.954,5.495-5.477,5.981l-.254.139"
              fill="#a9abad"
            />
            <path
              d="M1218.955,289.962c-.161,0-.169-.269-.09-.373.123-.146,5.046-5.855,5.2-6.064a1,1,0,0,1,.318-.291v.022l-.3.277c-.146.205-5.066,5.91-5.185,6.052-.075.086-.068.347.089.347l.24-.134c.5-.467,5.233-5.63,5.458-5.951l.022-.023h0c-.224.307-4.953,5.492-5.469,5.981l-.247.135"
              fill="#a8aaad"
            />
            <path
              d="M1218.966,289.947c-.157,0-.165-.261-.09-.347.123-.146,5.039-5.847,5.185-6.052l.3-.277v.022l-.28.262c-.146.2-5.058,5.9-5.178,6.041s-.067.344.086.344l.236-.131c.489-.452,5.039-5.435,5.431-5.914l.027-.026c-.24.318-4.954,5.484-5.458,5.951l-.24.135"
              fill="#a6a8ab"
            />
            <path
              d="M1218.973,289.936c-.153,0-.157-.258-.086-.344s5.032-5.839,5.178-6.041l.28-.261h0l-.265.25c-.142.2-5.051,5.891-5.167,6.034s-.063.332.083.332l.228-.127c.471-.433,4.859-5.233,5.4-5.873l.033-.033c-.374.482-4.942,5.465-5.431,5.914l-.236.13"
              fill="#a5a7a9"
            />
            <path
              d="M1218.981,289.914c-.146,0-.154-.247-.083-.333s5.025-5.832,5.167-6.033l.265-.251-.019.022-.243.236c-.142.2-5.039,5.888-5.155,6.022s-.063.322.083.322l.224-.124c.452-.415,4.639-4.994,5.342-5.8l.052-.056c-.534.632-4.927,5.435-5.394,5.869l-.228.127"
              fill="#a4a6a9"
            />
            <path
              d="M1218.988,289.914c-.146,0-.149-.24-.082-.322s5.017-5.824,5.155-6.022l.243-.236v.023l-.228.224c-.138.191-5.032,5.877-5.148,6.008s-.059.317.079.317l.217-.119c.433-.4,4.463-4.8,5.282-5.739l.056-.059c-.7.811-4.89,5.386-5.342,5.8l-.224.123"
              fill="#a2a4a7"
            />
            <path
              d="M1219,289.914c-.142,0-.146-.236-.079-.318s5.009-5.817,5.148-6.007l.228-.225v.023l-.206.209c-.134.187-5.024,5.869-5.136,5.981s-.06.307.075.307l.209-.116c.415-.374,4.25-4.572,5.207-5.652l.075-.082c-.819.938-4.86,5.338-5.282,5.741l-.217.12"
              fill="#a2a4a6"
            />
            <path
              d="M1219.007,289.891c-.135,0-.142-.228-.075-.306s5-5.809,5.136-5.981l.206-.21-.019.026-.187.191c-.134.183-5.016,5.862-5.128,5.981s-.056.3.074.3l.206-.112c.4-.374,4.075-4.381,5.129-5.562l.075-.079c-.954,1.081-4.793,5.267-5.208,5.652l-.209.116"
              fill="#a0a2a5"
            />
            <path
              d="M1219.014,289.88c-.13,0-.138-.22-.074-.3s4.994-5.806,5.125-5.981l.187-.191-.023.022-.164.176c-.131.183-5.01,5.854-5.114,5.981l.071.288.2-.108c.374-.355,3.9-4.183,5.043-5.466l.086-.093c-1.054,1.181-4.733,5.192-5.129,5.562l-.206.113"
              fill="#9fa1a4"
            />
            <path
              d="M1219.022,289.869l-.071-.288c.1-.127,4.983-5.8,5.114-5.981l.164-.176-.022.03-.142.153c-.127.18-5,5.847-5.107,5.982l.068.276.19-.1c.374-.336,3.69-3.958,4.931-5.334l.112-.116c-1.148,1.282-4.661,5.11-5.043,5.462l-.2.108"
              fill="#9ea0a2"
            />
            <path
              d="M1219.029,289.854l-.071-.277c.1-.123,4.98-5.79,5.107-5.981l.142-.153-.03.034-.112.127c-.127.175-4.995,5.839-5.095,5.958l.067.269.183-.1c.344-.322,3.473-3.739,4.8-5.185l.131-.142c-1.241,1.375-4.564,5-4.931,5.334l-.19.1"
              fill="#9d9fa2"
            />
            <path
              d="M1219.037,289.843l-.067-.269c.1-.124,4.971-5.787,5.095-5.959l.112-.127-.038.045-.074.089h0l-.071.086c-.655.782-4.909,5.738-5.006,5.851l.064.258.179-.1c.329-.3,3.286-3.522,4.669-5.047l.127-.131c-1.323,1.466-4.452,4.86-4.8,5.185l-.183.1"
              fill="#9b9da0"
            />
            <path
              d="M1219.044,289.831l-.063-.257c.1-.113,4.351-5.066,5.005-5.851l-.276.34c-1.148,1.354-4.632,5.413-4.718,5.511l.06.25.172-.093c.314-.3,3.121-3.339,4.545-4.909l.124-.131c-1.383,1.526-4.34,4.74-4.669,5.043l-.18.1"
              fill="#9b9d9f"
            />
            <path d="M1224.057,283.637l.007-.011.078-.093Z" fill="#9b9d9f" />
            <path
              d="M1219.052,289.82l-.06-.25c.086-.1,3.566-4.157,4.718-5.51l-.243.291c-1.327,1.559-4.385,5.125-4.464,5.211l.06.243.168-.089c.3-.277,2.9-3.1,4.363-4.711l.179-.19c-1.424,1.57-4.228,4.613-4.546,4.908l-.172.094"
              fill="#999b9e"
            />
            <path
              d="M1219.063,289.809l-.06-.243c.075-.086,3.137-3.652,4.464-5.211l-.2.239c-1.409,1.653-4.183,4.886-4.254,4.968l.023.225h.041l.153-.09c.277-.262,2.7-2.882,4.187-4.512l.176-.187c-1.466,1.611-4.068,4.434-4.363,4.71l-.168.09"
              fill="#989a9d"
            />
            <path
              d="M1219.078,289.794h.022l.131-.082c.262-.243,2.505-2.673,4-4.307l.187-.2c-1.495,1.63-3.906,4.251-4.187,4.512l-.149.086"
              fill="#97999b"
            />
            <path
              d="M1219.037,289.79l-.023-.224c.071-.082,2.845-3.316,4.254-4.968l-.2.247c-1.455,1.7-3.974,4.643-4.041,4.721v.209h0"
              fill="#97999b"
            />
            <path
              d="M1219.119,289.783h0l.112-.075c.247-.232,2.314-2.471,3.8-4.089l.194-.21c-1.495,1.634-3.738,4.064-4,4.307l-.13.082"
              fill="#96989b"
            />
            <path
              d="M1219.037,289.772v-.21c.067-.078,2.59-3.016,4.041-4.721l-.213.258c-1.469,1.723-3.738,4.389-3.821,4.463v.213"
              fill="#96989b"
            />
            <path
              d="M1219.119,289.768h0l.1-.071c.228-.209,2.1-2.243,3.566-3.832l.236-.25c-1.5,1.622-3.555,3.858-3.8,4.089l-.112.075"
              fill="#949699"
            />
            <path
              d="M1219.041,289.757v-.195c.063-.071,2.347-2.736,3.82-4.463l-.191.232-3.626,4.209v.183h0"
              fill="#949699"
            />
            <path
              d="M1219.119,289.753h0l.09-.064c.209-.2,1.9-2.022,3.316-3.562l.246-.266c-1.458,1.593-3.338,3.619-3.566,3.832l-.1.071"
              fill="#949698"
            />
            <path
              d="M1219.044,289.738v-.2l3.619-4.228-.209.258c-1.447,1.693-3.342,3.906-3.4,3.97v.172h0"
              fill="#949698"
            />
            <path
              d="M1219.149,289.738h0l.078-.06c.195-.183,1.694-1.8,3.051-3.274l.265-.281c-1.417,1.54-3.106,3.365-3.316,3.563l-.089.063"
              fill="#929497"
            />
            <path
              d="M1219.052,289.727v-.187c.056-.064,1.955-2.277,3.4-3.97l-.213.258c-1.41,1.652-3.122,3.648-3.174,3.708v.165h0"
              fill="#929497"
            />
            <path
              d="M1219.16,289.723h0l.067-.052c.172-.165,1.466-1.552,2.726-2.924l.321-.343c-1.357,1.472-2.86,3.091-3.05,3.274l-.079.06"
              fill="#919396"
            />
            <path
              d="M1219.059,289.712v-.165c.053-.059,1.765-2.056,3.174-3.7l-.2.243c-1.361,1.6-2.912,3.4-2.961,3.461v.15h0"
              fill="#919396"
            />
            <path
              d="M1219.175,289.708h0l.052-.041c.154-.146,1.256-1.331,2.412-2.579l.314-.333c-1.264,1.372-2.554,2.755-2.726,2.923l-.067.053"
              fill="#909295"
            />
            <path
              d="M1219.07,289.7v-.15c.049-.056,1.6-1.869,2.961-3.461l-.191.231-2.751,3.219v.142h0"
              fill="#909295"
            />
            <path
              d="M1219.19,289.693l.019-.022.033-.03c.135-.127,1.025-1.081,2.034-2.172l.374-.4c-1.155,1.249-2.243,2.43-2.411,2.579l-.053.041"
              fill="#8f9194"
            />
            <path d="M1219.078,289.686v-.142l2.759-3.226-.2.243-2.55,2.99v.131h0" fill="#8f9194" />
            <path
              d="M1219.209,289.671l.03-.03h0c.108-.1.747-.774,1.521-1.623l.508-.538c-1.009,1.092-1.9,2.041-2.033,2.172l-.034.03m-.123,0v-.131c.041-.048,1.305-1.521,2.549-2.99l-.216.261c-1.167,1.368-2.284,2.673-2.322,2.718v.123h0"
              fill="#8e9092"
            />
            <path
              d="M1219.242,289.637l.113-.12.874-.93.542-.576c-.785.849-1.413,1.518-1.521,1.622h0m-.146.027v-.12l2.325-2.718-.2.243-2.112,2.475v.116h0"
              fill="#8d8f92"
            />
            <path
              d="M1219.351,289.521l.875-.931-.875.931m-.247.135v-.112l2.116-2.471-.213.258-1.888,2.209v.1h0"
              fill="#8b8d90"
            />
            <path d="M1219.119,289.641v-.1l1.892-2.209-.225.269-1.645,1.94v.093h0" fill="#8b8d8f" />
            <path
              d="M1219.119,289.63v-.094l1.656-1.94-.194.236-1.432,1.708v.082h0"
              fill="#898b8e"
            />
            <path d="M1219.119,289.615v-.083l1.454-1.7-.22.269-1.193,1.439v.075h0" fill="#888a8d" />
            <path d="M1219.145,289.6v-.074l1.223-1.432-.225.273-.972,1.17v.067h0" fill="#87898b" />
            <path d="M1219.156,289.592v-.067l.991-1.155-.221.269-.747.886v.056h0" fill="#86888b" />
            <path d="M1219.168,289.577v-.056l.747-.886-.209.254-.538.628v.045h0" fill="#858789" />
            <path d="M1219.179,289.566v-.045l.538-.628-.217.262-.31.374v.033h0" fill="#848688" />
            <path d="M1219.19,289.54v-.034l.31-.374-.2.251-.1.112v.019h0" fill="#838487" />
            <path d="M1219.2,289.536l.011-.019.1-.112Z" fill="#828486" />
            <path d="M1218.869,301.532l-.045,0h.011l.232-.093Z" fill="#848688" />
            <path
              d="M1218.835,301.528h-.011l-.1-.034.1.015.262-.12.03-.037-.056.082Z"
              fill="#858789"
            />
            <path
              d="M1218.831,301.51l-.1-.015-.075-.049.176.049h0l.258-.123.067-.093-.034.071-.03.041Z"
              fill="#86888b"
            />
            <path
              d="M1216.712,299.035l-1.555-1.963c.433.535.979,1.227,1.495,1.892l.052.071"
              fill="#86888b"
            />
            <path d="M1223.725,294.624h0" fill="#86888b" />
            <path d="M1227.784,294.564l.037-.03.1-.2h0Z" fill="#86888b" />
            <path
              d="M1218.835,301.495h0l-.179-.049-.056-.049.232.082.258-.123.094-.135-.022.056-.067.093Z"
              fill="#87898b"
            />
            <path
              d="M1217.291,299.764l-.579-.748-.053-.067c-.519-.665-1.069-1.357-1.5-1.892l-.344-.414c.52.62,1.357,1.682,2.09,2.616l.374.494"
              fill="#87898b"
            />
            <path d="M1225.175,294.628h.007" fill="#87898b" />
            <path
              d="M1227.683,294.609l.127-.079.1-.254,0-.022.011.082-.093.2-.037.03Z"
              fill="#87898b"
            />
            <path d="M1208.764,291.689l-.785-.373c.3.142.538.261.722.373l.059.03" fill="#87898b" />
            <path
              d="M1218.827,301.476l-.232-.078-.049-.052.277.116.258-.123.112-.176h0l-.015.06-.094.135Z"
              fill="#888a8d"
            />
            <path
              d="M1217.747,300.34l-.456-.576-.374-.49c-.747-.942-1.57-2-2.089-2.617l-.165-.205h0c.5.576,1.436,1.764,2.243,2.807l.837,1.077"
              fill="#888a8d"
            />
            <path d="M1219.755,298.758c.123-.527.254-1.1.373-1.626l-.373,1.626" fill="#888a8d" />
            <path d="M1226.188,294.631h-3.663c1.121,0,2.56,0,3.656.03" fill="#888a8d" />
            <path
              d="M1227.586,294.631l.209-.112.1-.247,0-.075.015.056,0,.026-.1.25-.123.079Z"
              fill="#888a8d"
            />
            <path
              d="M1208.959,291.783l-.195-.094-.06-.033-.721-.355-.423-.206.1.049c.441.209.793.373,1.036.5l.258.138"
              fill="#888a8d"
            />
            <path
              d="M1218.824,301.461l-.277-.112-.03-.034-.015-.022.322.15.258-.123.127-.224-.015.067-.112.176Z"
              fill="#898b8e"
            />
            <path
              d="M1218.113,300.8l-.373-.463-.83-1.077c-.815-1.043-1.753-2.243-2.243-2.807h0l-.112-.146.123.142c.5.572,1.435,1.757,2.243,2.8.407.519.781,1,1.054,1.361l.15.194"
              fill="#898b8e"
            />
            <path
              d="M1219.377,300.381c.232-1.025.7-3.058,1.024-4.385l-.265,1.14c-.127.535-.258,1.1-.374,1.626l-.374,1.615"
              fill="#898b8e"
            />
            <path
              d="M1227.478,294.635h.015l.292-.12.094-.25-.011-.116.019.049.007.075-.1.247-.213.112Z"
              fill="#898b8e"
            />
            <path
              d="M1227.055,294.635h-.867c-1.092,0-2.535-.03-3.656-.03h-.026c1.462,0,3.48.03,4.553.041"
              fill="#898b8e"
            />
            <path
              d="M1209.045,291.828l-.09-.041-.258-.139c-.247-.127-.595-.3-1.036-.5l-.1-.048-.34-.168.433.2c.441.209.793.374,1.036.505l.291.153.064.037"
              fill="#898b8e"
            />
            <path d="M1224.67,287.4l.411-.508-.411.508" fill="#898b8e" />
            <path
              d="M1218.824,301.442l-.318-.153-.374-.493-.146-.195c-.273-.355-.65-.841-1.058-1.36-.811-1.043-1.749-2.243-2.243-2.8l-.123-.142-.082-.105.209.232c.5.572,1.432,1.761,2.243,2.8.408.52.781,1.005,1.054,1.361l.326.422.119.157a.554.554,0,0,0,.411.254l.258-.119.154-.314.041-.18c.175-.777.878-3.861,1.245-5.319l-.116.5c-.329,1.331-.793,3.364-1.025,4.385l-.168.721-.127.228-.258.124"
              fill="#8b8d8f"
            />
            <path
              d="M1227.47,294.635h-.415c-1.073,0-3.091-.041-4.557-.041h-.015c1.642,0,4.008.034,4.924.045h.086l.291-.124.1-.246-.022-.15.022.041v.116l-.1.251-.288.119h-.022"
              fill="#8b8d8f"
            />
            <path
              d="M1209.089,291.85l-.044-.022-.064-.041-.292-.154c-.246-.123-.594-.291-1.035-.5l-.434-.2-.284-.139.71.329c.442.21.793.374,1.04.505l.291.157.112.071"
              fill="#8b8d8f"
            />
            <path
              d="M1224.319,287.835c.321-.422.748-.964,1.241-1.536l-.46.591-.411.508-.351.437"
              fill="#8b8d8f"
            />
            <path
              d="M1218.82,301.427a.55.55,0,0,1-.411-.254l-.12-.157-.325-.422c-.273-.374-.647-.841-1.054-1.361-.811-1.039-1.746-2.243-2.243-2.8l-.21-.232-.052-.067h0c.157.153.486.546.89,1.039.6.748,1.379,1.742,2,2.546l.781,1.013.314.411a.554.554,0,0,0,.411.255l.258-.124.153-.31.041-.183c.183-.811.942-4.146,1.29-5.495l.067-.139-.082.374c-.374,1.458-1.069,4.542-1.245,5.32l-.041.179-.157.314-.258.12"
              fill="#8b8d90"
            />
            <path d="M1212.353,296.968c.374-.172.695-.333.99-.468l-.99.468" fill="#8b8d90" />
            <path
              d="M1226.412,292.28c-.4-.5-.845-1.054-1.248-1.581l1.248,1.581m.995,2.351h0c-.92,0-3.282-.044-4.924-.044h-1.607a2.829,2.829,0,0,1,.318-.019h1.26c1.641,0,4.022.037,4.938.045h.089l.288-.12.094-.247-.034-.187.023.042.022.149-.1.247-.288.119h-.079"
              fill="#8b8d90"
            />
            <path
              d="M1209.112,291.858h-.019l-.112-.071-.292-.157c-.246-.124-.6-.3-1.039-.505l-.71-.333-.239-.116.942.438c.445.209.792.374,1.039.5l.3.157.131.083h0"
              fill="#8b8d90"
            />
            <path
              d="M1224.154,288.045c.374-.509.984-1.253,1.634-2.034l.064-.071-.292.374c-.479.572-.92,1.121-1.241,1.536l-.165.2"
              fill="#8b8d90"
            />
            <path
              d="M1218.82,301.409a.558.558,0,0,1-.411-.254l-.314-.412-.782-1.013c-.624-.8-1.4-1.794-2-2.545-.4-.494-.748-.886-.89-1.04h0l-.034-.041.06.053c.322.321,1.32,1.57,2.243,2.74.456.587.894,1.151,1.215,1.57l.374.5.142.187h0a.551.551,0,0,0,.411.254l.258-.124.153-.31.042-.183c.183-.815.942-4.149,1.289-5.488l.09-.332-.049.175-.041.161c-.347,1.349-1.121,4.684-1.289,5.495l-.042.183-.153.314-.258.12"
              fill="#8d8f92"
            />
            <path
              d="M1211.467,297.39l.34-.168c.628-.3,1.23-.591,1.69-.8l.374-.161-.516.243-.991.468-.9.422"
              fill="#8d8f92"
            />
            <path
              d="M1226.823,292.8c-.6-.718-1.349-1.69-1.984-2.512l.325.411c.4.527.848,1.088,1.248,1.585l.411.519m.569,1.821h0c-.916,0-3.294-.045-4.938-.045h-1.26a2.814,2.814,0,0,0-.318.019h-.052a2.329,2.329,0,0,1,.373-.03h1.23c1.641,0,4.041.037,4.957.048h.09l.288-.119.1-.247-.045-.209.023.03.033.187-.1.246-.288.12h-.078"
              fill="#8d8f92"
            />
            <path
              d="M1209.116,291.861h0l-.131-.082-.3-.157c-.246-.123-.6-.291-1.039-.505l-.953-.456-.239-.119,1.177.546q.667.307,1.043.5l.3.157.135.086h0"
              fill="#8d8f92"
            />
            <path
              d="M1224.039,288.179c.351-.5,1.024-1.327,1.745-2.183l.322-.374-.258.318-.064.071c-.654.778-1.267,1.525-1.637,2.034l-.112.142"
              fill="#8d8f92"
            />
            <path
              d="M1218.816,301.394a.545.545,0,0,1-.411-.254h0l-.146-.187-.374-.5c-.321-.419-.747-.983-1.215-1.567-.912-1.17-1.91-2.418-2.242-2.74l-.064-.052-.022-.03h0l.086.071c.325.322,1.319,1.574,2.242,2.737l1.212,1.562.373.5.143.187a.543.543,0,0,0,.407.25h0l.25-.119.154-.311.041-.183c.183-.815.946-4.153,1.286-5.484.045-.179.086-.329.123-.441l-.026.12-.093.333c-.344,1.342-1.122,4.676-1.286,5.487l-.042.183-.153.311-.258.119"
              fill="#8e9092"
            />
            <path
              d="M1210.861,297.674l.954-.467c.628-.3,1.226-.591,1.685-.8.232-.1.427-.19.576-.25h0l-.232.108-.373.161c-.464.213-1.062.5-1.69.8l-.34.168-.606.288"
              fill="#8e9092"
            />
            <path
              d="M1227.085,293.136c-.654-.8-1.719-2.112-2.46-3.118l.21.266c.62.826,1.4,1.8,1.985,2.516l.261.332m.3,1.5h0c-.916,0-3.312-.049-4.957-.049h-1.234a2.6,2.6,0,0,0-.374.03h.064c.172-.019.52-.03.976-.03h.553c1.641,0,4.041.037,4.953.049h.09l.288-.12.1-.243-.06-.239h0v.018l.045.21-.094.247-.287.119h-.079"
              fill="#8e9092"
            />
            <path
              d="M1209.119,291.865h0l-.134-.086-.3-.157q-.375-.191-1.043-.505c-.34-.16-.747-.343-1.177-.545l-.217-.109,1.387.639c.441.206.792.374,1.043.505l.3.157.138.086v.03"
              fill="#8e9092"
            />
            <path
              d="M1223.96,288.284c.322-.494,1.047-1.376,1.828-2.3l.55-.647-.225.281-.321.373c-.725.856-1.4,1.679-1.746,2.183l-.082.1"
              fill="#8e9092"
            />
            <path
              d="M1218.816,301.375h0a.545.545,0,0,1-.407-.25l-.142-.187-.374-.5c-.322-.415-.748-.98-1.211-1.563-.912-1.166-1.907-2.418-2.243-2.74l-.086-.071h0l.086.071c.164.157.493.553.9,1.054.6.748,1.372,1.727,1.985,2.524l.774,1,.31.4a.55.55,0,0,0,.407.254l.255-.119.153-.311.041-.183c.183-.815.946-4.157,1.282-5.476.049-.2.1-.374.135-.475v-.041l-.019.086c-.037.116-.078.265-.123.441-.34,1.331-1.1,4.669-1.286,5.484l-.041.183-.154.31-.25.12"
              fill="#8f9194"
            />
            <path
              d="M1210.4,297.891l1.424-.7c.624-.3,1.222-.591,1.686-.8.232-.105.426-.194.572-.25l.127-.049-.116.056h0c-.146.06-.344.146-.576.251-.459.209-1.061.5-1.685.8l-.954.468-.463.22"
              fill="#8f9194"
            />
            <path
              d="M1227.306,293.416c-.636-.77-2-2.444-2.834-3.6l.153.2c.748,1.006,1.806,2.318,2.46,3.114l.221.281m-6.531,1.2h0l.1-.022a9.5,9.5,0,0,1,.972-.03h.516c1.641,0,4.074.041,4.986.048h.09l.288-.116.1-.246-.075-.27.03.034.06.239-.1.247-.288.12h-.116c-.908,0-3.308-.049-4.953-.049h-.553a7.832,7.832,0,0,0-.976.03h-.1"
              fill="#8f9194"
            />
            <path
              d="M1209.123,291.865h0v-.03l-.138-.09-.3-.157c-.251-.127-.6-.3-1.043-.5l-1.387-.639-.191-.094c.606.277,1.122.516,1.566.722s.793.373,1.047.5l.3.157.142.094.019.041"
              fill="#8f9194"
            />
            <path
              d="M1223.893,288.362l.033-.059a29.086,29.086,0,0,1,1.869-2.333l.748-.879-.2.243-.55.647c-.777.923-1.495,1.806-1.828,2.3l-.063.079"
              fill="#8f9194"
            />
            <path
              d="M1218.812,301.36a.55.55,0,0,1-.407-.254l-.31-.4c-.195-.25-.464-.605-.774-1-.617-.8-1.383-1.779-1.989-2.527-.4-.5-.747-.893-.9-1.05l-.086-.075h.019l.086.071c.164.161.493.553.9,1.051.6.747,1.372,1.727,1.985,2.523l.774,1,.306.4h0a.556.556,0,0,0,.408.251l.254-.12.153-.31.041-.179c.187-.815.946-4.165,1.282-5.473.049-.2.1-.374.135-.475l.034-.09v.1c-.041.115-.086.28-.135.478-.336,1.32-1.1,4.662-1.282,5.477l-.041.183-.153.31-.255.12"
              fill="#909295"
            />
            <path
              d="M1209.96,298.1l1.87-.92c.624-.3,1.222-.591,1.682-.8.228-.105.426-.2.572-.254l.183-.068h.022l-.078.041-.127.049c-.146.06-.344.146-.572.251-.464.209-1.062.5-1.686.8l-1.425.7-.433.206"
              fill="#909295"
            />
            <path
              d="M1227.459,293.607c-.553-.662-2.221-2.684-3.11-3.94l.123.157c.83,1.151,2.2,2.822,2.837,3.6l.154.191m-6.7,1h0l.1-.019c.171-.022.519-.03.971-.03h.49c1.641,0,4.112.041,5.006.049h.089l.284-.12.1-.243-.09-.3.034.041.071.27-.1.246-.287.116h-.094c-.912,0-3.346-.048-4.987-.048h-.516a8,8,0,0,0-.971.029l-.1.023h0"
              fill="#909295"
            />
            <path
              d="M1209.131,291.869h0l-.019-.041-.142-.094-.3-.157c-.25-.127-.6-.3-1.043-.5s-.961-.449-1.566-.722l-.195-.1c.684.31,1.275.579,1.757.807s.8.374,1.047.505l.3.157.146.093.022.053"
              fill="#909295"
            />
            <path
              d="M1223.844,288.418l.079-.13a28.161,28.161,0,0,1,1.869-2.333l.946-1.122-.2.24-.748.878c-.8.942-1.536,1.847-1.869,2.333l-.033.056-.049.063"
              fill="#909295"
            />
            <path
              d="M1218.809,301.341a.552.552,0,0,1-.408-.25h0l-.31-.4-.774-1c-.617-.8-1.383-1.779-1.985-2.523-.4-.5-.747-.89-.9-1.051l-.086-.074h-.022l-.041.018h-.023l-.183.068c-.146.059-.34.145-.572.254-.46.209-1.058.493-1.682.8l-1.869.92-.374.179c.635-.318,1.458-.725,2.243-1.121.62-.3,1.218-.591,1.678-.8.228-.105.426-.2.572-.251l.183-.071.09-.022h0l.1.086c.344.347,1.331,1.588,2.243,2.74.449.576.879,1.121,1.193,1.54l.374.493.142.184a.546.546,0,0,0,.4.25l.254-.12.154-.31.041-.183c.187-.819.946-4.168,1.278-5.465.053-.2.1-.374.135-.471l.052-.127v.044l-.033.09c-.042.116-.09.277-.139.475-.332,1.308-1.095,4.654-1.282,5.469l-.041.179-.15.311-.254.119"
              fill="#919396"
            />
            <path d="M1208.028,298.975l.134-.03Z" fill="#919396" />
            <path
              d="M1227.612,293.8l-.108-.131c-.5-.594-2.37-2.852-3.264-4.142l.1.135c.89,1.26,2.557,3.278,3.11,3.94l.154.2m-6.864.8h0v-.019l.1-.022a9.464,9.464,0,0,1,.968-.03h.467c1.645,0,4.112.041,5.021.049h.089l.288-.116.1-.243-.138-.374.06.075.093.3-.1.243-.284.12h-.071c-.912,0-3.364-.049-5.005-.049h-.486a7.784,7.784,0,0,0-.972.03l-.1.019h0"
              fill="#919396"
            />
            <path
              d="M1209.134,291.873h0l-.022-.053-.146-.093-.3-.157c-.25-.127-.6-.3-1.046-.505l-1.757-.8-.187-.093c.747.344,1.413.643,1.936.886.441.205.8.374,1.047.505l.3.16.145.094.03.063"
              fill="#919396"
            />
            <path
              d="M1223.8,288.471l.112-.187a29.57,29.57,0,0,1,1.869-2.337l1.122-1.319-.183.228c-.311.374-.632.748-.946,1.121-.8.942-1.54,1.843-1.869,2.333l-.079.127-.041.049"
              fill="#919396"
            />
            <path
              d="M1218.805,301.327a.549.549,0,0,1-.4-.251l-.142-.183-.374-.494c-.317-.411-.747-.964-1.192-1.54-.9-1.151-1.869-2.392-2.243-2.74l-.1-.086h0l-.09.023-.183.071c-.146.059-.34.145-.572.25-.46.209-1.058.5-1.678.8-.786.374-1.612.793-2.243,1.122l-.352.168c.658-.329,1.66-.83,2.617-1.29.62-.3,1.219-.587,1.678-.8.228-.105.423-.191.569-.251l.183-.067.089-.022h0l.1.086c.344.347,1.33,1.588,2.243,2.74l1.188,1.536.374.494.142.183h0a.545.545,0,0,0,.4.25l.25-.119.15-.307.041-.183c.183-.819.95-4.172,1.275-5.458a4.576,4.576,0,0,1,.134-.471l.056-.134h0v.037l-.052.127c-.037.116-.086.277-.134.475-.329,1.3-1.092,4.646-1.279,5.465l-.041.183-.153.31-.255.116"
              fill="#929497"
            />
            <path d="M1207.983,298.975h-.034l.3-.067-.094.037-.135.03Z" fill="#929497" />
            <path
              d="M1220.738,294.609h0l.022-.03.109-.022a9.441,9.441,0,0,1,.968-.03h.441c1.641,0,4.131.041,5.043.052h.086l.284-.116.1-.243a.68.68,0,0,0-.161-.4h0l-.123-.146c-.509-.609-2.471-2.964-3.327-4.235l.082.1c.89,1.293,2.766,3.547,3.264,4.142l.108.131.037.045.139.373-.1.243-.287.116h-.09c-.908,0-3.364-.048-5.021-.048h-.467a7.718,7.718,0,0,0-.968.03l-.1.022v.019"
              fill="#929497"
            />
            <path
              d="M1209.138,291.873h0l-.03-.064-.146-.093-.3-.161c-.254-.127-.605-.3-1.046-.505-.524-.247-1.174-.542-1.937-.886l-.224-.108c.856.374,1.581.714,2.157.983.441.206.8.374,1.047.505l.306.164.15.094.033.074"
              fill="#929497"
            />
            <path
              d="M1223.773,288.512l.138-.239a29.413,29.413,0,0,1,1.87-2.337c.441-.519.9-1.05,1.312-1.532l-.18.224-1.121,1.323c-.8.942-1.54,1.843-1.869,2.337l-.112.19-.03.041"
              fill="#929497"
            />
            <path
              d="M1218.8,301.308a.547.547,0,0,1-.4-.251l-.142-.183-.374-.493-1.189-1.537c-.9-1.151-1.869-2.388-2.243-2.74l-.1-.086h0l-.09.023-.183.067-.568.25c-.46.21-1.058.5-1.679.8-.942.459-1.944.957-2.617,1.289l-.295.142c.621-.31,1.8-.9,2.905-1.439.62-.3,1.215-.587,1.674-.8.229-.105.423-.194.572-.251l.184-.067.089-.022h.023a2.579,2.579,0,0,1,.3.31c.493.561,1.42,1.738,2.243,2.778l1.046,1.357.322.422.12.157h0a.539.539,0,0,0,.4.25l.251-.119.149-.307.041-.183c.187-.819.95-4.176,1.271-5.454a3.607,3.607,0,0,1,.135-.467l.056-.135.037-.052.109-.023c.172-.022.516-.029.964-.029h.423c1.644,0,4.145.044,5.054.052h.086l.288-.116.093-.239a.664.664,0,0,0-.161-.4h0l-.123-.146c-.523-.624-2.564-3.077-3.387-4.321l.071.089c.856,1.271,2.819,3.63,3.327,4.236l.124.145h0a.686.686,0,0,1,.16.4l-.1.243-.284.116h-.086c-.908,0-3.4-.052-5.043-.052h-.441a9.191,9.191,0,0,0-.968.03l-.109.022-.022.03h0v.019l-.056.134c-.041.116-.086.277-.135.471-.325,1.286-1.088,4.639-1.274,5.458l-.042.183-.153.31-.25.12"
              fill="#949698"
            />
            <path
              d="M1207.953,298.975h-.041a1.936,1.936,0,0,0,.411-.1l-.067.034-.3.067"
              fill="#949698"
            />
            <path
              d="M1209.145,291.876h0l-.033-.074-.15-.094-.306-.164c-.254-.128-.606-.3-1.047-.505-.576-.269-1.3-.6-2.157-.983l-.164-.082c.927.414,1.7.77,2.31,1.05.441.206.8.374,1.05.505a3.136,3.136,0,0,1,.464.258l.041.086"
              fill="#949698"
            />
            <path
              d="M1223.747,288.542l.157-.281c.325-.5,1.073-1.4,1.869-2.336l1.495-1.749-.187.228c-.414.482-.871,1.013-1.312,1.532-.8.939-1.54,1.839-1.869,2.337l-.138.239-.023.03"
              fill="#949698"
            />
            <path
              d="M1218.8,301.293a.547.547,0,0,1-.4-.251l-.12-.157-.322-.422-1.046-1.357c-.8-1.035-1.735-2.213-2.243-2.777a2.938,2.938,0,0,0-.3-.311h-.023l-.089.023-.184.067c-.145.06-.34.146-.571.25-.46.21-1.055.494-1.675.8-1.122.538-2.284,1.121-2.905,1.439l-.3.142.116-.056c.531-.269,1.869-.938,3.1-1.54.616-.3,1.211-.587,1.671-.793.228-.1.422-.19.571-.247l.184-.071.089-.022h.023a3.031,3.031,0,0,1,.3.31c.493.561,1.42,1.739,2.243,2.774l1.043,1.353.321.423.116.157a.54.54,0,0,0,.4.25l.25-.119.15-.307h0l.041-.183c.187-.819.949-4.179,1.267-5.447.049-.194.094-.351.135-.467l.056-.135.037-.052.1-.022a9.26,9.26,0,0,1,.942-.03h.422c1.641,0,4.165.041,5.069.052h.086l.284-.116.094-.243a.661.661,0,0,0-.161-.4h0l-.123-.146c-.535-.635-2.658-3.177-3.439-4.4l.063.083c.819,1.244,2.864,3.7,3.387,4.321l.123.146h0a.67.67,0,0,1,.161.4l-.1.243-.284.116h-.086c-.909,0-3.409-.053-5.054-.053h-.423a7.707,7.707,0,0,0-.964.03l-.109.023-.037.052-.056.134a4.3,4.3,0,0,0-.135.468c-.321,1.278-1.087,4.631-1.271,5.454l-.041.183-.153.31-.25.116"
              fill="#949699"
            />
            <path
              d="M1207.9,298.968h-.033a2.263,2.263,0,0,0,.542-.135l-.1.045a2.025,2.025,0,0,1-.412.1"
              fill="#949699"
            />
            <path
              d="M1209.149,291.88h0l-.041-.09a3.369,3.369,0,0,0-.463-.258c-.255-.127-.61-.3-1.051-.5-.609-.281-1.387-.636-2.31-1.051l-.157-.078c.991.441,1.82.818,2.463,1.121.441.206.8.374,1.055.509a3.748,3.748,0,0,1,.463.258l.049.1"
              fill="#949699"
            />
            <path
              d="M1223.728,288.568h0a1.365,1.365,0,0,1,.168-.318c.329-.5,1.073-1.4,1.87-2.336.579-.681,1.185-1.38,1.682-1.963l-.183.225-1.5,1.753c-.8.938-1.544,1.839-1.869,2.336l-.157.284-.018.023"
              fill="#949699"
            />
            <path
              d="M1209.026,292.721l.082-.355-.082.355m9.757,8.557a.535.535,0,0,1-.4-.251l-.12-.157-.321-.422c-.269-.351-.643-.837-1.043-1.353-.8-1.036-1.731-2.213-2.224-2.774a2.833,2.833,0,0,0-.3-.31h-.022l-.09.022-.183.071c-.146.06-.34.142-.572.247-.456.209-1.054.493-1.671.792-1.233.6-2.564,1.275-3.1,1.541l-.112.056-.213.1a2.284,2.284,0,0,1-.546.134h-.026a2.217,2.217,0,0,0,.673-.176h0l.235-.119c.528-.269,1.87-.938,3.092-1.537.617-.3,1.207-.586,1.667-.792.228-.105.423-.191.568-.251l.184-.071.089-.022h.023a2.931,2.931,0,0,1,.3.31c.493.561,1.417,1.739,2.22,2.77.4.516.774,1,1.043,1.354l.322.418.119.157a.543.543,0,0,0,.4.247h0l.247-.12.153-.3h0l.041-.184c.187-.822.95-4.183,1.267-5.442.049-.2.094-.352.135-.468l.06-.134.037-.053.1-.022c.168-.019.5-.03.923-.03h.445c1.641,0,4.157.041,5.062.052h.093l.284-.116.1-.239a.662.662,0,0,0-.164-.4h0l-.123-.149c-.546-.643-2.737-3.264-3.477-4.456l.052.067c.778,1.219,2.905,3.761,3.44,4.4l.123.146h0a.655.655,0,0,1,.161.4l-.094.243-.284.116h-.09c-.908,0-3.428-.049-5.069-.049h-.422a8.464,8.464,0,0,0-.942.026l-.1.023-.037.052-.056.135a4.371,4.371,0,0,0-.135.467c-.318,1.267-1.084,4.628-1.267,5.447l-.041.183h0l-.153.306-.251.116"
              fill="#96989b"
            />
            <path
              d="M1209.157,291.88h0l-.049-.1a3.748,3.748,0,0,0-.463-.258c-.258-.131-.61-.3-1.055-.508-.643-.3-1.472-.677-2.463-1.122l-.146-.071c1.054.468,1.929.868,2.617,1.178.441.205.8.374,1.054.5a3.7,3.7,0,0,1,.471.266l.052.112"
              fill="#96989b"
            />
            <path
              d="M1223.713,288.583v-.023a1.4,1.4,0,0,1,.172-.321c.333-.5,1.077-1.4,1.869-2.34.662-.774,1.357-1.57,1.869-2.2l-.2.25-1.683,1.963c-.8.938-1.543,1.835-1.869,2.336a1.329,1.329,0,0,0-.168.318h0"
              fill="#96989b"
            />
            <path
              d="M1209,292.9l.045-.19.082-.352.026-.119a4.837,4.837,0,0,1-.119.545l-.034.116m9.8,8.348h0a.539.539,0,0,1-.4-.247l-.12-.157-.321-.419c-.273-.351-.643-.837-1.043-1.353-.8-1.035-1.727-2.209-2.221-2.77a3.018,3.018,0,0,0-.3-.31h-.022l-.09.022-.183.071c-.146.06-.34.146-.568.251-.456.209-1.051.493-1.668.792-1.233.6-2.56,1.271-3.087,1.537l-.236.119h0a2.129,2.129,0,0,1-.669.176h-.022a2.153,2.153,0,0,0,.706-.179l.236-.124c.527-.265,1.869-.931,3.08-1.533.617-.3,1.207-.583,1.663-.792.228-.105.423-.187.572-.247l.184-.067.089-.022h.023a3.241,3.241,0,0,1,.3.31c.493.561,1.417,1.734,2.216,2.77.4.516.771,1,1.043,1.349l.322.423.116.157a.541.541,0,0,0,.4.25l.25-.119.15-.307h0l.041-.183c.187-.823.949-4.187,1.263-5.435.049-.191.094-.352.135-.464l.06-.135.041-.052.1-.022a8.521,8.521,0,0,1,.9-.03h.434c1.641,0,4.183.041,5.084.052h.082l.284-.112.094-.239a.69.69,0,0,0-.161-.4h0l-.123-.146c-.557-.658-2.812-3.364-3.511-4.512l.049.06c.748,1.2,2.931,3.813,3.477,4.456l.123.146h0a.675.675,0,0,1,.161.4l-.1.239-.284.116h-.089c-.9,0-3.417-.053-5.062-.053h-.445a7.165,7.165,0,0,0-.923.03l-.1.023-.041.052-.06.135a4.731,4.731,0,0,0-.134.463c-.318,1.256-1.081,4.621-1.268,5.439l-.041.184h0l-.153.306-.247.116"
              fill="#97999b"
            />
            <path
              d="M1209.16,291.884h0l-.052-.112a3.692,3.692,0,0,0-.471-.266c-.258-.131-.613-.3-1.054-.5-.673-.314-1.548-.711-2.617-1.178l-.146-.071c1.122.493,2.038.912,2.74,1.241.445.206.8.374,1.058.505a3.74,3.74,0,0,1,.475.265l.06.124"
              fill="#97999b"
            />
            <path
              d="M1223.7,288.6h0v-.045a1.334,1.334,0,0,1,.176-.321c.332-.505,1.08-1.406,1.869-2.344.748-.879,1.548-1.791,2.093-2.445v.026l-.187.228c-.53.628-1.226,1.424-1.869,2.2a28.93,28.93,0,0,0-1.869,2.34,1.382,1.382,0,0,0-.172.322v.022h0"
              fill="#97999b"
            />
            <path
              d="M1208.959,293.076l.037-.164.034-.116a4.9,4.9,0,0,0,.119-.546v-.067a3.991,3.991,0,0,1-.123.6l-.082.3m9.831,8.164a.543.543,0,0,1-.4-.25l-.116-.157-.321-.423c-.269-.351-.639-.834-1.043-1.349-.8-1.036-1.727-2.206-2.217-2.77a3.258,3.258,0,0,0-.3-.311h-.022l-.09.023-.183.067c-.146.06-.344.146-.572.247-.456.209-1.047.493-1.664.792-1.23.6-2.553,1.268-3.08,1.533l-.236.123a2.132,2.132,0,0,1-.706.18h0a2.129,2.129,0,0,0,.748-.183h0l.235-.12c.527-.265,1.847-.931,3.077-1.533.616-.3,1.2-.583,1.659-.788.228-.105.423-.191.572-.251l.183-.067.094-.023h.026a2.771,2.771,0,0,1,.3.314c.494.561,1.417,1.735,2.217,2.763l1.039,1.349.318.419.116.153h0a.539.539,0,0,0,.4.251l.25-.12.15-.3h0l.041-.183c.187-.822.953-4.194,1.26-5.428a3.883,3.883,0,0,1,.134-.463l.06-.135.041-.052.1-.023a8.441,8.441,0,0,1,.886-.029h.374c1.637,0,4.216.044,5.117.052h.116l.284-.112.094-.24a.681.681,0,0,0-.161-.4h0l-.127-.145a52.386,52.386,0,0,1-3.525-4.539V289.1l.041.052a53.984,53.984,0,0,0,3.51,4.512l.123.146h0a.679.679,0,0,1,.161.4l-.093.239-.284.112h-.086c-.9,0-3.443-.052-5.084-.052h-.46q-.453-.008-.9.03l-.105.022-.041.053-.059.134a4.18,4.18,0,0,0-.135.464c-.314,1.248-1.077,4.613-1.264,5.435l-.041.183h0l-.149.307-.251.116"
              fill="#989a9d"
            />
            <path
              d="M1209.168,291.888h0l-.06-.128a4.085,4.085,0,0,0-.475-.265c-.258-.131-.613-.3-1.058-.505-.7-.325-1.622-.747-2.74-1.237l-.134-.067c1.17.519,2.138.953,2.863,1.293.445.206.8.374,1.058.505a3.811,3.811,0,0,1,.479.269l.063.138"
              fill="#989a9d"
            />
            <path
              d="M1223.695,288.609h0v-.049a1.476,1.476,0,0,1,.179-.325c.336-.508,1.084-1.4,1.869-2.34s1.6-1.847,2.146-2.5l-.049.068c-.545.654-1.342,1.566-2.093,2.444a28.352,28.352,0,0,0-1.869,2.34,1.36,1.36,0,0,0-.176.322v.045h0"
              fill="#989a9d"
            />
            <path
              d="M1208.921,293.237l.038-.161.082-.3a3.951,3.951,0,0,0,.123-.6v-.052a3.663,3.663,0,0,1-.123.639c-.038.142-.082.3-.131.475m9.869,7.989a.542.542,0,0,1-.4-.251h0l-.116-.153-.318-.419-1.039-1.349c-.8-1.032-1.723-2.2-2.217-2.763a2.763,2.763,0,0,0-.3-.314h-.026l-.093.023-.184.067c-.145.06-.344.146-.572.25-.456.21-1.046.49-1.659.789-1.226.6-2.55,1.267-3.077,1.533l-.235.119h0a2.108,2.108,0,0,1-.748.184h0a2.156,2.156,0,0,0,.748-.187h0l.235-.12c.527-.265,1.843-.931,3.069-1.529.613-.3,1.2-.579,1.656-.789.228-.1.423-.19.568-.246l.184-.068.093-.022h.026a2.814,2.814,0,0,1,.3.31c.494.561,1.417,1.731,2.213,2.763l1.036,1.345.321.419.116.153a.538.538,0,0,0,.4.247l.247-.116.149-.306.042-.183c.186-.823.953-4.2,1.256-5.421a4.649,4.649,0,0,1,.134-.46l.06-.131.041-.056.1-.022a7.84,7.84,0,0,1,.867-.03h.449c1.637,0,4.216.041,5.113.052h.086l.285-.112.093-.239a.666.666,0,0,0-.161-.4h0l-.127-.146a52.441,52.441,0,0,1-3.525-4.538l-.037-.064.041.049v.022a52.387,52.387,0,0,0,3.525,4.539l.123.149h0a.666.666,0,0,1,.161.4l-.093.239-.285.113h-.071c-.9,0-3.48-.056-5.117-.056h-.411a8.441,8.441,0,0,0-.886.029l-.1.027-.041.052-.06.135a4.079,4.079,0,0,0-.135.463c-.31,1.237-1.076,4.6-1.259,5.428l-.041.183h0l-.15.307-.25.116"
              fill="#999b9e"
            />
            <path
              d="M1209.172,291.891h0l-.064-.138a3.878,3.878,0,0,0-.478-.269c-.258-.131-.617-.3-1.058-.505-.729-.34-1.694-.774-2.864-1.293l-.123-.06c1.222.538,2.243.991,2.99,1.342.445.209.8.374,1.062.508a4.579,4.579,0,0,1,.486.269l.071.15"
              fill="#999b9e"
            />
            <path
              d="M1223.683,288.62v-.082a1.609,1.609,0,0,1,.184-.329c.672-1.013,2.953-3.547,4.056-4.86l-.023.034c-.546.654-1.372,1.6-2.146,2.5a27.957,27.957,0,0,0-1.869,2.34,1.39,1.39,0,0,0-.179.325v.049h0"
              fill="#999b9e"
            />
            <path
              d="M1208.888,293.383l.033-.146c.049-.172.094-.333.131-.475a3.664,3.664,0,0,0,.123-.639v-.037a3.231,3.231,0,0,1-.123.661c-.049.183-.108.4-.176.636m9.9,7.824a.534.534,0,0,1-.4-.247l-.116-.153-.321-.419-1.036-1.346c-.8-1.031-1.723-2.2-2.213-2.762a2.826,2.826,0,0,0-.3-.31h-.022l-.094.026-.183.063c-.146.06-.34.142-.568.247-.456.209-1.047.494-1.656.793-1.226.6-2.542,1.263-3.069,1.529l-.236.119h0a2.123,2.123,0,0,1-.747.187h0a2.131,2.131,0,0,0,.792-.187h0l.236-.119c.523-.266,1.839-.931,3.061-1.529.61-.3,1.2-.58,1.656-.789.228-.105.423-.187.569-.247l.183-.067.093-.023h.026a2.872,2.872,0,0,1,.3.311c.494.56,1.413,1.73,2.209,2.758l1.036,1.346.318.419.116.157h0a.538.538,0,0,0,.4.247l.246-.116.15-.3.041-.183c.187-.826.953-4.2,1.256-5.417.037-.154.082-.306.134-.456l.06-.135.045-.052.1-.026a8,8,0,0,1,.849-.03h.426c1.637,0,4.235.041,5.129.056h.086l.284-.112.093-.239a.67.67,0,0,0-.161-.4h0l-.123-.146c-.284-.333-1-1.181-1.731-2.09a24.73,24.73,0,0,1-1.8-2.448l-.052-.1.034.041.033.063a51.43,51.43,0,0,0,3.529,4.538l.127.146h0a.667.667,0,0,1,.161.4l-.094.239-.284.116h-.086c-.9,0-3.476-.052-5.114-.052h-.426a7.847,7.847,0,0,0-.867.03l-.1.022-.041.056-.06.131c-.052.151-.1.3-.134.46-.3,1.226-1.073,4.6-1.256,5.42l-.041.184-.15.306-.247.116"
              fill="#9b9d9f"
            />
            <path
              d="M1209.179,291.891h0l-.071-.149a4.252,4.252,0,0,0-.486-.269c-.258-.128-.617-.3-1.062-.509-.747-.351-1.76-.8-2.99-1.342l-.123-.06c1.274.561,2.325,1.032,3.1,1.391.441.206.8.374,1.062.508a4.2,4.2,0,0,1,.493.27l.079.16"
              fill="#9b9d9f"
            />
            <path
              d="M1223.676,288.632v-.1a4.068,4.068,0,0,1,.572-.856c.934-1.2,2.755-3.238,3.689-4.359v.03c-1.121,1.32-3.383,3.858-4.055,4.86a1.477,1.477,0,0,0-.184.329v.082h0"
              fill="#9b9d9f"
            />
            <path
              d="M1208.854,293.532l.034-.149c.071-.236.127-.453.175-.636a3.3,3.3,0,0,0,.124-.661v-.034a3.113,3.113,0,0,1-.116.684c-.06.224-.135.5-.225.8m9.933,7.66a.539.539,0,0,1-.4-.247h0l-.116-.157-.318-.418-1.035-1.342c-.8-1.032-1.72-2.2-2.21-2.759a2.939,2.939,0,0,0-.3-.311h-.027l-.093.023-.183.067c-.146.06-.34.142-.568.247-.456.209-1.043.49-1.656.789-1.223.6-2.535,1.259-3.062,1.529l-.235.119h0a2.131,2.131,0,0,1-.793.187h.022a2.054,2.054,0,0,0,.8-.191l.235-.119c.524-.266,1.832-.927,3.054-1.522.61-.3,1.2-.579,1.653-.788.228-.105.422-.187.568-.247l.183-.071.093-.023h.027a2.846,2.846,0,0,1,.3.311c.494.56,1.413,1.727,2.21,2.755.4.516.747.994,1.031,1.346l.318.414.116.154a.535.535,0,0,0,.4.246l.247-.115.149-.3.041-.183c.187-.827.954-4.206,1.253-5.413a4.14,4.14,0,0,1,.134-.453l.06-.134.045-.056.1-.026a7.248,7.248,0,0,1,.834-.03h.4c1.633,0,4.265.048,5.162.056h.086l.28-.112.094-.236a.693.693,0,0,0-.161-.4h0l-.127-.145c-.28-.333-1-1.186-1.735-2.094a24.594,24.594,0,0,1-1.8-2.448l-.071-.128.03.038.053.1a24.762,24.762,0,0,0,1.8,2.452c.747.909,1.446,1.757,1.73,2.09l.124.146h0a.682.682,0,0,1,.161.4l-.094.239-.284.112h-.086c-.9,0-3.495-.056-5.129-.056h-.426a7.533,7.533,0,0,0-.849.03l-.1.026-.045.052-.06.135a4.471,4.471,0,0,0-.135.456c-.3,1.219-1.069,4.59-1.256,5.417l-.041.183-.149.306-.247.116"
              fill="#9b9da0"
            />
            <path
              d="M1209.183,291.895h0l-.079-.161a4.346,4.346,0,0,0-.493-.269c-.262-.134-.617-.3-1.062-.508-.777-.374-1.824-.83-3.1-1.391l-.124-.06c1.327.583,2.415,1.069,3.215,1.439.441.206.8.374,1.062.509a4.367,4.367,0,0,1,.5.273l.082.175"
              fill="#9b9da0"
            />
            <path
              d="M1223.668,288.643v-.124a4.269,4.269,0,0,1,.576-.863c.942-1.208,2.77-3.249,3.7-4.366h0v.022c-.939,1.121-2.759,3.155-3.69,4.363a4.068,4.068,0,0,0-.572.856v.1h0"
              fill="#9b9da0"
            />
            <path
              d="M1208.824,293.652l.03-.127c.09-.3.168-.572.224-.8a3,3,0,0,0,.116-.684v-.027h0a2.824,2.824,0,0,1-.112.684c-.067.258-.157.58-.266.939m9.955,7.514a.54.54,0,0,1-.4-.247l-.115-.153-.318-.415c-.269-.352-.632-.83-1.032-1.342-.8-1.028-1.716-2.2-2.209-2.759a2.691,2.691,0,0,0-.3-.31h-.026l-.094.022-.183.067c-.146.06-.34.142-.568.247-.456.209-1.043.49-1.652.792-1.223.6-2.531,1.26-3.055,1.522l-.235.116a2.052,2.052,0,0,1-.8.19h.022a2.089,2.089,0,0,0,.8-.19h0l.236-.12c.52-.265,1.828-.927,3.047-1.521.609-.3,1.2-.58,1.648-.785.228-.105.423-.187.568-.247l.183-.064.1-.026h.026a3.336,3.336,0,0,1,.3.314c.493.561,1.413,1.727,2.209,2.752.4.515.748.994,1.032,1.342l.317.415.116.153a.541.541,0,0,0,.4.247l.247-.116.15-.3.041-.183c.187-.826.953-4.213,1.248-5.406a4.4,4.4,0,0,1,.135-.452l.06-.135.044-.052.1-.026a7.473,7.473,0,0,1,.819-.03h.411c1.634,0,4.269.045,5.159.056h.09l.28-.112.094-.236a.688.688,0,0,0-.161-.4h0l-.127-.146c-.284-.333-1.006-1.185-1.739-2.094a23.769,23.769,0,0,1-1.794-2.448l-.082-.157.03.037.071.127a24.054,24.054,0,0,0,1.8,2.449c.748.908,1.45,1.757,1.734,2.093l.128.146h0a.7.7,0,0,1,.16.4l-.093.239-.281.112h-.089c-.9,0-3.525-.056-5.163-.056h-.4a7.237,7.237,0,0,0-.833.03l-.1.022-.045.057-.06.13a3.919,3.919,0,0,0-.135.453c-.3,1.207-1.065,4.586-1.252,5.413l-.041.183h0l-.149.3-.247.115"
              fill="#9d9fa2"
            />
            <path
              d="M1209.19,291.9h0l-.086-.176a4.2,4.2,0,0,0-.5-.273c-.262-.131-.62-.3-1.061-.508-.8-.374-1.888-.86-3.219-1.439l-.123-.06c1.379.6,2.5,1.121,3.33,1.495.441.206.8.374,1.062.508a4.135,4.135,0,0,1,.5.277l.09.187"
              fill="#9d9fa2"
            />
            <path
              d="M1223.661,288.654v-.142a4.283,4.283,0,0,1,.579-.867c.942-1.212,2.774-3.249,3.7-4.363h0v.023h0c-.935,1.121-2.766,3.158-3.7,4.37a4.313,4.313,0,0,0-.575.859v.124h0"
              fill="#9d9fa2"
            />
            <path
              d="M1208.794,293.8l.03-.142c.108-.373.2-.68.265-.938a2.933,2.933,0,0,0,.113-.684h0v.019a2.922,2.922,0,0,1-.1.669c-.075.288-.183.669-.31,1.088m9.984,7.357a.54.54,0,0,1-.4-.247l-.116-.153-.317-.415-1.032-1.342c-.8-1.025-1.716-2.2-2.209-2.752a2.863,2.863,0,0,0-.3-.31h-.026l-.094.026-.183.067c-.146.06-.34.142-.568.247-.452.206-1.043.486-1.649.785-1.218.6-2.523,1.256-3.046,1.522l-.236.116h0a2.126,2.126,0,0,1-.8.19h.019a2.136,2.136,0,0,0,.8-.194l.235-.12c.52-.265,1.824-.923,3.039-1.518.61-.3,1.2-.579,1.645-.785.228-.1.423-.186.568-.246l.184-.064.1-.022h.026a3.067,3.067,0,0,1,.3.31c.493.561,1.413,1.727,2.2,2.751l1.032,1.339.318.418.116.154h0a.523.523,0,0,0,.4.243l.243-.116.149-.3.041-.183c.187-.83.954-4.213,1.249-5.4a3.565,3.565,0,0,1,.135-.453l.059-.13.049-.057.1-.026a7.122,7.122,0,0,1,.8-.03h.411c1.63,0,4.28.049,5.17.056h.086l.28-.108.094-.239a.675.675,0,0,0-.161-.4l-.127-.149c-.288-.333-1.009-1.185-1.742-2.094a23.2,23.2,0,0,1-1.791-2.448l-.093-.184.026.03.082.157a23.884,23.884,0,0,0,1.8,2.449c.747.908,1.454,1.757,1.738,2.093l.127.146h0a.684.684,0,0,1,.161.4l-.094.235-.28.112h-.086c-.893,0-3.529-.056-5.162-.056h-.412a6.98,6.98,0,0,0-.818.03l-.1.026-.049.057-.06.13a4.275,4.275,0,0,0-.134.453c-.3,1.2-1.062,4.575-1.249,5.4l-.041.183-.149.3-.247.116"
              fill="#9ea0a2"
            />
            <path
              d="M1209.2,291.9h0l-.09-.186a4.013,4.013,0,0,0-.5-.277c-.265-.135-.62-.3-1.061-.508-.827-.374-1.952-.89-3.331-1.5l-.112-.056c1.428.621,2.586,1.14,3.439,1.533.441.205.8.374,1.065.508a4.91,4.91,0,0,1,.509.277l.1.2"
              fill="#9ea0a2"
            />
            <path
              d="M1223.65,288.661V288.5a4.525,4.525,0,0,1,.587-.871c.949-1.211,2.777-3.244,3.708-4.358l.026-.03v.018h0c-.931,1.122-2.759,3.152-3.7,4.363a4.342,4.342,0,0,0-.58.867v.142h0"
              fill="#9ea0a2"
            />
            <path
              d="M1208.76,293.94l.034-.142c.123-.423.232-.8.31-1.088a2.806,2.806,0,0,0,.1-.669v0a2.751,2.751,0,0,1-.1.65c-.082.325-.209.748-.355,1.241m10.026,7.192a.524.524,0,0,1-.4-.243h0l-.116-.153-.318-.419-1.032-1.338c-.792-1.024-1.712-2.19-2.2-2.751a3.07,3.07,0,0,0-.3-.31h-.023l-.093.022-.183.067c-.146.06-.34.142-.568.247-.453.206-1.04.486-1.645.785-1.215.594-2.52,1.252-3.039,1.522l-.232.115h0a2.114,2.114,0,0,1-.8.2h.015a2.134,2.134,0,0,0,.8-.2h0l.231-.119c.516-.266,1.821-.924,3.032-1.518.606-.3,1.189-.576,1.641-.785.228-.1.419-.187.568-.247l.183-.063.1-.023h.026a2.539,2.539,0,0,1,.3.311c.493.56,1.409,1.727,2.2,2.747l1.028,1.338.318.415.116.157a.532.532,0,0,0,.4.247h0l.243-.116.15-.3.041-.187c.187-.83.957-4.216,1.245-5.39a3.75,3.75,0,0,1,.134-.453l.06-.13.049-.056.1-.023a5.878,5.878,0,0,1,.785-.034h.4c1.63,0,4.3.049,5.192.06h.09l.28-.108.094-.236a.665.665,0,0,0-.161-.4h0l-.127-.15c-.288-.333-1.013-1.185-1.746-2.1a22.459,22.459,0,0,1-1.787-2.449l-.1-.205.026.03.093.183a23.351,23.351,0,0,0,1.791,2.448c.748.913,1.458,1.765,1.746,2.1l.127.149h0a.682.682,0,0,1,.161.4l-.094.239-.28.108h-.086c-.89,0-3.544-.056-5.174-.056h-.411a7.693,7.693,0,0,0-.8.03l-.1.026-.045.057-.059.13a3.589,3.589,0,0,0-.135.453c-.292,1.181-1.062,4.568-1.249,5.4l-.041.183-.149.3-.243.116"
              fill="#9fa1a4"
            />
            <path
              d="M1209.2,291.906h0l-.1-.2a5.2,5.2,0,0,0-.508-.277c-.266-.134-.621-.3-1.066-.508-.848-.374-2.007-.912-3.439-1.533l-.108-.052c1.473.639,2.669,1.174,3.536,1.574q.668.308,1.065.508a4.707,4.707,0,0,1,.513.281l.1.209"
              fill="#9fa1a4"
            />
            <path
              d="M1223.642,288.673v-.18a4.514,4.514,0,0,1,.591-.871c.949-1.218,2.777-3.245,3.708-4.359l.034-.037h0l-.023.03c-.93,1.121-2.758,3.148-3.708,4.359a4.466,4.466,0,0,0-.587.871v.157h0"
              fill="#9fa1a4"
            />
            <path
              d="M1208.727,294.078l.03-.138c.145-.486.273-.92.355-1.245a2.784,2.784,0,0,0,.1-.647h0a2.661,2.661,0,0,1-.094.628c-.089.355-.231.849-.4,1.4m10.06,7.054h0a.533.533,0,0,1-.4-.246l-.116-.157-.318-.415-1.028-1.339c-.793-1.024-1.708-2.19-2.2-2.747a2.469,2.469,0,0,0-.3-.31h-.026l-.1.022-.183.067c-.146.06-.34.142-.568.247-.453.206-1.036.486-1.641.781-1.212.6-2.516,1.253-3.032,1.518l-.232.116h0a2.152,2.152,0,0,1-.8.194h.023a2.092,2.092,0,0,0,.8-.2h0l.232-.116c.516-.261,1.817-.923,3.024-1.517.606-.3,1.189-.576,1.642-.782.228-.1.422-.187.568-.243l.183-.067.1-.022h0l.079.063c.157.146.482.527.882,1.017.594.748,1.353,1.708,1.959,2.5l.747,1,.307.4a.535.535,0,0,0,.4.247l.243-.116.146-.3.041-.183c.187-.83.957-4.224,1.241-5.383a3.549,3.549,0,0,1,.135-.453l.059-.127.049-.056.1-.022a5.549,5.549,0,0,1,.774-.034h.374c1.626,0,4.317.049,5.2.06h.086l.281-.108.093-.236a.693.693,0,0,0-.164-.4h0l-.128-.145c-.287-.333-1.016-1.189-1.749-2.1a21.742,21.742,0,0,1-1.787-2.448l-.108-.224.022.026.1.205a22.2,22.2,0,0,0,1.787,2.449c.747.912,1.458,1.764,1.745,2.1l.128.146h0a.669.669,0,0,1,.16.4l-.093.235-.281.108h-.089c-.89,0-3.563-.056-5.193-.056h-.4a6.057,6.057,0,0,0-.785.034l-.1.022-.048.057-.06.13a3.732,3.732,0,0,0-.135.453c-.288,1.17-1.058,4.56-1.245,5.39l-.041.187-.149.3-.24.116"
              fill="#a0a2a5"
            />
            <path
              d="M1209.213,291.91h0l-.1-.213a5.241,5.241,0,0,0-.508-.281c-.266-.13-.625-.3-1.069-.508-.868-.4-2.068-.934-3.537-1.574l-.1-.052h0c1.495.654,2.74,1.2,3.626,1.611.441.206.8.374,1.069.509a5.14,5.14,0,0,1,.516.28l.112.228"
              fill="#a0a2a5"
            />
            <path
              d="M1223.635,288.684v-.195a4.609,4.609,0,0,1,.594-.878c.953-1.219,2.778-3.241,3.708-4.351l.042-.049h0l-.034.037c-.931,1.122-2.755,3.141-3.708,4.359a4.465,4.465,0,0,0-.591.871v.18h0"
              fill="#a0a2a5"
            />
            <path
              d="M1208.7,294.213l.03-.135c.164-.549.306-1.043.4-1.4a2.673,2.673,0,0,0,.1-.628v0a2.554,2.554,0,0,1-.093.609c-.1.374-.255.938-.442,1.544m10.075,6.893a.533.533,0,0,1-.4-.246l-.3-.4-.748-.995c-.609-.792-1.364-1.764-1.962-2.5-.4-.486-.722-.871-.879-1.017l-.082-.06h0l-.1.022-.183.068c-.146.059-.341.142-.569.246-.452.206-1.035.486-1.641.782-1.211.594-2.508,1.252-3.024,1.517l-.232.116a2.091,2.091,0,0,1-.8.2h.018a2.115,2.115,0,0,0,.808-.2l.231-.12c.516-.262,1.81-.916,3.017-1.495.6-.3,1.185-.58,1.638-.782.228-.1.422-.187.564-.243l.183-.067.1-.022h0l.083.059c.157.146.482.531.878,1.017.595.748,1.35,1.7,1.959,2.5l.748,1,.306.4h0a.534.534,0,0,0,.393.243l.243-.116.145-.3.042-.187c.186-.83.96-4.224,1.237-5.376a4.033,4.033,0,0,1,.134-.448l.06-.127.049-.056.1-.027a6.213,6.213,0,0,1,.747-.033h.374c1.626,0,4.333.052,5.234.06h.089l.277-.109.094-.235a.679.679,0,0,0-.165-.4h0l-.127-.15c-.288-.332-1.021-1.188-1.753-2.1a21.008,21.008,0,0,1-1.787-2.448l-.112-.243.018.026.109.224a21.753,21.753,0,0,0,1.787,2.449c.747.912,1.461,1.764,1.749,2.1l.127.146h0a.68.68,0,0,1,.165.4l-.094.235-.28.109h-.086c-.886,0-3.578-.06-5.2-.06h-.373a5.876,5.876,0,0,0-.774.03l-.1.022-.049.056-.06.128a3.685,3.685,0,0,0-.134.452c-.284,1.159-1.054,4.553-1.241,5.383l-.041.183-.146.3-.247.112"
              fill="#a2a4a6"
            />
            <path
              d="M1209.22,291.914h0l-.112-.228a5.049,5.049,0,0,0-.516-.281c-.265-.134-.628-.3-1.069-.508-.886-.411-2.116-.953-3.626-1.611h0l-.108-.053.115.053c1.5.65,2.737,1.2,3.623,1.6.445.206.8.374,1.073.509a5.435,5.435,0,0,1,.519.284l.12.239"
              fill="#a2a4a6"
            />
            <path
              d="M1223.627,288.691v-.216a4.813,4.813,0,0,1,.6-.883c.957-1.218,2.782-3.237,3.709-4.347l.048-.056h0l-.041.048c-.931,1.122-2.755,3.133-3.708,4.352a4.5,4.5,0,0,0-.594.878v.194h0"
              fill="#a2a4a6"
            />
            <path
              d="M1218.768,301.087a.531.531,0,0,1-.4-.243h0l-.3-.4c-.191-.25-.456-.6-.748-.994-.606-.793-1.365-1.765-1.959-2.5a13.3,13.3,0,0,0-.878-1.017l-.083-.064h0l-.1.023-.183.067c-.146.06-.34.142-.564.247-.453.2-1.036.486-1.638.781-1.207.591-2.5,1.249-3.017,1.495l-.231.116a2.1,2.1,0,0,1-.808.2h.019a2.129,2.129,0,0,0,.807-.2h0l.232-.119c.512-.266,1.806-.916,3.009-1.5.6-.3,1.182-.575,1.634-.781.228-.1.419-.187.564-.247l.184-.063.1-.023h0l.082.06c.157.15.486.531.878,1.017.595.748,1.35,1.7,1.955,2.493l.748.991.3.4h0a.524.524,0,0,0,.374.243l.243-.116.145-.3.042-.183c.186-.834.957-4.232,1.233-5.372a3.454,3.454,0,0,1,.135-.445l.063-.127.053-.056.1-.026a5.483,5.483,0,0,1,.747-.03h.374c1.623,0,4.348.048,5.234.06h.086l.28-.109.094-.235a.678.678,0,0,0-.165-.4h0l-.127-.15c-.292-.333-1.021-1.189-1.757-2.1a20.47,20.47,0,0,1-1.779-2.445l-.116-.258v.026l.112.239a21.334,21.334,0,0,0,1.787,2.449c.747.912,1.465,1.768,1.753,2.1l.127.149h0a.68.68,0,0,1,.165.4l-.094.236-.28.108h-.086c-.886,0-3.6-.06-5.234-.06h-.374a6.2,6.2,0,0,0-.747.03l-.1.026-.053.056-.06.131a4.14,4.14,0,0,0-.134.449c-.28,1.151-1.051,4.545-1.237,5.375l-.042.187-.145.3-.243.112"
              fill="#a2a4a7"
            />
            <path
              d="M1208.652,294.351l.034-.138c.183-.61.344-1.159.441-1.544a2.559,2.559,0,0,0,.089-.61v-.127h0l-.119-.239c-.168-.1-.342-.2-.52-.284-.269-.131-.628-.3-1.069-.508-.886-.408-2.112-.954-3.626-1.6l-.116-.049-.194-.1.3.138c1.495.651,2.736,1.193,3.622,1.6a17.49,17.49,0,0,1,1.6.793.472.472,0,0,1,.138.4,2.471,2.471,0,0,1-.086.587c-.1.415-.28,1.028-.486,1.7"
              fill="#a2a4a7"
            />
            <path d="M1203.34,289.017l-.146-.071-.1-.064Z" fill="#a2a4a7" />
            <path
              d="M1223.6,288.7l-.019-.139v-.093a4.708,4.708,0,0,1,.606-.886c.961-1.219,2.781-3.234,3.708-4.34l.052-.064h0l-.048.056c-.927,1.122-2.751,3.129-3.708,4.348a4.671,4.671,0,0,0-.6.882v.217h0"
              fill="#a2a4a7"
            />
            <path
              d="M1218.764,301.072a.521.521,0,0,1-.374-.243h0l-.3-.4-.747-.991c-.606-.793-1.361-1.761-1.955-2.493a13.286,13.286,0,0,0-.879-1.017l-.082-.064h0l-.1.023-.187.063c-.146.06-.34.142-.565.247-.452.206-1.032.482-1.633.781-1.2.591-2.494,1.245-3.01,1.5l-.231.115h0a2.14,2.14,0,0,1-.808.2h.015a2.135,2.135,0,0,0,.811-.2h0l.228-.116c.516-.262,1.8-.92,2.991-1.5.6-.3,1.181-.572,1.63-.778.224-.105.418-.187.564-.247l.183-.067.1-.022h0l.083.059a13.054,13.054,0,0,1,.878,1.017c.594.748,1.346,1.7,1.951,2.49l.748.99.3.4a.529.529,0,0,0,.374.243l.243-.115.145-.3.042-.186c.186-.83.96-4.236,1.233-5.365a3.539,3.539,0,0,1,.131-.441l.064-.131.052-.06a.893.893,0,0,1,.277-.041h.93c1.623,0,4.359.049,5.234.06h.086l.28-.108.094-.232a.679.679,0,0,0-.165-.4h0l-.131-.149c-.288-.337-1.024-1.189-1.76-2.1a20.141,20.141,0,0,1-1.78-2.448l-.119-.273v.022l.115.258a20.4,20.4,0,0,0,1.78,2.445c.748.912,1.469,1.768,1.757,2.1l.127.15h0a.673.673,0,0,1,.164.4l-.093.235-.277.109h-.089c-.883,0-3.608-.06-5.234-.06h-.374a5.483,5.483,0,0,0-.747.03l-.1.026-.052.056-.063.131a3.454,3.454,0,0,0-.135.445c-.277,1.144-1.047,4.538-1.234,5.372l-.041.183-.146.3-.242.116"
              fill="#a4a6a9"
            />
            <path
              d="M1208.633,294.489l.03-.138c.206-.669.374-1.282.486-1.7a2.485,2.485,0,0,0,.086-.587.474.474,0,0,0-.138-.4,16.747,16.747,0,0,0-1.6-.792c-.886-.411-2.112-.954-3.619-1.6l-.306-.138-.232-.112-.25-.135-.038-.026c.267.148.542.283.823.4,1.495.646,2.728,1.188,3.618,1.6a15.745,15.745,0,0,1,1.6.8.48.48,0,0,1,.149.419,2.375,2.375,0,0,1-.082.568c-.108.445-.3,1.121-.527,1.847"
              fill="#a4a6a9"
            />
            <path
              d="M1223.6,288.71l-.023-.15v-.1a4.829,4.829,0,0,1,.61-.889c.964-1.223,2.785-3.234,3.712-4.337l.056-.071h0l-.052.064c-.928,1.121-2.752,3.121-3.709,4.34a4.735,4.735,0,0,0-.6.886v.232h0"
              fill="#a4a6a9"
            />
            <path
              d="M1218.745,301.057a.531.531,0,0,1-.374-.243l-.3-.4-.748-.99c-.606-.789-1.357-1.761-1.951-2.49a12.684,12.684,0,0,0-.879-1.017l-.082-.06h0l-.1.023-.183.067c-.146.06-.34.142-.565.247-.452.206-1.031.482-1.63.777-1.2.591-2.489,1.249-2.99,1.5l-.228.116h0a2.135,2.135,0,0,1-.811.2h.015a2.158,2.158,0,0,0,.815-.2l.228-.12c.512-.258,1.8-.912,2.99-1.5.6-.3,1.178-.572,1.63-.778.224-.1.415-.187.561-.243l.183-.067.1-.023h0l.082.06a12.327,12.327,0,0,1,.879,1.017c.594.748,1.345,1.7,1.951,2.486l.748.991.3.4h0a.531.531,0,0,0,.374.243l.239-.116.146-.3.041-.187c.187-.834.961-4.239,1.23-5.361a3.4,3.4,0,0,1,.135-.441l.116-.187a.863.863,0,0,1,.276-.045h.9c1.615,0,4.381.053,5.26.064h.086l.276-.109.094-.235a.679.679,0,0,0-.165-.4h0l-.13-.146c-.292-.336-1.028-1.192-1.765-2.108a19.537,19.537,0,0,1-1.775-2.445l-.124-.291v.022l.12.273a20.141,20.141,0,0,0,1.779,2.449c.748.912,1.473,1.768,1.761,2.1l.127.15h0a.667.667,0,0,1,.164.4l-.093.232-.28.112h-.086c-.879,0-3.619-.063-5.234-.063h-.931a.788.788,0,0,0-.276.045l-.053.056-.063.131a3.335,3.335,0,0,0-.131.441c-.273,1.121-1.043,4.534-1.234,5.364l-.041.187-.146.3-.243.112"
              fill="#a5a7a9"
            />
            <path
              d="M1208.6,294.624l.03-.135c.225-.747.423-1.4.527-1.846a2.408,2.408,0,0,0,.083-.572.489.489,0,0,0-.146-.419,16.506,16.506,0,0,0-1.6-.8c-.886-.408-2.108-.95-3.615-1.6a8.688,8.688,0,0,1-.822-.4l-.026-.022a8.174,8.174,0,0,0,.845.418c1.495.647,2.728,1.185,3.614,1.593a16.537,16.537,0,0,1,1.612.8.5.5,0,0,1,.157.43,2.4,2.4,0,0,1-.079.553c-.112.467-.329,1.207-.572,1.992"
              fill="#a5a7a9"
            />
            <path
              d="M1223.6,288.721l-.026-.161v-.1a4.906,4.906,0,0,1,.613-.9c.968-1.223,2.789-3.23,3.712-4.333l.06-.071h0l-.056.071c-.927,1.122-2.748,3.114-3.712,4.336a5.037,5.037,0,0,0-.61.894v.1l.023.15h0"
              fill="#a5a7a9"
            />
            <path
              d="M1218.745,301.039a.534.534,0,0,1-.374-.243h0l-.3-.4-.748-.991c-.6-.785-1.357-1.757-1.951-2.486a12.139,12.139,0,0,0-.879-1.017l-.082-.063h0l-.1.022-.183.068c-.146.059-.34.142-.565.243-.448.205-1.028.482-1.63.777-1.2.591-2.482,1.245-2.99,1.495l-.228.116h0a2.16,2.16,0,0,1-.815.206h-.168l.183.022a2.184,2.184,0,0,0,.815-.209h0l.228-.116c.508-.258,1.79-.912,2.99-1.5.6-.3,1.174-.572,1.627-.774.224-.1.414-.187.56-.243l.183-.067.1-.023h0l.083.064a12.818,12.818,0,0,1,.878,1.013c.591.747,1.342,1.7,1.948,2.486l.747.99.3.4h0a.524.524,0,0,0,.374.24h0l.239-.113.146-.3.041-.186c.191-.834.961-4.243,1.226-5.354a3.118,3.118,0,0,1,.135-.441l.116-.187a.872.872,0,0,1,.273-.044h.886c1.614,0,4.392.048,5.27.063h.086l.277-.105.093-.231a.685.685,0,0,0-.164-.4h0l-.131-.15c-.291-.336-1.032-1.192-1.772-2.108a19.686,19.686,0,0,1-1.772-2.445,1.047,1.047,0,0,1-.145-.452v-.109a5.032,5.032,0,0,1,.62-.9c.968-1.227,2.789-3.227,3.712-4.329l.064-.075h0l-.06.075c-.927,1.121-2.748,3.11-3.712,4.332a4.916,4.916,0,0,0-.613.894v.1l.026.161h0l.123.292a19.924,19.924,0,0,0,1.776,2.448c.748.912,1.477,1.768,1.768,2.105l.127.146h0a.692.692,0,0,1,.165.4l-.094.236-.276.108h-.086c-.879,0-3.645-.063-5.26-.063h-.9a.873.873,0,0,0-.277.045l-.115.187a3.556,3.556,0,0,0-.135.441c-.269,1.121-1.039,4.527-1.23,5.36l-.041.187-.146.3-.239.112"
              fill="#a6a8ab"
            />
            <path
              d="M1208.57,294.773l.03-.138c.243-.789.456-1.525.572-1.992a2.468,2.468,0,0,0,.078-.554.5.5,0,0,0-.157-.43,16.882,16.882,0,0,0-1.611-.8c-.886-.407-2.108-.945-3.615-1.592a7.817,7.817,0,0,1-.845-.419h0a7.739,7.739,0,0,0,.856.426c1.5.647,2.722,1.186,3.611,1.589a17.556,17.556,0,0,1,1.615.8.513.513,0,0,1,.165.449,2.293,2.293,0,0,1-.075.534c-.116.5-.355,1.3-.617,2.142"
              fill="#a6a8ab"
            />
            <path
              d="M1218.745,301.02h0a.523.523,0,0,1-.374-.239h0l-.3-.4-.748-.991c-.6-.785-1.353-1.753-1.944-2.482a13.007,13.007,0,0,0-.893-1.013l-.083-.064h0l-.1.023-.183.067c-.145.06-.34.142-.564.243-.452.206-1.024.482-1.626.774-1.2.59-2.479,1.245-2.991,1.5l-.228.116h0a2.178,2.178,0,0,1-.815.209l-.183-.022h0l.194.026a2.2,2.2,0,0,0,.819-.209h0l.228-.116c.508-.262,1.787-.912,2.991-1.5.6-.3,1.17-.572,1.622-.774.224-.1.415-.183.561-.243l.183-.068.1-.022h0l.082.064a12.3,12.3,0,0,1,.879,1.013c.59.729,1.342,1.693,1.943,2.482l.748.987.31.374a.534.534,0,0,0,.374.243l.239-.113.146-.3.041-.187c.191-.837.961-4.246,1.223-5.345a3.2,3.2,0,0,1,.134-.441l.12-.187a.826.826,0,0,1,.269-.045h.871c1.611,0,4.407.052,5.282.063h.086l.277-.1.093-.232a.685.685,0,0,0-.164-.4h0l-.131-.149c-.292-.337-1.032-1.193-1.776-2.109a19.408,19.408,0,0,1-1.772-2.444,1.064,1.064,0,0,1-.142-.445l.023-.116a5.113,5.113,0,0,1,.624-.9c.972-1.23,2.793-3.222,3.712-4.325l.071-.082h0l-.063.075c-.924,1.121-2.744,3.1-3.712,4.328a5.14,5.14,0,0,0-.621.9l-.019.113a1.047,1.047,0,0,0,.146.448,19.586,19.586,0,0,0,1.772,2.445c.748.912,1.495,1.768,1.772,2.105l.131.149h0a.684.684,0,0,1,.164.4l-.089.232-.281.1h-.086c-.878,0-3.656-.063-5.271-.063h-.889a.9.9,0,0,0-.273.045l-.116.186a3.248,3.248,0,0,0-.131.442c-.269,1.121-1.036,4.515-1.226,5.353l-.041.187-.146.3-.236.112"
              fill="#a8aaad"
            />
            <path
              d="M1208.544,294.886l.03-.128c.261-.844.5-1.648.616-2.145a2.2,2.2,0,0,0,.075-.535.517.517,0,0,0-.164-.445,16.8,16.8,0,0,0-1.619-.8c-.886-.4-2.105-.942-3.611-1.589a8.528,8.528,0,0,1-.856-.426h0a8.754,8.754,0,0,0,.867.433c1.495.643,2.721,1.178,3.607,1.585a17.288,17.288,0,0,1,1.627.8.525.525,0,0,1,.172.46,2.363,2.363,0,0,1-.072.516c-.123.516-.373,1.383-.654,2.284"
              fill="#a8aaad"
            />
            <path
              d="M1218.745,301.005a.531.531,0,0,1-.374-.243l-.3-.4-.748-.987c-.6-.785-1.353-1.753-1.944-2.482a12.611,12.611,0,0,0-.893-1l-.083-.064h0l-.1.023-.183.067c-.145.06-.34.142-.564.243-.449.206-1.024.482-1.623.774-1.2.587-2.471,1.241-2.99,1.5l-.228.116h0a2.2,2.2,0,0,1-.819.209l-.2-.026h0l.209.03a2.156,2.156,0,0,0,.819-.213l.228-.116c.508-.262,1.783-.908,2.991-1.5.594-.292,1.17-.572,1.618-.774.225-.105.415-.187.561-.243l.183-.067.1-.023h0l.082.064a11.93,11.93,0,0,1,.879,1.013c.59.725,1.342,1.693,1.94,2.478l.748.987.317.382h0a.531.531,0,0,0,.374.243l.239-.116.146-.3.041-.184c.187-.837.961-4.25,1.219-5.341a3.337,3.337,0,0,1,.131-.438l.123-.187a.984.984,0,0,1,.269-.045h.853c1.607,0,4.422.053,5.293.064h.086l.277-.105.093-.232a.69.69,0,0,0-.164-.4h0l-.131-.15c-.292-.336-1.036-1.2-1.78-2.108a18.539,18.539,0,0,1-1.768-2.445,1.022,1.022,0,0,1-.134-.437l.022-.124a5.247,5.247,0,0,1,.628-.908c.976-1.23,2.793-3.222,3.716-4.321l.071-.083h0l-.067.083c-.924,1.1-2.741,3.1-3.716,4.325a5.142,5.142,0,0,0-.624.9l-.023.116a1.044,1.044,0,0,0,.142.445,19.081,19.081,0,0,0,1.772,2.445c.748.912,1.495,1.772,1.776,2.108l.131.15h0a.684.684,0,0,1,.164.4l-.093.231-.277.105h-.086c-.875,0-3.671-.064-5.282-.064h-.871a.877.877,0,0,0-.269.045l-.12.187a3.1,3.1,0,0,0-.131.441c-.261,1.1-1.035,4.509-1.222,5.346l-.041.187-.146.3-.239.112"
              fill="#a9abad"
            />
            <path
              d="M1208.51,295.024l.03-.138c.276-.9.534-1.765.654-2.281a2.33,2.33,0,0,0,.071-.519.522.522,0,0,0-.172-.46,17.452,17.452,0,0,0-1.622-.8c-.89-.407-2.109-.942-3.608-1.585a7.713,7.713,0,0,1-.867-.434h0a8.033,8.033,0,0,0,.879.442c1.495.639,2.717,1.173,3.6,1.581a17.119,17.119,0,0,1,1.63.8.54.54,0,0,1,.183.474,2.189,2.189,0,0,1-.067.5c-.127.546-.4,1.5-.7,2.434"
              fill="#a9abad"
            />
            <path
              d="M1218.745,300.99a.531.531,0,0,1-.374-.243h0l-.3-.4-.747-.987c-.6-.785-1.35-1.75-1.94-2.478a12.334,12.334,0,0,0-.883-1.014l-.082-.063h0l-.1.022-.183.068c-.146.059-.34.142-.561.243-.448.2-1.024.478-1.618.773-1.193.587-2.468,1.238-2.991,1.5l-.228.116h0a2.174,2.174,0,0,1-.819.213l-.209-.03v-.019l.224.037a2.184,2.184,0,0,0,.823-.216l.228-.116c.508-.262,1.779-.909,2.968-1.5.594-.291,1.17-.568,1.619-.773.224-.1.414-.184.56-.24l.183-.067.1-.022h0l.083.063a12.307,12.307,0,0,1,.882,1.013c.59.726,1.338,1.694,1.94,2.475l.748.983.3.4h0a.523.523,0,0,0,.374.239l.239-.115.146-.3h0l.041-.187c.187-.837.961-4.254,1.219-5.334a2.845,2.845,0,0,1,.131-.434l.123-.187a1.043,1.043,0,0,1,.266-.045h.826c1.6,0,4.437.053,5.3.068h.086l.277-.105.093-.228a.684.684,0,0,0-.164-.4h0l-.131-.149c-.3-.337-1.039-1.2-1.783-2.112a18.263,18.263,0,0,1-1.765-2.445,1.02,1.02,0,0,1-.13-.434l.022-.127a5.3,5.3,0,0,1,.632-.912c.979-1.234,2.8-3.222,3.716-4.318l.074-.086h0l-.071.083c-.923,1.1-2.74,3.091-3.715,4.321a5.181,5.181,0,0,0-.613.893l-.023.124a1.037,1.037,0,0,0,.138.437,18.294,18.294,0,0,0,1.769,2.445c.747.916,1.495,1.776,1.779,2.112l.131.146h0a.685.685,0,0,1,.164.4l-.093.231-.277.105h-.086c-.871,0-3.682-.064-5.293-.064h-.856a1.023,1.023,0,0,0-.269.045l-.12.187a3.224,3.224,0,0,0-.134.438c-.258,1.087-1.032,4.485-1.219,5.342l-.041.183-.146.3-.239.112"
              fill="#aaacaf"
            />
            <path
              d="M1208.48,295.147l.03-.138c.3-.957.572-1.892.7-2.434a2.189,2.189,0,0,0,.067-.5.537.537,0,0,0-.183-.475,17.354,17.354,0,0,0-1.63-.8c-.886-.408-2.1-.942-3.6-1.582a7.6,7.6,0,0,1-.879-.441h0a7.341,7.341,0,0,0,.886.445c1.5.639,2.71,1.17,3.6,1.578a17.893,17.893,0,0,1,1.637.807.557.557,0,0,1,.191.494,2.147,2.147,0,0,1-.064.478c-.13.568-.429,1.574-.747,2.583"
              fill="#aaacaf"
            />
            <path
              d="M1218.745,300.971a.523.523,0,0,1-.374-.239h0l-.3-.4-.748-.987c-.6-.781-1.35-1.75-1.94-2.475a12.307,12.307,0,0,0-.882-1.013l-.083-.063h0l-.1.026-.183.063c-.146.056-.34.142-.56.24-.449.2-1.025.478-1.615.773-1.189.587-2.46,1.238-2.969,1.5l-.228.116a2.174,2.174,0,0,1-.822.216l-.224-.037h0l.235.041a2.2,2.2,0,0,0,.823-.217h0l.228-.116c.5-.257,1.772-.908,2.96-1.495.6-.291,1.167-.568,1.615-.77.225-.1.415-.183.561-.239l.187-.067.1-.019h0l.082.063a11.79,11.79,0,0,1,.879,1.014c.59.725,1.338,1.689,1.936,2.47.3.374.561.748.748.984l.3.4a.523.523,0,0,0,.373.239l.24-.112.146-.3.041-.187c.19-.837.96-4.257,1.215-5.326a3.234,3.234,0,0,1,.13-.434l.124-.183a.887.887,0,0,1,.265-.049h.834c1.6,0,4.452.056,5.319.067h.086l.277-.1.093-.228a.685.685,0,0,0-.164-.4h0l-.131-.15c-.295-.336-1.043-1.2-1.787-2.115a17.641,17.641,0,0,1-1.764-2.445,1.018,1.018,0,0,1-.127-.426l.026-.135a5.277,5.277,0,0,1,.639-.916c.983-1.233,2.8-3.218,3.716-4.31l.075-.09h0l-.075.086c-.92,1.1-2.737,3.084-3.716,4.318a5.3,5.3,0,0,0-.632.912l-.022.127a1.006,1.006,0,0,0,.131.434,18.16,18.16,0,0,0,1.764,2.445c.748.915,1.495,1.775,1.783,2.112l.131.149a.692.692,0,0,1,.165.4l-.094.228-.277.1h-.086c-.871,0-3.7-.068-5.3-.068h-.837a1.039,1.039,0,0,0-.266.041l-.123.187a3.185,3.185,0,0,0-.131.434c-.258,1.08-1.028,4.486-1.219,5.334l-.041.187h0l-.146.3-.239.112"
              fill="#abadb0"
            />
            <path
              d="M1208.45,295.289l.03-.131c.314-1.009.613-2.014.748-2.583a2.254,2.254,0,0,0,.06-.478.56.56,0,0,0-.187-.494,17.97,17.97,0,0,0-1.638-.807c-.889-.408-2.1-.938-3.6-1.574a7.8,7.8,0,0,1-.893-.43h0a7.362,7.362,0,0,0,.89.453c1.495.635,2.706,1.166,3.6,1.573a17.644,17.644,0,0,1,1.645.808.565.565,0,0,1,.2.5,2.253,2.253,0,0,1-.06.464c-.135.591-.456,1.671-.785,2.729"
              fill="#abadb0"
            />
            <path
              d="M1218.745,300.956a.523.523,0,0,1-.374-.239l-.3-.4c-.187-.247-.449-.594-.748-.983-.6-.781-1.346-1.746-1.936-2.471a12.1,12.1,0,0,0-.879-1.013l-.086-.064h0l-.1.023-.183.067c-.146.056-.34.142-.561.239-.445.206-1.02.479-1.615.771-1.185.586-2.452,1.233-2.96,1.5l-.225.116h0a2.212,2.212,0,0,1-.822.216l-.236-.041v-.018l.247.048a2.218,2.218,0,0,0,.826-.22h0l.225-.116c.5-.262,1.768-.909,2.953-1.495.59-.292,1.166-.569,1.611-.771.224-.1.415-.183.561-.239l.183-.067.1-.022h0l.086.063a11.524,11.524,0,0,1,.878,1.009c.591.726,1.335,1.69,1.933,2.471.3.374.561.748.748.984l.3.4h0a.519.519,0,0,0,.373.239l.24-.112.142-.295.041-.187c.191-.841.964-4.266,1.211-5.324a3.036,3.036,0,0,1,.131-.429l.123-.187a.857.857,0,0,1,.266-.045h.8c1.6,0,4.464.056,5.327.067h.086l.277-.105.09-.228a.683.683,0,0,0-.165-.4l-.131-.15c-.3-.336-1.046-1.2-1.79-2.116a17.289,17.289,0,0,1-1.761-2.445.99.99,0,0,1-.123-.422l.026-.142a5.682,5.682,0,0,1,.643-.92c.987-1.237,2.8-3.214,3.719-4.306l.075-.093h0l-.075.089c-.919,1.092-2.732,3.077-3.715,4.31a5.336,5.336,0,0,0-.64.916l-.026.135a1.013,1.013,0,0,0,.127.426,17.561,17.561,0,0,0,1.761,2.445c.748.916,1.495,1.779,1.787,2.116l.131.149h0a.685.685,0,0,1,.164.4l-.093.228-.277.105h-.086c-.867,0-3.719-.068-5.319-.068h-.815a.862.862,0,0,0-.266.049l-.123.183a2.859,2.859,0,0,0-.131.434c-.254,1.069-1.028,4.486-1.219,5.327l-.041.187-.146.3-.239.112"
              fill="#adafb1"
            />
            <path
              d="M1208.417,295.428l.033-.139c.329-1.058.651-2.138.785-2.729a2.125,2.125,0,0,0,.06-.463.566.566,0,0,0-.2-.5,17.893,17.893,0,0,0-1.645-.807c-.89-.408-2.1-.939-3.6-1.574a7.2,7.2,0,0,1-.89-.449h0a7.811,7.811,0,0,0,.894.453c1.495.631,2.706,1.166,3.592,1.57a17.228,17.228,0,0,1,1.652.815.576.576,0,0,1,.21.516,2.141,2.141,0,0,1-.056.444c-.135.613-.483,1.769-.83,2.879"
              fill="#adafb1"
            />
            <path
              d="M1218.745,300.938a.521.521,0,0,1-.374-.24h0l-.3-.4-.747-.983c-.6-.781-1.342-1.742-1.933-2.471a11.57,11.57,0,0,0-.882-1.009l-.086-.064h0l-.1.023-.183.063c-.146.056-.34.142-.561.239-.449.206-1.021.479-1.611.771-1.181.583-2.449,1.229-2.953,1.495l-.228.116a2.222,2.222,0,0,1-.827.22l-.246-.048h0l.258.056a2.244,2.244,0,0,0,.826-.221h0l.224-.116c.5-.258,1.765-.908,2.946-1.495.59-.292,1.162-.568,1.607-.766.225-.1.415-.183.561-.24l.183-.063.1-.023h0l.086.064a11.57,11.57,0,0,1,.882,1.009c.587.726,1.331,1.686,1.929,2.468l.748.979.3.4h0a.523.523,0,0,0,.374.239l.235-.112.146-.3h0l.041-.187c.191-.841.965-4.269,1.211-5.316a2.977,2.977,0,0,1,.131-.43l.127-.186.262-.049h.785c1.6,0,4.486.056,5.342.071h.086l.276-.105.094-.228a.677.677,0,0,0-.165-.4h0l-.13-.15c-.3-.336-1.051-1.2-1.8-2.116a16.91,16.91,0,0,1-1.757-2.445.97.97,0,0,1-.12-.411l.03-.146a5.437,5.437,0,0,1,.647-.927c.99-1.237,2.8-3.211,3.719-4.3l.079-.094h0l-.079.094c-.919,1.091-2.733,3.072-3.719,4.306a5.58,5.58,0,0,0-.643.92l-.027.142a.989.989,0,0,0,.124.418,17.635,17.635,0,0,0,1.76,2.445c.748.916,1.5,1.776,1.791,2.116l.131.153a.684.684,0,0,1,.164.4l-.089.228-.277.105h-.086c-.863,0-3.738-.064-5.327-.064h-.8a.827.827,0,0,0-.261.045l-.124.187a2.773,2.773,0,0,0-.13.43c-.251,1.058-1.025,4.486-1.212,5.323l-.041.187-.146.295-.239.112"
              fill="#adb0b2"
            />
            <path
              d="M1208.387,295.558l.03-.13c.347-1.122.691-2.266.829-2.875a2.054,2.054,0,0,0,.056-.449.57.57,0,0,0-.209-.516,18.049,18.049,0,0,0-1.652-.811c-.886-.4-2.1-.934-3.593-1.57a7.718,7.718,0,0,1-.893-.452h0a6.807,6.807,0,0,0,.9.456c1.495.632,2.7,1.159,3.589,1.566q.847.366,1.656.815a.59.59,0,0,1,.217.527,2.071,2.071,0,0,1-.053.43c-.142.636-.512,1.869-.871,3.024"
              fill="#adb0b2"
            />
            <path
              d="M1218.745,300.923a.526.526,0,0,1-.374-.239h0l-.3-.4-.747-.983c-.6-.781-1.342-1.738-1.929-2.467a11.758,11.758,0,0,0-.882-1.009l-.086-.064h0l-.1.022-.184.064c-.145.06-.34.138-.56.239-.445.2-1.017.479-1.608.767-1.181.583-2.444,1.229-2.945,1.5l-.225.116h0a2.238,2.238,0,0,1-.826.22l-.258-.056v-.018l.269.063a2.242,2.242,0,0,0,.83-.228l.225-.116c.5-.258,1.76-.9,2.938-1.495.59-.288,1.159-.565,1.6-.748.225-.1.415-.183.561-.243l.183-.063.1-.023h0l.086.064a12.4,12.4,0,0,1,.879,1.009c.587.725,1.331,1.686,1.925,2.464l.748.979.3.4h0a.51.51,0,0,0,.373.24h0l.232-.113.142-.291h0l.041-.187c.191-.841.965-4.273,1.208-5.308a2.718,2.718,0,0,1,.131-.426l.127-.187.258-.045h.785c1.592,0,4.486.052,5.334.067h.086l.273-.1.094-.228a.679.679,0,0,0-.165-.4h0l-.131-.149c-.3-.34-1.054-1.2-1.8-2.12a16.757,16.757,0,0,1-1.753-2.445.967.967,0,0,1-.116-.4l.03-.154a6.014,6.014,0,0,1,.654-.93c.995-1.238,2.8-3.211,3.739-4.3l.082-.1h0l-.079.094c-.919,1.088-2.729,3.065-3.719,4.3a5.5,5.5,0,0,0-.647.927l-.03.146a.985.985,0,0,0,.12.415,17.1,17.1,0,0,0,1.757,2.445c.747.916,1.495,1.779,1.8,2.116l.131.149h0a.685.685,0,0,1,.164.4l-.093.224-.277.105h-.086c-.86,0-3.738-.071-5.342-.071h-.785l-.262.049-.127.187a2.8,2.8,0,0,0-.13.429c-.247,1.047-1.021,4.486-1.212,5.312l-.041.187h0l-.146.3-.235.112"
              fill="#afb1b4"
            />
            <path
              d="M1208.357,295.689l.03-.131c.373-1.155.747-2.388.871-3.02a2.23,2.23,0,0,0,.052-.43.587.587,0,0,0-.221-.527,17.769,17.769,0,0,0-1.656-.815c-.889-.4-2.1-.931-3.588-1.563a6.887,6.887,0,0,1-.9-.456h0a7.344,7.344,0,0,0,.9.46c1.5.628,2.7,1.155,3.589,1.559a18.441,18.441,0,0,1,1.664.819.6.6,0,0,1,.228.542,2.1,2.1,0,0,1-.049.411c-.142.654-.538,1.966-.912,3.17"
              fill="#afb1b4"
            />
            <path
              d="M1218.745,300.9h0a.515.515,0,0,1-.374-.239h0l-.3-.4-.747-.98c-.6-.777-1.339-1.738-1.925-2.463a12.585,12.585,0,0,0-.879-1.01l-.086-.059h0l-.1.022-.183.064c-.146.056-.34.138-.561.239-.445.2-1.013.475-1.6.747-1.178.58-2.438,1.23-2.939,1.5l-.224.116a2.263,2.263,0,0,1-.83.228l-.269-.064v-.022l.277.074a2.263,2.263,0,0,0,.829-.228h0l.225-.115c.5-.258,1.757-.9,2.931-1.5.586-.288,1.158-.561,1.6-.766.225-.1.415-.183.561-.239l.187-.064.1-.022h0l.086.063a12.412,12.412,0,0,1,.879,1.01c.586.721,1.33,1.682,1.925,2.459.3.374.557.748.747.98l.3.4h0a.523.523,0,0,0,.374.239l.236-.112.142-.295h0v-.049c.108-.478.979-4.34,1.237-5.443a2.768,2.768,0,0,1,.127-.426l.131-.187.258-.045h.785c1.6,0,4.486.056,5.327.071h.086l.277-.1.089-.228a.686.686,0,0,0-.164-.407h0l-.135-.15c-.3-.34-1.054-1.2-1.8-2.119a16.291,16.291,0,0,1-1.75-2.441,1,1,0,0,1-.112-.4l.034-.165a5.78,5.78,0,0,1,.658-.934c.994-1.241,2.8-3.208,3.738-4.292l.082-.093h0l-.082.093c-.916,1.088-2.729,3.058-3.738,4.3a6.029,6.029,0,0,0-.655.93l-.029.154a.953.953,0,0,0,.115.407,16.742,16.742,0,0,0,1.754,2.445c.747.916,1.495,1.779,1.8,2.119l.131.15h0a.679.679,0,0,1,.165.4l-.094.228-.273.1h-.086c-.856,0-3.738-.071-5.334-.071h-.789l-.258.083-.127.183a2.752,2.752,0,0,0-.131.426c-.243,1.035-1.016,4.486-1.207,5.308l-.041.187h0l-.142.3-.232.113"
              fill="#b0b2b5"
            />
            <path
              d="M1208.327,295.824l.03-.135c.374-1.2.77-2.512.912-3.17a2.044,2.044,0,0,0,.048-.411.6.6,0,0,0-.228-.542,18.312,18.312,0,0,0-1.663-.819c-.89-.4-2.1-.93-3.585-1.558a7.211,7.211,0,0,1-.9-.46h0a6.972,6.972,0,0,0,.9.463c1.5.628,2.7,1.155,3.581,1.555a18.44,18.44,0,0,1,1.671.819.609.609,0,0,1,.239.557,1.9,1.9,0,0,1-.048.4c-.146.677-.568,2.075-.957,3.316"
              fill="#b0b2b5"
            />
            <path
              d="M1218.745,300.889a.523.523,0,0,1-.374-.239h0l-.3-.4c-.187-.247-.445-.591-.747-.98-.595-.777-1.335-1.738-1.922-2.46a12.791,12.791,0,0,0-.882-1.009l-.086-.063h0l-.105.022-.183.067c-.146.056-.34.139-.561.24-.441.2-1.013.478-1.6.766-1.178.579-2.43,1.226-2.931,1.495l-.225.116h0a2.282,2.282,0,0,1-.829.228l-.277-.075v-.022h0l.28.075a2.307,2.307,0,0,0,.83-.228h0l.224-.116c.5-.258,1.75-.9,2.924-1.5.587-.287,1.155-.56,1.6-.747.224-.1.415-.183.56-.239a1.273,1.273,0,0,1,.288-.086h0l.086.063a11.375,11.375,0,0,1,.879,1.009c.587.722,1.327,1.683,1.921,2.456.3.374.557.748.748.976l.3.4a.527.527,0,0,0,.374.24l.235-.112.146-.292.041-.191c.191-.845.965-4.28,1.2-5.3a2.69,2.69,0,0,1,.127-.422l.131-.183.254-.049h.785c1.593,0,4.486.056,5.324.071h.086l.276-.1.09-.224a.693.693,0,0,0-.165-.408h0l-.134-.153c-.3-.336-1.058-1.2-1.806-2.119a15.809,15.809,0,0,1-1.749-2.442.973.973,0,0,1-.109-.4l.034-.168a5.725,5.725,0,0,1,.662-.938c1-1.245,2.807-3.2,3.738-4.288l.082-.094h0l-.078.094c-.916,1.088-2.726,3.054-3.739,4.291a5.858,5.858,0,0,0-.658.935l-.033.164a.978.978,0,0,0,.112.4,16.338,16.338,0,0,0,1.753,2.442c.748.919,1.495,1.779,1.8,2.119l.135.15a.68.68,0,0,1,.164.407l-.09.228-.273.1h-.086c-.848,0-3.738-.071-5.327-.071h-.792l-.258.049-.131.187a2.718,2.718,0,0,0-.131.426c-.258,1.1-1.121,4.964-1.237,5.443v.048h0l-.142.295-.236.113"
              fill="#b1b4b6"
            />
            <path
              d="M1208.278,295.955l.03-.131c.374-1.241.811-2.639.957-3.316a2.037,2.037,0,0,0,.045-.4.617.617,0,0,0-.236-.557,18.151,18.151,0,0,0-1.671-.819c-.889-.4-2.1-.931-3.585-1.559a6.886,6.886,0,0,1-.9-.463h0a6.644,6.644,0,0,0,.9.463c1.5.628,2.692,1.152,3.581,1.555s1.462.684,1.679.819a.625.625,0,0,1,.247.568,1.957,1.957,0,0,1-.045.374c-.146.7-.6,2.179-1,3.462"
              fill="#b1b4b6"
            />
            <path
              d="M1218.745,300.87a.523.523,0,0,1-.374-.239l-.3-.4-.747-.979c-.595-.778-1.335-1.735-1.922-2.456a11.852,11.852,0,0,0-.878-1.01l-.09-.063h0a1.168,1.168,0,0,0-.288.086c-.146.056-.34.142-.561.239-.444.2-1.013.475-1.6.748-1.174.579-2.427,1.222-2.924,1.5l-.224.116h0a2.288,2.288,0,0,1-.83.228l-.28-.075h0v-.022h0l.276.074a2.243,2.243,0,0,0,.834-.231l.224-.116c.5-.258,1.746-.9,2.916-1.5.587-.287,1.155-.56,1.6-.747.225-.1.411-.183.561-.236a1.152,1.152,0,0,1,.288-.086h0l.086.064a12.324,12.324,0,0,1,.871,1.024c.587.722,1.327,1.679,1.918,2.452l.747.98.295.374a.52.52,0,0,0,.374.239l.236-.109.142-.295.041-.187c.191-.845.968-4.284,1.2-5.289a2.7,2.7,0,0,1,.127-.423l.135-.183.254-.049h.789c1.592,0,4.485.06,5.312.071h.086l.272-.1.09-.224a.693.693,0,0,0-.164-.407h0l-.135-.15c-.3-.34-1.062-1.2-1.813-2.123a15.782,15.782,0,0,1-1.746-2.441.962.962,0,0,1-.1-.374l.034-.176a6.027,6.027,0,0,1,.669-.942c1-1.241,2.811-3.2,3.738-4.284l.083-.093h0l-.083.093c-.912,1.084-2.725,3.047-3.738,4.288a5.83,5.83,0,0,0-.661.938l-.034.169a1,1,0,0,0,.108.4,16.1,16.1,0,0,0,1.75,2.441c.747.919,1.495,1.783,1.809,2.119l.135.15h0a.692.692,0,0,1,.164.407l-.09.228-.272.1h-.083c-.845,0-3.738-.071-5.323-.071h-.763l-.254.049-.131.183a2.581,2.581,0,0,0-.13.423c-.236,1.016-1.013,4.452-1.2,5.3l-.041.19-.143.292-.235.112"
              fill="#b2b5b7"
            />
            <path
              d="M1208.278,296.089l.03-.134c.4-1.279.852-2.763,1-3.458a1.812,1.812,0,0,0,.045-.374.617.617,0,0,0-.247-.564,18.287,18.287,0,0,0-1.674-.823c-.89-.4-2.1-.927-3.582-1.551a6.822,6.822,0,0,1-.9-.464h0a6.678,6.678,0,0,0,.886.445c1.495.621,2.687,1.148,3.577,1.548a16.4,16.4,0,0,1,1.682.826.634.634,0,0,1,.258.579,1.816,1.816,0,0,1-.041.374c-.149.714-.628,2.292-1.043,3.607"
              fill="#b2b5b7"
            />
            <path
              d="M1218.745,300.855a.517.517,0,0,1-.374-.239l-.295-.374-.748-.979c-.594-.774-1.334-1.731-1.921-2.452a12.148,12.148,0,0,0-.879-1.006l-.085-.063h0a1.063,1.063,0,0,0-.288.086c-.146.056-.337.138-.561.239-.441.2-1.009.475-1.6.747-1.17.576-2.419,1.219-2.916,1.477l-.224.116a2.256,2.256,0,0,1-.834.232l-.277-.075h0v-.052l.03.026.273.075a2.251,2.251,0,0,0,.834-.236h0l.22-.116c.5-.254,1.742-.9,2.912-1.476.584-.288,1.152-.561,1.593-.748.22-.1.411-.183.561-.239a1.155,1.155,0,0,1,.287-.086h0l.09.063a11.833,11.833,0,0,1,.879,1.006c.587.721,1.323,1.678,1.917,2.452l.748.976.3.392h0a.517.517,0,0,0,.374.236l.235-.109.142-.291.042-.191c.19-.845.968-4.288,1.2-5.282a2.475,2.475,0,0,1,.127-.419l.134-.187.255-.048h.785c1.592,0,4.486.06,5.308.075h.086l.273-.1.09-.225a.694.694,0,0,0-.165-.407h0l-.134-.153c-.3-.337-1.066-1.2-1.817-2.124a15.211,15.211,0,0,1-1.746-2.441.953.953,0,0,1-.1-.374l.041-.183a6.21,6.21,0,0,1,.673-.945c1.006-1.242,2.811-3.2,3.738-4.281l.083-.093h0l-.083.093c-.912,1.084-2.721,3.039-3.738,4.284a6.027,6.027,0,0,0-.669.942l-.034.176a.96.96,0,0,0,.1.374,15.774,15.774,0,0,0,1.746,2.441c.748.919,1.495,1.783,1.813,2.123l.135.15h0a.7.7,0,0,1,.164.407l-.09.224-.273.1h-.112c-.837,0-3.738-.071-5.312-.071h-.788l-.255.049-.134.183a2.612,2.612,0,0,0-.127.423c-.232,1.005-1.01,4.444-1.2,5.289l-.041.187-.142.295-.236.109"
              fill="#b4b6b8"
            />
            <path
              d="M1208.233,296.224l.03-.135c.415-1.316.894-2.889,1.043-3.607a1.743,1.743,0,0,0,.041-.374.634.634,0,0,0-.258-.579c-.22-.139-.8-.423-1.682-.826s-2.093-.928-3.577-1.548a6.488,6.488,0,0,1-.9-.464h0a6.286,6.286,0,0,0,.9.464c1.495.62,2.684,1.144,3.574,1.548a18.44,18.44,0,0,1,1.689.826.646.646,0,0,1,.27.59l-.038.348c-.149.748-.662,2.411-1.084,3.738"
              fill="#b4b6b8"
            />
            <path
              d="M1218.745,300.837a.52.52,0,0,1-.374-.236h0l-.295-.374-.748-.975c-.59-.774-1.33-1.731-1.917-2.452a11.077,11.077,0,0,0-.879-1.006l-.086-.064h0a1.258,1.258,0,0,0-.288.086c-.145.06-.336.139-.56.24-.441.2-1.01.471-1.593.747-1.17.576-2.415,1.219-2.912,1.477l-.22.116a2.255,2.255,0,0,1-.838.235l-.273-.074-.03-.027V298.5l.038.038.273.074a2.268,2.268,0,0,0,.833-.235h0l.221-.116c.493-.254,1.734-.9,2.9-1.473.584-.288,1.148-.561,1.589-.748.221-.1.411-.183.557-.235a1.192,1.192,0,0,1,.292-.09h0l.089.064a11.808,11.808,0,0,1,.879,1.005c.587.722,1.323,1.675,1.914,2.449l.748.972.3.392h0a.509.509,0,0,0,.374.236l.231-.112.143-.292.041-.187c.19-.845.968-4.3,1.2-5.278a2.262,2.262,0,0,1,.127-.419l.135-.183.254-.052h.785c1.592,0,4.486.059,5.3.071h.086l.272-.1.09-.224a.683.683,0,0,0-.168-.4h0l-.138-.149c-.3-.34-1.066-1.208-1.821-2.127a15.1,15.1,0,0,1-1.738-2.441.935.935,0,0,1-.094-.374l.042-.191a6.18,6.18,0,0,1,.68-.953c1.005-1.248,2.815-3.2,3.738-4.276l.082-.094h0l-.082.094c-.912,1.08-2.718,3.035-3.738,4.28a6.287,6.287,0,0,0-.673.946l-.037.187a.95.95,0,0,0,.1.373,15.387,15.387,0,0,0,1.746,2.441c.747.92,1.495,1.787,1.816,2.124l.135.149h0a.7.7,0,0,1,.164.408l-.089.224-.273.1h-.079c-.83,0-3.715-.071-5.308-.075h-.785l-.254.049-.135.187a2.427,2.427,0,0,0-.127.418c-.228,1-1.005,4.441-1.2,5.282l-.041.191-.142.292-.236.108"
              fill="#b5b7b9"
            />
            <path
              d="M1208.2,296.351l.03-.127c.426-1.346.935-3.017,1.084-3.757l.042-.348a.652.652,0,0,0-.27-.59,18.247,18.247,0,0,0-1.689-.823c-.89-.4-2.09-.923-3.574-1.547a6.4,6.4,0,0,1-.905-.468h0a6.075,6.075,0,0,0,.905.464c1.495.62,2.68,1.14,3.57,1.544s1.465.684,1.7.826a.656.656,0,0,1,.277.6l-.034.332c-.153.748-.7,2.524-1.121,3.9"
              fill="#b5b7b9"
            />
            <path
              d="M1218.727,300.818a.508.508,0,0,1-.374-.235h0l-.3-.374-.747-.976c-.591-.774-1.331-1.727-1.914-2.448a11.5,11.5,0,0,0-.879-1.006l-.089-.064h0a1.237,1.237,0,0,0-.292.09c-.146.056-.336.135-.557.236-.445.2-1.006.471-1.589.747-1.166.576-2.407,1.215-2.9,1.469l-.221.116a2.314,2.314,0,0,1-.837.236l-.273-.075-.038-.037v-.023l.049.049.265.075a2.3,2.3,0,0,0,.838-.24h0l.22-.116c.494-.254,1.735-.9,2.9-1.469.579-.287,1.143-.557,1.585-.747.22-.1.411-.183.56-.24a1.172,1.172,0,0,1,.288-.089h0l.09.063a12.462,12.462,0,0,1,.871.995c.587.721,1.323,1.674,1.91,2.448l.748.972.3.374a.508.508,0,0,0,.374.235l.232-.108.142-.292.041-.186c.19-.849.968-4.3,1.192-5.271a2.527,2.527,0,0,1,.127-.419l.139-.183.254-.049h.785c1.592,0,4.486.064,5.3.071H1227l.269-.1.09-.224a.678.678,0,0,0-.168-.4h0l-.135-.153c-.3-.34-1.073-1.208-1.824-2.127a14.713,14.713,0,0,1-1.739-2.445.9.9,0,0,1-.089-.374l.041-.194a6.2,6.2,0,0,1,.684-.957c1.013-1.249,2.815-3.193,3.738-4.269l.082-.1h0l-.082.094c-.912,1.08-2.717,3.028-3.738,4.276a6.076,6.076,0,0,0-.68.954l-.042.19a.915.915,0,0,0,.094.374,15.017,15.017,0,0,0,1.738,2.441c.748.92,1.518,1.787,1.821,2.127l.134.154h0a.685.685,0,0,1,.169.4l-.09.225-.273.1h-.086c-.826,0-3.708-.071-5.3-.071h-.785l-.254.052-.135.183a2.276,2.276,0,0,0-.127.419c-.228.983-1,4.433-1.2,5.278l-.041.187-.142.292-.232.108"
              fill="#b7b9bb"
            />
            <path
              d="M1208.174,296.486l.029-.135c.434-1.372.976-3.14,1.122-3.9l.034-.333a.652.652,0,0,0-.277-.6c-.228-.142-.808-.426-1.7-.83s-2.09-.919-3.57-1.54a6.292,6.292,0,0,1-.9-.467h0a6.2,6.2,0,0,0,.9.467c1.495.617,2.677,1.122,3.566,1.537s1.469.684,1.705.829a.668.668,0,0,1,.288.613l-.034.318c-.153.778-.748,2.651-1.17,4.045"
              fill="#b7b9bb"
            />
            <path
              d="M1218.723,300.8a.508.508,0,0,1-.374-.235l-.3-.374c-.184-.247-.441-.587-.748-.972-.591-.774-1.327-1.727-1.914-2.449a11.928,11.928,0,0,0-.878-1l-.09-.064h0a1.162,1.162,0,0,0-.288.09c-.146.056-.336.138-.557.239-.441.2-1.006.467-1.589.748-1.162.575-2.4,1.215-2.9,1.469l-.22.116h0a2.319,2.319,0,0,1-.838.239l-.269-.075-.049-.049v-.026l.057.06.265.075a2.307,2.307,0,0,0,.837-.239h0l.221-.116c.493-.254,1.727-.894,2.89-1.466.579-.287,1.143-.56,1.581-.747.22-.1.411-.18.557-.24a1.187,1.187,0,0,1,.291-.086h0l.09.064a11.3,11.3,0,0,1,.879,1c.586.721,1.319,1.671,1.91,2.441l.747.972.292.374h0a.512.512,0,0,0,.374.235h0l.228-.108.142-.292s.063-.269.157-.7c.288-1.279.886-3.933,1.073-4.759a2.1,2.1,0,0,1,.127-.415l.138-.187.251-.048h.781c1.592,0,4.486.063,5.29.074h.086l.269-.1.093-.225a.69.69,0,0,0-.168-.4h0l-.135-.154c-.3-.34-1.076-1.207-1.828-2.127a14.55,14.55,0,0,1-1.734-2.441.881.881,0,0,1-.09-.374l.045-.2a6.393,6.393,0,0,1,.688-.961c1.017-1.249,2.818-3.192,3.738-4.265l.078-.1h0l-.082.1c-.908,1.076-2.714,3.02-3.738,4.269a6.334,6.334,0,0,0-.684.957l-.041.194a.884.884,0,0,0,.093.374,14.713,14.713,0,0,0,1.739,2.445c.747.919,1.521,1.787,1.824,2.127l.134.153h0a.673.673,0,0,1,.169.4l-.094.224-.269.1h-.086c-.819,0-3.7-.071-5.3-.071h-.785l-.251.049-.138.183a2.465,2.465,0,0,0-.127.419c-.224.972-1,4.422-1.192,5.271l-.042.186-.142.292-.231.108"
              fill="#b7b9bc"
            />
            <path
              d="M1208.144,296.624l.03-.138c.441-1.395,1.016-3.268,1.17-4.045l.033-.318a.664.664,0,0,0-.288-.613c-.235-.146-.815-.43-1.7-.83s-2.09-.92-3.567-1.536a6.014,6.014,0,0,1-.9-.468h0a5.788,5.788,0,0,0,.9.468c1.477.616,2.673,1.121,3.563,1.532s1.473.684,1.708.83a.68.68,0,0,1,.3.625l-.03.3c-.153.8-.766,2.785-1.211,4.195"
              fill="#b7b9bc"
            />
            <path
              d="M1218.723,300.784h0a.508.508,0,0,1-.374-.235h0l-.3-.374-.748-.972c-.591-.77-1.323-1.723-1.91-2.441a11.489,11.489,0,0,0-.879-1.005l-.089-.064h0a1.184,1.184,0,0,0-.292.086c-.146.06-.336.142-.557.239-.441.2-1.006.471-1.585.748-1.163.572-2.4,1.211-2.886,1.465l-.22.116h0a2.278,2.278,0,0,1-.838.239l-.265-.074-.056-.06v-.026l.063.074.262.071a2.275,2.275,0,0,0,.837-.243h0l.221-.112c.49-.254,1.723-.893,2.882-1.465.579-.288,1.14-.557,1.581-.748.221-.1.411-.183.557-.239a1.182,1.182,0,0,1,.292-.086h0l.09.064a11.277,11.277,0,0,1,.878,1c.587.722,1.32,1.671,1.907,2.442l.747.971.3.374h0a.519.519,0,0,0,.373.236l.232-.112.142-.292.157-.7,1.069-4.752a2.37,2.37,0,0,1,.131-.414l.138-.184.251-.048a4.171,4.171,0,0,1,.448,0h.337c1.592,0,4.486.063,5.286.075h.086l.269-.1.082-.22a.679.679,0,0,0-.168-.408h0l-.138-.153c-.3-.34-1.077-1.207-1.832-2.127a14.141,14.141,0,0,1-1.731-2.441l-.086-.351.049-.214a6.607,6.607,0,0,1,.691-.964c1.021-1.252,2.823-3.189,3.738-4.262l.079-.093h0l-.079.1c-.908,1.073-2.713,3.017-3.738,4.265a6.327,6.327,0,0,0-.688.961l-.044.206a.885.885,0,0,0,.089.374,14.505,14.505,0,0,0,1.735,2.441c.747.919,1.525,1.787,1.828,2.127l.134.153h0a.686.686,0,0,1,.169.407l-.09.221-.269.1h-.086c-.815,0-3.7-.071-5.29-.075h-.785l-.25.049-.139.187a2.123,2.123,0,0,0-.127.415c-.187.826-.785,3.48-1.073,4.758l-.157.7-.142.291-.228.109"
              fill="#b9bbbd"
            />
            <path
              d="M1208.11,296.755l.03-.131c.445-1.409,1.062-3.4,1.211-4.194l.03-.3a.683.683,0,0,0-.3-.625c-.239-.145-.819-.429-1.708-.829s-2.086-.92-3.563-1.537a5.734,5.734,0,0,1-.9-.467h0a5.911,5.911,0,0,0,.9.467c2.946,1.226,4.789,2.064,5.279,2.363a.687.687,0,0,1,.306.631l-.03.292c-.153.819-.807,2.923-1.256,4.34"
              fill="#b9bbbd"
            />
            <path
              d="M1218.719,300.755a.524.524,0,0,1-.374-.236h0s-.108-.149-.295-.374l-.748-.972c-.587-.77-1.323-1.719-1.906-2.437a11.146,11.146,0,0,0-.879-1l-.089-.063h0a1.1,1.1,0,0,0-.292.086c-.15.059-.34.138-.557.239-.441.2-1,.467-1.581.747-1.159.572-2.393,1.212-2.882,1.466l-.221.112h0a2.3,2.3,0,0,1-.837.243l-.262-.071-.064-.075v-.03l.071.094.258.071a2.271,2.271,0,0,0,.841-.247h0l.221-.112c.49-.254,1.716-.89,2.875-1.462.579-.288,1.121-.557,1.577-.747.221-.1.411-.184.557-.236a1.183,1.183,0,0,1,.292-.086h0l.09.064a10.723,10.723,0,0,1,.878,1c.583.717,1.32,1.667,1.9,2.437l.747.968.292.374h0a.508.508,0,0,0,.374.235l.228-.112.142-.288.52-2.317.7-3.125a2.45,2.45,0,0,1,.127-.412l.142-.183.251-.048h.785c1.592,0,4.486.059,5.278.074h.086l.269-.1.1-.209a.7.7,0,0,0-.168-.408h0l-.138-.153c-.307-.34-1.081-1.207-1.836-2.131a13.84,13.84,0,0,1-1.727-2.441l-.082-.344.048-.22a6.7,6.7,0,0,1,.7-.968c1.02-1.253,2.822-3.185,3.738-4.258l.078-.094h0l-.078.094c-.908,1.073-2.71,3.009-3.738,4.261a6.577,6.577,0,0,0-.692.965l-.048.213.086.351a14.145,14.145,0,0,0,1.73,2.441c.748.92,1.529,1.787,1.832,2.127l.135.154h0a.674.674,0,0,1,.164.411l-.09.22-.269.1h-.086c-.807,0-3.689-.072-5.286-.075h-.785l-.25.048-.138.184a2.216,2.216,0,0,0-.131.414l-1.069,4.752-.157.7-.142.292-.232.112"
              fill="#babcbe"
            />
            <path
              d="M1208.08,296.893l.03-.138c.449-1.417,1.1-3.522,1.256-4.34l.03-.292a.689.689,0,0,0-.307-.632c-.489-.3-2.332-1.121-5.278-2.362a6.109,6.109,0,0,1-.9-.468h0a5.758,5.758,0,0,0,.9.471c2.942,1.223,4.781,2.056,5.282,2.359a.7.7,0,0,1,.318.643l-.026.277c-.157.837-.852,3.073-1.3,4.486"
              fill="#babcbe"
            />
            <path
              d="M1218.715,300.755a.514.514,0,0,1-.374-.236h0l-.291-.374-.748-.968c-.587-.77-1.319-1.72-1.9-2.437a10.734,10.734,0,0,0-.879-1l-.09-.064h0a1.281,1.281,0,0,0-.291.086c-.146.056-.337.139-.557.236-.441.2-1,.467-1.578.747-1.155.572-2.385,1.208-2.874,1.462l-.221.112h0a2.281,2.281,0,0,1-.841.247l-.258-.071-.071-.094v-.029l.082.108.254.075a2.354,2.354,0,0,0,.841-.247h0l.217-.112c.49-.254,1.716-.89,2.867-1.462.576-.284,1.122-.557,1.574-.747.221-.1.411-.18.557-.24a1.183,1.183,0,0,1,.292-.086h0l.09.064a11.29,11.29,0,0,1,.878,1c.583.718,1.316,1.667,1.9,2.437l.748.968.291.374h0a.514.514,0,0,0,.374.236l.228-.109.142-.288,1.223-5.439a1.939,1.939,0,0,1,.127-.407l.142-.187.246-.049h.785c1.593,0,4.486.064,5.275.079h.1l.269-.1.1-.217a.691.691,0,0,0-.168-.408h0l-.135-.153c-.31-.344-1.084-1.211-1.843-2.131a13.822,13.822,0,0,1-1.727-2.437l-.078-.34.052-.228a6.668,6.668,0,0,1,.7-.972c1.024-1.256,2.822-3.185,3.738-4.251l.079-.093h0l-.079.093c-.908,1.07-2.706,2.991-3.738,4.258a6.808,6.808,0,0,0-.695.968l-.049.225.082.344a14.03,14.03,0,0,0,1.727,2.441c.748.923,1.529,1.79,1.836,2.131l.138.153h0a.7.7,0,0,1,.157.4l-.09.22-.269.1h-.086c-.8,0-3.686-.075-5.278-.075h-.785l-.251.049-.142.187a2.273,2.273,0,0,0-.127.411l-.7,3.125-.519,2.318-.142.288-.228.108"
              fill="#bbbdc0"
            />
            <path
              d="M1208.05,297.016l.03-.134c.449-1.417,1.144-3.652,1.3-4.486l.026-.277a.7.7,0,0,0-.318-.643c-.5-.306-2.34-1.121-5.282-2.362a5.619,5.619,0,0,1-.9-.468h0a5.83,5.83,0,0,0,.9.468c2.935,1.218,4.778,2.048,5.282,2.359a.7.7,0,0,1,.329.654l-.026.261c-.153.86-.9,3.227-1.342,4.64"
              fill="#bbbdc0"
            />
            <path
              d="M1218.712,300.736a.512.512,0,0,1-.374-.236h0l-.292-.373-.747-.969c-.587-.77-1.316-1.719-1.9-2.437a11.467,11.467,0,0,0-.879-1l-.09-.063h0a1.187,1.187,0,0,0-.291.086c-.146.059-.337.138-.557.239-.441.2-1,.467-1.574.748-1.155.571-2.378,1.207-2.867,1.461l-.217.112h0a2.367,2.367,0,0,1-.841.247l-.254-.075-.083-.108v-.034l.086.131.251.071a2.337,2.337,0,0,0,.845-.25h0l.216-.113c.486-.254,1.709-.889,2.86-1.457.576-.288,1.122-.554,1.574-.748q.332-.152.557-.236a1.188,1.188,0,0,1,.292-.089h0l.093.063a11.466,11.466,0,0,1,.879,1c.583.714,1.312,1.664,1.9,2.43l.747.968.292.374h0a.5.5,0,0,0,.374.232l.228-.109.142-.288s.983-4.373,1.218-5.427a2.07,2.07,0,0,1,.127-.408l.146-.187.251-.048h.781c1.592,0,4.486.067,5.263.078h.1l.269-.1.09-.221a.7.7,0,0,0-.168-.407h0l-.138-.153c-.311-.341-1.088-1.212-1.847-2.135a13.362,13.362,0,0,1-1.723-2.441l-.075-.329.056-.239a6.77,6.77,0,0,1,.706-.976c1.028-1.26,2.827-3.181,3.739-4.25l.074-.09h0l-.074.09c-.905,1.065-2.707,2.99-3.739,4.25a6.827,6.827,0,0,0-.7.972l-.053.228.079.34a13.721,13.721,0,0,0,1.727,2.438c.748.923,1.533,1.79,1.843,2.13l.138.154h0a.691.691,0,0,1,.168.407l-.089.224-.27.094h-.082c-.8,0-3.678-.071-5.274-.079h-.785l-.247.053-.142.183a2.106,2.106,0,0,0-.127.411l-1.223,5.435-.142.288-.228.109"
              fill="#bcbec1"
            />
            <path
              d="M1208.017,297.17l.033-.142c.445-1.413,1.189-3.78,1.342-4.64l.026-.261a.708.708,0,0,0-.329-.654c-.5-.311-2.343-1.141-5.282-2.359a5.83,5.83,0,0,1-.9-.468h0a5.548,5.548,0,0,0,.894.468c2.934,1.215,4.77,2.048,5.285,2.359a.713.713,0,0,1,.341.661l-.023.251c-.093.538-.43,1.7-.777,2.837-.217.714-.442,1.421-.61,1.955"
              fill="#bcbec1"
            />
            <path
              d="M1218.712,300.717a.5.5,0,0,1-.374-.232h0l-.292-.373-.747-.969c-.587-.766-1.316-1.715-1.9-2.429a11.631,11.631,0,0,0-.879-1l-.093-.064h0a1.289,1.289,0,0,0-.292.086c-.146.056-.336.135-.553.236-.441.2-1,.467-1.574.747-1.151.572-2.374,1.2-2.86,1.458l-.217.112h0a2.315,2.315,0,0,1-.844.251l-.255-.071-.086-.131V298.3l.094.153.25.075a2.361,2.361,0,0,0,.845-.251h0l.217-.112c.486-.25,1.705-.886,2.852-1.454.576-.288,1.122-.553,1.57-.748.221-.1.408-.179.557-.235a1.142,1.142,0,0,1,.292-.086h0l.093.063a10.994,10.994,0,0,1,.879,1c.583.714,1.312,1.66,1.9,2.426l.748.965.291.373a.506.506,0,0,0,.374.236l.228-.1.138-.291s.984-4.381,1.219-5.424a2.079,2.079,0,0,1,.127-.408l.146-.183.247-.052h.781c1.592,0,4.486.063,5.26.082h.086l.269-.1.086-.221a.689.689,0,0,0-.168-.407h0l-.139-.154c-.31-.34-1.091-1.211-1.869-2.134a13.053,13.053,0,0,1-1.719-2.438l-.071-.321.056-.247a6.982,6.982,0,0,1,.714-.979c1.031-1.26,2.829-3.181,3.738-4.243l.075-.09h0l-.075.09c-.909,1.065-2.707,2.991-3.738,4.25a6.917,6.917,0,0,0-.707.976l-.052.239.075.329a13.294,13.294,0,0,0,1.723,2.441c.747.924,1.536,1.8,1.847,2.135l.138.149h0a.707.707,0,0,1,.168.408l-.09.22-.269.1h-.086c-.792,0-3.674-.075-5.263-.079h-.781l-.247.052-.146.187a2.051,2.051,0,0,0-.127.412c-.236,1.054-1.219,5.427-1.219,5.427l-.142.288-.228.109"
              fill="#bec0c2"
            />
            <path
              d="M1207.987,297.3l.03-.134c.168-.535.373-1.241.609-1.955.344-1.122.68-2.3.777-2.838l.023-.25a.719.719,0,0,0-.34-.662c-.516-.31-2.352-1.144-5.286-2.359a5.708,5.708,0,0,1-.894-.467h0a5.543,5.543,0,0,0,.89.467c2.927,1.211,4.763,2.041,5.29,2.355a.75.75,0,0,1,.351.673l-.022.239c-.094.535-.427,1.7-.774,2.834-.239.781-.482,1.559-.654,2.108"
              fill="#bec0c2"
            />
            <path
              d="M1218.708,300.7a.508.508,0,0,1-.374-.232l-.292-.373-.747-.965c-.583-.766-1.312-1.712-1.9-2.426a11.154,11.154,0,0,0-.878-1l-.093-.063h0a1.276,1.276,0,0,0-.292.086c-.146.056-.336.138-.553.235-.441.2-.995.467-1.57.748-1.148.568-2.367,1.2-2.853,1.454l-.216.112h0a2.365,2.365,0,0,1-.845.251l-.251-.075-.093-.153v-.045l.1.187.247.071a2.351,2.351,0,0,0,.845-.255h0l.216-.112c.486-.25,1.7-.886,2.845-1.454.576-.288,1.122-.553,1.566-.748.217-.1.408-.179.554-.235a1.187,1.187,0,0,1,.291-.086h0l.094.064a10.837,10.837,0,0,1,.878,1c.583.714,1.309,1.656,1.892,2.423l.729.964.291.374h0a.5.5,0,0,0,.374.232l.228-.109.139-.284s.986-4.388,1.214-5.416a2.043,2.043,0,0,1,.128-.4l.145-.183.247-.053h.778c1.592,0,4.485.068,5.252.079h.082l.269-.094.09-.216a.7.7,0,0,0-.168-.408h0l-.142-.149c-.311-.341-1.1-1.215-1.869-2.139a12.96,12.96,0,0,1-1.72-2.437l-.067-.318.059-.254a7.2,7.2,0,0,1,.718-.983c1.036-1.264,2.83-3.178,3.738-4.239l.071-.086h0l-.074.086c-.905,1.065-2.7,2.99-3.739,4.243a7.142,7.142,0,0,0-.714.979l-.056.247.071.321a13.124,13.124,0,0,0,1.72,2.441c.748.924,1.54,1.8,1.85,2.135l.139.149h0a.691.691,0,0,1,.168.408l-.09.22-.269.1h-.082c-.785,0-3.671-.075-5.26-.083h-.781l-.247.053-.146.183a2.241,2.241,0,0,0-.127.407c-.231,1.04-1.218,5.425-1.218,5.425l-.142.291-.228.105"
              fill="#bfc1c3"
            />
            <path
              d="M1207.953,297.446l.034-.146c.175-.549.418-1.327.654-2.108.347-1.121.68-2.3.774-2.833l.022-.24a.748.748,0,0,0-.351-.669c-.524-.318-2.359-1.148-5.29-2.359a5.463,5.463,0,0,1-.89-.467h0a5.62,5.62,0,0,0,.886.464c2.927,1.207,4.759,2.033,5.29,2.355a.747.747,0,0,1,.374.68l-.019.224c-.09.527-.422,1.69-.77,2.83-.262.86-.527,1.708-.707,2.269"
              fill="#bfc1c3"
            />
            <path
              d="M1218.7,300.684a.508.508,0,0,1-.374-.232h0l-.291-.374-.729-.965c-.583-.747-1.312-1.708-1.892-2.422a10.849,10.849,0,0,0-.878-1l-.094-.063h0a1.185,1.185,0,0,0-.291.086c-.146.056-.337.138-.554.235-.441.2-.994.467-1.566.748-1.148.568-2.359,1.2-2.845,1.454l-.216.112a2.326,2.326,0,0,1-.845.254l-.247-.071-.1-.187V298.2l.1.224.246.071a2.366,2.366,0,0,0,.845-.258h0l.217-.112c.482-.254,1.7-.886,2.841-1.45.572-.284,1.122-.553,1.563-.748.216-.1.407-.179.553-.235a1.1,1.1,0,0,1,.292-.086h0l.093.063a10.787,10.787,0,0,1,.878,1c.58.714,1.309,1.656,1.888,2.423l.725.964.292.374h0a.5.5,0,0,0,.374.228h0l.224-.105.142-.287s.987-4.393,1.211-5.413a1.9,1.9,0,0,1,.127-.4l.15-.183.247-.053h.8c1.592,0,4.486.071,5.233.083h.086l.27-.094.089-.217a.69.69,0,0,0-.168-.407h0l-.138-.153c-.31-.341-1.1-1.215-1.869-2.139a12.407,12.407,0,0,1-1.716-2.441l-.064-.306.064-.262a7.214,7.214,0,0,1,.721-.99c1.036-1.264,2.83-3.174,3.739-4.236l.071-.086h0l-.071.086c-.905,1.062-2.7,2.991-3.739,4.239a7.242,7.242,0,0,0-.714.983l-.059.255.067.317a12.918,12.918,0,0,0,1.719,2.441c.748.924,1.541,1.8,1.869,2.139l.139.153h0a.691.691,0,0,1,.168.407l-.09.217-.269.094h-.082c-.781,0-3.664-.071-5.256-.079h-.778l-.246.053-.146.183a1.833,1.833,0,0,0-.127.4c-.228,1.032-1.215,5.417-1.215,5.417l-.142.288-.228.105"
              fill="#c1c2c4"
            />
            <path
              d="M1207.9,297.6l.034-.146c.176-.561.445-1.409.707-2.269.343-1.144.68-2.3.77-2.83l.018-.224a.749.749,0,0,0-.374-.681c-.534-.321-2.366-1.147-5.289-2.355a5.446,5.446,0,0,1-.886-.463h0a5.25,5.25,0,0,0,.886.463c2.919,1.2,4.751,2.03,5.293,2.355a.748.748,0,0,1,.374.688v.21c-.086.523-.419,1.685-.748,2.826-.287.945-.583,1.869-.747,2.437"
              fill="#c1c2c4"
            />
            <path
              d="M1218.7,300.665h0a.5.5,0,0,1-.374-.228h0l-.291-.374-.729-.964c-.58-.748-1.309-1.709-1.888-2.423a10.651,10.651,0,0,0-.879-1l-.093-.063h0a1.139,1.139,0,0,0-.292.085c-.145.057-.336.139-.553.236-.437.2-.99.467-1.562.748-1.144.568-2.355,1.2-2.838,1.45l-.217.112h0a2.335,2.335,0,0,1-.848.258l-.243-.071-.1-.224v-.071h0l.1.25.239.071a2.37,2.37,0,0,0,.849-.261l.217-.113c.478-.25,1.689-.878,2.829-1.446.572-.284,1.122-.553,1.563-.748.217-.1.4-.179.553-.235a1.058,1.058,0,0,1,.3-.086h0l.093.067a10.232,10.232,0,0,1,.878.994c.58.714,1.305,1.656,1.87,2.419.291.374.545.721.725.961l.291.373h0a.508.508,0,0,0,.374.232l.228-.108.139-.288s.99-4.4,1.207-5.4a1.976,1.976,0,0,1,.127-.4l.15-.184.246-.052h.793c1.592,0,4.486.067,5.233.082h.083l.269-.093.089-.217a.7.7,0,0,0-.168-.4h0l-.142-.153c-.31-.344-1.1-1.215-1.869-2.138a12.536,12.536,0,0,1-1.712-2.441l-.064-.3.068-.27a18.919,18.919,0,0,1,1.955-2.418c.908-1.021,1.906-2.1,2.508-2.808l.067-.082h0l-.071.086c-.9,1.062-2.7,2.968-3.738,4.236a7.214,7.214,0,0,0-.721.99l-.064.266.064.306a12.763,12.763,0,0,0,1.716,2.441c.762.924,1.547,1.8,1.869,2.138l.138.154h0a.7.7,0,0,1,.168.411l-.09.217-.265.093h-.086c-.774,0-3.656-.075-5.233-.082h-.8l-.246.052-.15.184a1.943,1.943,0,0,0-.127.4c-.224,1.017-1.211,5.413-1.211,5.413l-.139.288-.224.105"
              fill="#c1c3c5"
            />
            <path
              d="M1207.882,297.764l.034-.161c.172-.549.467-1.495.747-2.433a25.416,25.416,0,0,0,.748-2.826v-.21a.749.749,0,0,0-.374-.688c-.542-.325-2.374-1.151-5.293-2.355a5.072,5.072,0,0,1-.886-.463h0a5.041,5.041,0,0,0,.882.463c2.916,1.2,4.748,2.023,5.3,2.352a.746.746,0,0,1,.374.7v.2c-.082.52-.415,1.679-.748,2.822-.318,1.058-.65,2.1-.807,2.617"
              fill="#c1c3c5"
            />
            <path
              d="M1218.7,300.654a.5.5,0,0,1-.373-.232l-.292-.374c-.183-.243-.434-.579-.725-.961-.58-.747-1.305-1.7-1.869-2.418a10.524,10.524,0,0,0-.879-.995l-.093-.063h0a1.033,1.033,0,0,0-.292.086c-.146.052-.333.134-.553.235-.438.2-.991.464-1.563.748-1.14.568-2.347,1.2-2.83,1.447l-.216.112a2.351,2.351,0,0,1-.849.261l-.239-.071-.1-.25v-.041l.086-.374c.157-.516.49-1.559.807-2.617a24.74,24.74,0,0,0,.748-2.822v-.2a.749.749,0,0,0-.374-.7c-.549-.329-2.381-1.155-5.3-2.351a5.179,5.179,0,0,1-.878-.463h0a5.179,5.179,0,0,0,.878.463c2.912,1.193,4.74,2.015,5.3,2.348a.765.765,0,0,1,.392.706v.187c-.078.509-.411,1.671-.747,2.819s-.707,2.276-.845,2.736l-.049.281.1.25.24.071a2.364,2.364,0,0,0,.848-.265h0l.217-.113c.479-.25,1.686-.878,2.822-1.446.569-.281,1.122-.55,1.559-.748.217-.1.4-.176.55-.235a1.206,1.206,0,0,1,.295-.086h0l.094.067a10.519,10.519,0,0,1,.878,1c.58.714,1.305,1.652,1.869,2.415.292.374.546.718.725.961l.288.373a.505.505,0,0,0,.374.232l.224-.1.139-.288s.99-4.408,1.207-5.4a1.861,1.861,0,0,1,.127-.4l.154-.183.246-.053h.789c1.592,0,4.486.072,5.233.086h.086l.266-.093.086-.217a.689.689,0,0,0-.168-.407h0l-.139-.154c-.314-.34-1.121-1.214-1.869-2.138a13.358,13.358,0,0,1-1.708-2.437l-.06-.292.071-.28a19.26,19.26,0,0,1,1.963-2.422c.908-1.017,1.9-2.1,2.5-2.8l.068-.079h0l-.068.082c-.6.707-1.6,1.787-2.508,2.808a18.909,18.909,0,0,0-1.955,2.418l-.067.27.063.3a12.43,12.43,0,0,0,1.712,2.441c.763.923,1.552,1.794,1.869,2.138l.139.153h0a.7.7,0,0,1,.168.408l-.09.217-.269.093h-.082c-.767,0-3.649-.075-5.234-.082h-.792l-.247.052-.15.183a2.017,2.017,0,0,0-.127.4c-.22,1.006-1.207,5.406-1.207,5.406l-.138.288-.228.108"
              fill="#c3c5c7"
            />
            <path
              d="M1218.7,300.635a.5.5,0,0,1-.374-.228l-.292-.374c-.179-.243-.433-.579-.725-.961-.579-.747-1.305-1.7-1.869-2.415a10.407,10.407,0,0,0-.879-1l-.093-.063h0a1.182,1.182,0,0,0-.292.086c-.145.056-.332.134-.549.232-.438.2-.991.463-1.559.747-1.121.568-2.344,1.2-2.822,1.447l-.217.112h0a2.337,2.337,0,0,1-.849.265l-.239-.071-.1-.25.048-.28c.139-.46.5-1.593.845-2.737a25.113,25.113,0,0,0,.748-2.818v-.187a.772.772,0,0,0-.393-.707c-.56-.333-2.388-1.155-5.3-2.347a5.086,5.086,0,0,1-.879-.464h0a5,5,0,0,0,.875.46c2.908,1.189,4.732,2.007,5.3,2.347a.779.779,0,0,1,.408.711v.175a28.376,28.376,0,0,1-.748,2.812c-.347,1.147-.71,2.284-.848,2.747l-.049.28.1.247.236.071a2.391,2.391,0,0,0,.848-.261h0l.213-.113c.479-.25,1.683-.878,2.819-1.443.568-.284,1.121-.549,1.555-.747.217-.1.4-.18.553-.236a1.182,1.182,0,0,1,.292-.086h0l.093.068a10.289,10.289,0,0,1,.879,1c.579.71,1.3,1.648,1.869,2.411l.729.942.288.374a.5.5,0,0,0,.374.228l.224-.105.138-.284h0s.991-4.415,1.2-5.394a1.868,1.868,0,0,1,.127-.4l.153-.183a1.93,1.93,0,0,1,.654-.071h.374c1.593,0,4.486.071,5.234.082h.086l.265-.094.09-.213a.689.689,0,0,0-.168-.407h0l-.142-.153c-.314-.341-1.122-1.215-1.869-2.139a13.283,13.283,0,0,1-1.709-2.441l-.056-.284.071-.288a19.5,19.5,0,0,1,1.97-2.426c.909-1.017,1.9-2.1,2.505-2.8l.063-.075h0l-.067.079c-.6.706-1.6,1.787-2.505,2.8a19.255,19.255,0,0,0-1.962,2.422l-.071.281.06.291a12.112,12.112,0,0,0,1.708,2.438c.766.923,1.551,1.8,1.869,2.138l.142.153h0a.691.691,0,0,1,.168.408l-.089.216-.266.094h-.086c-.747,0-3.644-.079-5.233-.086h-.789l-.247.052-.153.183a1.885,1.885,0,0,0-.127.4c-.217.995-1.208,5.4-1.208,5.4l-.138.288-.224.1"
              fill="#c4c6c8"
            />
            <path
              d="M1218.693,300.616a.5.5,0,0,1-.374-.228l-.288-.374-.725-.96c-.576-.748-1.3-1.7-1.869-2.411a10.681,10.681,0,0,0-.879-1l-.093-.063h0a1.1,1.1,0,0,0-.292.086c-.145.052-.336.134-.553.232-.434.2-.987.459-1.555.747-1.121.561-2.336,1.193-2.819,1.443l-.213.112h0a2.346,2.346,0,0,1-.848.266l-.236-.071-.1-.247.049-.28c.138-.464.5-1.6.848-2.748a28.431,28.431,0,0,0,.748-2.811v-.176a.782.782,0,0,0-.408-.71c-.568-.34-2.4-1.159-5.3-2.348a5.063,5.063,0,0,1-.875-.459h0a4.824,4.824,0,0,0,.867.459c2.905,1.189,4.729,2,5.309,2.348a.792.792,0,0,1,.418.721v.165a27.752,27.752,0,0,1-.747,2.807c-.344,1.152-.711,2.288-.849,2.752l-.048.284.093.246.235.068a2.4,2.4,0,0,0,.849-.266h0l.213-.112c.479-.25,1.675-.878,2.811-1.439.565-.284,1.122-.55,1.552-.748.216-.1.407-.179.549-.231a1.134,1.134,0,0,1,.3-.086h0l.094.063a10.634,10.634,0,0,1,.878.995c.58.71,1.3,1.648,1.87,2.411l.721.957.288.373h0a.49.49,0,0,0,.374.229l.224-.105.138-.281h0s.991-4.418,1.2-5.386a1.735,1.735,0,0,1,.127-.4l.154-.186a1.842,1.842,0,0,1,.631-.068h.4c1.592,0,4.485.071,5.233.086h.082l.266-.093.089-.217a.691.691,0,0,0-.168-.408h0l-.142-.153c-.318-.344-1.121-1.218-1.869-2.142a12.853,12.853,0,0,1-1.7-2.437l-.052-.277.075-.3a19.532,19.532,0,0,1,1.977-2.43c.909-1.013,1.9-2.086,2.505-2.792l.063-.071h0l-.063.074c-.6.7-1.6,1.78-2.505,2.8a19.528,19.528,0,0,0-1.97,2.426l-.071.288.056.287a12.474,12.474,0,0,0,1.709,2.438c.766.942,1.555,1.8,1.869,2.138l.138.153h0a.691.691,0,0,1,.168.408l-.09.213-.265.093h-.086c-.748,0-3.637-.075-5.233-.082h-.374a1.974,1.974,0,0,0-.654.071l-.154.183a1.936,1.936,0,0,0-.127.4c-.213.983-1.2,5.394-1.2,5.394h0l-.138.285-.224.1"
              fill="#c6c7c9"
            />
            <path
              d="M1218.693,300.6a.489.489,0,0,1-.374-.228h0l-.288-.374-.721-.957c-.58-.747-1.3-1.7-1.869-2.411a10.516,10.516,0,0,0-.879-.994l-.093-.067h0a1.14,1.14,0,0,0-.3.086c-.145.052-.332.134-.549.235-.434.2-.983.46-1.552.748-1.121.561-2.332,1.189-2.811,1.439l-.213.112h0a2.433,2.433,0,0,1-.848.266l-.236-.068-.093-.246.048-.281c.139-.463.5-1.6.849-2.751a27.963,27.963,0,0,0,.748-2.808v-.164a.793.793,0,0,0-.419-.721c-.58-.344-2.4-1.159-5.308-2.348a4.775,4.775,0,0,1-.868-.46h0a4.82,4.82,0,0,0,.868.456c2.9,1.185,4.721,2,5.312,2.348a.8.8,0,0,1,.429.725v.157a26.915,26.915,0,0,1-.747,2.8c-.344,1.147-.71,2.291-.849,2.762l-.048.277.093.243.232.071a2.349,2.349,0,0,0,.815-.314h0l.213-.112c.478-.251,1.671-.871,2.8-1.44.564-.28,1.121-.545,1.547-.747.217-.1.4-.176.55-.232a1.052,1.052,0,0,1,.3-.086h0l.094.064a10.115,10.115,0,0,1,.878.994c.579.71,1.3,1.649,1.869,2.407l.718.957.288.374h0a.494.494,0,0,0,.374.228l.224-.1.138-.285h0s.991-4.422,1.2-5.383a1.908,1.908,0,0,1,.127-.4l.157-.183a1.934,1.934,0,0,1,.651-.067h.374c1.588,0,4.486.071,5.233.086h.082l.266-.094.09-.213a.711.711,0,0,0-.169-.407h0l-.142-.154c-.317-.343-1.121-1.218-1.869-2.145a12.874,12.874,0,0,1-1.7-2.438l-.052-.269.078-.3a19.764,19.764,0,0,1,1.981-2.388c.909-1.013,1.9-2.086,2.5-2.789l.06-.067h0l-.063.071c-.6.7-1.593,1.775-2.505,2.792a19.7,19.7,0,0,0-1.978,2.43l-.074.295.052.277a12.387,12.387,0,0,0,1.7,2.437c.763.942,1.559,1.8,1.869,2.142l.142.154h0a.691.691,0,0,1,.168.407l-.089.217-.266.093h-.082c-.748,0-3.63-.078-5.234-.086h-.4a1.874,1.874,0,0,0-.632.068l-.153.186a1.7,1.7,0,0,0-.127.393c-.209.972-1.2,5.387-1.2,5.387h0l-.138.28-.225.1"
              fill="#c6c8ca"
            />
            <path
              d="M1218.689,300.583a.5.5,0,0,1-.374-.228h0l-.288-.374-.721-.953c-.576-.748-1.3-1.7-1.869-2.408a10.239,10.239,0,0,0-.879-.994l-.093-.068h0a1.122,1.122,0,0,0-.295.086c-.146.053-.333.135-.55.232-.434.2-.983.464-1.548.748-1.121.564-2.325,1.189-2.8,1.439l-.213.112h0a2.319,2.319,0,0,1-.849.273l-.232-.071-.093-.243.049-.277c.138-.467.5-1.611.848-2.762a27.194,27.194,0,0,0,.748-2.8v-.157a.8.8,0,0,0-.426-.725c-.591-.344-2.412-1.163-5.312-2.348a4.82,4.82,0,0,1-.868-.456h0a4.676,4.676,0,0,0,.86.453c2.9,1.177,4.718,2,5.316,2.344a.814.814,0,0,1,.441.747v.146a26.338,26.338,0,0,1-.748,2.8c-.344,1.155-.71,2.3-.848,2.77l-.049.277.094.243.228.071a2.425,2.425,0,0,0,.852-.273h0l.213-.112c.475-.251,1.667-.871,2.8-1.436.565-.28,1.122-.546,1.544-.748.217-.1.4-.175.55-.231a1.042,1.042,0,0,1,.295-.086h0l.093.063a10.514,10.514,0,0,1,.879.995c.576.71,1.3,1.644,1.869,2.4l.718.953.288.374h0a.5.5,0,0,0,.373.228l.225-.1.138-.284h0s.991-4.43,1.2-5.376a1.761,1.761,0,0,1,.124-.374l.157-.187a1.775,1.775,0,0,1,.624-.067h.4c1.589,0,4.486.071,5.211.086h.082l.266-.093.09-.217a.7.7,0,0,0-.169-.408h0l-.142-.153c-.317-.344-1.121-1.222-1.869-2.146a12.779,12.779,0,0,1-1.7-2.437l-.049-.262.083-.314a19.671,19.671,0,0,1,1.973-2.448c.909-1.013,1.9-2.083,2.5-2.782l.06-.067h0l-.06.067c-.6.7-1.592,1.776-2.5,2.789a19.913,19.913,0,0,0-1.985,2.437l-.078.307.052.269a12.217,12.217,0,0,0,1.7,2.437c.767.943,1.563,1.8,1.869,2.146l.142.153h0a.717.717,0,0,1,.169.408l-.09.217-.266.093h-.082c-.747,0-3.626-.078-5.233-.086h-.374a1.935,1.935,0,0,0-.651.068l-.157.186a1.942,1.942,0,0,0-.127.393c-.2.957-1.2,5.383-1.2,5.383h0l-.138.284-.224.105"
              fill="#c8cacc"
            />
            <path
              d="M1218.685,300.568a.506.506,0,0,1-.373-.228h0l-.288-.374-.718-.953c-.576-.748-1.3-1.7-1.869-2.408a10.243,10.243,0,0,0-.879-.994l-.093-.068h0a1.112,1.112,0,0,0-.295.086c-.146.057-.333.135-.55.236-.434.194-.979.456-1.544.748-1.121.56-2.321,1.185-2.8,1.435l-.213.112h0a2.359,2.359,0,0,1-.852.277l-.228-.071-.094-.243.049-.277c.138-.471.5-1.615.848-2.77a26.811,26.811,0,0,0,.748-2.8v-.145a.816.816,0,0,0-.441-.748c-.6-.348-2.419-1.166-5.316-2.344a4.784,4.784,0,0,1-.86-.456h0a4.637,4.637,0,0,0,.856.452c2.894,1.178,4.711,1.989,5.32,2.344a.821.821,0,0,1,.452.748v.134a26.088,26.088,0,0,1-.747,2.793c-.344,1.155-.711,2.306-.849,2.777l-.049.277.094.243.224.071a2.417,2.417,0,0,0,.852-.277h0l.214-.112c.471-.25,1.663-.875,2.788-1.435.561-.281,1.122-.546,1.54-.748.217-.1.4-.179.55-.232a1.121,1.121,0,0,1,.3-.086h0l.1.068a9.939,9.939,0,0,1,.879.99c.579.71,1.3,1.645,1.869,2.4l.718.953.288.374a.487.487,0,0,0,.373.228h0l.221-.1.135-.281h0s1-4.433,1.2-5.387a1.53,1.53,0,0,1,.124-.373l.157-.187a1.782,1.782,0,0,1,.62-.071h.4c1.588,0,4.486.074,5.2.086h.083l.265-.09.086-.213a.7.7,0,0,0-.168-.408h0l-.142-.153c-.318-.344-1.122-1.222-1.888-2.146a11.827,11.827,0,0,1-1.694-2.437l-.044-.25.086-.322a20.079,20.079,0,0,1,2-2.445c.912-1.013,1.9-2.078,2.5-2.777l.056-.064h0l-.06.068c-.6.7-1.589,1.768-2.5,2.781a19.682,19.682,0,0,0-1.992,2.441l-.083.314.049.262a12.019,12.019,0,0,0,1.7,2.437c.766.942,1.566,1.8,1.869,2.149l.142.15h0a.7.7,0,0,1,.168.407l-.089.217-.266.094h-.082c-.748,0-3.619-.079-5.211-.086h-.4a1.715,1.715,0,0,0-.625.071l-.157.183a1.693,1.693,0,0,0-.123.374c-.2.945-1.2,5.375-1.2,5.375h0l-.138.284-.225.105"
              fill="#c9cacd"
            />
            <path
              d="M1218.689,300.549h0a.5.5,0,0,1-.374-.228l-.288-.374-.717-.953c-.576-.748-1.294-1.694-1.869-2.4a10.345,10.345,0,0,0-.879-.99l-.093-.068h0a1.128,1.128,0,0,0-.3.086c-.145.056-.332.135-.549.232-.434.194-.98.456-1.54.748-1.122.56-2.318,1.185-2.789,1.435l-.209.112h0a2.45,2.45,0,0,1-.853.277l-.224-.071-.093-.243.048-.277c.142-.471.505-1.622.849-2.777a26.181,26.181,0,0,0,.747-2.793v-.134a.819.819,0,0,0-.452-.748c-.609-.355-2.426-1.166-5.316-2.34a4.789,4.789,0,0,1-.859-.452h0a4.708,4.708,0,0,0,.852.448c2.886,1.17,4.7,1.982,5.319,2.34a.836.836,0,0,1,.464.748v.123a25.6,25.6,0,0,1-.748,2.789c-.347,1.155-.71,2.307-.848,2.785l-.049.277.09.239.224.067a2.417,2.417,0,0,0,.852-.276h0l.21-.113c.471-.25,1.659-.871,2.781-1.428.561-.28,1.121-.545,1.54-.747.213-.1.4-.176.55-.232a1.143,1.143,0,0,1,.3-.082h0l.1.067a10.228,10.228,0,0,1,.878.994c.576.707,1.294,1.642,1.869,2.4.288.374.539.714.714.953l.288.374a.5.5,0,0,0,.374.228l.224-.1.135-.28h0s.987-4.449,1.192-5.38a1.753,1.753,0,0,1,.124-.373l.161-.184a1.756,1.756,0,0,1,.616-.071h.4c1.588,0,4.486.075,5.2.09h.082l.265-.09.086-.213a.7.7,0,0,0-.168-.407h0l-.142-.153c-.318-.344-1.121-1.223-1.891-2.15a11.673,11.673,0,0,1-1.694-2.441l-.041-.243.09-.329a20.2,20.2,0,0,1,2.007-2.448c.909-1.013,1.9-2.079,2.5-2.774l.053-.06h0l-.053.064c-.594.7-1.588,1.768-2.5,2.777a20.25,20.25,0,0,0-2,2.445l-.086.321.045.251a11.882,11.882,0,0,0,1.694,2.441c.766.946,1.57,1.8,1.869,2.146l.142.153h0a.691.691,0,0,1,.168.407l-.09.213-.261.09h-.083c-.747,0-3.615-.075-5.2-.086h-.4a1.736,1.736,0,0,0-.62.071l-.157.183a1.558,1.558,0,0,0-.127.374c-.2.935-1.193,5.372-1.193,5.372h0l-.138.28-.217.105"
              fill="#caccce"
            />
            <path
              d="M1218.682,300.534a.5.5,0,0,1-.374-.228l-.284-.374c-.18-.239-.43-.576-.718-.949-.572-.748-1.29-1.694-1.869-2.4a10.106,10.106,0,0,0-.879-.995l-.1-.067h0a1.165,1.165,0,0,0-.3.082c-.146.06-.337.135-.55.232-.43.194-.975.456-1.54.748-1.121.557-2.31,1.181-2.781,1.428l-.209.112h0a2.4,2.4,0,0,1-.856.276l-.225-.067-.089-.239.048-.277c.138-.474.505-1.63.849-2.785a25.3,25.3,0,0,0,.747-2.788v-.124a.827.827,0,0,0-.463-.747,55.926,55.926,0,0,0-5.32-2.34,4.748,4.748,0,0,1-.852-.449h0a4.748,4.748,0,0,0,.849.449c2.886,1.166,4.7,1.973,5.323,2.336a.842.842,0,0,1,.475.748v.115a24.7,24.7,0,0,1-.726,2.785c-.344,1.159-.71,2.314-.848,2.789l-.049.277.09.239.22.067a2.427,2.427,0,0,0,.857-.28h0l.209-.112c.471-.247,1.656-.868,2.777-1.428.561-.281,1.122-.546,1.533-.748.217-.1.4-.176.55-.232a1.181,1.181,0,0,1,.3-.082h0l.1.067a10.2,10.2,0,0,1,.878.991c.576.707,1.294,1.641,1.869,2.4.288.374.539.71.714.953l.288.374h0a.5.5,0,0,0,.374.225l.221-.1.134-.28h0s.995-4.449,1.189-5.357a1.529,1.529,0,0,1,.123-.374l.165-.183a1.67,1.67,0,0,1,.617-.071h.392c1.589,0,4.486.074,5.192.089h.083l.265-.089.086-.21a.7.7,0,0,0-.172-.411h0l-.146-.153c-.321-.344-1.121-1.223-1.9-2.15a11.143,11.143,0,0,1-1.686-2.437l-.041-.235.094-.341a20.107,20.107,0,0,1,2.011-2.452c.912-1.009,1.9-2.075,2.5-2.77l.049-.056h0l-.049.06c-.6.7-1.589,1.764-2.5,2.777a20.277,20.277,0,0,0-2,2.449l-.089.329.041.243a11.511,11.511,0,0,0,1.69,2.441c.77.946,1.573,1.8,1.891,2.149l.142.154h0a.7.7,0,0,1,.168.407l-.089.213-.262.09h-.082c-.725,0-3.608-.079-5.2-.09h-.4a1.759,1.759,0,0,0-.617.071l-.161.183a1.7,1.7,0,0,0-.123.374c-.2.923-1.189,5.364-1.189,5.364h0l-.135.281-.224.105"
              fill="#cbcdcf"
            />
            <path
              d="M1218.678,300.515a.5.5,0,0,1-.374-.224h0l-.288-.374c-.175-.239-.426-.575-.714-.949-.572-.748-1.289-1.69-1.869-2.4a10.232,10.232,0,0,0-.878-.994l-.1-.067h0a1.164,1.164,0,0,0-.3.082c-.145.056-.332.138-.549.232-.43.194-.976.456-1.537.747-1.121.561-2.3,1.182-2.773,1.428l-.21.113h0a2.416,2.416,0,0,1-.856.28l-.22-.067-.09-.24.049-.276c.138-.479.5-1.634.848-2.789a24.494,24.494,0,0,0,.725-2.785v-.116a.843.843,0,0,0-.474-.747,56.647,56.647,0,0,0-5.324-2.337,4.705,4.705,0,0,1-.848-.448h0a4.476,4.476,0,0,0,.845.444c2.878,1.163,4.691,1.967,5.327,2.337a.854.854,0,0,1,.486.748v.108a24.015,24.015,0,0,1-.722,2.781c-.344,1.159-.714,2.318-.852,2.8l-.049.277.09.235.217.068a2.452,2.452,0,0,0,.856-.285h0l.209-.112c.467-.246,1.649-.867,2.766-1.424.561-.277,1.1-.542,1.533-.748.217-.1.4-.175.55-.231a1.144,1.144,0,0,1,.3-.083h0l.1.064a10.329,10.329,0,0,1,.878.991c.576.706,1.29,1.641,1.87,2.392.287.374.534.71.714.95l.284.373h0a.491.491,0,0,0,.373.228l.221-.1.135-.281s1-4.456,1.185-5.353a1.543,1.543,0,0,1,.123-.374l.164-.183a1.756,1.756,0,0,1,.632-.071h.374c1.589,0,4.486.075,5.185.09h.082l.266-.09.086-.209a.687.687,0,0,0-.169-.408h0l-.142-.153c-.321-.344-1.121-1.222-1.9-2.149a10.929,10.929,0,0,1-1.686-2.438l-.038-.228.094-.351a20.5,20.5,0,0,1,2.018-2.452c.912-1.01,1.9-2.071,2.5-2.767l.045-.052h0l-.048.056c-.595.7-1.589,1.761-2.5,2.77a20.115,20.115,0,0,0-2.012,2.452l-.093.341.041.235a11.224,11.224,0,0,0,1.686,2.437c.77.942,1.578,1.806,1.9,2.15l.142.153h0a.7.7,0,0,1,.172.411l-.086.21-.265.089h-.083c-.721,0-3.6-.082-5.192-.089h-.4a1.673,1.673,0,0,0-.617.071l-.165.183a1.578,1.578,0,0,0-.123.374c-.191.908-1.189,5.357-1.189,5.357l-.134.28-.221.1"
              fill="#cdcfd0"
            />
            <path
              d="M1218.678,300.5a.49.49,0,0,1-.374-.228h0l-.284-.373-.714-.95c-.572-.748-1.29-1.686-1.869-2.392a10.507,10.507,0,0,0-.879-.995l-.1-.063h0a1.2,1.2,0,0,0-.3.082c-.149.056-.333.134-.549.232-.43.194-.972.456-1.533.747-1.122.557-2.3,1.178-2.766,1.425l-.21.112h0a2.444,2.444,0,0,1-.856.284l-.217-.067-.089-.236.048-.277c.139-.478.505-1.637.849-2.8a24.211,24.211,0,0,0,.721-2.781v-.108a.847.847,0,0,0-.486-.748,58.149,58.149,0,0,0-5.327-2.336,4.512,4.512,0,0,1-.844-.445h0a4.572,4.572,0,0,0,.837.445c2.875,1.159,4.684,1.962,5.331,2.332a.869.869,0,0,1,.5.748v.1a23.718,23.718,0,0,1-.718,2.774c-.344,1.162-.714,2.325-.852,2.807l-.049.277.086.235.217.071a2.45,2.45,0,0,0,.856-.288h0l.209-.108c.468-.247,1.645-.867,2.759-1.424.557-.277,1.1-.538,1.529-.748.217-.1.4-.176.55-.232a1.182,1.182,0,0,1,.3-.082h0l.1.067a9.926,9.926,0,0,1,.878.991c.576.7,1.29,1.634,1.87,2.389l.71.949.284.374a.489.489,0,0,0,.374.224l.22-.1.135-.28s1-4.463,1.181-5.346c.067-.321.168-.512.292-.568a1.673,1.673,0,0,1,.609-.071h.393c1.588,0,4.485.079,5.177.094h.082l.266-.09.086-.209a.713.713,0,0,0-.169-.412h0l-.145-.157c-.322-.343-1.122-1.222-1.907-2.149a10.7,10.7,0,0,1-1.682-2.437l-.034-.221.1-.374a20.409,20.409,0,0,1,2.027-2.459c.912-1.01,1.9-2.068,2.493-2.763l.041-.049h0l-.045.053c-.594.695-1.585,1.757-2.5,2.766a20.617,20.617,0,0,0-2.019,2.452l-.093.352.037.228a11.1,11.1,0,0,0,1.683,2.437c.77.942,1.581,1.806,1.9,2.149l.146.154h0a.691.691,0,0,1,.168.407l-.089.21-.262.089h-.082c-.714,0-3.6-.078-5.185-.089h-.374a1.712,1.712,0,0,0-.632.071l-.164.183a1.579,1.579,0,0,0-.124.374c-.187.9-1.185,5.353-1.185,5.353l-.134.28-.221.105"
              fill="#cecfd1"
            />
            <path
              d="M1218.674,300.482a.488.488,0,0,1-.374-.225l-.284-.373-.71-.95c-.572-.748-1.286-1.686-1.869-2.389a9.94,9.94,0,0,0-.879-.99l-.1-.068h0a1.243,1.243,0,0,0-.3.083c-.146.056-.333.134-.549.231-.43.2-.972.456-1.529.748-1.122.557-2.292,1.178-2.763,1.424l-.209.112h0a2.48,2.48,0,0,1-.856.285l-.217-.071-.086-.236.045-.277c.142-.482.508-1.644.852-2.807a23.665,23.665,0,0,0,.718-2.774v-.1a.866.866,0,0,0-.5-.747c-.647-.374-2.456-1.174-5.331-2.333a4.477,4.477,0,0,1-.841-.445h0a4.316,4.316,0,0,0,.834.441c2.87,1.155,4.68,1.955,5.334,2.333a.869.869,0,0,1,.508.748v.056a23.231,23.231,0,0,1-.714,2.773c-.347,1.163-.714,2.329-.856,2.812l-.045.272.086.236.213.067a2.461,2.461,0,0,0,.857-.288h0l.209-.112c.467-.246,1.641-.867,2.751-1.42.557-.277,1.1-.539,1.529-.748.213-.1.4-.176.55-.228a1.151,1.151,0,0,1,.3-.086h0l.1.067a10.194,10.194,0,0,1,.878.991c.572.7,1.286,1.634,1.869,2.389l.71.946.281.373h0a.487.487,0,0,0,.374.225l.216-.1.135-.28s1-4.486,1.178-5.342c.067-.322.168-.509.291-.565a1.694,1.694,0,0,1,.624-.075h.374c1.589,0,4.486.079,5.174.094h.082l.262-.086.086-.209a.7.7,0,0,0-.172-.412h0l-.146-.153c-.321-.344-1.121-1.222-1.91-2.153a10.588,10.588,0,0,1-1.679-2.437l-.033-.21.1-.374a20.749,20.749,0,0,1,2.033-2.463c.912-1.006,1.9-2.064,2.494-2.759l.037-.041h0l-.041.049c-.594.7-1.585,1.753-2.493,2.762a20.406,20.406,0,0,0-2.027,2.46l-.1.374.034.22a10.747,10.747,0,0,0,1.682,2.438c.774.942,1.585,1.8,1.907,2.149l.145.153h0a.707.707,0,0,1,.169.412l-.086.209-.266.09h-.082c-.71,0-3.589-.083-5.177-.094h-.393a1.674,1.674,0,0,0-.609.071c-.124.056-.225.247-.292.568-.183.886-1.181,5.346-1.181,5.346l-.135.28-.22.1"
              fill="#d0d1d3"
            />
            <path
              d="M1218.67,300.467a.485.485,0,0,1-.373-.225h0l-.284-.373-.711-.946c-.572-.748-1.282-1.682-1.869-2.389a10.2,10.2,0,0,0-.878-.991l-.1-.067h0a1.149,1.149,0,0,0-.3.086c-.146.056-.333.135-.55.228-.43.195-.972.456-1.529.748-1.121.553-2.284,1.174-2.751,1.42l-.209.113h0a2.5,2.5,0,0,1-.856.284l-.214-.068-.086-.235.045-.273c.142-.482.509-1.652.856-2.811a23,23,0,0,0,.714-2.77v-.09a.87.87,0,0,0-.508-.748c-.654-.373-2.46-1.177-5.334-2.332a4.29,4.29,0,0,1-.834-.441h0a4.258,4.258,0,0,0,.83.441c2.867,1.151,4.673,1.951,5.334,2.329a.882.882,0,0,1,.524.77v.03a22.347,22.347,0,0,1-.711,2.766c-.344,1.166-.714,2.336-.856,2.822l-.045.273.083.236.209.063a1.353,1.353,0,0,0,.452-.108c.154-.06.3-.135.4-.183h0l.209-.112c.468-.247,1.638-.864,2.748-1.417.557-.277,1.1-.539,1.521-.748.217-.093.4-.176.55-.228a1.152,1.152,0,0,1,.3-.086h.019l.1.071a9.724,9.724,0,0,1,.878.991c.576.7,1.286,1.63,1.869,2.385l.711.945.28.374h0a.5.5,0,0,0,.374.225h0l.213-.1.135-.281s1-4.486,1.177-5.334c.064-.322.165-.505.292-.565a1.633,1.633,0,0,1,.6-.071h.392c1.589,0,4.486.079,5.166.094h.086l.262-.09.086-.209a.711.711,0,0,0-.172-.411h0l-.146-.154c-.325-.344-1.14-1.222-1.914-2.153a10.822,10.822,0,0,1-1.675-2.437l-.029-.2.1-.374a20.685,20.685,0,0,1,2.038-2.471c.912-1.005,1.9-2.063,2.493-2.751l.034-.041h0l-.038.041c-.594.7-1.581,1.749-2.493,2.759a20.851,20.851,0,0,0-2.034,2.463l-.1.374.034.209a10.466,10.466,0,0,0,1.678,2.438c.778.942,1.589,1.809,1.911,2.153l.145.153h0a.7.7,0,0,1,.172.411l-.086.21-.261.089h-.083c-.7,0-3.584-.082-5.173-.093h-.374a1.71,1.71,0,0,0-.624.075c-.124.056-.225.246-.292.564-.179.871-1.177,5.338-1.177,5.338l-.135.281-.217.1"
              fill="#d0d2d3"
            />
            <path
              d="M1218.67,300.448h0a.5.5,0,0,1-.373-.224h0l-.281-.374-.71-.946c-.568-.748-1.282-1.682-1.869-2.385a9.818,9.818,0,0,0-.879-.99l-.1-.071h-.018a1.21,1.21,0,0,0-.3.085c-.145.057-.332.135-.549.229-.43.194-.968.452-1.522.747-1.121.553-2.28,1.17-2.747,1.417l-.209.112h0c-.1.049-.247.12-.4.18a1.39,1.39,0,0,1-.452.108l-.21-.064-.082-.231.045-.273c.142-.486.508-1.656.856-2.823a22.17,22.17,0,0,0,.71-2.766V292.1a.878.878,0,0,0-.523-.77c-.662-.374-2.467-1.178-5.335-2.333a4.288,4.288,0,0,1-.83-.441h0a4.193,4.193,0,0,0,.827.437q2.726,1.031,5.338,2.329a.889.889,0,0,1,.534.774v.075a22.241,22.241,0,0,1-.706,2.762c-.344,1.167-.714,2.341-.853,2.827l-.048.276.082.228.209.067a1.312,1.312,0,0,0,.453-.112c.157-.059.3-.134.4-.183h0l.21-.112c.463-.247,1.63-.86,2.736-1.417.557-.276,1.092-.538,1.522-.747.213-.094.4-.176.545-.228a1.205,1.205,0,0,1,.3-.083h.018l.1.068a9.955,9.955,0,0,1,.879.99c.572.7,1.282,1.63,1.869,2.381l.71.943.28.373h0a.494.494,0,0,0,.374.225l.217-.1.135-.277s1-4.486,1.173-5.33c.064-.318.169-.5.292-.561a1.761,1.761,0,0,1,.62-.075h.374c1.589,0,4.486.079,5.163.094h.082l.262-.086.086-.21a.725.725,0,0,0-.172-.411h0l-.146-.153c-.325-.348-1.144-1.226-1.922-2.157-.373-.464-.766-.938-1.069-1.365a4.379,4.379,0,0,1-.6-1.069l-.03-.2.112-.374a20.388,20.388,0,0,1,2.045-2.471c.912-1.005,1.9-2.056,2.493-2.747l.03-.038h0l-.034.041c-.59.692-1.581,1.746-2.493,2.752a20.775,20.775,0,0,0-2.038,2.471l-.1.374.03.2a10.572,10.572,0,0,0,1.674,2.438c.774.942,1.589,1.809,1.914,2.156l.142.154h0a.712.712,0,0,1,.172.411l-.086.209-.261.09h-.086c-.7,0-3.578-.082-5.166-.093h-.393a1.637,1.637,0,0,0-.6.071c-.127.059-.228.243-.291.564-.176.86-1.178,5.334-1.178,5.334l-.135.281-.213.1"
              fill="#d2d3d5"
            />
            <path
              d="M1218.667,300.433a.5.5,0,0,1-.374-.224l-.284-.374c-.176-.239-.423-.568-.71-.942-.569-.748-1.279-1.679-1.87-2.381a9.709,9.709,0,0,0-.878-.991l-.1-.067h0a1.194,1.194,0,0,0-.3.082c-.146.056-.333.135-.546.228-.426.191-.968.456-1.521.748-1.122.553-2.273,1.166-2.74,1.413l-.206.112h0c-.1.048-.25.12-.4.179a1.313,1.313,0,0,1-.452.112l-.206-.067-.082-.228.049-.276c.138-.486.508-1.66.852-2.827a22.075,22.075,0,0,0,.707-2.762v-.075a.892.892,0,0,0-.535-.774c-.673-.374-2.478-1.181-5.338-2.329a4.158,4.158,0,0,1-.826-.437h0a4.168,4.168,0,0,0,.818.434,57.673,57.673,0,0,1,5.342,2.329.9.9,0,0,1,.546.777v.064a21.465,21.465,0,0,1-.7,2.758c-.344,1.167-.714,2.344-.856,2.834l-.045.273.083.228.2.067a1.369,1.369,0,0,0,.453-.112c.157-.06.3-.134.4-.183h0l.206-.112c.463-.247,1.626-.86,2.732-1.413.554-.277,1.088-.539,1.518-.748.213-.1.4-.176.55-.228a1.182,1.182,0,0,1,.3-.082h.018l.1.067a9.963,9.963,0,0,1,.879.991c.572.7,1.282,1.63,1.847,2.377.284.374.53.707.706.942l.28.374h0a.485.485,0,0,0,.374.224l.217-.1.135-.28s1-4.486,1.17-5.323c.063-.318.164-.5.295-.561a1.639,1.639,0,0,1,.6-.075h.393c1.588,0,4.486.083,5.155.094h.082l.262-.086.086-.209a.7.7,0,0,0-.172-.412h0l-.146-.153c-.329-.348-1.148-1.23-1.925-2.157-.374-.463-.767-.942-1.07-1.368a4.284,4.284,0,0,1-.6-1.069l-.027-.187a.775.775,0,0,1,.113-.374,20.8,20.8,0,0,1,2.052-2.478c.916-1.006,1.9-2.053,2.493-2.741l.026-.033h0l-.029.037c-.591.692-1.582,1.738-2.494,2.748a20.752,20.752,0,0,0-2.045,2.471l-.112.374.03.2a4.456,4.456,0,0,0,.6,1.069c.3.426.68.9,1.069,1.364.778.931,1.593,1.813,1.922,2.161l.145.153h0a.714.714,0,0,1,.172.411l-.086.21-.261.086h-.083c-.691,0-3.573-.082-5.158-.094h-.374a1.738,1.738,0,0,0-.621.071c-.127.056-.231.247-.3.561-.172.849-1.17,5.331-1.17,5.331l-.135.276-.216.105"
              fill="#d4d5d7"
            />
            <path
              d="M1218.663,300.414a.483.483,0,0,1-.374-.224h0l-.28-.374c-.176-.239-.423-.568-.707-.942-.564-.747-1.274-1.674-1.846-2.377a9.963,9.963,0,0,0-.879-.991l-.1-.067h-.019a1.167,1.167,0,0,0-.3.082c-.149.056-.332.135-.549.228-.426.191-.965.452-1.518.748-1.121.553-2.269,1.166-2.732,1.413l-.206.112h0c-.1.049-.251.123-.4.183a1.358,1.358,0,0,1-.452.112l-.206-.067-.082-.228.045-.277c.142-.489.512-1.667.856-2.833a21.681,21.681,0,0,0,.7-2.759v-.064a.9.9,0,0,0-.546-.777c-.68-.374-2.482-1.181-5.342-2.325a4.255,4.255,0,0,1-.819-.434h0a4.135,4.135,0,0,0,.815.426c2.853,1.14,4.654,1.933,5.346,2.325a.914.914,0,0,1,.557.782v.059a20.837,20.837,0,0,1-.7,2.752c-.344,1.17-.718,2.351-.856,2.841l-.045.276.082.225.2.067a1.359,1.359,0,0,0,.452-.112c.153-.064.3-.138.4-.183h0l.205-.109c.464-.246,1.623-.859,2.726-1.413.549-.276,1.087-.538,1.514-.747.213-.094.4-.172.545-.228a1.186,1.186,0,0,1,.3-.083h0l.1.068a10,10,0,0,1,.879.983c.572.7,1.282,1.626,1.846,2.377.284.374.531.707.707.942l.28.374a.483.483,0,0,0,.374.224l.217-.1.134-.277s1-4.486,1.167-5.316c.063-.318.168-.5.3-.561a1.739,1.739,0,0,1,.617-.074h.373c1.589,0,4.486.082,5.148.093h.078l.266-.086.086-.209a.7.7,0,0,0-.172-.408h0l-.146-.153c-.329-.344-1.151-1.23-1.929-2.161-.374-.463-.766-.942-1.069-1.372a4.172,4.172,0,0,1-.6-1.069l-.026-.175a.779.779,0,0,1,.119-.4,21.082,21.082,0,0,1,2.06-2.482c.912-1.006,1.9-2.049,2.494-2.737l.022-.03h0l-.026.034c-.591.688-1.578,1.735-2.494,2.74a21.089,21.089,0,0,0-2.052,2.479.776.776,0,0,0-.116.392l.03.187a4.3,4.3,0,0,0,.6,1.069c.3.426.681.9,1.069,1.368.778.931,1.6,1.81,1.926,2.157l.145.154h0a.685.685,0,0,1,.172.407l-.086.209-.261.086h-.083c-.684,0-3.566-.082-5.155-.093h-.392a1.636,1.636,0,0,0-.6.071c-.127.06-.232.243-.3.561-.168.837-1.17,5.323-1.17,5.323l-.134.276-.217.1"
              fill="#d4d6d7"
            />
            <path
              d="M1218.659,300.381a.489.489,0,0,1-.374-.225l-.28-.373c-.176-.236-.422-.569-.706-.942-.565-.748-1.275-1.675-1.847-2.378a10.072,10.072,0,0,0-.875-.983l-.1-.067h-.019a1.117,1.117,0,0,0-.3.082c-.145.052-.332.134-.545.228-.427.191-.961.456-1.5.748-1.1.549-2.261,1.166-2.725,1.413l-.2.112h0c-.1.045-.251.119-.4.183a1.31,1.31,0,0,1-.452.112l-.2-.067-.083-.225.045-.276c.139-.49.509-1.671.856-2.841a21.034,21.034,0,0,0,.7-2.752v-.059a.915.915,0,0,0-.557-.782q-2.613-1.295-5.346-2.325a4.24,4.24,0,0,1-.815-.426h0a4.18,4.18,0,0,0,.808.426,58.22,58.22,0,0,1,5.349,2.322.929.929,0,0,1,.572.785v.052a20.79,20.79,0,0,1-.692,2.748c-.344,1.17-.717,2.358-.856,2.852l-.045.273.079.224.2.067a1.316,1.316,0,0,0,.452-.116c.153-.063.3-.138.4-.186h0l.2-.109c.46-.247,1.615-.86,2.718-1.4.55-.277,1.084-.539,1.495-.729.213-.094.4-.172.546-.228a1.243,1.243,0,0,1,.3-.083h.023l.1.068a9.251,9.251,0,0,1,.878.987c.572.7,1.279,1.622,1.843,2.373l.7.942.28.374a.489.489,0,0,0,.374.221l.217-.1.134-.277s1-4.486,1.167-5.312c.063-.314.164-.5.3-.557a1.676,1.676,0,0,1,.613-.075h.374c1.588,0,4.485.083,5.143.1h.083l.261-.086.086-.209a.685.685,0,0,0-.172-.407h0l-.145-.154c-.329-.344-1.156-1.23-1.933-2.16-.374-.464-.77-.942-1.069-1.369a4.117,4.117,0,0,1-.591-1.065l-.022-.172a.8.8,0,0,1,.123-.411,21.373,21.373,0,0,1,2.067-2.486c.916-1.006,1.9-2.045,2.49-2.733l.019-.022h0l-.023.026c-.59.688-1.577,1.735-2.489,2.736a21.093,21.093,0,0,0-2.06,2.483.8.8,0,0,0-.116.4l.022.176a4.176,4.176,0,0,0,.6,1.069c.3.426.681.9,1.07,1.372.777.927,1.6,1.813,1.929,2.157l.145.153h0a.7.7,0,0,1,.172.411l-.089.206-.262.086h-.082c-.681,0-3.563-.082-5.148-.093h-.374a1.72,1.72,0,0,0-.617.074c-.13.06-.231.243-.3.557-.164.826-1.166,5.316-1.166,5.316h0l-.135.277-.217.1"
              fill="#d6d7d9"
            />
            <path
              d="M1218.655,300.381a.487.487,0,0,1-.373-.221l-.281-.374c-.175-.239-.422-.568-.706-.942-.565-.747-1.271-1.671-1.843-2.373a9.457,9.457,0,0,0-.879-.987l-.1-.068h-.022a1.186,1.186,0,0,0-.3.083c-.146.056-.333.134-.546.228-.426.19-.96.452-1.495.729-1.1.549-2.243,1.162-2.718,1.4l-.2.112h0c-.1.049-.251.124-.4.187a1.29,1.29,0,0,1-.452.116l-.2-.067-.079-.224.045-.27c.142-.493.512-1.682.856-2.852a20.815,20.815,0,0,0,.692-2.747v-.053a.931.931,0,0,0-.572-.785,57.938,57.938,0,0,0-5.349-2.321,4.126,4.126,0,0,1-.808-.426h0a4.277,4.277,0,0,0,.8.422,57.238,57.238,0,0,1,5.353,2.321.936.936,0,0,1,.583.789v.045a20.06,20.06,0,0,1-.684,2.744c-.344,1.174-.718,2.362-.86,2.86l-.045.269.079.224.2.064a1.308,1.308,0,0,0,.452-.116c.154-.064.3-.139.4-.187h0l.206-.112c.456-.247,1.611-.856,2.71-1.406.549-.277,1.084-.534,1.495-.725.213-.1.4-.172.546-.224a1.152,1.152,0,0,1,.3-.086h.022l.1.067a9.644,9.644,0,0,1,.879.983c.572.7,1.278,1.622,1.839,2.37l.7.938.28.374a.48.48,0,0,0,.374.221l.217-.1.134-.277s1.006-4.486,1.163-5.3c.06-.311.164-.494.3-.554a1.577,1.577,0,0,1,.591-.074h.374c1.588,0,4.485.082,5.136.1h.082l.258-.086.086-.206a.712.712,0,0,0-.172-.411h0l-.146-.153c-.329-.348-1.155-1.23-1.94-2.161-.374-.467-.77-.942-1.069-1.372a4.117,4.117,0,0,1-.591-1.065l-.022-.165a.807.807,0,0,1,.127-.418,21.1,21.1,0,0,1,2.075-2.49c.916-1.006,1.9-2.045,2.489-2.729h0l-.018.022c-.591.688-1.574,1.728-2.49,2.733a21.276,21.276,0,0,0-2.067,2.486.8.8,0,0,0-.124.411l.023.172a4.092,4.092,0,0,0,.59,1.069c.3.426.681.9,1.07,1.369.777.93,1.6,1.813,1.932,2.16l.146.153h0a.7.7,0,0,1,.172.408l-.086.206-.262.086h-.082c-.673,0-3.555-.086-5.144-.1h-.373a1.636,1.636,0,0,0-.614.074c-.13.06-.235.243-.3.554-.108.822-1.11,5.308-1.11,5.308l-.131.277-.217.1"
              fill="#d7d8da"
            />
            <path
              d="M1218.655,300.381a.48.48,0,0,1-.373-.221l-.281-.374-.7-.938c-.561-.748-1.268-1.667-1.84-2.37a9.64,9.64,0,0,0-.878-.983l-.1-.067h-.023a1.124,1.124,0,0,0-.3.086c-.145.052-.332.127-.545.224-.427.194-.961.452-1.5.725-1.095.55-2.243,1.159-2.71,1.406l-.2.112c-.1.048-.251.123-.408.187a1.268,1.268,0,0,1-.452.116l-.2-.064-.079-.224.045-.269c.142-.5.512-1.686.86-2.86a20.24,20.24,0,0,0,.684-2.744v-.045a.936.936,0,0,0-.583-.789,57.342,57.342,0,0,0-5.353-2.321,4.2,4.2,0,0,1-.8-.422h0a4.129,4.129,0,0,0,.8.422,57.533,57.533,0,0,1,5.357,2.318c.419.235.594.508.594.792v.038a19.742,19.742,0,0,1-.684,2.74c-.344,1.173-.717,2.366-.86,2.863l-.044.269.078.225.194.063a1.248,1.248,0,0,0,.453-.116c.157-.063.3-.142.407-.187h0l.206-.108c.456-.247,1.607-.856,2.7-1.406.545-.273,1.08-.53,1.495-.725.213-.093.4-.172.546-.224a1.125,1.125,0,0,1,.3-.082h.023l.1.067a9.421,9.421,0,0,1,.878.983c.569.7,1.275,1.619,1.84,2.366l.7.939.281.373h0a.48.48,0,0,0,.373.221l.217-.1.135-.277s1-4.508,1.159-5.3c.06-.311.164-.49.3-.55a1.631,1.631,0,0,1,.609-.075h.393c1.585,0,4.485.083,5.128.1h.083l.258-.086.086-.2a.73.73,0,0,0-.172-.411h0l-.146-.153c-.329-.348-1.159-1.23-1.944-2.161-.374-.463-.77-.942-1.069-1.372a3.97,3.97,0,0,1-.587-1.065l-.019-.157a.846.846,0,0,1,.131-.426,21.458,21.458,0,0,1,2.079-2.494c.915-1,1.9-2.041,2.489-2.725h0c-.587.684-1.574,1.724-2.489,2.729a21.111,21.111,0,0,0-2.075,2.49.806.806,0,0,0-.127.418l.022.165a4.117,4.117,0,0,0,.591,1.065c.3.427.68.9,1.069,1.372.781.931,1.611,1.813,1.94,2.161l.146.153h0a.723.723,0,0,1,.172.411l-.086.206-.258.086h-.094c-.669,0-3.547-.086-5.136-.1h-.374a1.575,1.575,0,0,0-.59.075c-.135.059-.24.243-.3.553-.157.8-1.163,5.308-1.163,5.308l-.135.273-.216.1"
              fill="#d9dadb"
            />
            <path
              d="M1218.652,300.347a.483.483,0,0,1-.374-.22h0l-.28-.374-.7-.939c-.565-.747-1.268-1.667-1.84-2.366a9.212,9.212,0,0,0-.878-.983l-.1-.071h-.023a1.125,1.125,0,0,0-.3.082c-.146.053-.333.131-.546.224-.426.195-.961.453-1.495.726-1.1.549-2.243,1.159-2.7,1.4l-.206.109h0c-.1.048-.25.123-.4.187a1.273,1.273,0,0,1-.453.115l-.2-.063-.075-.224.045-.27c.142-.5.512-1.693.856-2.863a19.57,19.57,0,0,0,.684-2.74v-.037c0-.285-.175-.557-.594-.793a57.587,57.587,0,0,0-5.353-2.318,3.865,3.865,0,0,1-.8-.422h0a4.069,4.069,0,0,0,.792.415,57.733,57.733,0,0,1,5.361,2.318c.426.239.6.512.6.8v.033a6.941,6.941,0,0,1-.217,1.077c-.373,1.462-1.121,3.779-1.319,4.531l-.045.269.079.22.194.064a1.288,1.288,0,0,0,.452-.116c.157-.067.3-.142.408-.191h0l.2-.108c.456-.247,1.6-.856,2.7-1.4.546-.273,1.077-.534,1.5-.725.209-.093.4-.172.542-.224a1.128,1.128,0,0,1,.3-.083h.023l.1.072a9.3,9.3,0,0,1,.878.983c.568.7,1.271,1.615,1.836,2.362l.7.939.28.373h0a.486.486,0,0,0,.374.221h0l.213-.1.131-.277s1.005-4.515,1.159-5.293c.06-.307.16-.486.3-.55a1.538,1.538,0,0,1,.587-.074h.392c1.589,0,4.486.082,5.125.1h.083l.257-.086.086-.2a.683.683,0,0,0-.172-.411h0l-.149-.157c-.333-.344-1.163-1.23-1.948-2.161-.373-.467-.773-.942-1.073-1.372a3.949,3.949,0,0,1-.583-1.065v-.15a.843.843,0,0,1,.135-.433,21.6,21.6,0,0,1,2.086-2.5c.916-1,1.9-2.038,2.489-2.718h0c-.586.684-1.573,1.723-2.489,2.725a21.641,21.641,0,0,0-2.079,2.494.857.857,0,0,0-.13.426l.018.157a4.04,4.04,0,0,0,.587,1.065c.3.43.68.9,1.073,1.372.781.931,1.611,1.817,1.944,2.161l.146.153h0a.711.711,0,0,1,.172.411l-.086.2-.258.086h-.083c-.661,0-3.543-.086-5.128-.1h-.393a1.632,1.632,0,0,0-.609.075c-.135.064-.239.243-.3.55-.154.792-1.159,5.3-1.159,5.3l-.131.273-.217.1"
              fill="#dadbdc"
            />
            <path
              d="M1218.652,300.332h0a.476.476,0,0,1-.374-.22h0l-.28-.374-.7-.939c-.561-.747-1.264-1.663-1.836-2.362a9.2,9.2,0,0,0-.878-.983l-.1-.071h-.023a1.145,1.145,0,0,0-.3.082c-.145.052-.332.131-.542.228-.426.191-.957.449-1.495.721-1.091.546-2.243,1.159-2.695,1.4l-.206.109h0c-.1.048-.25.123-.407.19a1.294,1.294,0,0,1-.453.116l-.194-.063-.075-.221.045-.269c.209-.748.95-3.069,1.32-4.527a6.783,6.783,0,0,0,.217-1.077v-.033c0-.284-.184-.557-.61-.8a57.827,57.827,0,0,0-5.36-2.318,4.07,4.07,0,0,1-.793-.415h0a4.037,4.037,0,0,0,.785.415,56.778,56.778,0,0,1,5.361,2.314c.433.247.62.519.62.8v.026a6.882,6.882,0,0,1-.213,1.073c-.374,1.458-1.121,3.783-1.323,4.534l-.045.269.075.217.19.064a1.273,1.273,0,0,0,.453-.12c.157-.067.3-.146.407-.194h0l.2-.112c.456-.243,1.6-.853,2.692-1.4.545-.273,1.076-.531,1.495-.722.209-.093.4-.172.542-.224a1.1,1.1,0,0,1,.3-.082h.022l.1.067a9.5,9.5,0,0,1,.879.979c.568.7,1.271,1.615,1.831,2.363.281.374.524.7.7.934l.281.374a.49.49,0,0,0,.374.221l.213-.1.13-.277s1.006-4.519,1.156-5.286c.059-.3.16-.482.3-.545a1.6,1.6,0,0,1,.6-.075h.4c1.589,0,4.486.086,5.118.1h.082l.262-.086.086-.2a.714.714,0,0,0-.176-.411h0l-.149-.157c-.333-.344-1.167-1.23-1.952-2.165-.373-.467-.773-.942-1.072-1.372a3.8,3.8,0,0,1-.58-1.065v-.142a.879.879,0,0,1,.138-.445,21.656,21.656,0,0,1,2.094-2.5c.916-1,1.9-2.033,2.486-2.714h0c-.587.684-1.574,1.72-2.49,2.718a21.328,21.328,0,0,0-2.086,2.5.832.832,0,0,0-.134.433v.146a3.883,3.883,0,0,0,.579,1.066c.3.426.68.9,1.073,1.371.785.931,1.615,1.817,1.948,2.161l.145.157h0a.7.7,0,0,1,.176.411l-.086.2-.258.086h-.049c-.657,0-3.536-.082-5.125-.1h-.4a1.518,1.518,0,0,0-.587.075c-.138.063-.243.239-.3.549-.153.778-1.155,5.294-1.155,5.294l-.131.276-.209.1"
              fill="#dbdcde"
            />
            <path
              d="M1218.648,300.313a.489.489,0,0,1-.374-.22l-.28-.374c-.176-.235-.419-.564-.7-.934-.561-.748-1.264-1.664-1.832-2.363a9.374,9.374,0,0,0-.878-.979l-.1-.068h-.023a1.109,1.109,0,0,0-.3.083c-.145.052-.332.13-.542.228-.422.186-.953.448-1.495.721-1.091.546-2.243,1.155-2.691,1.4l-.2.109h0c-.1.048-.251.123-.408.194a1.289,1.289,0,0,1-.452.12l-.191-.064-.075-.221.045-.269c.21-.747.95-3.076,1.324-4.534a7.048,7.048,0,0,0,.213-1.077v-.026c0-.28-.187-.553-.621-.8a57.035,57.035,0,0,0-5.36-2.314,3.982,3.982,0,0,1-.785-.415h0a3.907,3.907,0,0,0,.781.411,57.755,57.755,0,0,1,5.364,2.314c.445.251.632.524.632.8v.023a6.545,6.545,0,0,1-.209,1.065c-.374,1.458-1.122,3.791-1.32,4.546l-.045.269.075.217.191.063a1.227,1.227,0,0,0,.452-.119c.153-.068.3-.146.407-.191h0l.2-.108c.453-.243,1.6-.849,2.684-1.395.542-.276,1.073-.534,1.5-.721.209-.094.4-.172.542-.225a1.205,1.205,0,0,1,.3-.082h.023l.1.071a9.072,9.072,0,0,1,.878.98c.568.7,1.271,1.615,1.828,2.359l.695.934.277.374h0a.484.484,0,0,0,.374.217l.209-.1.131-.273s1.006-4.527,1.151-5.282c.06-.3.161-.479.3-.546a1.54,1.54,0,0,1,.587-.079h.4c1.589,0,4.486.086,5.114.1h.082l.258-.082.086-.2a.708.708,0,0,0-.175-.411h0l-.15-.157c-.333-.348-1.17-1.234-1.959-2.164-.374-.468-.774-.942-1.069-1.372a3.77,3.77,0,0,1-.576-1.066v-.134a.891.891,0,0,1,.146-.453,21.789,21.789,0,0,1,2.1-2.508c.916-1,1.9-2.03,2.482-2.706h0c-.587.68-1.57,1.715-2.486,2.714a21.415,21.415,0,0,0-2.093,2.5.856.856,0,0,0-.138.441v.142a3.779,3.779,0,0,0,.579,1.066c.3.426.68.9,1.073,1.372.785.93,1.619,1.816,1.951,2.164l.146.157h0a.723.723,0,0,1,.176.411l-.086.2-.262.086h-.082c-.651,0-3.533-.082-5.118-.1h-.4a1.615,1.615,0,0,0-.6.075c-.142.064-.243.243-.3.55-.149.766-1.155,5.285-1.155,5.285l-.131.277-.213.1"
              fill="#dcddde"
            />
            <path
              d="M1218.644,300.3a.486.486,0,0,1-.374-.217h0l-.276-.374-.7-.934c-.557-.748-1.26-1.66-1.828-2.359a9.382,9.382,0,0,0-.879-.98l-.1-.071h-.022a1.186,1.186,0,0,0-.3.083c-.146.056-.333.134-.542.228-.422.186-.953.444-1.495.721-1.084.546-2.243,1.151-2.68,1.394l-.2.109h0c-.1.045-.251.123-.408.19a1.208,1.208,0,0,1-.452.12l-.191-.063-.075-.217.045-.269c.21-.748.95-3.088,1.32-4.546a6.6,6.6,0,0,0,.209-1.069v-.023c0-.276-.187-.549-.632-.8a58.287,58.287,0,0,0-5.364-2.314,3.912,3.912,0,0,1-.781-.411h0a3.9,3.9,0,0,0,.774.408,57.291,57.291,0,0,1,5.368,2.314c.452.254.643.53.643.8h0a5.946,5.946,0,0,1-.206,1.066c-.374,1.454-1.121,3.79-1.319,4.553l-.045.269.074.217.187.063a1.3,1.3,0,0,0,.453-.119c.157-.067.306-.146.407-.195h0l.2-.108c.452-.243,1.589-.849,2.673-1.394.542-.273,1.073-.531,1.495-.718.209-.1.4-.172.542-.224a1.108,1.108,0,0,1,.3-.083h.022l.1.071a9.083,9.083,0,0,1,.879.98c.568.7,1.267,1.615,1.828,2.355l.7.934.276.374h0a.477.477,0,0,0,.352.221l.209-.1.131-.273s1.009-4.534,1.148-5.275c.056-.306.16-.482.3-.545a1.578,1.578,0,0,1,.6-.079h.4c1.588,0,4.467.09,5.106.105h.082l.258-.082.086-.206a.715.715,0,0,0-.176-.411h0l-.149-.154c-.333-.351-1.174-1.233-1.963-2.168-.392-.467-.773-.946-1.073-1.372a3.593,3.593,0,0,1-.571-1.065v-.123a.887.887,0,0,1,.149-.46,21.9,21.9,0,0,1,2.108-2.512c.916-.995,1.892-2.023,2.479-2.7h0c-.587.676-1.566,1.708-2.482,2.706a21.422,21.422,0,0,0-2.1,2.509.879.879,0,0,0-.146.452v.134a3.7,3.7,0,0,0,.576,1.066c.3.426.676.9,1.069,1.372.785.931,1.622,1.82,1.959,2.168l.149.153h0a.709.709,0,0,1,.176.411l-.086.206-.258.082h-.082c-.647,0-3.525-.086-5.114-.1h-.4a1.557,1.557,0,0,0-.587.079c-.142.063-.247.239-.3.546-.146.747-1.152,5.282-1.152,5.282l-.13.273-.21.1"
              fill="#dedfe0"
            />
            <path
              d="M1218.641,300.284a.475.475,0,0,1-.352-.221h0l-.276-.374-.7-.934c-.557-.748-1.26-1.656-1.828-2.355a9.072,9.072,0,0,0-.878-.98l-.1-.071h-.022a1.124,1.124,0,0,0-.3.082c-.146.053-.329.131-.542.228-.423.187-.953.445-1.495.718-1.085.546-2.221,1.151-2.673,1.394l-.2.109h0c-.1.048-.251.127-.408.194a1.289,1.289,0,0,1-.452.12l-.187-.064-.075-.216.045-.27c.21-.747.953-3.1,1.32-4.549a6.026,6.026,0,0,0,.205-1.065h0c0-.273-.19-.55-.643-.8a57.062,57.062,0,0,0-5.368-2.314,3.948,3.948,0,0,1-.773-.4h0a3.8,3.8,0,0,0,.766.4,56.483,56.483,0,0,1,5.372,2.314,1.678,1.678,0,0,1,.5.4.655.655,0,0,1,.153.408h0a6.135,6.135,0,0,1-.2,1.058c-.374,1.454-1.121,3.8-1.32,4.56l-.041.27.071.216.187.064a1.3,1.3,0,0,0,.453-.123c.153-.068.306-.15.407-.2h0l.2-.112c.452-.243,1.585-.845,2.669-1.391.538-.269,1.069-.527,1.495-.717.21-.094.4-.172.542-.225a1.225,1.225,0,0,1,.3-.082h.023l.1.071a9.177,9.177,0,0,1,.879.98c.568.7,1.267,1.611,1.824,2.351.28.374.52.7.695.931l.277.374h0a.483.483,0,0,0,.351.216l.21-.1.13-.276s1.01-4.539,1.148-5.268c.056-.3.157-.474.3-.542a1.556,1.556,0,0,1,.6-.082h.4c1.585,0,4.467.09,5.1.105h.063l.258-.079.083-.2a.73.73,0,0,0-.172-.411h0l-.15-.153c-.336-.348-1.177-1.237-1.966-2.168-.393-.468-.774-.946-1.073-1.372a3.65,3.65,0,0,1-.568-1.066v-.119a.9.9,0,0,1,.153-.467,21.834,21.834,0,0,1,2.112-2.52c.912-.991,1.869-2.007,2.475-2.688h0c-.587.677-1.563,1.7-2.479,2.7a21.987,21.987,0,0,0-2.108,2.512.9.9,0,0,0-.149.46v.127a3.634,3.634,0,0,0,.571,1.062c.3.426.677.908,1.073,1.372.785.934,1.626,1.82,1.963,2.168l.149.153h0a.706.706,0,0,1,.176.408l-.086.2-.258.082h-.082c-.639,0-3.521-.085-5.106-.1h-.4a1.6,1.6,0,0,0-.6.078c-.145.064-.246.239-.3.546-.142.747-1.148,5.274-1.148,5.274l-.131.27-.209.1"
              fill="#dedfe1"
            />
            <path
              d="M1218.641,300.265a.48.48,0,0,1-.352-.217h0l-.276-.374-.7-.931c-.557-.747-1.256-1.656-1.824-2.347a9.01,9.01,0,0,0-.879-.983l-.1-.071h-.023a1.215,1.215,0,0,0-.3.082c-.146.052-.329.131-.542.224-.423.187-.95.445-1.5.718-1.08.546-2.217,1.148-2.665,1.391l-.2.108h0c-.1.049-.254.127-.411.194a1.263,1.263,0,0,1-.449.127l-.187-.063-.071-.217.041-.269c.21-.748.954-3.107,1.32-4.561a5.954,5.954,0,0,0,.2-1.058h0a.642.642,0,0,0-.153-.407,1.646,1.646,0,0,0-.5-.4,56.226,56.226,0,0,0-5.372-2.314,3.72,3.72,0,0,1-.77-.4h0a3.781,3.781,0,0,0,.747.4,56.2,56.2,0,0,1,5.372,2.306,1.735,1.735,0,0,1,.512.4.636.636,0,0,1,.157.4h0a5.9,5.9,0,0,1-.2,1.054c-.374,1.451-1.121,3.8-1.319,4.568l-.045.266.071.217.183.059a1.207,1.207,0,0,0,.452-.123c.154-.071.3-.146.408-.2h0l.2-.108c.448-.243,1.581-.845,2.658-1.391.538-.269,1.065-.527,1.495-.714.209-.093.392-.172.538-.224a1.127,1.127,0,0,1,.307-.083h.022l.1.071a9.773,9.773,0,0,1,.875.98c.568.695,1.263,1.611,1.82,2.347l.692.931.276.374h0a.469.469,0,0,0,.352.217l.209-.1.131-.273s1.009-4.546,1.144-5.26c.056-.3.16-.475.306-.542a1.6,1.6,0,0,1,.6-.078h.4c1.588,0,4.485.089,5.095.1h.1l.258-.082.083-.2a.7.7,0,0,0-.172-.411h0l-.169-.131c-.336-.348-1.181-1.237-1.97-2.172a17.046,17.046,0,0,1-1.072-1.372,3.475,3.475,0,0,1-.565-1.065v-.112a.945.945,0,0,1,.157-.475,22.351,22.351,0,0,1,2.12-2.52c.912-.986,1.869-2,2.467-2.676h0c-.587.676-1.559,1.7-2.471,2.688a21.76,21.76,0,0,0-2.116,2.519.911.911,0,0,0-.153.467v.12a3.6,3.6,0,0,0,.613,1.047c.3.426.677.9,1.073,1.372.789.931,1.63,1.82,1.966,2.168l.15.153h0a.72.72,0,0,1,.175.411l-.086.2-.258.079h-.082c-.635,0-3.514-.086-5.1-.105h-.4a1.56,1.56,0,0,0-.6.079c-.145.067-.25.239-.306.542-.138.747-1.148,5.267-1.148,5.267l-.131.273-.209.1"
              fill="#e0e1e2"
            />
            <path
              d="M1218.637,300.246a.471.471,0,0,1-.352-.217l-.276-.373-.692-.931c-.557-.748-1.252-1.653-1.82-2.348a9.536,9.536,0,0,0-.875-.979l-.1-.071h-.022a1.163,1.163,0,0,0-.307.082c-.145.052-.329.131-.538.224-.422.187-.949.445-1.495.714-1.077.546-2.21,1.148-2.662,1.391l-.2.108h0c-.1.049-.254.127-.411.2a1.213,1.213,0,0,1-.449.127l-.183-.063-.071-.217.045-.265c.209-.767.957-3.118,1.32-4.568a6.093,6.093,0,0,0,.2-1.055h0a.644.644,0,0,0-.157-.4,1.716,1.716,0,0,0-.512-.4,56.673,56.673,0,0,0-5.372-2.307,3.888,3.888,0,0,1-.748-.4h0a3.676,3.676,0,0,0,.748.4,55.738,55.738,0,0,1,5.375,2.306,1.752,1.752,0,0,1,.52.4.62.62,0,0,1,.161.4h0a5.5,5.5,0,0,1-.2,1.047c-.374,1.45-1.122,3.8-1.316,4.575l-.045.269.071.21.183.063a1.193,1.193,0,0,0,.449-.127c.157-.071.306-.149.411-.2h0l.2-.108c.449-.24,1.578-.845,2.654-1.387.539-.269,1.066-.527,1.495-.714.21-.094.4-.168.543-.225a1.127,1.127,0,0,1,.3-.082h.027l.1.071a9.4,9.4,0,0,1,.879.98c.568.7,1.263,1.6,1.816,2.344l.67.919.276.374a.482.482,0,0,0,.352.217h0l.205-.1.127-.273s1.013-4.55,1.144-5.26c.053-.3.157-.471.307-.538a1.576,1.576,0,0,1,.594-.079h.4c1.585,0,4.467.09,5.088.105h.1l.262-.082.086-.2a.709.709,0,0,0-.176-.411h0l-.153-.153c-.336-.348-1.185-1.237-1.977-2.172-.4-.467-.778-.946-1.073-1.372a3.452,3.452,0,0,1-.557-1.062v-.1a.935.935,0,0,1,.164-.482,22.377,22.377,0,0,1,2.127-2.524c.909-.983,1.869-1.992,2.46-2.665h0c-.587.677-1.555,1.69-2.467,2.677a22.548,22.548,0,0,0-2.12,2.519.94.94,0,0,0-.157.479v.108a3.475,3.475,0,0,0,.565,1.065,17.382,17.382,0,0,0,1.073,1.372c.788.935,1.633,1.825,1.97,2.172l.149.153h0a.717.717,0,0,1,.172.412l-.082.2-.258.082h-.082c-.628,0-3.507-.086-5.1-.1h-.4a1.6,1.6,0,0,0-.6.075c-.146.067-.25.239-.306.542-.135.718-1.144,5.263-1.144,5.263l-.131.273-.209.1"
              fill="#e1e2e3"
            />
            <path
              d="M1218.637,300.231h0a.479.479,0,0,1-.352-.217l-.276-.373-.692-.931c-.557-.748-1.252-1.653-1.817-2.344a9.159,9.159,0,0,0-.878-.979l-.1-.072h-.022a1.2,1.2,0,0,0-.307.083c-.145.052-.332.131-.542.224-.422.187-.945.445-1.495.714-1.077.542-2.2,1.148-2.654,1.387l-.2.108h0c-.1.049-.254.127-.411.2a1.2,1.2,0,0,1-.449.128l-.183-.064-.071-.209.045-.27c.209-.766.957-3.125,1.315-4.575a5.483,5.483,0,0,0,.2-1.047h0a.62.62,0,0,0-.161-.4,1.736,1.736,0,0,0-.52-.4,56.366,56.366,0,0,0-5.375-2.306,3.758,3.758,0,0,1-.748-.4h0a3.658,3.658,0,0,0,.748.374,55.927,55.927,0,0,1,5.379,2.306,1.809,1.809,0,0,1,.527.4.625.625,0,0,1,.168.4h0a5.71,5.71,0,0,1-.194,1.047c-.374,1.447-1.122,3.8-1.316,4.572l-.041.269.071.209.179.06a1.213,1.213,0,0,0,.449-.127c.157-.071.306-.153.411-.2h0l.2-.108c.445-.24,1.57-.845,2.647-1.384.538-.269,1.061-.527,1.495-.714.209-.093.392-.172.538-.228a1.162,1.162,0,0,1,.307-.082h.026l.1.071a9.09,9.09,0,0,1,.874.976c.569.695,1.264,1.6,1.817,2.344l.666.964.276.374a.475.475,0,0,0,.352.217h0l.205-.1.127-.273h0s1.013-4.557,1.122-5.234c.056-.3.16-.467.31-.538a1.52,1.52,0,0,1,.576-.075h.4c1.585,0,4.463.094,5.08.105h.082l.254-.083.086-.2a.748.748,0,0,0-.175-.411h0l-.154-.153c-.34-.352-1.188-1.237-1.981-2.176-.4-.463-.777-.946-1.073-1.372a3.447,3.447,0,0,1-.557-1.061v-.1a.99.99,0,0,1,.165-.493,22.46,22.46,0,0,1,2.134-2.527c.909-.979,1.869-1.985,2.456-2.658h0c-.587.673-1.551,1.686-2.459,2.665a22.161,22.161,0,0,0-2.127,2.524.936.936,0,0,0-.165.482v.1a3.468,3.468,0,0,0,.557,1.061c.3.426.677.9,1.073,1.372.792.935,1.641,1.821,1.977,2.172l.154.153h0a.716.716,0,0,1,.175.412l-.086.2-.261.082h-.079c-.62,0-3.5-.09-5.088-.105h-.4a1.537,1.537,0,0,0-.594.079c-.149.067-.254.239-.306.538-.131.707-1.144,5.26-1.144,5.26l-.127.269-.206.1"
              fill="#e2e3e4"
            />
            <path
              d="M1218.633,300.213h0a.481.481,0,0,1-.351-.217l-.277-.374-.692-.927c-.553-.748-1.252-1.649-1.816-2.344a8.86,8.86,0,0,0-.879-.979l-.1-.071h-.027a1.165,1.165,0,0,0-.306.082c-.146.052-.329.131-.538.228-.419.187-.942.441-1.5.714-1.073.542-2.2,1.144-2.646,1.383l-.2.108h0c-.1.049-.254.131-.411.2a1.207,1.207,0,0,1-.445.128l-.183-.064-.067-.209.041-.269c.209-.771.953-3.122,1.316-4.569a5.667,5.667,0,0,0,.2-1.05h0a.621.621,0,0,0-.168-.4,1.809,1.809,0,0,0-.527-.4,56.447,56.447,0,0,0-5.38-2.306,3.726,3.726,0,0,1-.747-.374h0a3.675,3.675,0,0,0,.747.374,56.714,56.714,0,0,1,5.383,2.3,1.906,1.906,0,0,1,.539.408.625.625,0,0,1,.168.374h0a6,6,0,0,1-.194,1.054c-.374,1.446-1.122,3.794-1.313,4.564l-.041.265.068.21.175.063a1.192,1.192,0,0,0,.449-.13c.157-.071.306-.154.411-.2h0l.2-.109c.445-.239,1.57-.841,2.639-1.379.535-.269,1.058-.527,1.5-.714.209-.094.392-.168.538-.224a1.108,1.108,0,0,1,.3-.083h.026l.1.071a8.968,8.968,0,0,1,.878.98c.565.691,1.26,1.6,1.813,2.34l.688.927.277.374h0a.481.481,0,0,0,.351.217l.209-.1.128-.269s1.013-4.561,1.121-5.234c.056-.3.157-.467.31-.535a1.562,1.562,0,0,1,.591-.082h.4c1.589,0,4.467.09,5.076.1h.083l.254-.079.086-.2a.7.7,0,0,0-.176-.411h0l-.153-.157c-.34-.348-1.2-1.237-1.985-2.172a17.091,17.091,0,0,1-1.073-1.372,3.359,3.359,0,0,1-.553-1.061v-.094a.968.968,0,0,1,.172-.5,22,22,0,0,1,2.138-2.535c.905-.975,1.869-1.973,2.448-2.646h0c-.586.673-1.547,1.678-2.456,2.658a22.368,22.368,0,0,0-2.134,2.527.968.968,0,0,0-.168.493v.1a3.394,3.394,0,0,0,.557,1.066c.295.426.676.9,1.073,1.372.792.938,1.641,1.824,1.981,2.172l.149.153h0a.722.722,0,0,1,.176.411l-.086.2-.254.082h-.082c-.617,0-3.5-.089-5.081-.1h-.4a1.5,1.5,0,0,0-.575.078c-.15.071-.255.239-.311.539-.127.7-1.121,5.233-1.121,5.233h0l-.127.269-.206.1"
              fill="#e3e4e5"
            />
            <path
              d="M1218.629,300.2a.483.483,0,0,1-.347-.217h0s-.1-.142-.277-.374l-.688-.927c-.553-.748-1.248-1.645-1.813-2.34a9.172,9.172,0,0,0-.878-.98l-.1-.071h-.026a1.126,1.126,0,0,0-.307.083c-.145.052-.329.127-.538.224-.419.187-.942.441-1.495.714-1.073.538-2.2,1.14-2.639,1.383l-.2.108h0c-.1.049-.25.131-.411.2a1.2,1.2,0,0,1-.448.131l-.176-.063-.067-.21.041-.269c.209-.774.953-3.118,1.312-4.564a5.95,5.95,0,0,0,.194-1.051h0a.619.619,0,0,0-.168-.374,1.953,1.953,0,0,0-.535-.407,56.976,56.976,0,0,0-5.383-2.3,3.48,3.48,0,0,1-.747-.374h0a3.624,3.624,0,0,0,.747.374,57.7,57.7,0,0,1,5.387,2.3,1.982,1.982,0,0,1,.546.407.619.619,0,0,1,.176.374h0a5.7,5.7,0,0,1-.2,1.054c-.374,1.447-1.1,3.791-1.308,4.561l-.041.265.067.21.176.063a1.212,1.212,0,0,0,.448-.13c.157-.072.307-.154.412-.2h0l.2-.109c.445-.239,1.562-.837,2.616-1.379.535-.269,1.058-.524,1.473-.71.21-.094.393-.169.539-.225a1.246,1.246,0,0,1,.306-.082h.026l.105.071a9.053,9.053,0,0,1,.878.979c.565.692,1.26,1.6,1.81,2.337l.688.927.273.374h0a.472.472,0,0,0,.347.217l.21-.1.127-.269h0s1.013-4.568,1.121-5.233c.052-.292.157-.464.31-.531a1.45,1.45,0,0,1,.576-.082h.4c1.589,0,4.467.093,5.069.1h.082l.254-.078.086-.2a.725.725,0,0,0-.175-.412h0l-.154-.157c-.34-.347-1.2-1.237-1.992-2.171-.4-.468-.778-.946-1.073-1.376a3.3,3.3,0,0,1-.546-1.062v-.086a1,1,0,0,1,.18-.5,22.217,22.217,0,0,1,2.146-2.539c.9-.972,1.869-1.966,2.444-2.616h0c-.583.669-1.544,1.667-2.448,2.646a21.911,21.911,0,0,0-2.138,2.535.967.967,0,0,0-.172.5v.093a3.3,3.3,0,0,0,.549,1.062c.3.426.677.9,1.073,1.372.793.935,1.645,1.824,1.985,2.172l.153.157h0a.716.716,0,0,1,.176.411l-.086.2-.254.079h-.082c-.61,0-3.488-.09-5.073-.105h-.4a1.56,1.56,0,0,0-.591.082.659.659,0,0,0-.31.535c-.123.68-1.122,5.233-1.122,5.233l-.13.27-.21.1"
              fill="#e5e6e7"
            />
            <path
              d="M1218.626,300.179a.473.473,0,0,1-.348-.217h0l-.273-.374-.688-.927c-.549-.747-1.245-1.645-1.809-2.336a8.877,8.877,0,0,0-.879-.98l-.1-.071h-.026a1.2,1.2,0,0,0-.307.083c-.146.052-.329.13-.538.224-.419.187-.939.441-1.473.71-1.069.542-2.187,1.14-2.617,1.38l-.2.108h0c-.1.049-.254.131-.411.2a1.218,1.218,0,0,1-.449.131l-.175-.064-.068-.205.041-.266c.206-.774.954-3.118,1.309-4.564a5.651,5.651,0,0,0,.194-1.054h0a.619.619,0,0,0-.176-.374,1.98,1.98,0,0,0-.545-.408,57.288,57.288,0,0,0-5.387-2.3,3.788,3.788,0,0,1-.748-.374h0a3.609,3.609,0,0,0,.748.374,57.383,57.383,0,0,1,5.387,2.3,2.126,2.126,0,0,1,.553.407.627.627,0,0,1,.183.374v.022a5.793,5.793,0,0,1-.194,1.054c-.374,1.447-1.1,3.783-1.305,4.557l-.041.266.067.2.172.064a1.223,1.223,0,0,0,.449-.135c.157-.071.306-.153.411-.2h0l.2-.108c.441-.239,1.559-.837,2.617-1.376.534-.269,1.054-.523,1.473-.71.209-.093.374-.168.538-.224a1.16,1.16,0,0,1,.307-.082h.026l.1.071a8.638,8.638,0,0,1,.879.975c.564.692,1.256,1.6,1.805,2.333l.688.923.273.374h0a.473.473,0,0,0,.348.217l.209-.1.127-.269s1.013-4.576,1.122-5.234a.648.648,0,0,1,.314-.531,1.5,1.5,0,0,1,.587-.082h.4c1.585,0,4.463.094,5.061.1h.082l.255-.079.086-.2a.716.716,0,0,0-.176-.411h0l-.153-.157c-.344-.348-1.2-1.237-2-2.176a17.368,17.368,0,0,1-1.072-1.372,3.175,3.175,0,0,1-.546-1.061v-.079a1.025,1.025,0,0,1,.183-.512,22.686,22.686,0,0,1,2.153-2.542c.9-.968,1.869-1.955,2.437-2.617h0c-.586.67-1.54,1.664-2.444,2.636a22.169,22.169,0,0,0-2.146,2.534,1.021,1.021,0,0,0-.18.505v.086a3.258,3.258,0,0,0,.546,1.062c.292.426.677.9,1.073,1.375.8.935,1.652,1.825,1.993,2.172l.153.157h0a.722.722,0,0,1,.176.411l-.086.2-.255.074h-.082c-.6,0-3.484-.086-5.069-.1h-.4a1.45,1.45,0,0,0-.576.082.655.655,0,0,0-.31.531c-.119.669-1.121,5.233-1.121,5.233h0l-.127.269-.21.1"
              fill="#e6e7e8"
            />
            <path
              d="M1218.622,300.164a.472.472,0,0,1-.348-.217h0l-.273-.374c-.168-.231-.411-.557-.688-.923-.549-.748-1.241-1.641-1.8-2.333a8.829,8.829,0,0,0-.879-.975l-.108-.071h-.026a1.164,1.164,0,0,0-.307.078c-.145.052-.329.131-.538.224-.419.187-.938.442-1.473.711-1.069.538-2.183,1.121-2.617,1.379l-.2.108h0c-.1.049-.254.131-.411.2a1.218,1.218,0,0,1-.449.135l-.171-.064-.068-.205.041-.266c.21-.773.95-3.114,1.309-4.56a5.825,5.825,0,0,0,.194-1.054v-.023a.628.628,0,0,0-.179-.374,2.173,2.173,0,0,0-.554-.407,57.454,57.454,0,0,0-5.39-2.3,3.608,3.608,0,0,1-.748-.374h0a3.634,3.634,0,0,0,.729.374,57.61,57.61,0,0,1,5.391,2.3,2.241,2.241,0,0,1,.56.411.616.616,0,0,1,.187.374v.03a5.651,5.651,0,0,1-.194,1.054c-.374,1.447-1.095,3.783-1.305,4.557l-.041.265.068.2.168.064a1.2,1.2,0,0,0,.448-.135c.157-.071.307-.157.412-.206l.2-.108c.441-.239,1.551-.834,2.616-1.372.531-.269,1.051-.523,1.47-.71.209-.094.373-.168.538-.224a1.167,1.167,0,0,1,.306-.083h.027l.108.071a9.083,9.083,0,0,1,.875.976c.564.688,1.256,1.6,1.805,2.329.277.374.516.692.688.923l.273.374a.475.475,0,0,0,.348.217l.205-.1.127-.269s1.013-4.583,1.122-5.234a.646.646,0,0,1,.314-.527,1.5,1.5,0,0,1,.587-.082h.4c1.585,0,4.463.1,5.058.108h.082l.254-.075.086-.2a.716.716,0,0,0-.176-.411h0l-.153-.153c-.344-.352-1.2-1.241-2-2.18-.4-.467-.781-.949-1.073-1.372a3.14,3.14,0,0,1-.538-1.061v-.071a1.037,1.037,0,0,1,.187-.52,22.519,22.519,0,0,1,2.161-2.546c.9-.964,1.846-1.943,2.43-2.616h0c-.587.669-1.537,1.656-2.438,2.616a22.509,22.509,0,0,0-2.153,2.542,1.008,1.008,0,0,0-.183.513v.074a3.22,3.22,0,0,0,.542,1.062,17.092,17.092,0,0,0,1.073,1.372c.8.938,1.652,1.828,2,2.176l.153.157h0a.7.7,0,0,1,.176.411l-.086.2-.254.078h-.082c-.6,0-3.477-.086-5.062-.1h-.4a1.488,1.488,0,0,0-.591.082.658.658,0,0,0-.314.531c-.116.658-1.121,5.233-1.121,5.233l-.127.269-.206.1"
              fill="#e8e8e9"
            />
            <path
              d="M1218.622,300.145a.472.472,0,0,1-.348-.217l-.273-.373-.688-.924c-.549-.747-1.241-1.641-1.8-2.329a9.006,9.006,0,0,0-.875-.975l-.108-.071h-.026a1.088,1.088,0,0,0-.307.082c-.146.052-.329.127-.538.22-.419.187-.939.442-1.469.711-1.066.538-2.176,1.121-2.617,1.375l-.2.1h0c-.1.049-.254.135-.411.206a1.185,1.185,0,0,1-.449.134l-.172-.063-.064-.2.042-.266c.2-.773.945-3.11,1.3-4.557a5.7,5.7,0,0,0,.195-1.054v-.03a.627.627,0,0,0-.187-.373,2.244,2.244,0,0,0-.561-.412,57.913,57.913,0,0,0-5.394-2.3,3.609,3.609,0,0,1-.729-.373h0a3.6,3.6,0,0,0,.721.373h0a56,56,0,0,1,5.394,2.3,2.237,2.237,0,0,1,.569.411.609.609,0,0,1,.19.374v.033a5.794,5.794,0,0,1-.194,1.054c-.374,1.447-1.092,3.776-1.3,4.554l-.041.265.063.2.169.06a1.186,1.186,0,0,0,.445-.135c.157-.075.31-.157.411-.2h0l.2-.109c.441-.235,1.551-.833,2.617-1.372.53-.269,1.046-.519,1.465-.706.206-.094.374-.169.538-.225a1.2,1.2,0,0,1,.307-.082h.03l.108.071a8.823,8.823,0,0,1,.875.976c.564.691,1.252,1.592,1.8,2.325l.684.923.273.374h0a.473.473,0,0,0,.344.213l.205-.1.127-.269h0s1.013-4.583,1.122-5.234a.642.642,0,0,1,.318-.527,1.5,1.5,0,0,1,.586-.082h.4c1.585,0,4.464.094,5.051.108h.082l.25-.074.083-.2a.716.716,0,0,0-.176-.412h0l-.153-.157c-.344-.351-1.208-1.241-2.008-2.175a16.985,16.985,0,0,1-1.076-1.376,3.076,3.076,0,0,1-.535-1.062v-.067a1.049,1.049,0,0,1,.2-.527,22.7,22.7,0,0,1,2.168-2.549c.9-.961,1.839-1.937,2.426-2.617h0c-.587.669-1.533,1.648-2.43,2.617a22.324,22.324,0,0,0-2.161,2.545,1.038,1.038,0,0,0-.187.52v.071a3.158,3.158,0,0,0,.539,1.062c.291.426.676.9,1.073,1.372.8.934,1.656,1.828,2,2.179l.153.153h0a.717.717,0,0,1,.176.411l-.086.2-.254.074h-.082c-.6,0-3.473-.089-5.058-.108h-.4a1.5,1.5,0,0,0-.587.082.645.645,0,0,0-.314.527c-.112.647-1.121,5.234-1.121,5.234l-.128.269-.2.1"
              fill="#e9e9ea"
            />
            <path
              d="M1218.618,300.13a.47.47,0,0,1-.344-.213h0l-.273-.374-.684-.923c-.549-.748-1.237-1.637-1.8-2.325a8.954,8.954,0,0,0-.874-.972l-.109-.071h-.026a1.109,1.109,0,0,0-.31.082c-.146.052-.329.127-.539.221-.414.187-.938.441-1.465.71-1.062.535-2.168,1.121-2.617,1.372l-.2.108h0c-.1.049-.254.135-.411.206a1.16,1.16,0,0,1-.445.134l-.168-.059-.064-.2.041-.266c.206-.777.943-3.11,1.3-4.553a5.713,5.713,0,0,0,.2-1.054v-.034a.611.611,0,0,0-.191-.373,2.35,2.35,0,0,0-.568-.412,56.448,56.448,0,0,0-5.394-2.295h0a3.531,3.531,0,0,1-.722-.374h0a3.567,3.567,0,0,0,.714.374,56.411,56.411,0,0,1,5.4,2.295,2.413,2.413,0,0,1,.576.412.607.607,0,0,1,.194.373v.042a5.651,5.651,0,0,1-.194,1.054c-.374,1.443-1.092,3.772-1.3,4.549l-.041.266.063.2.168.064a1.179,1.179,0,0,0,.445-.138c.157-.075.307-.161.411-.21h0l.2-.1c.441-.236,1.547-.834,2.617-1.369.527-.269,1.046-.519,1.465-.706a5.1,5.1,0,0,1,.538-.221,1.162,1.162,0,0,1,.307-.082h.03l.108.067a9.143,9.143,0,0,1,.875.972c.561.688,1.252,1.593,1.8,2.326l.684.919.273.374h0a.465.465,0,0,0,.344.213h0l.2-.1.127-.266s1.013-4.594,1.121-5.233a.632.632,0,0,1,.318-.523,1.464,1.464,0,0,1,.583-.083h.4c1.585.023,4.464.1,5.043.112h.082l.251-.074.086-.2a.71.71,0,0,0-.176-.412h0l-.153-.157c-.348-.351-1.211-1.241-2.015-2.179a16.976,16.976,0,0,1-1.073-1.376,3.023,3.023,0,0,1-.531-1.058v-.063a1.074,1.074,0,0,1,.2-.531,22.6,22.6,0,0,1,2.172-2.553c.894-.957,1.832-1.925,2.419-2.6h0c-.583.67-1.529,1.645-2.426,2.617a22.8,22.8,0,0,0-2.168,2.55,1.043,1.043,0,0,0-.191.523v.067a3.1,3.1,0,0,0,.534,1.062,17.244,17.244,0,0,0,1.077,1.376c.8.934,1.66,1.828,2.007,2.175l.154.157h0a.7.7,0,0,1,.175.412l-.086.194-.25.075h-.082c-.587,0-3.466-.09-5.051-.109h-.4a1.534,1.534,0,0,0-.586.082.639.639,0,0,0-.318.528c-.112.635-1.122,5.233-1.122,5.233h0l-.127.269-.205.1"
              fill="#eaebec"
            />
            <path
              d="M1218.618,300.112h0a.468.468,0,0,1-.344-.213h0l-.273-.374-.684-.92c-.546-.748-1.237-1.637-1.8-2.325a8.972,8.972,0,0,0-.875-.972l-.108-.071h-.03a1.119,1.119,0,0,0-.306.082c-.146.053-.329.127-.539.221-.418.187-.934.437-1.465.706-1.058.535-2.165,1.122-2.617,1.368l-.194.1h0c-.1.052-.254.135-.412.209a1.194,1.194,0,0,1-.444.139l-.169-.064-.063-.2.041-.265c.206-.778.942-3.107,1.3-4.549a5.729,5.729,0,0,0,.195-1.055v-.041a.605.605,0,0,0-.195-.374,2.467,2.467,0,0,0-.576-.411,56.411,56.411,0,0,0-5.4-2.295,3.652,3.652,0,0,1-.714-.374h0a3.52,3.52,0,0,0,.711.374,55.964,55.964,0,0,1,5.4,2.295,2.53,2.53,0,0,1,.587.411l.2.374v.045a5.794,5.794,0,0,1-.194,1.054c-.355,1.443-1.088,3.772-1.293,4.55l-.041.261.063.2.165.059a1.175,1.175,0,0,0,.444-.138c.157-.075.307-.161.412-.209h0l.194-.109c.437-.235,1.54-.83,2.6-1.364.527-.266,1.043-.52,1.458-.7.209-.093.374-.168.535-.221a1.1,1.1,0,0,1,.31-.082h.03l.108.071a8.508,8.508,0,0,1,.875.972c.561.684,1.248,1.589,1.794,2.318l.681.919.269.374h0a.473.473,0,0,0,.344.213l.205-.1.127-.265s1.017-4.6,1.122-5.211a.628.628,0,0,1,.318-.524,1.444,1.444,0,0,1,.568-.082h.4c1.585.023,4.463.1,5.039.112h.078l.255-.075.086-.194a.716.716,0,0,0-.176-.411h0l-.157-.157c-.344-.352-1.215-1.245-2.015-2.183a16.782,16.782,0,0,1-1.077-1.376,2.962,2.962,0,0,1-.527-1.058v-.056a1.063,1.063,0,0,1,.206-.538,22.53,22.53,0,0,1,2.2-2.557c.889-.95,1.828-1.918,2.411-2.583h0c-.583.665-1.522,1.637-2.419,2.594a22.614,22.614,0,0,0-2.172,2.553,1.078,1.078,0,0,0-.2.531v.064a3.041,3.041,0,0,0,.531,1.058,17.166,17.166,0,0,0,1.073,1.375c.8.939,1.667,1.828,2.015,2.18l.153.157h0a.71.71,0,0,1,.176.411l-.086.194-.251.075h-.082c-.579,0-3.462-.09-5.043-.112h-.4a1.482,1.482,0,0,0-.583.082.633.633,0,0,0-.318.523c-.1.625-1.121,5.234-1.121,5.234l-.127.265-.2.1"
              fill="#ebecec"
            />
            <path
              d="M1218.614,300.1a.483.483,0,0,1-.344-.213h0l-.269-.374-.68-.92c-.546-.748-1.234-1.633-1.794-2.318a8.43,8.43,0,0,0-.879-.972l-.108-.071h-.03a1.07,1.07,0,0,0-.31.083c-.146.052-.329.127-.535.22-.415.187-.931.438-1.458.707-1.058.534-2.161,1.121-2.6,1.364l-.194.109h0l-.412.209a1.175,1.175,0,0,1-.444.138l-.165-.06-.063-.2.041-.261c.205-.778.942-3.107,1.293-4.55a5.794,5.794,0,0,0,.194-1.054v-.041l-.2-.374a2.5,2.5,0,0,0-.587-.411,55.962,55.962,0,0,0-5.4-2.292,3.541,3.541,0,0,1-.711-.373h0a3.36,3.36,0,0,0,.7.373,56.05,56.05,0,0,1,5.406,2.292,2.586,2.586,0,0,1,.59.415.614.614,0,0,1,.206.374v.052a5.783,5.783,0,0,1-.194,1.054c-.352,1.443-1.088,3.765-1.29,4.546l-.041.262.063.2.165.06a1.193,1.193,0,0,0,.445-.143c.157-.074.31-.164.415-.209h0l.194-.1c.437-.239,1.536-.829,2.591-1.364.527-.265,1.042-.52,1.454-.7a5.275,5.275,0,0,1,.538-.22,1.112,1.112,0,0,1,.31-.083h.03l.109.071a8.523,8.523,0,0,1,.878.972c.561.684,1.249,1.589,1.791,2.318l.68.916.269.374h0a.47.47,0,0,0,.344.213l.206-.094.127-.265h0s1.017-4.609,1.121-5.2a.625.625,0,0,1,.318-.519,1.5,1.5,0,0,1,.583-.082h.4c1.585.022,4.464.1,5.032.112h.082l.251-.075.082-.194a.709.709,0,0,0-.176-.412h0l-.157-.153c-.348-.351-1.219-1.245-2.022-2.183a16.1,16.1,0,0,1-1.077-1.376,2.924,2.924,0,0,1-.52-1.058v-.052a1.072,1.072,0,0,1,.21-.542,22.759,22.759,0,0,1,2.187-2.564c.889-.946,1.82-1.911,2.4-2.569h0c-.583.662-1.521,1.63-2.411,2.58a22.633,22.633,0,0,0-2.179,2.56,1.071,1.071,0,0,0-.206.539v.056a2.972,2.972,0,0,0,.527,1.058,16.622,16.622,0,0,0,1.077,1.375c.8.939,1.671,1.832,2.015,2.184l.157.157h0a.714.714,0,0,1,.179.411l-.086.194-.254.075h-.078c-.576,0-3.455-.09-5.04-.112h-.4a1.444,1.444,0,0,0-.568.082.63.63,0,0,0-.318.527c-.1.609-1.121,5.207-1.121,5.207l-.127.266-.206.1"
              fill="#ededee"
            />
            <path
              d="M1218.611,300.078a.471.471,0,0,1-.344-.213h0l-.269-.374-.681-.916c-.546-.747-1.23-1.633-1.794-2.318a8.3,8.3,0,0,0-.875-.971l-.108-.071h-.03a1.1,1.1,0,0,0-.31.082c-.146.052-.329.127-.535.22-.415.187-.931.438-1.458.7-1.054.535-2.153,1.122-2.591,1.365l-.194.1c-.1.049-.258.139-.415.21a1.179,1.179,0,0,1-.445.142l-.164-.06-.064-.2.041-.266c.206-.777.939-3.1,1.294-4.545a5.809,5.809,0,0,0,.194-1.055v-.048a.608.608,0,0,0-.205-.374,2.614,2.614,0,0,0-.591-.415,55.809,55.809,0,0,0-5.409-2.288,3.364,3.364,0,0,1-.7-.374h0a3.426,3.426,0,0,0,.7.374,54.835,54.835,0,0,1,5.406,2.288c.538.292.788.557.807.77v.056a5.841,5.841,0,0,1-.194,1.058c-.352,1.443-1.084,3.761-1.29,4.538l-.041.262.064.2.16.06a1.162,1.162,0,0,0,.445-.142c.157-.075.31-.165.415-.213l.194-.1c.434-.235,1.533-.83,2.584-1.361.527-.265,1.039-.519,1.454-.7a5.117,5.117,0,0,1,.534-.221,1.115,1.115,0,0,1,.311-.082h.029l.109.071a8.3,8.3,0,0,1,.878.968c.561.688,1.249,1.589,1.791,2.314l.68.916.27.374h0a.459.459,0,0,0,.343.213l.2-.094.127-.265s1.017-4.613,1.122-5.2a.619.619,0,0,1,.321-.52,1.487,1.487,0,0,1,.569-.082h.4c1.585,0,4.463.1,5.024.112h.082l.25-.075.083-.194a.719.719,0,0,0-.176-.415h0l-.157-.157c-.348-.351-1.222-1.245-2.026-2.183a16.407,16.407,0,0,1-1.077-1.376,2.874,2.874,0,0,1-.519-1.058V288.5a1.088,1.088,0,0,1,.213-.549,22.659,22.659,0,0,1,2.194-2.568c.886-.942,1.813-1.9,2.4-2.557h0c-.587.661-1.518,1.622-2.4,2.568a22.933,22.933,0,0,0-2.187,2.564,1.1,1.1,0,0,0-.21.542v.053a2.965,2.965,0,0,0,.524,1.058,16.406,16.406,0,0,0,1.076,1.375c.8.939,1.675,1.832,2.023,2.183l.157.154h0a.745.745,0,0,1,.175.411l-.082.194-.25.075h-.083c-.568,0-3.446-.093-5.031-.112h-.4a1.482,1.482,0,0,0-.583.082.625.625,0,0,0-.318.524c-.1.6-1.122,5.2-1.122,5.2h0l-.123.266-.206.093"
              fill="#edeeef"
            />
            <path
              d="M1218.607,300.063a.465.465,0,0,1-.344-.213l-.269-.374-.681-.916c-.545-.725-1.229-1.63-1.79-2.314a8.658,8.658,0,0,0-.879-.968l-.108-.071h-.03a1.11,1.11,0,0,0-.31.082c-.146.053-.329.127-.535.221-.415.183-.931.437-1.454.7-1.05.534-2.146,1.121-2.583,1.36l-.194.105h0c-.105.049-.258.138-.412.213a1.145,1.145,0,0,1-.444.142l-.161-.06-.06-.2.041-.261c.206-.782.938-3.1,1.29-4.539a5.663,5.663,0,0,0,.194-1.058v-.052c-.019-.213-.269-.478-.807-.77a54.127,54.127,0,0,0-5.413-2.3,3.4,3.4,0,0,1-.7-.374h0a3.464,3.464,0,0,0,.692.352,53.565,53.565,0,0,1,5.413,2.288,2.84,2.84,0,0,1,.609.415.617.617,0,0,1,.213.351v.06a5.783,5.783,0,0,1-.19,1.061c-.352,1.443-1.084,3.739-1.286,4.539l-.041.261.059.2.154.06h0a1.136,1.136,0,0,0,.445-.143c.157-.078.306-.164.411-.216h0l.194-.1c.434-.236,1.525-.826,2.576-1.357.523-.265,1.039-.516,1.45-.7a5.325,5.325,0,0,1,.535-.22,1.1,1.1,0,0,1,.31-.079h.03a1.039,1.039,0,0,1,.284.236c.467.474,1.327,1.577,2.071,2.56.374.49.714.95.961,1.29l.295.4.109.146h0a.464.464,0,0,0,.34.213l.2-.1.127-.265s1.016-4.62,1.121-5.192a.614.614,0,0,1,.322-.52,1.5,1.5,0,0,1,.579-.082h.4c1.585.022,4.463.1,5.02.116h.083l.25-.075.082-.194a.715.715,0,0,0-.175-.412h0l-.157-.157c-.348-.351-1.226-1.245-2.034-2.183a16.18,16.18,0,0,1-1.076-1.375,2.825,2.825,0,0,1-.516-1.055v-.041a1.118,1.118,0,0,1,.216-.557,23.056,23.056,0,0,1,2.2-2.572c.882-.942,1.806-1.887,2.389-2.549h0c-.583.662-1.495,1.615-2.4,2.557a23.151,23.151,0,0,0-2.195,2.568,1.132,1.132,0,0,0-.213.549v.045a2.892,2.892,0,0,0,.52,1.058,16.075,16.075,0,0,0,1.077,1.376c.8.938,1.678,1.832,2.026,2.183l.157.157h0a.729.729,0,0,1,.179.415l-.082.194-.254.075h-.079c-.561,0-3.443-.093-5.024-.112h-.4a1.421,1.421,0,0,0-.564.082.617.617,0,0,0-.322.52c-.093.587-1.121,5.2-1.121,5.2l-.127.265-.2.094"
              fill="#eff0f0"
            />
            <path
              d="M1218.6,300.044a.461.461,0,0,1-.34-.213l-.108-.149-.3-.4c-.25-.34-.59-.8-.96-1.29-.748-.983-1.6-2.086-2.071-2.56a1.044,1.044,0,0,0-.285-.236h-.029a1.112,1.112,0,0,0-.311.079c-.146.052-.329.127-.534.22-.412.183-.927.434-1.451.7-1.046.531-2.142,1.122-2.575,1.361l-.195.105h0c-.1.052-.258.138-.411.216a1.129,1.129,0,0,1-.445.142h0l-.153-.059-.06-.195.041-.261c.2-.782.935-3.1,1.286-4.539a5.713,5.713,0,0,0,.191-1.061v-.06a.616.616,0,0,0-.213-.351,2.868,2.868,0,0,0-.61-.415,53.727,53.727,0,0,0-5.42-2.281,3.407,3.407,0,0,1-.692-.351h0a3.369,3.369,0,0,0,.685.348,54.311,54.311,0,0,1,5.412,2.284,3.018,3.018,0,0,1,.617.415.606.606,0,0,1,.217.351v.06a5.784,5.784,0,0,1-.191,1.061c-.351,1.443-1.08,3.739-1.282,4.535l-.041.262.06.194.157.06a1.127,1.127,0,0,0,.441-.146c.157-.079.31-.168.415-.217h0l.194-.105c.434-.235,1.522-.826,2.569-1.353.523-.265,1.035-.516,1.446-.7a5.751,5.751,0,0,1,.535-.22,1.188,1.188,0,0,1,.31-.079h.03a1.025,1.025,0,0,1,.284.236c.467.475,1.327,1.574,2.067,2.557.374.49.711.949.961,1.289l.295.4.112.15a.467.467,0,0,0,.341.213l.2-.094.127-.265s1.02-4.624,1.121-5.189a.614.614,0,0,1,.322-.516,1.481,1.481,0,0,1,.579-.086h.4c1.581.023,4.463.1,5.013.113h.082l.251-.075.082-.191a.708.708,0,0,0-.18-.415h0l-.157-.157c-.351-.351-1.233-1.248-2.037-2.183a15.9,15.9,0,0,1-1.077-1.376,2.786,2.786,0,0,1-.508-1.057v-.034a1.172,1.172,0,0,1,.224-.561,23.162,23.162,0,0,1,2.21-2.575c.878-.935,1.8-1.87,2.385-2.535h0c-.583.662-1.5,1.607-2.389,2.549a23.149,23.149,0,0,0-2.2,2.572,1.134,1.134,0,0,0-.217.557v.041a2.844,2.844,0,0,0,.516,1.058,16.052,16.052,0,0,0,1.077,1.376c.8.938,1.682,1.832,2.033,2.183l.157.157h0a.716.716,0,0,1,.176.411l-.082.2-.251.075h-.082c-.557,0-3.435-.094-5.02-.116h-.4a1.5,1.5,0,0,0-.58.082.618.618,0,0,0-.321.52c-.094.575-1.122,5.188-1.122,5.188l-.127.266-.2.1"
              fill="#f0f0f1"
            />
            <path
              d="M1218.6,300.029a.458.458,0,0,1-.34-.213h0l-.108-.149-.3-.4c-.25-.34-.59-.8-.96-1.29-.748-.983-1.6-2.082-2.068-2.557a.993.993,0,0,0-.284-.235h-.03a1.185,1.185,0,0,0-.31.078c-.146.053-.329.127-.535.221-.411.183-.927.434-1.446.7-1.047.527-2.135,1.121-2.568,1.353l-.195.1h0c-.1.048-.258.138-.415.217a1.127,1.127,0,0,1-.441.142l-.157-.06-.06-.194.041-.262c.2-.785.931-3.092,1.283-4.535a5.709,5.709,0,0,0,.194-1.061v-.06a.61.61,0,0,0-.221-.348,2.986,2.986,0,0,0-.616-.415,54.372,54.372,0,0,0-5.424-2.269,3.366,3.366,0,0,1-.685-.347h0a3.366,3.366,0,0,0,.677.344,54.268,54.268,0,0,1,5.417,2.284,3.2,3.2,0,0,1,.62.418.617.617,0,0,1,.225.344v.067a5.879,5.879,0,0,1-.191,1.062c-.351,1.439-1.084,3.738-1.282,4.531l-.041.262.059.194.157.06a1.137,1.137,0,0,0,.442-.146c.157-.079.31-.168.411-.217h0l.194-.105c.43-.235,1.518-.826,2.561-1.353.519-.265,1.032-.516,1.443-.7a4.935,4.935,0,0,1,.534-.217,1.117,1.117,0,0,1,.311-.082h.029a1,1,0,0,1,.288.236c.468.474,1.327,1.573,2.068,2.553.373.49.71.949.957,1.286l.3.4.108.146h0a.463.463,0,0,0,.34.21h0l.2-.094.127-.265s1.016-4.632,1.121-5.178a.607.607,0,0,1,.325-.516,1.466,1.466,0,0,1,.576-.086h.4c1.585.023,4.463.105,5.006.116h.082l.25-.074.082-.191a.744.744,0,0,0-.175-.411h0l-.157-.157c-.352-.352-1.238-1.249-2.045-2.187a16.249,16.249,0,0,1-1.077-1.376,2.721,2.721,0,0,1-.5-1.058v-.03a1.171,1.171,0,0,1,.231-.568,23.414,23.414,0,0,1,2.213-2.579c.879-.931,1.791-1.869,2.374-2.524h0c-.583.658-1.5,1.6-2.385,2.535a23.48,23.48,0,0,0-2.209,2.576,1.161,1.161,0,0,0-.224.56v.034a2.776,2.776,0,0,0,.508,1.058,15.861,15.861,0,0,0,1.077,1.376c.807.938,1.686,1.831,2.037,2.186l.157.157h0a.721.721,0,0,1,.176.415l-.083.2-.25.071h-.082c-.55,0-3.428-.094-5.013-.112h-.4a1.453,1.453,0,0,0-.58.086.613.613,0,0,0-.321.516c-.09.56-1.122,5.184-1.122,5.184l-.127.266-.2.093"
              fill="#f2f2f3"
            />
            <path
              d="M1218.6,300.007h0a.465.465,0,0,1-.34-.209h0l-.108-.15-.3-.4c-.246-.336-.59-.8-.957-1.286-.747-.979-1.6-2.074-2.067-2.553a1.021,1.021,0,0,0-.288-.235h-.03a1.109,1.109,0,0,0-.31.082c-.146.052-.329.127-.535.22-.411.184-.923.434-1.446.7-1.043.527-2.131,1.121-2.561,1.353l-.194.1h0c-.1.049-.258.142-.412.217a1.13,1.13,0,0,1-.444.146l-.157-.06-.06-.194.041-.262c.2-.785.931-3.091,1.282-4.531a5.75,5.75,0,0,0,.2-1.057v-.072a.623.623,0,0,0-.225-.343,3.2,3.2,0,0,0-.624-.419,54.423,54.423,0,0,0-5.417-2.284,3.29,3.29,0,0,1-.68-.344h0a3.543,3.543,0,0,0,.673.34,53.413,53.413,0,0,1,5.42,2.269,3.256,3.256,0,0,1,.632.419.623.623,0,0,1,.228.336v.075a5.557,5.557,0,0,1-.191,1.062c-.351,1.439-1.076,3.738-1.278,4.53l-.041.258.06.191.153.06a1.172,1.172,0,0,0,.441-.146c.157-.078.31-.172.415-.22h0l.194-.105c.43-.236,1.5-.823,2.553-1.353.52-.262,1.032-.513,1.443-.7.206-.089.374-.164.535-.217a1.146,1.146,0,0,1,.31-.082h.034a1.039,1.039,0,0,1,.284.236c.467.475,1.323,1.574,2.063,2.553.374.486.711.949.957,1.282l.3.4.1.146h0a.466.466,0,0,0,.341.209h0l.194-.093.123-.266s1.021-4.635,1.122-5.17a.6.6,0,0,1,.325-.516,1.465,1.465,0,0,1,.576-.086h.4c1.581.023,4.463.1,5,.116h.083l.246-.071.086-.194a.741.741,0,0,0-.179-.415h0l-.157-.157c-.355-.355-1.241-1.252-2.049-2.191a15.706,15.706,0,0,1-1.076-1.375,2.663,2.663,0,0,1-.5-1.058v-.023a1.186,1.186,0,0,1,.235-.575,23.954,23.954,0,0,1,2.221-2.584c.874-.927,1.787-1.869,2.37-2.512h0c-.583.655-1.5,1.593-2.374,2.524a23.565,23.565,0,0,0-2.217,2.579,1.184,1.184,0,0,0-.232.568v.03a2.719,2.719,0,0,0,.505,1.058,16.052,16.052,0,0,0,1.077,1.376c.807.938,1.689,1.835,2.044,2.187l.157.157h0a.709.709,0,0,1,.18.411l-.086.194-.251.075h-.082c-.546,0-3.424-.093-5-.116h-.4a1.469,1.469,0,0,0-.58.086.608.608,0,0,0-.325.516c-.086.549-1.121,5.177-1.121,5.177l-.127.266-.2.093"
              fill="#f3f3f4"
            />
            <path
              d="M1218.6,300.007h0a.465.465,0,0,1-.34-.209h0l-.108-.146-.292-.4c-.25-.336-.587-.8-.957-1.282-.747-.98-1.592-2.075-2.063-2.553a1.006,1.006,0,0,0-.284-.236h-.03a1.21,1.21,0,0,0-.314.082c-.146.053-.329.127-.535.217-.411.183-.919.434-1.443.7-1.039.53-2.123,1.121-2.553,1.353l-.191.1h0c-.1.049-.258.142-.415.221a1.161,1.161,0,0,1-.441.146l-.153-.06-.06-.194.041-.258c.2-.785.927-3.092,1.279-4.531a5.672,5.672,0,0,0,.191-1.058v-.075a.635.635,0,0,0-.232-.336,3.247,3.247,0,0,0-.636-.449,53.637,53.637,0,0,0-5.42-2.284,3.4,3.4,0,0,1-.673-.34h0a3.223,3.223,0,0,0,.662.336,53.336,53.336,0,0,1,5.424,2.281,3.406,3.406,0,0,1,.639.418.639.639,0,0,1,.235.333v.079a5.766,5.766,0,0,1-.19,1.058c-.352,1.439-1.077,3.738-1.275,4.526l-.041.258.056.191.153.06a1.111,1.111,0,0,0,.441-.146c.157-.078.311-.168.415-.22l.195-.1c.43-.236,1.495-.819,2.549-1.346.52-.265,1.024-.512,1.439-.7.206-.09.374-.165.535-.217a1.11,1.11,0,0,1,.31-.082h.03a1.021,1.021,0,0,1,.288.235c.471.479,1.323,1.574,2.06,2.55.374.489.706.946.953,1.282l.292.4.108.146h0a.456.456,0,0,0,.336.209l.2-.093.124-.262s.254-1.159.519-2.385.539-2.52.58-2.781a.588.588,0,0,1,.329-.512,1.373,1.373,0,0,1,.56-.086h.4c1.582.022,4.464.1,4.995.119h.078l.251-.075.082-.19a.71.71,0,0,0-.18-.411h0l-.157-.157c-.373-.352-1.244-1.249-2.056-2.187a15.369,15.369,0,0,1-1.076-1.376,2.586,2.586,0,0,1-.494-1.058v-.022a1.223,1.223,0,0,1,.24-.58,23.676,23.676,0,0,1,2.243-2.587c.871-.923,1.779-1.85,2.358-2.5h0c-.583.65-1.495,1.585-2.366,2.508a23.375,23.375,0,0,0-2.22,2.587,1.192,1.192,0,0,0-.236.572v.026a2.677,2.677,0,0,0,.5,1.058,15.888,15.888,0,0,0,1.077,1.375c.807.939,1.693,1.836,2.048,2.191l.161.157h0a.729.729,0,0,1,.18.415l-.083.191-.25.071h-.082c-.539,0-3.421-.1-5-.116h-.4a1.465,1.465,0,0,0-.576.086.6.6,0,0,0-.325.516c-.082.534-1.1,5.17-1.1,5.17l-.127.265-.194.093"
              fill="#f4f5f5"
            />
            <path
              d="M1218.6,299.977a.456.456,0,0,1-.34-.209h0l-.109-.146-.291-.4c-.247-.337-.587-.793-.954-1.282-.747-.976-1.592-2.075-2.059-2.55a1.038,1.038,0,0,0-.288-.235h-.03a1.2,1.2,0,0,0-.311.082c-.145.052-.328.127-.53.217-.412.183-.92.433-1.44.695-1.039.527-2.119,1.122-2.545,1.346l-.195.1h0c-.1.053-.258.143-.415.225a1.127,1.127,0,0,1-.441.146l-.153-.06-.056-.191.041-.258c.2-.785.927-3.088,1.275-4.527a5.581,5.581,0,0,0,.19-1.058v-.093a.643.643,0,0,0-.235-.333,3.522,3.522,0,0,0-.639-.419,53.79,53.79,0,0,0-5.424-2.28,3.251,3.251,0,0,1-.666-.336h0a3.252,3.252,0,0,0,.658.332,52.579,52.579,0,0,1,5.42,2.288,3.516,3.516,0,0,1,.647.423.663.663,0,0,1,.239.329v.082a5.563,5.563,0,0,1-.172,1.035c-.347,1.44-1.072,3.739-1.274,4.524l-.038.261.056.191.154.06a1.1,1.1,0,0,0,.441-.15c.157-.078.31-.172.415-.22h0l.19-.105c.427-.236,1.5-.819,2.542-1.346.516-.261,1.025-.512,1.436-.695.205-.09.374-.165.534-.217a1.154,1.154,0,0,1,.311-.078h.03a1.019,1.019,0,0,1,.287.235,32.028,32.028,0,0,1,2.06,2.546c.374.486.707.942.953,1.282l.292.4.108.15h0a.467.467,0,0,0,.337.209l.2-.094.123-.261s.258-1.163.52-2.389.538-2.516.579-2.774a.592.592,0,0,1,.329-.508,1.448,1.448,0,0,1,.576-.086h.4c1.581.022,4.46.1,4.991.119h.078l.251-.071.082-.19a.72.72,0,0,0-.18-.415h0l-.157-.157c-.374-.352-1.248-1.252-2.059-2.191a14.935,14.935,0,0,1-1.077-1.379,2.556,2.556,0,0,1-.49-1.054v-.019a1.21,1.21,0,0,1,.247-.579,23.39,23.39,0,0,1,2.243-2.6c.871-.919,1.772-1.835,2.355-2.489h0c-.583.654-1.495,1.577-2.359,2.5a23.686,23.686,0,0,0-2.243,2.587,1.222,1.222,0,0,0-.243.576v.022a2.638,2.638,0,0,0,.5,1.055,15.051,15.051,0,0,0,1.077,1.375c.811.939,1.7,1.836,2.056,2.187l.157.157h0a.713.713,0,0,1,.179.415l-.082.191-.25.075h-.079c-.534,0-3.413-.1-4.994-.12h-.4a1.393,1.393,0,0,0-.561.086.591.591,0,0,0-.325.512c-.041.262-.314,1.551-.579,2.781s-.52,2.385-.52,2.385l-.123.262-.2.093"
              fill="#f5f6f6"
            />
            <path
              d="M1218.592,299.962a.464.464,0,0,1-.336-.206h0l-.109-.149-.291-.4c-.247-.336-.587-.793-.954-1.282a32.644,32.644,0,0,0-2.059-2.546,1.086,1.086,0,0,0-.288-.235h-.03a1.154,1.154,0,0,0-.311.078c-.145.052-.328.127-.534.217-.411.183-.92.433-1.436.7-1.035.527-2.112,1.122-2.542,1.346l-.19.1h0c-.1.048-.258.142-.415.224a1.085,1.085,0,0,1-.441.149l-.153-.059-.057-.187.038-.262c.2-.807.916-3.092,1.289-4.546a5.631,5.631,0,0,0,.2-1.061v-.083a.679.679,0,0,0-.239-.329,3.688,3.688,0,0,0-.647-.422,53.06,53.06,0,0,0-5.428-2.277,3.383,3.383,0,0,1-.658-.332h0a3.546,3.546,0,0,0,.654.329,52.432,52.432,0,0,1,5.409,2.306,3.954,3.954,0,0,1,.655.423.647.647,0,0,1,.243.325v.086a5.59,5.59,0,0,1-.184,1.035c-.347,1.44-1.072,3.739-1.271,4.524l-.041.257.056.191.15.056a1.1,1.1,0,0,0,.441-.153c.157-.079.31-.176.415-.224h0l.191-.1c.426-.236,1.495-.819,2.53-1.346.516-.261,1.025-.508,1.432-.691a5.493,5.493,0,0,1,.531-.221,1.186,1.186,0,0,1,.31-.078h.034a.975.975,0,0,1,.288.235,32.552,32.552,0,0,1,2.056,2.542c.374.486.7.942.949,1.279l.292.4.108.145h0a.454.454,0,0,0,.337.21l.2-.094.123-.261h0s.254-1.163.52-2.389.538-2.512.576-2.766a.586.586,0,0,1,.328-.505,1.433,1.433,0,0,1,.572-.09h.4c1.582.023,4.46.1,4.983.12h.079l.25-.071.083-.187a.723.723,0,0,0-.18-.415h0l-.161-.157c-.373-.355-1.252-1.252-2.067-2.194a15.252,15.252,0,0,1-1.076-1.38,2.525,2.525,0,0,1-.486-1.054h0a1.266,1.266,0,0,1,.25-.587,23.911,23.911,0,0,1,2.243-2.6c.867-.912,1.765-1.828,2.344-2.475h0c-.583.651-1.5,1.57-2.355,2.49a23.457,23.457,0,0,0-2.243,2.594,1.213,1.213,0,0,0-.247.58h0a2.567,2.567,0,0,0,.516,1.091,15.258,15.258,0,0,0,1.077,1.38c.811.942,1.7,1.839,2.059,2.19l.157.157h0a.724.724,0,0,1,.18.415l-.082.191-.251.071h-.078c-.527,0-3.406-.1-4.991-.12h-.4a1.468,1.468,0,0,0-.576.086.59.59,0,0,0-.328.509c-.038.258-.311,1.547-.58,2.773s-.52,2.389-.52,2.389l-.123.262-.2.093"
              fill="#f7f7f8"
            />
            <path
              d="M1218.588,299.943a.452.452,0,0,1-.336-.2h0l-.109-.15-.291-.4c-.247-.336-.587-.792-.953-1.278-.748-.972-1.585-2.067-2.056-2.542a1.024,1.024,0,0,0-.288-.236h-.034a1.188,1.188,0,0,0-.31.079c-.146.052-.325.127-.531.22-.411.184-.916.43-1.432.692-1.032.527-2.108,1.121-2.531,1.346l-.19.1h0c-.1.049-.258.142-.415.225a1.085,1.085,0,0,1-.441.149l-.15-.056-.056-.187.041-.258c.2-.789.924-3.084,1.271-4.523a5.543,5.543,0,0,0,.191-1.062v-.086a.666.666,0,0,0-.243-.325,4.053,4.053,0,0,0-.654-.422,53.044,53.044,0,0,0-5.428-2.277,3.4,3.4,0,0,1-.651-.329h0a3.391,3.391,0,0,0,.643.322h0a53.549,53.549,0,0,1,5.432,2.272,4.248,4.248,0,0,1,.662.423.647.647,0,0,1,.246.318v.089a5.575,5.575,0,0,1-.2,1.058c-.347,1.44-1.069,3.739-1.267,4.52l-.041.258.056.187.146.056a1.087,1.087,0,0,0,.441-.154c.153-.082.31-.175.411-.224h0l.191-.105c.426-.235,1.495-.814,2.523-1.342.516-.261,1.021-.508,1.428-.691.206-.094.374-.165.531-.221a1.187,1.187,0,0,1,.31-.078h.034a1.125,1.125,0,0,1,.288.235,32.328,32.328,0,0,1,2.056,2.542c.374.486.7.942.949,1.279l.292.4.1.146h0a.456.456,0,0,0,.336.209l.2-.094.124-.261s.254-1.167.519-2.393.538-2.512.572-2.758a.583.583,0,0,1,.333-.505,1.449,1.449,0,0,1,.572-.09h.4c1.581.026,4.459.1,4.979.12h.079l.246-.071.083-.187a.722.722,0,0,0-.18-.411h0l-.161-.157c-.373-.352-1.259-1.253-2.071-2.2a14.527,14.527,0,0,1-1.08-1.379,2.438,2.438,0,0,1-.478-1.051h0a1.288,1.288,0,0,1,.257-.59,23.989,23.989,0,0,1,2.243-2.617c.86-.908,1.757-1.817,2.337-2.463h0c-.58.65-1.5,1.562-2.344,2.474a23.7,23.7,0,0,0-2.243,2.6,1.246,1.246,0,0,0-.25.587h0a2.5,2.5,0,0,0,.485,1.054,14.859,14.859,0,0,0,1.081,1.38c.811.942,1.708,1.839,2.067,2.194l.161.157h0a.716.716,0,0,1,.179.415l-.082.187-.247.071h-.082c-.52,0-3.4-.1-4.983-.12h-.4a1.468,1.468,0,0,0-.576.09.589.589,0,0,0-.329.505c-.037.25-.31,1.54-.575,2.766s-.52,2.389-.52,2.389h0l-.123.262-.2.093"
              fill="#f8f8f9"
            />
            <path
              d="M1218.588,299.928a.458.458,0,0,1-.336-.205h0l-.1-.15-.291-.4c-.247-.332-.584-.788-.95-1.278a32.143,32.143,0,0,0-2.056-2.542,1.056,1.056,0,0,0-.288-.236h-.034a1.19,1.19,0,0,0-.31.075c-.146.052-.325.127-.531.221-.407.183-.912.43-1.428.691-1.031.527-2.1,1.122-2.523,1.342l-.191.1h0c-.1.049-.257.142-.414.224a1.076,1.076,0,0,1-.442.153l-.145-.059-.056-.187.041-.258c.2-.793.919-3.08,1.267-4.52a5.754,5.754,0,0,0,.191-1.065v-.09a.664.664,0,0,0-.247-.318,4.194,4.194,0,0,0-.662-.422,53.3,53.3,0,0,0-5.431-2.273h0a3.206,3.206,0,0,1-.643-.325h0a3.368,3.368,0,0,0,.639.318,52.913,52.913,0,0,1,5.435,2.273,4.487,4.487,0,0,1,.673.426.668.668,0,0,1,.251.314v.093a5.755,5.755,0,0,1-.191,1.066c-.348,1.439-1.066,3.738-1.267,4.515l-.038.258.053.183.142.06h0a1.083,1.083,0,0,0,.437-.153c.157-.082.31-.176.415-.228h0l.187-.105c.422-.231,1.495-.815,2.519-1.338.516-.262,1.017-.512,1.428-.692.206-.089.374-.164.531-.216a1.16,1.16,0,0,1,.31-.079h.034a1.056,1.056,0,0,1,.288.236,32.434,32.434,0,0,1,2.052,2.538c.374.486.7.938.946,1.275l.292.4.108.145h0a.464.464,0,0,0,.336.21l.2-.094.123-.258h0s.258-1.166.52-2.392.538-2.512.572-2.751a.573.573,0,0,1,.332-.5,1.431,1.431,0,0,1,.572-.09h.4c1.582.026,4.46.1,4.969.12h.078l.25-.071.083-.187a.75.75,0,0,0-.18-.415h0l-.16-.157c-.374-.352-1.264-1.253-2.079-2.2a14.529,14.529,0,0,1-1.08-1.379,2.408,2.408,0,0,1-.475-1.05h0a1.286,1.286,0,0,1,.265-.6,24.084,24.084,0,0,1,2.243-2.617c.86-.9,1.75-1.805,2.333-2.448h0c-.579.647-1.477,1.555-2.336,2.463a23.8,23.8,0,0,0-2.243,2.617,1.279,1.279,0,0,0-.258.591h0a2.44,2.44,0,0,0,.478,1.05,14.512,14.512,0,0,0,1.081,1.38c.814.942,1.712,1.839,2.071,2.194l.16.157h0a.728.728,0,0,1,.18.411l-.083.187-.246.071h-.079c-.516,0-3.394-.093-4.979-.12h-.4a1.449,1.449,0,0,0-.572.09.581.581,0,0,0-.333.505c-.033.247-.306,1.529-.572,2.759s-.519,2.392-.519,2.392l-.124.262-.2.093"
              fill="#fafafb"
            />
            <path
              d="M1218.584,299.91a.46.46,0,0,1-.332-.206h0l-.1-.146-.291-.4c-.247-.332-.58-.788-.946-1.274-.748-.972-1.582-2.064-2.053-2.539a1.033,1.033,0,0,0-.287-.235h-.034a1.107,1.107,0,0,0-.31.075c-.146.052-.329.127-.531.216-.408.184-.912.43-1.428.692-1.028.523-2.094,1.121-2.52,1.338l-.187.105h0c-.1.052-.258.146-.415.228a1.083,1.083,0,0,1-.437.153h0l-.142-.06-.052-.183.037-.258c.2-.792.92-3.076,1.267-4.515a5.755,5.755,0,0,0,.191-1.066v-.093a.674.674,0,0,0-.25-.314,4.34,4.34,0,0,0-.67-.426,52.832,52.832,0,0,0-5.435-2.273,3.2,3.2,0,0,1-.635-.318h0a3.1,3.1,0,0,0,.628.314c2.213.83,3.775,1.469,4.8,1.955a8.834,8.834,0,0,1,1.152.624c.257.176.392.322.422.43v.1a5.77,5.77,0,0,1-.191,1.066c-.347,1.435-1.065,3.738-1.263,4.515l-.038.258.053.183.146.056a1.068,1.068,0,0,0,.437-.153c.157-.082.31-.175.415-.228h0l.187-.1c.422-.236,1.495-.815,2.512-1.335.512-.265,1.013-.508,1.424-.692.206-.089.374-.164.527-.216a1.178,1.178,0,0,1,.314-.079h.034a1.051,1.051,0,0,1,.291.236,31.93,31.93,0,0,1,2.049,2.534c.374.486.7.938.946,1.275l.291.4.109.15h0a.456.456,0,0,0,.332.205h0l.195-.093.123-.258.52-2.4c.265-1.226.534-2.509.568-2.744a.586.586,0,0,1,.273-.471,1.242,1.242,0,0,1,.62-.12h.4c.867,0,2.13.045,3.17.071l1.327.038h.542l.247-.071.082-.187a.743.743,0,0,0-.18-.415h0l-.164-.157c-.374-.352-1.264-1.253-2.082-2.2a15.094,15.094,0,0,1-1.081-1.364,2.377,2.377,0,0,1-.471-1.054h0a.968.968,0,0,1,.165-.426,20.127,20.127,0,0,1,2.332-2.741c.868-.912,1.769-1.828,2.359-2.478h0c-.579.643-1.469,1.548-2.329,2.449a24.193,24.193,0,0,0-2.243,2.616,1.31,1.31,0,0,0-.265.6h0a2.412,2.412,0,0,0,.478,1.05,14.675,14.675,0,0,0,1.077,1.383c.815.942,1.716,1.843,2.079,2.2l.16.157h0a.75.75,0,0,1,.18.415l-.083.186-.25.071h-.079c-.508,0-3.39-.093-4.968-.119h-.4a1.416,1.416,0,0,0-.572.09.574.574,0,0,0-.332.5c-.034.24-.307,1.526-.568,2.752s-.52,2.392-.52,2.392h0l-.123.258-.2.094"
              fill="#fcfcfc"
            />
            <path d="M1209.549,297.495" fill="#fff" />
          </g>
        </g>
      </g>
      <path
        d="M1218.689,276.082l-4.254,5.234-6.075-2.946a.325.325,0,0,0-.5.4l1.432,6.583-6.079,2.924c-.179.086-.28.2-.28.317s.1.236.276.322l6.03,2.99-1.495,6.587a.325.325,0,0,0,.5.4l6.075-2.875,4.228,5.286c.123.157.254.228.373.2s.206-.146.255-.34l1.529-6.553h6.777c.2,0,.34-.056.374-.165a.5.5,0,0,0-.109-.411l-4.175-5.293,4.239-5.29a.405.405,0,0,0,.116-.407.39.39,0,0,0-.374-.169h-1.716l-.318.139-6.355,6.758-.145.06.029-.146c.128-.16,3.642-4.429,5.413-6.575l.023-.153-.15-.094h-2.243a.7.7,0,0,0-.467.236c-1.032,1.1-6.168,6.564-6.276,6.687l-.146.06.03-.146,5.364-6.519c.127-.153.2-.258.176-.393l-1.454-6.575c-.045-.194-.131-.318-.247-.344s-.251.041-.374.2"
        fill="#df271c"
      />
    </g>
  </IconSvg>
);
