import { IconProps, IconSvg } from '../icons/icon-props';

export const LogoUBank = (props: IconProps) => (
  <IconSvg
    height="16.641px"
    viewBox="0 0 69.039 16.641"
    width="69.039px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-1085.071 -278.597)">
      <path
        d="M1117.507,289.242c0,3.4-2.27,5.952-5.537,5.952a4.566,4.566,0,0,1-4.192-2.316l-.046,1.714c0,.209-.092.324-.3.324h-1.344a.292.292,0,0,1-.324-.324V278.921a.3.3,0,0,1,.326-.324h1.459a.292.292,0,0,1,.324.324v6.544a4.594,4.594,0,0,1,4.1-2.177c3.267,0,5.537,2.57,5.537,5.952Zm-2.038,0a3.791,3.791,0,0,0-3.868-4.03,4.039,4.039,0,0,0,0,8.06A3.791,3.791,0,0,0,1115.469,289.24Z"
        fill="#190079"
      />
      <path
        d="M1130.454,283.892v10.7a.292.292,0,0,1-.324.324h-1.343c-.209,0-.3-.115-.3-.324l-.046-1.714a4.566,4.566,0,0,1-4.193,2.316c-3.267,0-5.536-2.547-5.536-5.952s2.269-5.952,5.536-5.952a4.583,4.583,0,0,1,4.193,2.338l.046-1.736c0-.209.092-.324.3-.324h1.343A.292.292,0,0,1,1130.454,283.892Zm-2.06,5.373a3.74,3.74,0,0,0-3.775-4.053,4.033,4.033,0,0,0,0,8.059A3.687,3.687,0,0,0,1128.394,289.265Z"
        fill="#190079"
      />
      <path
        d="M1142.439,287.921v6.669a.292.292,0,0,1-.323.324h-1.459c-.209,0-.3-.115-.3-.324v-6.28a2.773,2.773,0,0,0-2.839-3.1,3,3,0,0,0-3.142,3.1v6.282c0,.209-.092.324-.3.324h-1.459a.292.292,0,0,1-.324-.324v-10.7a.292.292,0,0,1,.324-.324h1.367c.232,0,.324.092.324.347l.069,1.435a4.153,4.153,0,0,1,3.767-2.06C1140.6,283.29,1142.439,284.934,1142.439,287.921Z"
        fill="#190079"
      />
      <path
        d="M1153.823,294.916h-1.553a.445.445,0,0,1-.418-.209l-3.8-4.909-1.8,1.992v2.8a.292.292,0,0,1-.324.324h-1.459a.292.292,0,0,1-.324-.324V278.921a.292.292,0,0,1,.324-.324h1.459a.292.292,0,0,1,.324.324v10.273l4.886-5.442a.463.463,0,0,1,.393-.186h1.76c.255,0,.3.186.14.37l-4.076,4.47,4.678,6.115c.163.209.069.393-.209.393"
        fill="#190079"
      />
      <path
        d="M1103.934,283.813v6.177a5.373,5.373,0,0,1-10.744,0v-6.177a.217.217,0,0,1,.233-.242h1.645a.219.219,0,0,1,.238.242v5.91a3.272,3.272,0,1,0,6.51-.094v-5.816a.217.217,0,0,1,.234-.242h1.645a.219.219,0,0,1,.237.242"
        fill="#190079"
      />
      <path
        d="M1087.532,288.222a2.462,2.462,0,1,1,2.462-2.461A2.464,2.464,0,0,1,1087.532,288.222Z"
        fill="#00f7af"
      />
      <path
        d="M1087.532,295.238a2.462,2.462,0,1,1,2.462-2.461A2.464,2.464,0,0,1,1087.532,295.238Z"
        fill="#00f7af"
      />
    </g>
  </IconSvg>
);
