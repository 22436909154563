import styled from 'styled-components';
import { DesignPatternLast } from '../../assets/icons';
import { fontFamily } from '../../constants/font-family';
import { fontSize } from '../../constants/font-size';
import { ButtonTransparent, Flex, Link, Text } from '../primitives';
//@ts-ignore
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NM56K6W',
  events: {
    contactFormCompleted: 'true'
}
}

TagManager.initialize(tagManagerArgs)

const PatternCotainer = styled(Flex)`
  justify-content: center;
  margin-top: 60px;
  width: 100%;
`;

const StepDoneContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;
`;

const SubTitle = styled(Text)`
  font-size: ${fontSize.xs};
  letter-spacing: 0.54px;
  margin: 24px 0 42px;
  text-align: center;
`;

const Title = styled(Text)`
  font-family: ${fontFamily.desolator};
  font-size: ${fontSize.l};
  text-align: center;
`;

Title.defaultProps = {
  children: 'Done!'
};

const StepDone = () => (
  <StepDoneContainer>
    <Title />
    <SubTitle>
      One of our home loan experts will contact you. We’ll help you find the best home loan.
      <br />
      <br />
      Check out the latest home loan news
    </SubTitle>
    <Link href="https://lendingloop.com.au/blog/">
      <ButtonTransparent>Latest News</ButtonTransparent>
    </Link>
    <PatternCotainer>
      <DesignPatternLast />
    </PatternCotainer>
  </StepDoneContainer>
);

export { StepDone };
