import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Footer, Header, LendersBar, ProgressBar } from './components';
import { Box, Flex } from './components/primitives';
import {
  StepAnnualIncome,
  StepBorrowAmount,
  StepContactForm,
  StepDone,
  StepEmploymentStatus,
  StepGetStarted,
  StepTypeOfProperty
} from './components/steps';
import { color } from './constants/color';
import { TypesOfStepEnum } from './enums/type-of-step';
import { ContactFormModel } from './types/contact-form';
import { FlowStepModel } from './types/flow-step';
import { TypeOfPropertyModel } from './types/type-of-property';
import { TypeOfStepModel } from './types/type-of-step';
//@ts-ignore
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NM56K6W'
}

TagManager.initialize(tagManagerArgs)

const Main = styled(Box)`
  background: ${color.purpleLoop};
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface StepContainerProps {
  isActiveStepDone: boolean;
}

const StepContainer = styled(Flex)<StepContainerProps>`
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: ${({ isActiveStepDone }) => (isActiveStepDone ? 76 : 112)}px;
  padding-top: ${({ isActiveStepDone }) => (isActiveStepDone ? 42 : 72)}px;
  width: 100%;
`;

const StepOuterContainer = styled(Box)<StepContainerProps>`
  background: ${({ isActiveStepDone }) => (isActiveStepDone ? color.greyLoop : color.whiteLoop)};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface AppState {
  activeStep: TypeOfStepModel;
  annualIncome: number;
  borrowAmount: number;
  employmentStatus: string;
  flowStep: FlowStepModel;
  progress: number;
  typeOfProperty: TypeOfPropertyModel;
}

const initialAppState: AppState = {
  activeStep: TypesOfStepEnum.getStarted,
  annualIncome: 0,
  borrowAmount: 0,
  employmentStatus: '',
  flowStep: null,
  progress: 0,
  typeOfProperty: null
};

const STORAGE_KEY = 'leadFlowState';
const STORAGE_EXPIRATION_KEY = 'leadFlowStateExpiration';

const saveState = (state: AppState) => {
  const expirationTime = new Date().getTime() + 2 * 60 * 60 * 1000; // 2 hours from now
  localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  localStorage.setItem(STORAGE_EXPIRATION_KEY, expirationTime.toString());
};

const loadState = (): AppState | null => {
  const savedState = localStorage.getItem(STORAGE_KEY);
  const expirationTime = localStorage.getItem(STORAGE_EXPIRATION_KEY);

  if (savedState && expirationTime) {
    const now = new Date().getTime();
    if (now < parseInt(expirationTime, 10)) {
      return JSON.parse(savedState);
    } else {
      localStorage.removeItem(STORAGE_KEY);
      localStorage.removeItem(STORAGE_EXPIRATION_KEY);
    }
  }
  return null;
};

const getStepFromUrl = (): TypeOfStepModel => {
  const path = window.location.pathname;
  switch (path) {
    case '/type-of-property':
      return TypesOfStepEnum.typeOfProperty;
    case '/borrow-amount':
      return TypesOfStepEnum.borrowAmount;
    case '/employment-status':
      return TypesOfStepEnum.employmentStatus;
    case '/annual-income':
      return TypesOfStepEnum.annualIncome;
    case '/contact-form':
      return TypesOfStepEnum.contactForm;
    case '/thankyou':
      return TypesOfStepEnum.done;
    default:
      return TypesOfStepEnum.getStarted;
  }
};

const App = () => {
  const [state, setState] = useState<AppState>(() => {
    const savedState = loadState();
    if (savedState) {
      const urlStep = getStepFromUrl();
      return { ...savedState, activeStep: urlStep };
    }
    return initialAppState;
  });

  const stateSetter = (updates: Partial<AppState>) => {
    setState((previousState) => {
      const newState = { ...previousState, ...updates };
      saveState(newState);
      return newState;
    });
  };

  const { activeStep, progress, ...restState } = state;

  const { annualIncome, borrowAmount, employmentStatus, typeOfProperty } = restState;

  const handleOnActiveStepChange = (nextStep: TypeOfStepModel) => {
    stateSetter({ activeStep: nextStep });
    let path = '/';
    switch (nextStep) {
      case TypesOfStepEnum.typeOfProperty:
        path = '/type-of-property';
        break;
      case TypesOfStepEnum.borrowAmount:
        path = '/borrow-amount';
        break;
      case TypesOfStepEnum.employmentStatus:
        path = '/employment-status';
        break;
      case TypesOfStepEnum.annualIncome:
        path = '/annual-income';
        break;
      case TypesOfStepEnum.contactForm:
        path = '/contact-form';
        break;
      case TypesOfStepEnum.done:
        path = '/thankyou';
        break;
    }
    window.history.pushState(null, '', path);
  };

  const handleOnSubmit = (updatedContactFormValues: ContactFormModel) => {
    const hookBody = { ...restState, contactForm: updatedContactFormValues };

    fetch('https://hooks.zapier.com/hooks/catch/9825322/bq3yls3/', {
      method: 'post',
      body: JSON.stringify(hookBody)
    });
    
    // Push 'successful_submit' event to dataLayer
    //@ts-ignore
    window.dataLayer.push({
      event: 'successful_submit',
    });

    handleOnActiveStepChange(TypesOfStepEnum.done);
    
    // Clear localStorage after successful submission
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(STORAGE_EXPIRATION_KEY);
  };

  const isActiveStepDone = activeStep === TypesOfStepEnum.done;
  const isActiveStepGetStarted = activeStep === TypesOfStepEnum.getStarted;

  const showProgressBar = !isActiveStepDone && !isActiveStepGetStarted;

  const renderActiveStep = () => {
    switch (activeStep) {
      case TypesOfStepEnum.getStarted:
        return (
          <StepGetStarted
            onNextButton={(newFlowStep) => {
              stateSetter({ flowStep: newFlowStep });
              handleOnActiveStepChange(TypesOfStepEnum.typeOfProperty);
            }}
          />
        );
      case TypesOfStepEnum.typeOfProperty:
        return (
          <StepTypeOfProperty
            onBackButton={() => handleOnActiveStepChange(TypesOfStepEnum.getStarted)}
            onNextButton={(selectedTypeOfProperty) => {
              stateSetter({ typeOfProperty: selectedTypeOfProperty });
              handleOnActiveStepChange(TypesOfStepEnum.borrowAmount);
            }}
            typeOfPropertyState={typeOfProperty}
          />
        );
      case TypesOfStepEnum.borrowAmount:
        return (
          <StepBorrowAmount
            borrowAmountState={borrowAmount}
            onBackButton={() => handleOnActiveStepChange(TypesOfStepEnum.typeOfProperty)}
            onNextButton={(newBorrowAmount) => {
              stateSetter({ borrowAmount: newBorrowAmount });
              handleOnActiveStepChange(TypesOfStepEnum.employmentStatus);
            }}
          />
        );
      case TypesOfStepEnum.employmentStatus:
        return (
          <StepEmploymentStatus
            employmentStatusState={employmentStatus}
            onBackButton={() => handleOnActiveStepChange(TypesOfStepEnum.borrowAmount)}
            onNextButton={(selectedEmploymentStatus) => {
              stateSetter({ employmentStatus: selectedEmploymentStatus });
              handleOnActiveStepChange(TypesOfStepEnum.annualIncome);
            }}
          />
        );
      case TypesOfStepEnum.annualIncome:
        return (
          <StepAnnualIncome
            annualIncomeState={annualIncome}
            onBackButton={() => handleOnActiveStepChange(TypesOfStepEnum.employmentStatus)}
            onNextButton={(newAnnualIncome) => {
              stateSetter({ annualIncome: newAnnualIncome });
              handleOnActiveStepChange(TypesOfStepEnum.contactForm);
            }}
          />
        );
      case TypesOfStepEnum.contactForm:
        return <StepContactForm onSubmitButton={handleOnSubmit} />;
      case TypesOfStepEnum.done:
        return <StepDone />;

      default:
        return null;
    }
  };

  useEffect(() => {
    let currentProgress = 0;

    switch (activeStep) {
      case TypesOfStepEnum.typeOfProperty:
        currentProgress = 20;
        break;
      case TypesOfStepEnum.borrowAmount:
        currentProgress = 40;
        break;
      case TypesOfStepEnum.employmentStatus:
        currentProgress = 60;
        break;
      case TypesOfStepEnum.annualIncome:
        currentProgress = 80;
        break;
      case TypesOfStepEnum.contactForm:
        currentProgress = 100;
        break;

      default:
        currentProgress = 0;
    }

    stateSetter({ progress: currentProgress });
  }, [activeStep]);

  useEffect(() => {
    const handlePopState = () => {
      const urlStep = getStepFromUrl();
      stateSetter({ activeStep: urlStep });
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <Main>
      <Header isActiveStepGetStarted={isActiveStepGetStarted} />
      <Content>
        {isActiveStepGetStarted && <LendersBar />}
        {showProgressBar && <ProgressBar progress={progress} />}
        <StepOuterContainer isActiveStepDone={isActiveStepDone}>
          <StepContainer isActiveStepDone={isActiveStepDone}>{renderActiveStep()}</StepContainer>
        </StepOuterContainer>
      </Content>
      <Footer />
    </Main>
  );
};

export default App;
