import styled from 'styled-components';
import { DesignPatternMain } from '../assets/icons';
import { LogoListingLoop } from '../assets/logos';
import { color } from '../constants/color';
import { deviceTypeBreakpoint } from '../constants/device-type-breakpoints';
import { fontFamily } from '../constants/font-family';
import { fontSize } from '../constants/font-size';
import { Flex, Text, Width80Box, Link } from './primitives';

interface CustomFlexCotainerProps {
  isActiveStepGetStarted: boolean;
}

const CustomFlexCotainer = styled(Flex)<CustomFlexCotainerProps>`
  flex-direction: column;
  margin-bottom: ${({ isActiveStepGetStarted }) => (isActiveStepGetStarted ? 0 : 24)}px;
  row-gap: 52px;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    align-items: center;
    width: 100%;
  }
`;

const HeaderContainer = styled(Flex)`
  column-gap: 24px;
  justify-content: space-between;
  padding-top: 30px;
`;

const IconContainer = styled(Flex)`
  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    display: none;
  }
`;

const Title = styled(Text)`
  color: ${color.greenLoop};
  font-family: ${fontFamily.desolator};
  font-size: ${fontSize.xxl};
  letter-spacing: 2.25px;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    font-size: ${fontSize.xl};
    text-align: center;
  }
`;

Title.defaultProps = {
  children: 'Let’s get started'
};

interface HeaderProps {
  isActiveStepGetStarted: boolean;
}

const Header = (props: HeaderProps) => {
  const { isActiveStepGetStarted } = props;

  return (
    <Width80Box>
      <HeaderContainer>
        <CustomFlexCotainer isActiveStepGetStarted={isActiveStepGetStarted}>
          <Link href="https://lendingloop.com.au">
            <LogoListingLoop />
          </Link>
          {isActiveStepGetStarted && <Title />}
        </CustomFlexCotainer>
        {isActiveStepGetStarted && (
          <IconContainer>
            <DesignPatternMain />
          </IconContainer>
        )}
      </HeaderContainer>
    </Width80Box>
  );
};

export { Header };
