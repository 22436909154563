import { IconProps, IconSvg } from './icon-props';

export const DesignPatternLast = (props: IconProps) => (
  <IconSvg
    height="362.71px"
    maxWidth="422.521px"
    viewBox="0 0 422.521 362.71"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-11556.856 -475.197)">
      <g>
        <g>
          <g>
            <path
              d="M11846.23,779.937l20.82-22.149-54.163-50.914-20.82,22.149a110.044,110.044,0,0,1-155.377,4.8l-28.921-27.186-50.913,54.163,28.919,27.185C11659.8,857.576,11776.642,853.965,11846.23,779.937Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
      <path
        d="M11612.81,692.65c-4.36-15.381-12.274-40.368-4.235-52.841,5.046-7.829,19.583-13.348,19.583-13.348s1.707,1.878,10.01,7.8c12.052,8.6,25.979,2.552,25.979,2.552s12.525-4.581,11.134-19.369c0,0,16.526-1.217,22.441,9.221s14.193,31.205,17.907,48.057c.292,1.325-6.724,3.875-6.724,3.875s-1.672,2.349-2.007,8.369-.535,20.131-.535,20.131l18.929,8.965a66.945,66.945,0,0,0-17.056,25.417c-6.021,16.053-7.519,21.4-7.519,21.4s-28.267-4.007-43.984-14.04S11621.06,721.746,11612.81,692.65Z"
        fill="#38e6bf"
      />
      <path
        d="M11709.236,664.665a85.093,85.093,0,0,1-10,3.227l-1.943,11.7s5.952,10.638,9.03,11.7c0,0-.24-10.709,2.58-12.7a34.848,34.848,0,0,1,6.965-3.483l-3.317-11.941Z"
        fill="#fff"
      />
      <path
        d="M11694.858,683.49a.873.873,0,0,1-.787-1.249c.053-.112,5.332-11.327,3.778-19.755-1.569-8.517-10.431-20.864-10.52-20.987a.873.873,0,0,1,1.415-1.023c.375.518,9.181,12.788,10.822,21.693,1.655,8.99-3.692,20.345-3.92,20.824A.873.873,0,0,1,11694.858,683.49Z"
        fill="#fff"
      />
      <path
        d="M11612.757,693.248a.873.873,0,0,1-.1-1.741,92.231,92.231,0,0,0,16.41-3.562,44.021,44.021,0,0,0,8.028-3.357c-1.128-3.54-6.5-20.73-6.653-27.592a.873.873,0,0,1,.853-.892h.021a.875.875,0,0,1,.873.854c.16,7.25,6.7,27.5,6.767,27.707l.17.523-.4.379c-.152.145-1.7,1.49-9.092,4.03a92.307,92.307,0,0,1-16.778,3.646A.851.851,0,0,1,11612.757,693.248Z"
        fill="#fff"
      />
      <path
        d="M11612.811,693.524a.872.872,0,0,1-.823-.585c-6.221-17.739-11.988-41.438-4.147-53.6,5.146-7.985,19.558-13.281,20.168-13.5l.545-.2.395.426c.062.065,1.7,1.761,9.727,7.487,11.519,8.215,24.989,2.52,25.124,2.462l.088-.033c.464-.146,11.343-3.728,10.038-17.6l-.08-.846.843-.1c.722-.086,17.665-2.014,23.8,8.806,5.487,9.685,14.7,31.08,18,48.324a.873.873,0,1,1-1.715.328c-3.266-17.049-12.38-38.21-17.809-47.791-4.837-8.535-17.812-8.242-21.232-8.005.86,14.1-10.388,18.237-11.272,18.539-.967.413-14.67,5.995-26.8-2.657a116.209,116.209,0,0,1-9.557-7.3c-2.588,1.009-14.482,5.926-18.795,12.616-7.852,12.182-.659,37.858,4.326,52.079a.873.873,0,0,1-.534,1.113A.862.862,0,0,1,11612.811,693.524Z"
        fill="#fff"
      />
      <path
        d="M11912.346,756.2s5.06,5.232,13.067,12.4,9.073,5.94,12.874,4.951c5.1-1.329,23.078-10.465,31.238-13.353s9.853,3.849,9.853,3.849l-9.394,7.456c-4.783,4.02-25.356,18.776-30.372,21.337-3.022,1.544-8.13-.224-12.7-2.778s-23.371-15.207-23.371-15.207S11910.932,762.84,11912.346,756.2Z"
        fill="#fff"
      />
      <path
        d="M11960.817,763.876s4.214,3.242-6.04,9.544-24.671,17.1-29.085,16.176c0,0,6.688,6.408,13.92,3.243s38.143-26.762,39.766-28.793c0,0-.862-4.988-6.837-4.592S11960.817,763.876,11960.817,763.876Z"
        fill="#48185c"
      />
      <path
        d="M11923.371,619.245s13.969-5.91,17.551-7.7,1.97-6.448,1.97-6.448-3.437-12.17-5.9-22.527c-.955-4.022-1.762-7.771-2.163-10.426-1.433-9.493,2.686-8.418,2.686-8.418s0-.716,2.149,2.149,17.552,34.208,19.88,40.476,2.507,11.641-1.612,14.328-24.715,16.656-24.715,16.656S11929.876,626.826,11923.371,619.245Z"
        fill="#fff"
      />
      <path
        d="M11954,623.185s3.224-4.3-2.866-19.7-7.88-19.7-10.565-20.058-2.328,4.119-2.328,4.119-5.892-19.589-2.932-23.459,5.691,3.643,12.292,16.618,10.472,21.47,11.951,25.646S11964.948,619.909,11954,623.185Z"
        fill="#48185c"
      />
      <path
        d="M11729.288,663.985a.874.874,0,0,1-.316-1.688c.8-.313,19.618-7.988,13.124-35.407-3.145-13.283-8.457-20.83-14.607-29.567-5.529-7.855-11.8-16.759-16.977-31.349a64.163,64.163,0,0,1,.117-44.272c5.619-14.727,16.656-26.632,31.919-34.426,13.38-6.831,36.329-10.49,57.44-3.271,18.146,6.206,31.926,19.086,40.952,38.279,12,25.516,7.946,47.016,2.433,60.559-6.854,16.832-18.65,28.532-26.67,33.436-20.43,12.491-32.716,14.655-42.587,16.4-2.575.454-5.007.882-7.279,1.462a.873.873,0,0,1-.432-1.692c2.335-.6,4.8-1.03,7.406-1.49,9.728-1.714,21.832-3.847,41.98-16.165,7.794-4.766,19.271-16.165,25.964-32.6,5.382-13.22,9.334-34.214-2.4-59.157-8.817-18.749-22.254-31.322-39.937-37.369-20.628-7.056-43.03-3.491-56.08,3.173-14.872,7.6-25.62,19.177-31.081,33.494a62.4,62.4,0,0,0-.1,43.065c5.1,14.365,11.026,22.785,16.758,30.927,5.994,8.516,11.657,16.56,14.879,30.17,3.572,15.076-.449,24.443-4.451,29.647a24.925,24.925,0,0,1-9.742,7.792A.863.863,0,0,1,11729.288,663.985Z"
        fill="#48185c"
      />
      <path
        d="M11705.875,691.771c-4.518-4.267-8.679-13.018-8.853-13.388l1.183-.56c.042.089,4.233,8.9,8.569,13Z"
        fill="#fff"
      />
      <path
        d="M11699.49,668.312l-.171-1.3c.093-.012,9.318-1.264,12.645-4.806l.955.9C11709.273,666.986,11699.888,668.26,11699.49,668.312Z"
        fill="#fff"
      />
      <path
        d="M11667.849,614.58c-1.569-1.308-2.77-6.559-3.208-8.724a16.544,16.544,0,0,1-4.547.626,26.6,26.6,0,0,1-12.311-3.484.873.873,0,0,1,.9-1.5c.09.053,8.821,5.205,16.441,2.374,6.729-2.5,9.74-10.99,8.948-25.235-.682-12.258-2.289-16.876-2.306-16.921a.874.874,0,0,1,.528-1.117.864.864,0,0,1,.773.111c-.128-.589-.207-.917-.207-.917s-37.205-5.86-35.38-2.212-.443,23.882-.443,23.882c-4.146-3.648-11.111,2.432-8.624,7.076s3.649,4.312,6.634,5.971,6.689,12.383,5.528,19.017-14.373,11.719-14.373,11.719l9.8,7.574s9.619,9.287,25.375,4.975,13.9-20.355,13.9-20.355A10.647,10.647,0,0,1,11667.849,614.58Z"
        fill="#fff"
      />
      <path
        d="M11660.272,588.526a.655.655,0,0,1-.2-1.28l4.1-1.272c-3.58-4.537-4.843-11.705-4.9-12.025a.655.655,0,1,1,1.291-.222c.014.079,1.407,7.982,5.229,12.135a.654.654,0,0,1-.287,1.069l-5.045,1.566A.652.652,0,0,1,11660.272,588.526Z"
        fill="#48185c"
      />
      <path
        d="M11645.887,573.917a.655.655,0,0,1-.336-1.218c6.722-4,9.8-1.978,9.926-1.89a.655.655,0,0,1-.732,1.085c-.066-.04-2.663-1.555-8.523,1.931A.648.648,0,0,1,11645.887,573.917Z"
        fill="#48185c"
      />
      <path
        d="M11661.218,569.945a.656.656,0,0,1-.5-1.081,8.918,8.918,0,0,1,8.771-2.652.655.655,0,0,1-.336,1.266,7.691,7.691,0,0,0-7.439,2.237A.652.652,0,0,1,11661.218,569.945Z"
        fill="#48185c"
      />
      <path
        d="M11661.845,596.284a13,13,0,0,1-6.214-1.8.655.655,0,0,1,.7-1.111c.036.023,3.69,2.267,7.239,1.406a7.168,7.168,0,0,0,4.462-3.555.655.655,0,0,1,1.125.671,8.42,8.42,0,0,1-5.287,4.159A8.581,8.581,0,0,1,11661.845,596.284Z"
        fill="#48185c"
      />
      <ellipse
        cx="1.326"
        cy="2.037"
        fill="#48185c"
        rx="1.326"
        ry="2.037"
        transform="matrix(0.985, -0.171, 0.171, 0.985, 11650.999, 576.667)"
      />
      <ellipse
        cx="1.326"
        cy="2.037"
        fill="#48185c"
        rx="1.326"
        ry="2.037"
        transform="translate(11666.014 571.935) rotate(-1.358)"
      />
      <path
        d="M11628.488,542.548s-8.4,4.2-9.951,13.488-3.095,30.516-10.394,34.054c0,0-7.518,3.98-19.016,9.066s-21.008,14.595-21.45,28.968,7.52,23,13.269,26.093,16.142,5.307,23.218,3.1c0,0-2.211-11.941,5.528-19.681s13.637-10.835,22.481-12.825,10.466-6.413,10.909-13.71-5.844-16.966-5.844-16.966-8.087-1.609-7.866-7.8,6.245-4.434,8.1-3.1c0,0,4.236-9.213,3.77-16.578-.295-4.644-1.548-6.413-1.548-6.413a49.364,49.364,0,0,0,22.406,3.1c13.048-1.106,16.144-4.2,16.585-9.73s-2.432-12.162-12.383-12.162-10.451,3.087-19.091-.663S11633.353,540.115,11628.488,542.548Z"
        fill="#48185c"
      />
      <g>
        <path
          d="M11801.335,479.228s-12.322,7.6-7.341,25.954,28.838,16.516,36.965,10.748,9.438-22.283,4.457-30.672S11815.492,470.839,11801.335,479.228Z"
          fill="#fff"
        />
        <g>
          <path
            d="M11808.278,497.26a8.989,8.989,0,0,1-1.826-.184,6.334,6.334,0,0,1-4.954-4.145c-1.475-4.264,1.852-7.781,3.818-9.044l.082-.047c2.667-1.331,6.335-.377,8.534,2.223,2.406,2.844,1.6,6.993-.569,9.361A7.068,7.068,0,0,1,11808.278,497.26Zm-2.056-11.879c-.469.315-4.414,3.108-3.073,6.979a4.634,4.634,0,0,0,3.657,3.006c2.2.457,4.449-.228,5.269-1.122,1.624-1.772,2.286-4.971.523-7.052A5.629,5.629,0,0,0,11806.223,485.381Z"
            fill="#48185c"
          />
          <path
            d="M11823.624,497.26a9,9,0,0,1-1.826-.184,6.334,6.334,0,0,1-4.954-4.145c-1.475-4.264,1.853-7.781,3.818-9.044l.082-.047c2.668-1.331,6.335-.377,8.535,2.223,2.405,2.844,1.6,6.993-.57,9.361A7.067,7.067,0,0,1,11823.624,497.26Zm-2.055-11.879c-.47.315-4.414,3.108-3.074,6.979a4.636,4.636,0,0,0,3.658,3.006c2.2.457,4.448-.228,5.268-1.122,1.624-1.772,2.286-4.971.524-7.052A5.629,5.629,0,0,0,11821.569,485.381Z"
            fill="#48185c"
          />
          <path
            d="M11808.278,512.075a8.989,8.989,0,0,1-1.826-.184,6.334,6.334,0,0,1-4.954-4.145c-1.475-4.264,1.852-7.781,3.818-9.044l.082-.047c2.667-1.331,6.335-.377,8.534,2.223,2.406,2.844,1.6,6.993-.569,9.361A7.068,7.068,0,0,1,11808.278,512.075Zm-2.056-11.879c-.469.315-4.414,3.108-3.073,6.979a4.634,4.634,0,0,0,3.657,3.006c2.2.459,4.449-.228,5.269-1.122,1.624-1.771,2.286-4.971.523-7.052A5.629,5.629,0,0,0,11806.223,500.2Z"
            fill="#48185c"
          />
          <path
            d="M11823.624,512.075a9,9,0,0,1-1.826-.184,6.334,6.334,0,0,1-4.954-4.145c-1.475-4.264,1.853-7.781,3.818-9.044l.082-.047c2.668-1.331,6.335-.377,8.535,2.223,2.405,2.844,1.6,6.993-.57,9.361A7.067,7.067,0,0,1,11823.624,512.075Zm-2.055-11.879c-.47.315-4.414,3.108-3.074,6.979a4.636,4.636,0,0,0,3.658,3.006c2.2.459,4.448-.228,5.268-1.122,1.624-1.771,2.286-4.971.524-7.052A5.629,5.629,0,0,0,11821.569,500.2Z"
            fill="#48185c"
          />
        </g>
        <path
          d="M11809.815,491.233c-1.348,0-3.517-.1-4.312-.145a.873.873,0,1,1,.088-1.744c2.118.109,4.546.184,4.936.125a.873.873,0,0,1,.286,1.722A7.705,7.705,0,0,1,11809.815,491.233Z"
          fill="#48185c"
        />
        <path
          d="M11825.751,505.12c-1.346,0-3.517-.1-4.312-.146a.873.873,0,1,1,.089-1.744c2.119.109,4.548.185,4.934.125a.873.873,0,0,1,.286,1.723A7.668,7.668,0,0,1,11825.751,505.12Z"
          fill="#48185c"
        />
        <path
          d="M11825.524,507.624c-1.348,0-3.519-.1-4.313-.145a.874.874,0,0,1-.828-.917.893.893,0,0,1,.917-.827c2.119.108,4.548.185,4.935.124a.873.873,0,0,1,.287,1.723A7.708,7.708,0,0,1,11825.524,507.624Z"
          fill="#48185c"
        />
        <g>
          <path
            d="M11825.522,491.233c-1.347,0-3.517-.1-4.312-.145a.873.873,0,1,1,.089-1.744c2.118.109,4.544.184,4.935.125a.873.873,0,0,1,.287,1.722A7.733,7.733,0,0,1,11825.522,491.233Z"
            fill="#48185c"
          />
          <path
            d="M11823.744,493.723h-.045a.873.873,0,0,1-.828-.917c.109-2.119.185-4.544.125-4.935a.876.876,0,0,1,.724-1,.867.867,0,0,1,1,.714c.119.72-.049,4.247-.1,5.31A.875.875,0,0,1,11823.744,493.723Z"
            fill="#48185c"
          />
        </g>
        <g>
          <path
            d="M11806.247,507.946a.872.872,0,0,1-.585-1.521c1.574-1.422,3.342-3.082,3.576-3.4a.873.873,0,0,1,1.421,1.016c-.425.6-3.037,2.969-3.827,3.682A.872.872,0,0,1,11806.247,507.946Z"
            fill="#48185c"
          />
          <path
            d="M11809.868,508.049a.87.87,0,0,1-.648-.288c-1.421-1.575-3.082-3.343-3.4-3.577a.873.873,0,0,1,1.015-1.421c.594.424,2.969,3.037,3.682,3.828a.873.873,0,0,1-.647,1.458Z"
            fill="#48185c"
          />
        </g>
      </g>
      <path
        d="M11775.145,515.344s-32.64,26.221-35.842,29.223,1.6,3.4,4,3.2a35.107,35.107,0,0,1,4.928,0V590.4h55.444V546.769h8.607s4.2-1.4.6-3.4S11775.145,515.344,11775.145,515.344Z"
        fill="#48185c"
      />
      <path
        d="M11784.077,563.977c-.816-5.262-3.811-6.622-8.346-8.618s-6.441-4.627-4.536-7.8,8.891-1.723,10.613.091l1.181-3.266a13.378,13.378,0,0,0-6.261-1.995V537.85l-3.084.182.3,4.5a12.47,12.47,0,0,0-3.743,1.3c-6.8,3.628-4.264,11.793.816,14.061s9.436,2.359,8.891,8.437-10.07,3.447-12.882,1.723l-1.361,3.448a20.608,20.608,0,0,0,7.4,1.886l.221,5.28h2.994v-5.565c.09-.022.182-.035.271-.059C11781.628,571.688,11784.894,569.239,11784.077,563.977Z"
        fill="#fff"
      />
      <path
        d="M11663.291,575.808a.672.672,0,0,1-.152-.018.655.655,0,0,1-.485-.786,5.567,5.567,0,0,1,2.423-3.268,5.874,5.874,0,0,1,4.737-.316.656.656,0,0,1-.37,1.258,4.629,4.629,0,0,0-3.7.184,4.289,4.289,0,0,0-1.818,2.445A.656.656,0,0,1,11663.291,575.808Z"
        fill="#48185c"
      />
      <path
        d="M11648.353,581.5a.8.8,0,0,1-.083,0,.654.654,0,0,1-.567-.729c.007-.055.748-5.561,6.953-4.945a.655.655,0,0,1-.13,1.3c-4.916-.492-5.5,3.628-5.523,3.8A.657.657,0,0,1,11648.353,581.5Z"
        fill="#48185c"
      />
      <g>
        <path
          d="M11762.5,696.194s1.16-28.3,1.392-36.65-4.64-6.263-4.64-6.263l-48.711,24.356s-3.711,1.16-3.943,6.727-.231,22.732-.231,22.732l26.956,3.614Z"
          fill="#fdeb89"
        />
        <path
          d="M11733.468,711.611l-27.978-3.751v-.765c0-.171,0-17.246.232-22.768.237-5.72,3.869-7.276,4.476-7.5l48.661-24.33a4.08,4.08,0,0,1,3.707.291c1.553,1.055,2.291,3.335,2.2,6.777-.229,8.261-1.381,36.378-1.393,36.661l-.021.516Zm-26.23-5.28,25.933,3.478,28.477-14.167c.157-3.885,1.157-28.494,1.368-36.122.1-3.483-.718-4.792-1.419-5.276a2.33,2.33,0,0,0-2.009-.158L11710.8,678.47c-.12.04-3.129,1.1-3.331,5.93C11707.267,689.216,11707.241,702.94,11707.237,706.331Z"
          fill="#e9e9ea"
        />
      </g>
      <path d="M11631.628,661.745" fill="#fff" />
      <path
        d="M11854.134,697.622c-10.1-9.114-24.028-21.085-32.98-26.36-7.467-4.4-17.767-1.322-21.23-.072-9.827,5.008-53.439,30.917-53.439,30.917-7.98,3.921-26.268,18.148-26.268,18.148s-14.006,20.652-19.5,42.621c0,0,26.806,4.439,59.756-10.073,23.864-10.51,51.563-44.391,51.563-44.391s17.22,14.5,28.683,25.269c16.447,15.453,54.428,46.132,54.292,45.756,10.408-1.53,18.357-22.728,19.894-27.106C11901.6,740.356,11861.379,704.157,11854.134,697.622Z"
        fill="#48185c"
      />
      <path
        d="M11737.484,523.073a.87.87,0,0,1-.682-.325c-.028-.035-1.868-2.246-7.518-5.723a.873.873,0,1,1,.916-1.487c5.979,3.679,7.886,6.019,7.964,6.116a.873.873,0,0,1-.681,1.419Z"
        fill="#48185c"
      />
      <path
        d="M11751.938,508.424a.874.874,0,0,1-.841-.638c-.01-.034-.824-2.791-4.655-8.222a.873.873,0,1,1,1.428-1.006c4.046,5.737,4.877,8.639,4.91,8.759a.874.874,0,0,1-.609,1.075A.892.892,0,0,1,11751.938,508.424Z"
        fill="#48185c"
      />
      <path
        d="M11774.507,505.154a.887.887,0,0,1-.325-.063.873.873,0,0,1-.486-1.133c.012-.03,1.035-2.717,1.278-9.361a.873.873,0,1,1,1.745.064c-.258,7.016-1.354,9.828-1.4,9.944A.875.875,0,0,1,11774.507,505.154Z"
        fill="#48185c"
      />
      <g>
        <path
          d="M11822.04,669.757c8.172,4.816,20.342,15.056,30.106,23.741,15.761-8.3,82.508-51.09,82.508-51.09-3.345-16.716-16-29.133-16-29.133s-16.238,7.164-35.341,17.193c-15.7,8.244-60.2,29.638-75.55,37C11812.238,666.892,11817.534,667.1,11822.04,669.757Z"
          fill="#48185c"
        />
      </g>
      <path
        d="M11624.434,690.371s-9.367,2.518-12.479,2.807c0,0,7.5,20.411,17.564,33.016s14.927,14.373,32.4,10.835,30.626-6.523,44.226-12.162,27.419-12.052,32.727-17.027,9.231-8.679,9.4-10.282a10.514,10.514,0,0,0-.055-2.764s1.935-2.1,1.493-3.7a11.444,11.444,0,0,1-.443-1.88,6.021,6.021,0,0,0,.608-2.709,4.4,4.4,0,0,0-.608-1.99,3.722,3.722,0,0,0,.388-3.261c-.719-1.6-3.317.774-4.589,2.045s-9.674,10.669-9.674,10.669,3.482-5.694,1.6-7.518-4.257,5.2-7.3,7.74-8.79,8.679-26.48,12.825-38.089,9.951-45.331,6.855-9.9-6.413-12.992-13.765-5.8-15.092-5.8-15.092S11632.339,687.883,11624.434,690.371Z"
        fill="#fff"
      />
    </g>
  </IconSvg>
);
