import { IconProps, IconSvg } from './icon-props';

export const IconRefinance = (props: IconProps) => (
  <IconSvg
    height="134.003"
    viewBox="0 0 132.488 134.003"
    width="132.488"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-743.127 -514.142)">
      <g>
        <g>
          <g>
            <path
              d="M875.615,571.007V558.281H844.494v12.726a46.069,46.069,0,0,1-46.017,46.018H781.86v31.12h16.616A77.226,77.226,0,0,0,875.615,571.007Z"
              fill="#38e6bf"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          d="M845.471,558.849a50.817,50.817,0,0,1-92.563,35.658"
          fill="none"
          stroke="#48185c"
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <path
          d="M744.871,573.263a50.817,50.817,0,0,1,92.515-35.25"
          fill="none"
          stroke="#48185c"
          strokeLinecap="round"
          strokeWidth="2.5"
        />
      </g>
      <path
        d="M839.233,527.145l-1.195,11.785L826.465,536.4"
        fill="none"
        stroke="#48185c"
        strokeLinecap="round"
        strokeWidth="2.5"
      />
      <path
        d="M751.467,606.043l1.195-11.785,11.573,2.525"
        fill="none"
        stroke="#48185c"
        strokeLinecap="round"
        strokeWidth="2.5"
      />
      <path
        d="M796.147,598.091c-15.522.295-21.753-6.229-22.59-18.5s14.688-28.354,14.688-28.354a91.9,91.9,0,0,1-4.927-10.97c-1.3-4.09.979-6.1,4.263-5.589,2.417.373,4.166,3.7,8.628,3.232s5.177-5.323,9.7-4.253c4.072.963,2.233,7.447.559,10.886a77.5,77.5,0,0,1-3.811,6.787,72.749,72.749,0,0,1,11.713,16.64c4.834,9.668,3.44,18.314,0,23.241S805.909,597.905,796.147,598.091Z"
        fill="none"
        stroke="#48185c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M788.245,551.237a18.276,18.276,0,0,0,14.41.093"
        fill="none"
        stroke="#48185c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <g>
        <path
          d="M800.847,565.7s-3.307-2.609-7.054-2.163c-3.364.4-5.267,2.764-5.316,5.107-.067,3.225,1.192,5.108,5.912,6.55s6.385,2.969,6.756,5.829c.34,2.624-1.689,5.889-6.409,5.769a10.464,10.464,0,0,1-7.352-3.245"
          fill="none"
          stroke="#48185c"
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          fill="none"
          stroke="#48185c"
          strokeLinecap="round"
          strokeWidth="2.5"
          transform="translate(794.475 560.883)"
          y2="28.042"
        />
      </g>
    </g>
  </IconSvg>
);
