import { ChangeEvent } from 'react';
import { Input, TextContainer } from '../primitives';

interface TextInputProps {
  onChange: (newValue: string) => void;
  placeholder: string;
  value: string;
  variant: 'large' | 'small';
  autofocus?: boolean;
}

const TextInput = (props: TextInputProps) => {
  const { onChange, placeholder, value, variant, autofocus } = props;

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;

    onChange(newValue);
  };

  return (
    <TextContainer variant={variant}>
      <Input
        onChange={onInputChange}
        placeholder={placeholder}
        value={value}
        autoFocus={autofocus}
      />
    </TextContainer>
  );
};

export { TextInput };
