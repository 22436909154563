import styled from 'styled-components';
import { color } from '../constants/color';
import { Box } from './primitives';

interface ProgressProps {
  progress: number;
}

const ProgressBarContainer = styled(Box)`
  background: ${color.greyLoop};
  height: 12px;
`;

const ProgressContainer = styled(Box)<ProgressProps>`
  background: ${color.greenLoop};
  height: 100%;
  width: ${({ progress }) => progress}%;
`;

const ProgressBar = (props: ProgressProps) => {
  const { progress } = props;

  return (
    <ProgressBarContainer>
      <ProgressContainer progress={progress} />
    </ProgressBarContainer>
  );
};
export { ProgressBar };
