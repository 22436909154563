const fontSize = {
  xxs: '16px',
  xs: '18px',
  s: '20px',
  m: '22px',
  l: '26px',
  xl: '40px',
  xxl: '50px'
};

export { fontSize };
