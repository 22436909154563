import styled from 'styled-components';
import { Flex } from '../../components/primitives';
import { color } from '../../constants/color';
import { IconProps, IconSvg } from './icon-props';

const CheckBoxWrap = styled(Flex)<{ checked: boolean }>`
  align-items: center;
  background-color: ${(p) => (p.checked ? color.purpleLoop : color.whiteLoop)};
  border: 2px solid ${color.purpleLoop};
  height: 24px;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  :focus {
    outline: 1px solid;
  }
`;

type IconTickProps = {
  checked: boolean;
  iconProps?: IconProps;
  onInputChange: (newValue: boolean) => void;
  tabIndex?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

export const IconTick = (props: IconTickProps) => {
  const { checked, iconProps, onInputChange, tabIndex } = props;

  const handleToggleInputChange = () => onInputChange(!checked);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      handleToggleInputChange();
    }
  };

  return (
    <CheckBoxWrap
      tabIndex={tabIndex}
      checked={checked}
      onKeyDown={handleKeyDown}
      onClick={handleToggleInputChange}
    >
      <IconSvg
        height="16px"
        width="16px"
        viewBox="0 0 490 490"
        xmlns="http://www.w3.org/2000/svg"
        {...iconProps}
      >
        <polygon
          fill={color.whiteLoop}
          points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528 "
        />
      </IconSvg>
    </CheckBoxWrap>
  );
};
