import { useState } from 'react';
import styled from 'styled-components';
import { TypeOfPropertyEnum } from '../../type-of-property';
import { TypeOfPropertyModel } from '../../types/type-of-property';
import { Flex, HoverableItem } from '../primitives';
import { StepWrapper } from '../wrappers/step-wrapper';

const HoverableContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  row-gap: 48px;
  width: 100%;
`;

interface StepTypeOfPropertyProps {
  onBackButton: () => void;
  onNextButton: (selectedTypeOfProperty: TypeOfPropertyModel) => void;
  typeOfPropertyState: TypeOfPropertyModel;
}

const { investmentProperty, ownerOccupied } = TypeOfPropertyEnum;

const StepTypeOfProperty = (props: StepTypeOfPropertyProps) => {
  const { onBackButton, onNextButton, typeOfPropertyState } = props;

  const [selectedOption, setSelectedOption] = useState<TypeOfPropertyModel>(typeOfPropertyState);

  const HoverableItemElements = [
    {
      isSelected: selectedOption === investmentProperty,
      onClick: () => setSelectedOption(investmentProperty),
      title: 'Investment property'
    },
    {
      isSelected: selectedOption === ownerOccupied,
      onClick: () => setSelectedOption(ownerOccupied),
      title: 'Owner occupied'
    }
  ];

  return (
    <StepWrapper
      onBackButton={onBackButton}
      onNextButton={() => onNextButton(selectedOption)}
      nextButtonDisabled={!selectedOption}
      showNavigationStepButtons
      title="What type of property do you want to purchase?"
    >
      <HoverableContainer>
        {HoverableItemElements.map(({ title, ...restProps }) => (
          <HoverableItem key={title} children={title} variant="large" {...restProps} />
        ))}
      </HoverableContainer>
    </StepWrapper>
  );
};

export { StepTypeOfProperty };
