const isValidPhoneNumber = (phoneNumber: string): boolean => {
  if (!phoneNumber) {
    return false;
  }

  const ausPhoneReg = new RegExp(
    /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
  );

  return ausPhoneReg.test(phoneNumber);
};

export { isValidPhoneNumber };
