import { useState } from 'react';
import styled from 'styled-components';
import { IconArrowDown } from '../../assets/icons';
import { Box, DropdownItem, DropdownMenu, Text, TextContainer } from '../primitives';
import { color } from '../../constants/color';

const SelectInputContainer = styled(Box)`
  margin: 0 auto;
  max-width: 488px;
  position: relative;
`;

const SelectInputOuterContainer = styled(Box)`
  width: 100%;
`;

const SelectLabel = styled(Text)<{ value: string }>`
  color: ${(p) => (p.value ? color.purpleLoop : '#808080')};
  font-size: 20px;
  cursor: pointer;
`;

interface SelectInputProps {
  dropdownOptions: string[];
  onChange: (newValue: string) => void;
  placeholder: string;
  value: string;
  variant: 'large' | 'small';
}

const SelectInput = (props: SelectInputProps) => {
  const { dropdownOptions = [], onChange, placeholder, value, variant } = props;

  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState<boolean>(false);

  const handleOnItemSelected = (selectedItem: string) => {
    setIsDropdownMenuOpen(false);
    onChange(selectedItem);
  };

  const toggleIsDropdownMenuOpen = () => setIsDropdownMenuOpen(!isDropdownMenuOpen);

  const handleDropKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      toggleIsDropdownMenuOpen();
    }
    if (e.key === 'ArrowDown' && dropdownOptions.length > 0) {
      e.preventDefault();
      const currentIndex = dropdownOptions.indexOf(value);
      const nextIndex = (currentIndex + 1) % dropdownOptions.length;
      const nextOption = dropdownOptions[nextIndex];
      onChange(nextOption);
    }
    if (e.key === 'ArrowUp' && dropdownOptions.length > 0) {
      e.preventDefault();
      const currentIndex = dropdownOptions.indexOf(value);
      const previousIndex = (currentIndex - 1 + dropdownOptions.length) % dropdownOptions.length;
      const previousOption = dropdownOptions[previousIndex];
      onChange(previousOption);
    }
  };

  return (
    <SelectInputOuterContainer>
      <SelectInputContainer>
        <TextContainer
          onClick={toggleIsDropdownMenuOpen}
          variant={variant}
          tabIndex={0}
          style={{ justifyContent: 'space-between', cursor: 'pointer' }}
          onKeyDown={handleDropKeyDown}
        >
          <SelectLabel value={value}>{value || placeholder}</SelectLabel>
          <IconArrowDown arrowDirectionUp={isDropdownMenuOpen} />
        </TextContainer>
        {isDropdownMenuOpen && (
          <DropdownMenu>
            {dropdownOptions.map((option, index) => (
              <DropdownItem
                isSelected={option === value}
                tabIndex={index + 1}
                key={option}
                onClick={() => handleOnItemSelected(option)}
              >
                {option}
              </DropdownItem>
            ))}
          </DropdownMenu>
        )}
      </SelectInputContainer>
    </SelectInputOuterContainer>
  );
};

export { SelectInput };
