import styled from 'styled-components';

const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

Form.displayName = 'Form';

export { Form };
