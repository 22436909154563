import { IconProps, IconSvg } from './icon-props';

export const IconBuild = (props: IconProps) => (
  <IconSvg
    height="136.469px"
    viewBox="0 0 129.864 136.469"
    width="129.864px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-1061.131 -512.576)">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M1182.257,624.9l8.738-9.253-22.627-21.366-8.737,9.253a46.069,46.069,0,0,1-65.052,1.863L1082.5,593.992l-21.367,22.627,12.081,11.408A77.225,77.225,0,0,0,1182.257,624.9Z"
                fill="#38e6bf"
              />
            </g>
          </g>
        </g>
        <line
          fill="none"
          stroke="#48185c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          transform="translate(1106.077 588.309)"
          y2="24.545"
        />
        <path
          d="M1142.257,541.714c-.976-15.843-16.819-15.6-16.819-15.6s-.008.065-.021.16l-.026-.16s-16.3-.265-16.819,15.6c-.487,14.991,10.664,22.21,16.629,22.538.128.009.522.011.555.009C1130.972,563.995,1143.158,556.36,1142.257,541.714Z"
          fill="none"
          stroke="#48185c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
        <line
          fill="none"
          stroke="#48185c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          transform="translate(1146.57 588.309)"
          y2="24.545"
        />
        <path
          d="M1142.12,532.663s.222-6.6-3.516-10.995-10.842-4.8-13.174-4.8-9.436.413-13.173,4.8-3.516,10.995-3.516,10.995h-2.035V538.4h37.449v-5.741Z"
          fill="#fff"
          stroke="#48185c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
        <path
          d="M3.734,0h5.4a3.734,3.734,0,0,1,3.734,3.734v15.1a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3.734A3.734,3.734,0,0,1,3.734,0Z"
          fill="#fff"
          stroke="#48185c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          transform="translate(1118.901 513.826)"
        />
        <path
          d="M1162.369,595.768s-.273-5.4-5.148-17.826-18.768-10.238-18.768-10.238l-12.381,12.867L1113.691,567.7s-13.893-2.194-18.768,10.238-5.148,17.826-5.148,17.826-2.284,7.119,8.36,13.586a52.266,52.266,0,0,0,55.874,0C1164.653,602.887,1162.369,595.768,1162.369,595.768Z"
          fill="none"
          stroke="#48185c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
      </g>
    </g>
  </IconSvg>
);
