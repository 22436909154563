enum TypesOfStepEnum {
  annualIncome = 'annualIncome',
  borrowAmount = 'borrowAmount',
  contactForm = 'contactForm',
  done = 'done',
  employmentStatus = 'employmentStatus',
  getStarted = 'getStarted',
  typeOfProperty = 'typeOfProperty'
}

export { TypesOfStepEnum };
