import { useState } from 'react';
import { SelectInput } from '../inputs';
import { StepWrapper } from '../wrappers/step-wrapper';

const dropdownOptions = ['Full Time', 'Part Time', 'Self Employed'];

interface StepEmploymentStatusProps {
  employmentStatusState: string;
  onBackButton: () => void;
  onNextButton: (selectedEmploymentStatus: string) => void;
}

const StepEmploymentStatus = (props: StepEmploymentStatusProps) => {
  const { employmentStatusState, onBackButton, onNextButton } = props;

  const [employmentStatus, setEmploymentStatus] = useState<string>(employmentStatusState);

  const handleOnInputChange = (newValue: string) => setEmploymentStatus(newValue);

  return (
    <StepWrapper
      nextButtonDisabled={!employmentStatus}
      onBackButton={onBackButton}
      onNextButton={() => onNextButton(employmentStatus)}
      showNavigationStepButtons
      title="What is your current employment status?"
    >
      <SelectInput
        dropdownOptions={dropdownOptions}
        onChange={handleOnInputChange}
        placeholder="Select employment"
        value={employmentStatus}
        variant="large"
      />
    </StepWrapper>
  );
};

export { StepEmploymentStatus };
