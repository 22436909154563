import { IconProps, IconSvg } from './icon-props';

export const IconBuy = (props: IconProps) => (
  <IconSvg
    height="111.165px"
    viewBox="0 0 129.863 111.165"
    width="129.863px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-408.878 -520.976)">
      <g>
        <g>
          <g>
            <path
              d="M530,545.117l8.737,9.253-22.627,21.367-8.737-9.253a46.069,46.069,0,0,0-65.052-1.864l-12.081,11.408L408.878,553.4l12.081-11.408A77.225,77.225,0,0,1,530,545.117Z"
              fill="#38e6bf"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              d="M522.978,581.753l-24.724-24.725L473.529,532.3,448.8,557.028,424.08,581.753h14.544v45.175a3.975,3.975,0,0,0,3.964,3.963h61.883a3.974,3.974,0,0,0,3.963-3.963V581.753Z"
              fill="none"
              stroke="#48185c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
            />
          </g>
        </g>
        <g>
          <path
            d="M483.1,575.8s-4.821-3.8-10.284-3.154c-4.9.584-7.679,4.03-7.75,7.447-.1,4.7,1.738,7.446,8.619,9.549s9.309,4.328,9.85,8.5c.5,3.825-2.463,8.585-9.343,8.41a15.254,15.254,0,0,1-10.719-4.731"
            fill="none"
            stroke="#48185c"
            strokeLinecap="round"
            strokeWidth="2.5"
          />
          <line
            fill="none"
            stroke="#48185c"
            strokeLinecap="round"
            strokeWidth="2.5"
            transform="translate(473.809 568.785)"
            y2="40.884"
          />
        </g>
      </g>
    </g>
  </IconSvg>
);
