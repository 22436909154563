import { useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { deviceTypeBreakpoint } from '../../constants/device-type-breakpoints';
import { isValidPhoneNumber } from '../../methods/valid-phone-number';
import { ContactFormModel } from '../../types/contact-form';
import { CheckBoxInput, NumberInput, SelectInput, TextInput } from '../inputs';
import { Button, Form, Grid, Link, Text } from '../primitives';
import { StepWrapper } from '../wrappers/step-wrapper';

const ContactFormContainer = styled(Grid)`
  column-gap: 32px;
  grid-template-columns: repeat(2, minmax(0, 460px));
  justify-items: center;
  margin-bottom: 48px;
  row-gap: 52px;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    grid-template-columns: minmax(0, 460px);
  }
`;

const SubmitButton = styled(Button).attrs({
  type: 'submit'
})`
  margin-top: 52px;
`;

SubmitButton.defaultProps = {
  children: 'Submit'
};

type stringStates = 'bestTimeToContactYou' | 'contactNumber' | 'email' | 'firstName' | 'lastName';

const dropdownOptions = [
  'A soon as possible / anytime',
  'Morning (8am - 12pm)',
  'Afternoon (12pm - 5:30pm)',
  'Evening (after 5:30pm)'
];

const initialValues: ContactFormModel = {
  agreeToCondtions: false,
  bestTimeToContactYou: '',
  contactNumber: '',
  email: '',
  firstName: '',
  lastName: '',
  postcode: 0
};

const validationSchema = Yup.object({
  agreeToCondtions: Yup.boolean().oneOf([true]),
  bestTimeToContactYou: Yup.string().required(),
  contactNumber: Yup.string().test('is-number', 'Please enter mobile number', (phoneNumber) => {
    const newPhone = phoneNumber as string;

    return isValidPhoneNumber(newPhone);
  }),
  email: Yup.string().email().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  postcode: Yup.number().required().min(800).max(9999)
});

interface StepContactFormProps {
  onSubmitButton: (updatedContactFormValues: ContactFormModel) => void;
}

const StepContactForm = (props: StepContactFormProps) => {
  const { onSubmitButton } = props;

  const { dirty, handleSubmit, isValid, setFieldValue, values } = useFormik({
    initialValues,
    onSubmit: onSubmitButton,
    validationSchema
  });

  const {
    agreeToCondtions,
    bestTimeToContactYou,
    contactNumber,
    email,
    firstName,
    lastName,
    postcode
  } = values;

  const handleOnCheckboxInputChange = (newValue: boolean) =>
    setFieldValue('agreeToCondtions', newValue);

  const handleOnPostcodeChange = (newValue: number) => setFieldValue('postcode', newValue);

  const handleOnStringInputChange = (stateName: stringStates, newValue: string) =>
    setFieldValue(stateName, newValue);

  return (
    <StepWrapper
      subTitle="Please share your contact information and one of our home loan experts will be in touch with some excellent loan options."
      title="Nice work!"
    >
      <Form onSubmit={handleSubmit}>
        <ContactFormContainer>
          <TextInput
            onChange={(newValue: string) => handleOnStringInputChange('firstName', newValue)}
            placeholder="First name"
            value={firstName}
            variant="small"
            autofocus
          />
          <TextInput
            onChange={(newValue: string) => handleOnStringInputChange('lastName', newValue)}
            placeholder="Last name"
            value={lastName}
            variant="small"
          />
          <NumberInput
            numberType="postcode"
            onChange={(newValue: number) => handleOnPostcodeChange(newValue)}
            placeholder="Postcode"
            value={postcode}
            variant="small"
          />
          <TextInput
            onChange={(newValue: string) => handleOnStringInputChange('email', newValue)}
            placeholder="Email"
            value={email}
            variant="small"
          />
          <TextInput
            onChange={(newValue: string) => handleOnStringInputChange('contactNumber', newValue)}
            placeholder="Contact number"
            value={contactNumber}
            variant="small"
          />
          <SelectInput
            dropdownOptions={dropdownOptions}
            onChange={(newValue: string) =>
              handleOnStringInputChange('bestTimeToContactYou', newValue)
            }
            placeholder="Best time to contact you"
            value={bestTimeToContactYou}
            variant="small"
          />
        </ContactFormContainer>
        <CheckBoxInput
          checked={agreeToCondtions}
          label={
            <Text>
              I have read and agree with the&nbsp;
              <Link
                href="https://lendingloop.com.au/terms-and-conditions/"
                target="_blank"
                style={{ outline: 'none' }}
              >
                Terms and Conditions
              </Link>
              &nbsp;and&nbsp;
              <Link
                href="https://lendingloop.com.au/privacy-policy/"
                target="_blank"
                style={{ outline: 'none' }}
              >
                Privacy Policy
              </Link>
            </Text>
          }
          onChange={(newValue) => handleOnCheckboxInputChange(newValue)}
        />

        <SubmitButton disabled={!dirty || !isValid}>Submit</SubmitButton>
      </Form>
    </StepWrapper>
  );
};

export { StepContactForm };
