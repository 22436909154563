import styled from 'styled-components';
import { color } from '../../constants/color';
import { PositionProps, position } from 'styled-system';
import { fontSize } from '../../constants/font-size';

interface TextContainerOwnProps {
  variant: 'large' | 'small';
}

type TextContainerProps = PositionProps & TextContainerOwnProps;

const HoverableButton = styled('button')<TextContainerProps>`
  align-items: center;
  border: 2px solid;
  display: flex;

  font-size: ${fontSize.s};
  height: 60px;
  justify-content: center;
  max-width: ${({ variant }) => (variant === 'large' ? 488 : 460)}px;
  padding: 0 28px;
  width: 100%;
  :focus {
    outline: 1px solid;
  }
  ${position};
`;

interface HoverableItemProps {
  isSelected: boolean;
}

const HoverableItem = styled(HoverableButton)<HoverableItemProps>`
  background: ${({ isSelected }) => (isSelected ? color.purpleLoop : color.whiteLoop)};
  color: ${({ isSelected }) => (isSelected ? color.whiteLoop : color.purpleLoop)};
  cursor: pointer;

  &:hover {
    background: ${color.purpleLoop};
    color: ${color.whiteLoop};
  }
`;

export { HoverableItem };
