import { ResponsiveValue, style } from 'styled-system';

export interface CursorProps {
  cursor?: string;
}
export interface GapProps {
  columnGap?: ResponsiveValue<string | number>;
  rowGap?: ResponsiveValue<string | number>;
}

export interface SvgColorProps {
  color?: ResponsiveValue<string>;
}

export interface TransformProps {
  transform?: ResponsiveValue<string>;
}
export interface VisibilityProps {
  visibility?: ResponsiveValue<string>;
}

export const columnGap = style({
  cssProperty: 'column-gap',
  prop: 'columnGap'
});

export const cursor = style({
  prop: 'cursor'
});

export const rowGap = style({
  cssProperty: 'row-gap',
  prop: 'rowGap'
});

export const svgColor = style({
  cssProperty: 'fill',
  prop: 'color'
});

export const transform = style({
  prop: 'transform'
});

export const visibility = style({
  prop: 'visibility'
});
