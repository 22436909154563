import { useState } from 'react';
import { NumberInput } from '../inputs';
import { StepWrapper } from '../wrappers/step-wrapper';

interface StepAnnualIncomeProps {
  annualIncomeState: number;
  onBackButton: () => void;
  onNextButton: (newAnnualIncome: number) => void;
}

const StepAnnualIncome = (props: StepAnnualIncomeProps) => {
  const { annualIncomeState, onBackButton, onNextButton } = props;
  const [annualIncome, setAnnualIncome] = useState<number>(annualIncomeState);

  const handleOnInputChange = (newValue: number) => setAnnualIncome(newValue);

  return (
    <StepWrapper
      nextButtonDisabled={!annualIncome}
      onBackButton={onBackButton}
      onNextButton={() => onNextButton(annualIncome)}
      showNavigationStepButtons
      title="What is your annual household income?"
    >
      <NumberInput
        numberType="amount"
        onChange={handleOnInputChange}
        placeholder="100,000"
        value={annualIncome}
        variant="large"
        autofocus
      />
    </StepWrapper>
  );
};

export { StepAnnualIncome };
