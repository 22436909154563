import styled from 'styled-components';
import { color } from '../constants/color';
import { deviceTypeBreakpoint } from '../constants/device-type-breakpoints';
import { Flex, Text, Width80Box } from './primitives';

const AddressContainer = styled(Flex)`
  align-items: flex-end;
  column-gap: 64px;
  flex: 1;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 16px;
  }
`;

const FooterContainer = styled(Flex)`
  align-items: flex-end;
  column-gap: 52px;
  padding: 44px 0;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    align-items: flex-start;
    flex-direction: column;
    padding: 24px 0;
    row-gap: 24px;
  }
`;

const LightPurpleText = styled(Text)`
  color: ${color.lightPurpleLoop};
`;

const AddressText = styled(LightPurpleText)`
  flex: 1;
`;

const Footer = () => (
  <Width80Box>
    <FooterContainer>
      <AddressText>
        This website is owned and operated by Lending Loop Pty Ltd (ACN 645 781 451).
        <br />
        <br />
        Lending Loop Pty Ltd is a Credit Representative 529998 of National Lending Group Pty Ltd,
        Australian Credit Licence, 412778 that offers services within the finance mortgage brokering
        industry Australia-wide.
        <br />
        Our goal is for the process to be simple, straightforward and hassle-free.
      </AddressText>
      <AddressContainer>
        <LightPurpleText>
          Address:
          <br />1 Fuji Crescent, Mornington VIC 3931
        </LightPurpleText>
        <LightPurpleText>Copyright © Lending Loop 2022</LightPurpleText>
      </AddressContainer>
    </FooterContainer>
  </Width80Box>
);

export { Footer };
