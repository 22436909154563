import styled from 'styled-components';
import { color } from '../../constants/color';
import { fontSize } from '../../constants/font-size';
import { Box } from './box';
import { Text } from './text';

const DropdownMenu = styled(Box)`
  background: ${color.whiteLoop};
  border: 2px solid ${color.purpleLoop};
  border-top: none;
  position: absolute;
  width: 100%;
`;

const DropdownItem = styled(Text)<{ isSelected?: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: ${fontSize.s};
  height: 48px;
  padding: 0 28px;
  background: ${(p) => (p.isSelected ? color.greyLoop : 'transparent')};
  :hover {
    background: ${color.greyLoop};
  }
`;

export { DropdownItem, DropdownMenu };
