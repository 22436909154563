import { IconProps, IconSvg } from '../icons/icon-props';

export const LogoMacquarie = (props: IconProps) => (
  <IconSvg
    height="24.638px"
    viewBox="0 0 127.324 24.638"
    width="127.324px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-912.713 -275.886)">
      <g>
        <path d="M934.794,296.624a10.355,10.355,0,0,1-2.973,2.007c-.211.076-.423.166-.62.226.2-.121.409-.256.62-.392a14.927,14.927,0,0,0,2.973-2.642,11.4,11.4,0,0,0,2.808-7.7,11.821,11.821,0,0,0-2.808-7.546,15.472,15.472,0,0,0-2.973-2.642c-.211-.136-.423-.272-.62-.407.2.106.409.2.62.3a12.67,12.67,0,0,1,2.973,2.069,11.561,11.561,0,0,1,0,16.724m-5.056-3.717a3.59,3.59,0,0,1-1.087.436,8.568,8.568,0,0,0,1.087-.829,5.412,5.412,0,0,0,1.735-4.3,5.676,5.676,0,0,0-1.735-4.286,8.589,8.589,0,0,0-1.087-.861,6.919,6.919,0,0,1,1.087.512,5.747,5.747,0,0,1,2.083,2.235,5.253,5.253,0,0,1,0,4.888,5.548,5.548,0,0,1-2.083,2.206m-8.838-4.7a5,5,0,1,1,4.968,5.572A5.566,5.566,0,0,1,920.9,288.2m1.125,4.718a5.415,5.415,0,0,1-2.1-2.221,5.253,5.253,0,0,1,0-4.888,5.5,5.5,0,0,1,2.1-2.25,6.606,6.606,0,0,1,1.056-.5,7.576,7.576,0,0,0-1.056.828,5.606,5.606,0,0,0-1.75,4.319,5.336,5.336,0,0,0,1.75,4.316,6.313,6.313,0,0,0,1.056.814,3.458,3.458,0,0,1-1.056-.421m-2.113,5.709a10.35,10.35,0,0,1-2.975-2.007,11.563,11.563,0,0,1,0-16.724,12.639,12.639,0,0,1,2.975-2.069c.21-.1.422-.194.618-.3-.2.135-.408.271-.618.407a15.452,15.452,0,0,0-2.975,2.642,11.837,11.837,0,0,0-2.808,7.546,11.41,11.41,0,0,0,2.808,7.7,14.882,14.882,0,0,0,2.975,2.642c.21.136.422.271.618.392-.2-.06-.408-.15-.618-.226m5.954-22.745c-7.435,0-13.153,5.515-13.153,12.318s5.718,12.32,13.153,12.32,13.154-5.516,13.154-12.32-5.718-12.318-13.154-12.318" />
      </g>
      <g>
        <path
          d="M1032.611,288.382V291.5h7.426v1.3h-8.788v-9.779h8.788v1.3h-7.426v2.785h5.01v1.3h-5.01Zm-46.095-4.487a4.106,4.106,0,0,0-4.267,3.961,4.313,4.313,0,0,0,4.27,4.269,2.663,2.663,0,0,0,.865-.124,2.393,2.393,0,0,0-2.166-.62c.251-.516,1.551-1.113,3.54.124a3.906,3.906,0,0,0,1.966-3.524,4.193,4.193,0,0,0-4.208-4.086m0-.989c1.637,0,5.753.738,5.753,5.075a4.767,4.767,0,0,1-2.662,4.206l1.546,1.361-.741.99-1.982-1.67a6.189,6.189,0,0,1-1.917.309c-4.985,0-5.813-4.069-5.813-5.189,0-3.427,2.756-5.082,5.816-5.082m41.391.121h1.36v9.779h-1.36v-9.779Zm-58.284,4.891c0,3.54,5.368,5.454,9.154,2.1l.807.927a7.152,7.152,0,0,1-5.319,1.979c-2.531,0-6.25-1.12-6.25-5.01a4.789,4.789,0,0,1,2.5-3.943,8.457,8.457,0,0,1,8.638.23l-.68.99c-3.283-2.4-8.848-.939-8.848,2.723M954.9,285.131l-4.392,7.86-4.271-7.86V292.8h-1.363v-9.776h1.858l3.836,7.3,3.962-7.3h1.729V292.8H954.9Zm8.044-.8,2.1,4.763h-4.272Zm-.68-1.3h1.361l4.7,9.779h-1.484l-1.232-2.538h-5.323l-1.24,2.537h-1.422Zm46.576,1.3,2.106,4.763h-4.273Zm-.68-1.3h1.362l4.7,9.779h-1.486l-1.23-2.538h-5.325l-1.24,2.537h-1.422Zm14.116,0c3.417,0,3.465,2.291,3.465,2.725,0,.622-.214,2.313-2.968,2.721l3.093,4.333H1024.2l-2.908-4.271h-4.206v4.271h-1.361v-9.779Zm-.431,4.395c2.514-.025,2.474-1.422,2.474-1.67,0-.186-.077-1.612-2.1-1.612h-5.136v3.282Zm-23.615,4.553c1.948-.031,2.751-.525,3.07-.85a2.237,2.237,0,0,0,.415-1.415v-6.683h1.3v6.747a2.426,2.426,0,0,1-.742,2.163c-.4.344-1.208,1.106-4.044,1.106s-3.643-.762-4.042-1.106a2.423,2.423,0,0,1-.742-2.163v-6.747h1.3v6.683a2.243,2.243,0,0,0,.415,1.415c.32.325,1.122.819,3.07.85"
          fillRule="evenodd"
        />
      </g>
    </g>
  </IconSvg>
);
