import { ReactNode } from 'react';
import styled from 'styled-components';
import { deviceTypeBreakpoint } from '../../constants/device-type-breakpoints';
import { fontFamily } from '../../constants/font-family';
import { fontSize } from '../../constants/font-size';
import { Button, ButtonBack, Flex, Text } from '../primitives';

const ButtonContainer = styled(Flex)`
  align-items: center;
  column-gap: 88px;
  margin-top: 72px;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    flex-direction: column-reverse;
    row-gap: 24px;
  }
`;

const ChildrenContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ContinueButton = styled(Button)``;

ContinueButton.defaultProps = {
  children: 'Continue'
};

const StepWrapperContainer = styled(ChildrenContainer)`
  padding: 0 24px;
`;

const SubTitle = styled(Text)`
  font-size: ${fontSize.xs};
  letter-spacing: 0.54px;
  margin-top: 24px;
  max-width: 60%;
  text-align: center;
`;

const TextItemsContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 52px;
`;

const Title = styled(Text)`
  font-family: ${fontFamily.desolator};
  font-size: ${fontSize.l};
  text-align: center;
`;

interface StepWrapperProps {
  children: ReactNode;
  nextButtonDisabled?: boolean;
  onBackButton?: () => void;
  onNextButton?: () => void;
  showNavigationStepButtons?: boolean;
  subTitle?: string;
  title: string;
}

const StepWrapper = (props: StepWrapperProps) => {
  const {
    children,
    nextButtonDisabled,
    onBackButton,
    onNextButton,
    showNavigationStepButtons,
    subTitle,
    title
  } = props;

  return (
    <StepWrapperContainer>
      <TextItemsContainer>
        <Title>{title}</Title>
        {subTitle && <SubTitle> {subTitle}</SubTitle>}
      </TextItemsContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
      {showNavigationStepButtons && (
        <ButtonContainer >
          <ButtonBack onClick={onBackButton} />
          <ContinueButton disabled={nextButtonDisabled} onClick={onNextButton} />
        </ButtonContainer>
      )}
    </StepWrapperContainer>
  );
};

export { StepWrapper };
