import { IconProps, IconSvg } from './icon-props';

interface IconArrowDownUpOwnProps {
  arrowDirectionUp?: boolean;
}

type IconArrowDownProps = IconArrowDownUpOwnProps & IconProps;

export const IconArrowDown = (props: IconArrowDownProps) => {
  const { arrowDirectionUp, ...restProps } = props;

  const transformDegree = arrowDirectionUp ? 180 : 0;

  return (
    <IconSvg
      height="24px"
      viewBox="0 0 30 30"
      width="30px"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${transformDegree})`}
      {...restProps}
    >
      <polyline
        fill="none"
        height="12px"
        points="26.8 9.18 15.33 20.65 3.87 9.18"
        stroke="#48185c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
        width="24px"
      />
    </IconSvg>
  );
};
