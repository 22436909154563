import styled from 'styled-components';
import { IconTick } from '../../assets/icons';
import { Flex, Text } from '../primitives';

const CheckBoxInputContainer = styled(Flex)`
  align-items: center;
  column-gap: 12px;
`;

interface CheckBoxInputProps {
  checked: boolean;
  label: string | JSX.Element;
  onChange: (newValue: boolean) => void;
}

const CheckBoxInput = (props: CheckBoxInputProps) => {
  const { checked, label, onChange } = props;

  const handleOnInputChange = (newValue: boolean) => onChange(newValue);

  return (
    <CheckBoxInputContainer>
      <IconTick checked={checked} onInputChange={handleOnInputChange} tabIndex={0} />
      <Text>{label}</Text>
    </CheckBoxInputContainer>
  );
};

export { CheckBoxInput };
