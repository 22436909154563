import styled from 'styled-components';
import {
  borders,
  BordersProps,
  color,
  ColorProps,
  flexGrow,
  FlexGrowProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps
} from 'styled-system';
import { cursor, CursorProps } from './custom-css-props';

type BoxProps = BordersProps &
  ColorProps &
  CursorProps &
  FlexGrowProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SpaceProps;

const Box = styled.div<BoxProps>`
  ${borders}
  ${color}
  ${cursor}
  ${flexGrow}
  ${layout}
  ${position}
  ${shadow}
  ${space}
`;

Box.displayName = 'Box';

const Width80Box = styled(Box)`
  margin: 0 auto;
  max-width: 80%;
`;

Width80Box.displayName = 'Width80Box';

export { Box, Width80Box };
