import styled from 'styled-components';
import { position, PositionProps } from 'styled-system';
import { fontSize } from '../../constants/font-size';
import { Text } from './text';

interface TextContainerOwnProps {
  variant: 'large' | 'small';
}

type TextContainerProps = PositionProps & TextContainerOwnProps;

const TextContainer = styled(Text)<TextContainerProps>`
  align-items: center;
  border: 2px solid;
  display: flex;
  font-size: ${fontSize.s};
  height: 60px;
  justify-content: center;
  max-width: ${({ variant }) => (variant === 'large' ? 488 : 460)}px;
  padding: 0 28px;
  width: 100%;
  :focus {
    outline: 1px solid;
  }
  ${position};
`;

TextContainer.displayName = 'Text Container';

export { TextContainer };
