import styled from 'styled-components';
import {
  color as colorProp,
  ColorProps,
  display,
  DisplayProps,
  flex,
  FlexProps,
  layout,
  LayoutProps,
  opacity,
  OpacityProps,
  space,
  SpaceProps,
  TextStyleProps,
  typography,
  TypographyProps
} from 'styled-system';
import { color } from '../../constants/color';
import { fontFamily } from '../../constants/font-family';
import { fontSize } from '../../constants/font-size';
import { cursor, CursorProps } from './custom-css-props';

type TextProps = ColorProps &
  CursorProps &
  DisplayProps &
  FlexProps &
  LayoutProps &
  OpacityProps &
  SpaceProps &
  TextStyleProps &
  TypographyProps;

const Text = styled.div<TextProps>`
  ${colorProp};
  ${cursor};
  ${display};
  ${flex};
  ${layout}
  ${opacity};
  ${space};
  ${typography};
`;

Text.displayName = 'Text';

Text.defaultProps = {
  color: color.purpleLoop,
  fontFamily: fontFamily.lato,
  fontSize: fontSize.xxs,
  fontWeight: 300
};

export { Text };
