import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { fontSize } from '../../constants/font-size';
import { Input, Text, TextContainer } from '../primitives';

const DollarText = styled(Text)`
  font-size: ${fontSize.s};
  margin-right: 2px;
`;

DollarText.defaultProps = {
  children: '$'
};

interface NumberInputProps {
  numberType: 'amount' | 'postcode';
  onChange: (newValue: number) => void;
  placeholder: string;
  value: number;
  variant: 'large' | 'small';
  autofocus?: boolean;
}

const NumberInput = (props: NumberInputProps) => {
  const { numberType, onChange, placeholder, value, variant, autofocus } = props;

  const isNumberTypeAmount = numberType === 'amount';

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedValue = event.currentTarget.value;

    if (isNumberTypeAmount) {
      updatedValue = updatedValue.replace(/,/g, '');
    }

    const valueConvertedToNumber = parseInt(updatedValue, 10);

    if (Number.isNaN(valueConvertedToNumber)) {
      onChange(0);
    } else {
      onChange(valueConvertedToNumber);
    }
  };

  let inputValue = value || '';

  if (value && isNumberTypeAmount) {
    inputValue = value.toLocaleString();
  }

  return (
    <TextContainer variant={variant}>
      {isNumberTypeAmount && <DollarText />}
      <Input
        onChange={onInputChange}
        placeholder={placeholder}
        value={inputValue}
        autoFocus={autofocus}
      />
    </TextContainer>
  );
};

export { NumberInput };
