import styled from 'styled-components';
import { IconBuild, IconBuy, IconRefinance } from '../../assets/icons';
import { deviceTypeBreakpoint } from '../../constants/device-type-breakpoints';
import { FlowStepEnum } from '../../enums/flow-step';
import { FlowStepModel } from '../../types/flow-step';
import { IconWithLabelModel } from '../../types/icon-with-label';
import { IconWithLabel } from '../icon-with-label';
import { Flex } from '../primitives';
import { StepWrapper } from '../wrappers/step-wrapper';

const IconWithLabelContainer = styled(Flex)`
  align-items: center;
  column-gap: 56px;
  flex-wrap: wrap;
  row-gap: 24px;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    flex-direction: column;
  }
`;

const { Build, Buy, Refinance } = FlowStepEnum;

interface StepGetStartedProps {
  onNextButton: (newFlowStep: FlowStepModel) => void;
}

const StepGetStarted = (props: StepGetStartedProps) => {
  const { onNextButton } = props;

  const IconWithLabelElements: IconWithLabelModel[] = [
    {
      Icon: IconBuy,
      label: Buy,
      marginTopVariant: 'large',
      onClick: () => onNextButton(Buy)
    },
    {
      Icon: IconRefinance,
      label: Refinance,
      marginTopVariant: 'small',
      onClick: () => onNextButton(Refinance)
    },
    {
      Icon: IconBuild,
      label: Build,
      marginTopVariant: 'small',
      onClick: () => onNextButton(Build)
    }
  ];

  return (
    <StepWrapper title="How can we help you?">
      <IconWithLabelContainer>
        {IconWithLabelElements.map((elementProps) => (
          <IconWithLabel key={elementProps.label} {...elementProps} />
        ))}
      </IconWithLabelContainer>
    </StepWrapper>
  );
};

export { StepGetStarted };
