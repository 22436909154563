import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { color } from '../../constants/color';
import { fontSize } from '../../constants/font-size';

const Button = styled.button<SpaceProps>`
  background: ${color.purpleLoop};
  border: none;
  color: ${color.whiteLoop};
  font-size: ${fontSize.xs};
  height: 48px;
  width: 200px;

  &:disabled {
    background: ${color.greyLoop};
    color: ${color.purpleLoop};
    cursor: not-allowed;
  }

  &:hover {
    background: ${color.whiteLoop};
    border: 2px solid;
    color: ${color.purpleLoop};

    &:disabled {
      background: ${color.greyLoop};
      color: ${color.purpleLoop};
    }
  }

  ${space}
`;

Button.defaultProps = {};

Button.displayName = 'Button';

const ButtonBack = styled(Button)`
  background: ${color.greyLoop};
  color: ${color.purpleLoop};
`;

ButtonBack.displayName = 'ButtonBack';

ButtonBack.defaultProps = {
  children: 'Back'
};

const ButtonTransparent = styled(Button)`
  background: transparent;
  border: 2px solid;
  color: ${color.purpleLoop};

  &:hover {
    background: ${color.purpleLoop};
    border: none;
    color: ${color.whiteLoop};
  }
`;

ButtonTransparent.displayName = 'ButtonTransparent';

export { Button, ButtonBack, ButtonTransparent };
